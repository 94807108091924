import {PROCESSING_CONTROL_L2} from "./const";

/**
 * Data object representing the accounts list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class AccountsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} name - List item's name.
	 * @param {string} idAccount - User friendly account ID. This value is not used by the app to identify the account.
	 * @param {boolean} blockDevices - Flag that specifies if devices are blocked for this account.
	 * @param {boolean} autoArchiveStudies - 
	 * @param {boolean} deleteRawFiles - 
	 * @param {boolean} systemAutoArchive - 
	 */
	constructor(id, name, idAccount, blockDevices, autoArchiveStudies, deleteRawFiles, systemAutoArchive) {
		this.id = id;
		this.name = name;
		this.idAccount = idAccount;
		this.blockDevices = blockDevices;
		this.autoArchiveStudies = autoArchiveStudies;
		this.deleteRawFiles = deleteRawFiles;
		this.systemAutoArchive = systemAutoArchive;
	}
}

/**
 * Data object representing the accounts item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class AccountsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [name=''] - Item's name.
	 * @param {string} [idAccount] - Account ID string value.
	 * @param {boolean} [blockDevices] - Defines if account can block devices.
	 * @param {boolean} [autoArchiveStudies] - Auto archive studies.
	 * @param {boolean} [systemAutoArchive=true] -
	 * @param {string} [processingControl] - Processing Control.
	 * @param {Object} [defaultUser] - Accounts default user.
	 * @param {string} [defaultUserId] - Accounts default user ID.
	 * @param {boolean} [deleteRawFiles] - Defines if account can delete raw files.
	 * @param {string} [parentAccountId] - Item's parent account ID value.
	 */
	constructor(
		id = null, name = '', idAccount = '', blockDevices = false, autoArchiveStudies = true, systemAutoArchive = true,  
		processingControl = PROCESSING_CONTROL_L2, defaultUser = {}, defaultUserId = '', deleteRawFiles= true, 
		parentAccountId = ''
	) {
		this.id = id;
		this.name = name;
		this.idAccount = idAccount;
		this.blockDevices = blockDevices;
		this.autoArchiveStudies = autoArchiveStudies;
		this.systemAutoArchive = systemAutoArchive;
		this.processingControl = processingControl;
		this.defaultUser = defaultUser;
		this.defaultUserId = defaultUserId;
		this.deleteRawFiles = deleteRawFiles;
		this.parentAccountId = parentAccountId;
	}
}

/**
 * Data object representing Accounts Roles list data
 */
export class AccountsItemRoleDataObject {
	/**
	 *
	 * @param {string} GUIID - ID value used for GUI manipulation.
	 * @param {string} accountId - Account ID.
	 * @param {string} id - User role data row ID
	 * @param {string} userAccountId - User account ID.
	 * @param {string} roleId - Role ID.
	 * @param {boolean} forAllAccounts - Boolean value if data is for all accounts.
	 */
	constructor(GUIID = '', accountId = '', id = '', userAccountId = '', roleId = '', forAllAccounts = false) {
		this.GUIID = GUIID;
		this.accountId = accountId;
		this.id = id;
		this.userAccountId = userAccountId;
		this.roleId = roleId;
		this.forAllAccounts = forAllAccounts;
	}
}

/**
 * Data object representing selected accounts Sub accounts data list.
 */
export class AccountsItemSubAccountsDataObject {
	/**
	 * 
	 * @param {string} id - Sub account id value.
	 * @param {string} idAccount - Sub account ID value.
	 * @param {string} name - Sub account name value.
	 */
	constructor(id = '', idAccount = '', name= '') {
		this.id = id;
		this.idAccount = idAccount;
		this.name = name;
	}
}
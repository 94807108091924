import {isset} from "Core/helpers/data";

/**
 * Check if max. selection is defined and is a proper number value
 * 
 * @param {any} [maxSelection] - Max. section raw value to check.
 * @return {boolean}
 */
export const hasMaxSelection = maxSelection => (isset(maxSelection) && isFinite(maxSelection));

/**
 * Check if max. selection has been exceeded
 * 
 * @param {number} maxSelection - Max. selection size.
 * @param {Array} [selection=[]] - Selection array.
 * @return {*|boolean}
 */
export const isMaxSelectionExceeded = (maxSelection, selection) => 
	!((hasMaxSelection(maxSelection) && selection.length <= maxSelection) || !hasMaxSelection(maxSelection));

import {ioJsonAction} from "Core/store/actions/io";

/**
 * Fetch study status available for the current user
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): *}
 */
export const fetchStudyStatusesAction = abortCallback => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'user-permissions/fetch-allowed-study-statuses',
	)(dispatch);
}
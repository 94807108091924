import {get, omit} from "lodash";
import {getBoolean, getInteger, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'users';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@users/reset',
	SET_LIST_DATA: '@users/set_list_data',
	CLEAR_LIST_DATA: '@users/clear_list_data',
	SET_ITEM: '@users/set_item',
	CLEAR_ITEM: '@users/clear_item',
	SET_ACCOUNT_ROLE_MAP: '@users/set_account_role_map',
	CLEAR_ACCOUNT_ROLE_MAP: '@users/clear_account_role_map',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} usersListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	
	setUsersListData: usersListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: usersListData
	}),
	clearUsersListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	
	/**
	 * @param {UsersItemDataObject} usersItem
	 * @return {{type: string, item: UsersItemDataObject}}
	 */
	setUsersItem: usersItem => ({
		type: REDUCER_TYPES.SET_ITEM, 
		item: usersItem
	}),
	clearUsersItem: () => ({type: REDUCER_TYPES.CLEAR_ITEM}),

	setAccountRoleMap: map => ({type: REDUCER_TYPES.SET_ACCOUNT_ROLE_MAP, map}),
	clearAccountRoleMap: () => ({type: REDUCER_TYPES.CLEAR_ACCOUNT_ROLE_MAP}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {UsersItemDataObject}
	 */
	item: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {UserRoleItemDataObject[]}
	 */
	accountRoleMap: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		case REDUCER_TYPES.SET_ITEM: return {...state, item: action.item};
		case REDUCER_TYPES.CLEAR_ITEM: return {...state, item: initialState.item};
		
		case REDUCER_TYPES.SET_ACCOUNT_ROLE_MAP: return {...state, accountRoleMap: action.map};
		case REDUCER_TYPES.CLEAR_ACCOUNT_ROLE_MAP: return {...state, accountRoleMap: initialState.accountRoleMap};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getUsersList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getUsersListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getUsersListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getUsersListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),

	getUsersItem: state => get(state, [reducerStoreKey, 'item']),
	
	getUserInfo: state => omit(get(state, [reducerStoreKey, 'accountRoleMap']), ),
	getAccountRoleMap: state => get(state, [reducerStoreKey, 'accountRoleMap']),
	getAllowedAccounts: state => {
		const accountRoleMap = get(state, [reducerStoreKey, 'accountRoleMap']);
		return (accountRoleMap && Array.isArray(accountRoleMap) ? accountRoleMap.map(i => i.account) : []);
	}
};

export default reducer;
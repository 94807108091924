import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {get, omit} from "lodash";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLES} from "../../display/Button";
import {afterDialogClose, openDialog} from "../../../helpers/dialog";
import InsertValueDialog from "../../dialogs/InsertValueDialog";
import {waitingFunctionCallback} from "../../../helpers/function";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "./const";
import {getString} from "../../../helpers/data";
import {getTranslation, i18nEffect, translate} from "../../../i18n";

/**
 * Insert value button component that will open either an insert dialog or insert dropdown
 * @note This component is rendered using React portal into a parent component that is usually an input component.
 */
function InsertValueButton({buttonProps, parentSelector, insertType, insertTypeOptions, onInsert, onDialogClose}) {
	// Define component's translation path.
	const translationPath = 'InsertValueButton';
	
	// Use 'i18nEffect' function in React 'useEffect' function to update (re-render) component when translation changes.
	// TODO: Test if this works!!!
	const i18nEffectHook = i18nEffect.bind(null, useState(getTranslation())[1]);
	useEffect(() => { i18nEffectHook(); }, [i18nEffectHook]);
	
	const renderButton = () => (
		<Button
			title={getString(buttonProps, 'title', translate('button_title', translationPath))}
			className={`insert-value-button-component ${getString(buttonProps, 'className')}`}
			onClick={() => {
				switch (insertType) {
					case INSERT_VALUE_BUTTON_TYPE.DIALOG:
						const dialogGUIID = openDialog(
							'',
							InsertValueDialog,
							{
								onInsert,
								...get(insertTypeOptions, 'dialogProps', {})
							},
							get(insertTypeOptions, 'dialogOptions', {
								className: 'bordered-title',
								closeOnEscape: true,
								closeOnClickOutside: false,
								hideCloseBtn: false,
								maxWidth: 800
							})
						);
						// Trigger 'onDialogClose' event
						afterDialogClose(dialogGUIID).then(() => {
							if (typeof onDialogClose === 'function') onDialogClose(dialogGUIID);
						});
						break;

					case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
						// TODO: dropdown insert value
						break;
						
					// no default
				}

				if (buttonProps?.onClick) buttonProps.onClick();
			}}
			icon="tags"
			displayType={BUTTON_DISPLAY_TYPE.NONE}
			{...omit(buttonProps, ['onClick', 'className', 'title'])}
		/>
	);
	
	const [parentElement, setParentElement] = useState();
	let parentIntervalId;
	
	useEffect(() => {
		// Clear parent search interval when component unmounts
		return () => clearInterval(parentIntervalId);
	});

	/**
	 * Render button without using portal if parent selector is not defined ---------------------------------------------
	 */
	if (!parentSelector) return renderButton();

	
	/**
	 * Render button using portal ---------------------------------------------------------------------------------------
	 */
	// Wait for parent element to become available
	// @note There is not timeout set so it will wait forever or until component unmounts.
	waitingFunctionCallback(
		i => parentIntervalId = i, 
		() => !!document.querySelector(parentSelector), 
		1, 
		Infinity
	).then(() => 
		setParentElement(document.querySelector(parentSelector))
	);

	// Do not render component if parent component does not exist
	if (!parentElement) return null;

	// Render component inside a parent element using React portal
	return ReactDOM.createPortal(renderButton(), parentElement);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
InsertValueButton.propTypes = {
	// Button component props that will override default ones used by this component
	buttonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,
		// Button title
		title: PropTypes.string,

		// Events
		onClick: PropTypes.func,
	}),
	
	// Selector of the parent component where insert button component will be rendered
	parentSelector: PropTypes.string,
	// Insert type
	insertType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES).isRequired,
	// Insert type options
	// @note Options depend on 'insertType'.
	insertTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]).isRequired,
	
	// Event triggered when insert component (dialog, dropdown, ...) dispatches its 'onInsert' event.
	// @arguments {string} - value to insert
	onInsert: PropTypes.func,
	// Event triggered when insert dialog has been closed
	// @note This si only relevant if 'insertType' is INSERT_VALUE_BUTTON_TYPE_DIALOG.
	// @arguments {string} - dialogs GUI ID
	onDialogClose: PropTypes.func,
};

export default InsertValueButton;
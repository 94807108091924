import "./index.css";

import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import * as globalActions from "Core/store/actions/global";
import * as pageActions from "Core/store/actions/page";
import {cloneDeep, orderBy, forOwn, get} from "lodash";
import {icon_font_close_symbol, icon_font_error_symbol} from "Config/app";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import {getBool, getObject, isset} from "Core/helpers/data";
import {calculateCurrentBreakpointName} from "Core/helpers/dom";
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import React from "react";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import QuestionnaireResultDialog from "../../dialogs/QuestionnaireResultDialog";
import {hideLoading, showLoading} from "Core/helpers/loading";
import ACL from "../../../../../../acl";
import {isAfter, subYears} from "date-fns";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	// Current breakpoint is added, even though it is not directly used in the component, so that the component will be 
	// updated every time the current breakpoint changes (on resize).
	// @note Tab title 'iconSmall' and 'labelSmall' props depend on this. Please don't remove this.
	currentBreakpoint: calculateCurrentBreakpointName(),
	shouldShowOsaRiskWarning: selectors[reducerStoreKey].getOsaRiskWarningVisible(state),
	questionnaire: selectors[reducerStoreKey].getQuestionnaire(state),
	savedData: selectors[reducerStoreKey].getAllSavedTabData(state),
});

class QuestionnairePopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.QuestionnairePopup`,
			domPrefix: 'questionnaire-popup',
			hideSingleTab: true,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: [
				...Object.getOwnPropertyNames(globalActions).filter(i => i !== '__esModule'),
				...Object.getOwnPropertyNames(pageActions).filter(i => i !== '__esModule'),
				...Object.getOwnPropertyNames(actions).filter(i => i !== '__esModule'),
				'popupCloseAction', 'onClose', 'onGlobalAction', 'onTabAction',
			]
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Tab methods
		this.enableCurrentTab = this.enableCurrentTab.bind(this);
		this.updateTabActionsVisibility = this.updateTabActionsVisibility.bind(this);

		// Action methods
		this.prev = this.prev.bind(this);
		this.next = this.next.bind(this);
		this.save = this.save.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// If current tab changes
		if (this.state.currentTabId !== prevState.currentTabId) {
			this.enableCurrentTab().then();
			this.updateTabActionsVisibility().then();
		}
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		// Clear questionnaire data from Redux store
		const {clearQuestionnaireAction} = this.props;
		clearQuestionnaireAction();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update tabs disabled flag based on the current tab ID since only the currently opened tab should be enabled
	 * @return {Promise<*[]>}
	 */
	enableCurrentTab() {
		return this.enableTab(this.state.currentTabId)
			.then(() => this.disableTabs(
				this.getTabs()
					.filter(tab => (!tab.disabled && tab.id !== this.state.currentTabId))
					.map(tab => tab.id)
			));
	}

	/**
	 * Update tab actions visibility based on the current tab's index
	 * @return {Promise<*[]>}
	 */
	updateTabActionsVisibility() {
		// Update actions visibility based on the current tab's index
		const currentTabIndex = this.getCurrentTabIndex();
		const tabs = this.getTabs();
		let actionsToHide = [];
		let actionsToShow = [];

		// Calculate prev action visibility
		if (currentTabIndex < 1) actionsToHide.push('prev');
		else actionsToShow.push('prev');

		// Calculate next action visibility
		if (currentTabIndex >= (tabs.length - 1)) actionsToHide.push('next');
		else actionsToShow.push('next');

		// Calculate create/update action visibility
		if (currentTabIndex === (tabs.length - 1)) {
			if (this.actionExists('create')) actionsToShow.push('create');
			else if (this.actionExists('update')) actionsToShow.push('update');
		} else {
			if (this.actionExists('create')) actionsToHide.push('create');
			else if (this.actionExists('update')) actionsToHide.push('update');
		}

		// Hide and show actions
		return this.hideActions(actionsToHide)
			.then(() => this.showActions(actionsToShow));
	}
	
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({isNew}) {
		// Remove actions before deciding which ones to display
		await this.removeActions(['create', 'update']);

		// Add save related global actions
		await this.addAction(new PopupActionDataObject({
			id: isNew ? 'create' : 'update',
			action: this.save,
			buttonProps: {
				label: this.getTranslationPath(`${isNew ? 'create_action' : 'update_action'}`),
				icon: 'paper-plane',
				displayStyle: BUTTON_STYLE.ACTION
			},
			visible: false,
			disabled: ACL.isGuest(ACL),
			ordinal: 3
		}));
	}

	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const isNew = getBool(this.props, 'isNew');

		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
			new PopupActionDataObject({
				id: 'prev',
				action: this.prev,
				buttonProps: {
					label: <Label
						icon='chevron-left'
						iconPosition={LABEL_ICON_POSITION.LEFT} content={this.t('Previous', 'general')}
					/>,
				},
				visible: false,
				ordinal: 1,
			}),
			new PopupActionDataObject({
				id: 'next',
				action: this.next,
				buttonProps: {
					label: <Label
						icon='chevron-right'
						iconPosition={LABEL_ICON_POSITION.RIGHT} content={this.t('Next', 'general')}
					/>,
				},
				visible: false,
				ordinal: 2,
			})
		];
		await this.setActions(actions);
		await this.updateTabActionsVisibility();
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({isNew});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'DemographicInfoTab',
				label: this.getTranslationPath('DemographicInfoTab.tab_label'),
				labelSmall: this.getTranslationPath('DemographicInfoTab.tab_label_small'),
			}),
			new PopupTabDataObject({
				id: 'DiseasesAndConditionsTab',
				label: this.getTranslationPath('DiseasesAndConditionsTab.tab_label'),
				labelSmall: this.getTranslationPath('DiseasesAndConditionsTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'EpworthTab',
				label: this.getTranslationPath('EpworthTab.tab_label'),
				labelSmall: this.getTranslationPath('EpworthTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'SleepProblemsTab',
				label: this.getTranslationPath('SleepProblemsTab.tab_label'),
				labelSmall: this.getTranslationPath('SleepProblemsTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'HabitsTab',
				label: this.getTranslationPath('HabitsTab.tab_label'),
				labelSmall: this.getTranslationPath('HabitsTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'PatientHealthTab',
				label: this.getTranslationPath('PatientHealthTab.tab_label'),
				labelSmall: this.getTranslationPath('PatientHealthTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'GeneralizedAnxietyDisordersTab',
				label: this.getTranslationPath('GeneralizedAnxietyDisordersTab.tab_label'),
				labelSmall: this.getTranslationPath('GeneralizedAnxietyDisordersTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
			new PopupTabDataObject({
				id: 'MedicationsTab',
				label: this.getTranslationPath('MedicationsTab.tab_label'),
				labelSmall: this.getTranslationPath('MedicationsTab.tab_label_small'),
				disabled: true,
				preload: false,
			}),
		]).then(this.importTabComponents);
		await this.enableCurrentTab();
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({isNew});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Go to previous tab
	 * @return {Promise<void>} 
	 */
	prev() {
		const loading = this.showLoading();
		
		const tabRef = this.getTabRef(this.state.currentTabId);
		return tabRef.validateTab()
			.then(valid => (
				valid ?
					(
						tabRef.hasOwnProperty('beforePrev') ?
							tabRef.beforePrev().then(stop => (!stop ? this.goToPreviousTab() : Promise.resolve())) :
							this.goToPreviousTab()
					)
					:
					Promise.resolve()
			))
			.then(() => this.hideLoading(loading));
	}

	/**
	 * Go to next tab
	 */
	next() {
		const loading = this.showLoading();
		
		const tabRef = this.getTabRef(this.state.currentTabId);
		return tabRef.validateTab()
			.then(valid => (
				valid ?
					(
						tabRef.hasOwnProperty('beforeNext') ?
							tabRef.beforeNext().then(stop => (!stop ? this.goToNextTab() : Promise.resolve())) :
							this.goToNextTab()
					)
					:
					Promise.resolve()
			))
			.then(() => this.hideLoading(loading));
	}
	
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {
			questionnaire, savedData, studyId, accountId, redirectToBase, openDialogAction, createQuestionnaireAction, 
			updateQuestionnaireAction,
		} = this.props;
		
		const loading = showLoading('#questionnaire-popup');
		return this.getTabRef('DemographicInfoTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {QuestionnaireDataObject} */
					let questionnaireToSave = cloneDeep(questionnaire);
					const demographicInfoData = getObject(allTabsData, 'DemographicInfoTab');
					const currentTabData = getObject(this.getTabData(this.getCurrentTab().id));
					forOwn(savedData, tabData => {
						questionnaireToSave = {...questionnaireToSave, ...demographicInfoData, ...tabData, ...currentTabData};
					});

					// Close the popup since result dialog will take over
					redirectToBase();
					hideLoading(loading);

					// Open result dialog
					// @note Timeout is added to allow the URL to change to before opening the dialog since switching to the 
					// pages base URL will close all popups and dialogs.
					setTimeout(() => {
						openDialogAction('questionnaire-result-dialog', QuestionnaireResultDialog, {}, {
							id: 'questionnaire-result-dialog',
							closeOnEscape: false,
							closeOnClickOutside: false,
							hideCloseBtn: false,
							maxWidth: 768
						});
					});
					
					// Create questionnaire
					if (actionId === 'create') {
						return this.executeAbortableAction(createQuestionnaireAction,studyId,accountId,questionnaireToSave);
					}
					// Update item
					else if (actionId === 'update') {
						return this.executeAbortableAction(updateQuestionnaireAction, questionnaireToSave);
					} else {
						console.error(`Invalid questionnaire popup save method: ${actionId}`);
					}
				} else {
					hideLoading(loading);
				}
				return Promise.resolve();
			});
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render global action buttons
	 * @note Global actions are actions that apply to the whole popup.
	 *
	 * @return {JSX.Element|null}
	 */
	renderGlobalActionButtons() {
		const {shouldShowOsaRiskWarning} = this.props;
		const actions = this.getVisibleActionsGlobal();
		const youngerThan18 = isAfter(get(this.getTabData('DemographicInfoTab'), 'dateOfBirth'),subYears(new Date(), 18));
		
		return (
			actions.length > 0 ?
				<div className="popup-global-action-buttons">
					<div className="popup-global-legend">
						<Label
							element="span"
							elementProps={{className: (shouldShowOsaRiskWarning ? 'warning-color' : '')}}
							icon={shouldShowOsaRiskWarning ? icon_font_error_symbol : null}
							iconPosition={LABEL_ICON_POSITION.LEFT}
							content={
								`<sup>1</sup>&nbsp;` +
								this.t('osa_risk_legend', `${pageConfig.translationPath}.QuestionnairePopup`)
							}
							supportHtml={true}
							tooltip={
								shouldShowOsaRiskWarning ?
									this.t('osa_risk_warning', `${pageConfig.translationPath}.QuestionnairePopup`)
									: ''
							}
						/>
						{youngerThan18 ?
							<div>
								<Label
									element="span"
									elementProps={{className: 'warning-color age-warning'}}
									icon={icon_font_error_symbol}
									iconPosition={LABEL_ICON_POSITION.LEFT}
									content={
										this.t('younger_then_18_warning_tooltip', `${pageConfig.translationPath}.QuestionnairePopup`) + ' ' +
										this.t('younger_then_18_warning', `${pageConfig.translationPath}.QuestionnairePopup`)
									}
								/>
							</div>
							: null
						}
					</div>
					
					{actions.map((action, index) =>
						<Button
							key={index}
							{...action.buttonProps}
							label={
								get(action.buttonProps, 'label') ?
									this.translatePath(get(action.buttonProps, 'label')) :
									''
							}
							disabled={action.disabled}
							onClick={e => this.handleGlobalActionButtonClick(e, action)}
						/>
					)}
				</div>
				: null
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
QuestionnairePopup.propTypes = {
	// Flag that specifies if this popup is for creating a new item
	// @note This prop can be dynamically changed.
	isNew: PropTypes.bool,
	// ID of the study
	studyId: PropTypes.string,
	// ID of the account
	accountId: PropTypes.string,
	// Function that will redirect to the base URL of the page
	redirectToBase: PropTypes.func, // Arguments: no arguments 

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(QuestionnairePopup);
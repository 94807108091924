import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {DevicesItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DevicesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	result = new DevicesItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceSerialNumber'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {DevicesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		deviceSerialNumber: result.deviceSerialNumber,
	};

	return result;
}
import "./default.style.css";
import "./card.style.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import DataValueValidation from "Core/validation";
import Label from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import PropTypes from "prop-types";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {omit} from "lodash";
import {getString, isset} from "Core/helpers/data";

class AppPasswordResetSecurityQuestion extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				answer: '',
			},
		}, {
			translationPath: 'PasswordResetSecurityQuestion',
			disableLoad: true
		});
		
		// Refs
		this.answerInputRef = null;

		// DOM methods
		this.focus = this.focus.bind(this);

		// Action methods
		this.answer = this.answer.bind(this);
	}


	// DOM methods
	/**
	 * Focus answer input field
	 */
	focus() {
		if (this.answerInputRef) this.answerInputRef.focus();
	}
	
	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('answer', 'required');
		
		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Answer the security question
	 */
	answer() {
		const {action} = this.props;
		const answer = this.getValue('answer');

		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => {
				if (this.validate()) return action(answer);
			});
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, showAppName, appName, showFormTitle, formTitle, showInputLabel, inputLabelPosition,
			question, buttonProps, renderToTop, renderToBottom,
		} = this.props;
		const error = this.getErrorMessage();
		
		return (
			<div id={this.getDomId()} className={`password-reset-security-question-component ${styleName}-style ${className}`}>
				{renderToTop ? renderToTop : null}
				
				{/* Header --------------------------------------------------------------------------------------------- */}
				<div className="password-reset-security-question-header">
					{
						showAppName ?
							<div className="app-name">
								{isset(appName) ? appName : this.t('title', 'App')}
							</div>
							: null
					}

					{
						showFormTitle ?
							<>
								<div className="form-title">
									{isset(formTitle) ? formTitle : this.t('form_title')}
									<Label
										element="div"
										elementProps={{className: 'form-desc'}}
										content={this.t('form_description')}
										supportHtml={true}
									/>
								</div>
							</>
							: null
					}
				</div>


				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-security-question-content">
					<FormWrapper>
						<FormField 
							label={this.t('question_label')} 
							labelPosition={inputLabelPosition ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.STACKED}
						>
							<Label element="div" elementProps={{className: 'question notice'}} content={question} />
						</FormField>
						<FormField
							label={this.t('answer_label')}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('answer')}
							required={true}
						>
							<TextInput
								name="answer"
								value={this.getValue('answer')}
								placeholder={this.t('answer_placeholder')}
								onChange={this.handleInputChange}
								onEnterKey={this.answer}
								ref={node => { this.answerInputRef = node?.inputRef; }}
								autoComplete="new-password"
							/>
						</FormField>
					</FormWrapper>
				</div>

				
				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-security-question-actions">
					<Button
						className={`submit-btn ${getString(buttonProps, 'className')}`}
						label={this.t('action_btn')}
						onClick={this.answer}
						{...AppPasswordResetSecurityQuestion.defaultProps.buttonProps}
						{...omit(buttonProps, ['className', 'onClick'])}
					/>
				</div>

				{renderToBottom ? renderToBottom : null}

				{error ? <p className={`component-error error-color`}>{error}</p> : null}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppPasswordResetSecurityQuestion.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component wrapper element ID class attribute
	id: PropTypes.string,
	// Component wrapper element class attribute
	className: PropTypes.string,

	// Flag that specifies if app name will be rendered
	showAppName: PropTypes.bool,
	// App name to render instead of default app name
	// @note If undefined, default app name will be rendered.
	appName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if form title will be rendered
	showFormTitle: PropTypes.bool,
	// Form title to render instead of default form title
	// @note If undefined, default form title will be rendered.
	formTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if form input fields should have labels
	showInputLabel: PropTypes.bool,
	// Input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	inputLabelPosition: PropTypes.string,
	
	// Security question
	question: PropTypes.string,
	// Main button component props
	buttonProps: PropTypes.object,
	// Main submit function
	action: PropTypes.func, // Arguments: answer

	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
};

/**
 * Define component default values for own props
 */
AppPasswordResetSecurityQuestion.defaultProps = {
	styleName: 'default',
	className: '',
	showAppName: false,
	appName: undefined,
	showFormTitle: true,
	formTitle: undefined,
	showInputLabel: true,
	inputLabelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	question: '',
	buttonProps: {
		icon: 'send',
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},

	renderToTop: null,
	renderToBottom: null,
};

export default connect(
	null, getGlobalActions(), null, {forwardRef: true}
)(AppPasswordResetSecurityQuestion);
import styles from "./index.module.css";
import logo from "Images/logo_for_content.png";

// Import locale policies
import CookiePolicyEnUs from "../../../dataProtection/policies/CookiePolicy/en-US";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {withRouter} from "react-router-dom";
import Label from "Core/components/display/Label";
import headerStyles from "Layout/elements/PublicHeader/index.module.css";
import TeamViewerSupport from "../../../components/display/TeamViewerSupport";
import * as pageConfig from "./config";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocaleCode: selectors.i18n.getLocaleCode(state)
});

class CookiesInfoPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'public',
			domPrefix: 'cookies-info-page',
			renderTitle: false,
			translationPath: pageConfig.translationPath,
		}, 'title');
	}

	render() {
		const {appLocaleCode} = this.props;

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<TeamViewerSupport className={styles['teamViewer']} itemWrapperClassName={styles['itemWrapper']} />
				
				<div className={`page-component-content card`}>
					<div className={`page-component-content-inner card-content`}>
						<div className={`app-name ${headerStyles['hideMobile']}`}>
							<img src={logo} alt={this.t('title', 'App')} />
						</div>
						<Label
							element="h1"
							elementProps={{className: 'title'}}
							content={this.t('title', this.titlePathPrefix)}
						/>
						{
							appLocaleCode === 'en-US' ? <CookiePolicyEnUs className={styles['policy']} /> :
								null
						}
					</div>
				</div>
			</div>
		), '', undefined,{
			showHeader: true,
			headerProps: {
				showTeamViewerLinks: false,
				showAppLogo: true,
			},
			showFooter: true,
			footerProps: {
				justifyContent: 'center',
			}
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, null)(CookiesInfoPage));
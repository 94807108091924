import {ioJsonFetchItemAction, ioJsonManualAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {isSuccessful} from "Core/helpers/io";
import {get} from "lodash";
import * as dataMap from "./dataMap";
import {addErrorMessageAction} from "Core/components/global/Message";
import {messages_default_auto_hide_after} from "Config/app";
import {StandardJsonResponseError} from "Core/errors";
import {PATIENT_CHANGED_ERROR_CODE, PATIENT_EXISTS_ERROR_CODE} from "./const";

/**
 * Fetch patient/study info data
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchStudyInfoAction = (abortCallback, studyId) => dispatch => {
	const loading = showLoading('#patient-study-info-popup');
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-by-id',
		studyId,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				hideLoading(loading);
				return {
					...responseData,
					data: dataMap.input(get(responseData, 'data'))
				};
			}
			hideLoading(loading);
			return undefined;
		});
};

/**
 * Update patient/study info data
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @param {PatientStudyInfoDataObject} studyInfo - Patient/study info data to update.
 * @param {string} [loadingSelector='#patient-study-info-popup'] - DOM selector for loading overlay.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const updateStudyInfoAction = (
	abortCallback, studyId, studyInfo, loadingSelector = '#patient-study-info-popup'
) => dispatch => {
	const loading = showLoading(loadingSelector);
	return ioJsonManualAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/update-by-id',
		{
			id: studyId,
			data: dataMap.output(studyInfo, studyId),
		}
	)(dispatch)
		// Get mapped data from response data
		.then(res => {
			hideLoading(loading);
			return isSuccessful(res) ? {...res, data: dataMap.input(res.data)} : res;
		})
		.catch(error => {
			// Render error if it is not 'AbortError'
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError') {
				// Handle custom patient related response error codes
				if (
					error instanceof StandardJsonResponseError && 
					[PATIENT_EXISTS_ERROR_CODE, PATIENT_CHANGED_ERROR_CODE].includes(error.response.errorCode)
				) {
					hideLoading(loading)
					return error.response;
				} else {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
			}
			hideLoading(loading)
		});
};
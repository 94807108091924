import {AclCheckDataObject} from "Core/acl";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = null;

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject();

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = '/ip-unblock';

/**
 * Page translation path.
 * 
 * @type {string}
 */
export const translationPath = 'AppsSection.Auth.IpUnblockPage';
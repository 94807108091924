import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {FirmwareItemDataObject} from "../dataObjects";
import {getDate} from "Core/helpers/datetime";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {get} from "lodash";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {FirmwareItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new FirmwareItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceType'),
		getString(result, 'firmwareVersion'),
		getDate(get(result, 'firmwareReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S)
	);

	return result;
}

/**
 * Get output data from input data
 * @param {FirmwareItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		// Form data properties
		firmware: result.firmwareFormData,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
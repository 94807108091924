import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {LanguageFilesListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import * as languageItemDataMap from "../../languagesCodebook/dataMap/item";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {LanguageFilesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new LanguageFilesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceType'),
		getString(result, 'languageFileVersion'),
		getDate(get(result, 'languageFileReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		languageItemDataMap.input(get(result, 'dmLanguageData')),
	);

	return result;
}

// @note There is no output function since list is read only.
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Fetch account (including sub-accounts) available for the current user
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): *}
 */
export const fetchAccountsAction = abortCallback => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-allowed-accounts',
	)(dispatch);
}
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import {CookieData} from "Core/dataProtection/objects/cookie";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {skin_default_mode, skin_mode_cookie_group, skin_use_system_mode} from "Config/app";
import {SKIN_MODE, SKIN_MODES} from "Core/const/global";
import {get, isFunction, isString} from "lodash";

/**
 * Check if operating system uses dark mode
 * @return {boolean}
 */
export const isSystemDarkMode = () => {
	return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}

/**
 * Get current app skin mode (dark, light)
 * @return {SkinMode}
 */
export const getSkin = () => {
	// Get skin mode cookie settings
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	
	// Try to get saved skin mode
	const savedSkinMode = (
		CookieConsent.isAllowed(skinModeCookie) ?
			getStorageValue('skin_mode', STORAGE_TYPE.LOCAL) :
			undefined
	);

	return (
		savedSkinMode ? 
			savedSkinMode :
			(skin_use_system_mode ? (isSystemDarkMode() ? SKIN_MODE.DARK : SKIN_MODE.LIGHT) : skin_default_mode)
	);
};

/**
 * Set app skin mode
 * @param {SkinMode} skinMode - Skin mode to set.
 */
export const setSkin = skinMode => {
	// Set skin mode as document body class
	document.body.classList.remove(SKIN_MODE.DARK, SKIN_MODE.LIGHT);
	document.body.classList.add(skinMode);
	
	// Save skin mode to cookies if allowed
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(skinModeCookie)) setStorageValue('skin_mode', skinMode, STORAGE_TYPE.LOCAL);
};

/**
 * Handle skin mode changes
 *
 * @param {function(skinMode: SkinMode)} [callback] - Callback function that will be called when skin mode changes and 
 * receive new skin mode as it's only parameter.
 * @return void
 */
export const onSkinModeChange = callback => {
	const classMutationObserver = new MutationObserver(mutations => {
		for (const mutation of mutations) {
			const oldSkinMode = 
				isString(mutation.oldValue) ? 
					get(mutation.oldValue.split(' ').filter(i => SKIN_MODES.includes(i)), '[0]', '')
					: 
					'';
			const newSkinMode = get(
				mutation.target.getAttribute('class').split(' ').filter(i => SKIN_MODES.includes(i)), 
				'[0]', 
				''
			);
			
			if (oldSkinMode && newSkinMode && oldSkinMode !== newSkinMode) {
				const skinChangeEvent = new CustomEvent('onSkinModeChange', {detail: {newSkinMode, oldSkinMode}});
				window.dispatchEvent(skinChangeEvent);
				if (isFunction(callback)) callback(newSkinMode);
			}
		}
	});
	classMutationObserver.observe(document.body, {
		attributes: true,
		attributeFilter: ['class'],
		attributeOldValue: true,
	});
}
import {cloneDeep} from "lodash";

// @note There is no input function since all data sent to the API will be specified by the user.

/**
 * Get output data from input data
 * @param {UserAccountActivationDataObject} inputData - Input data.
 * @returns {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	result = {
		id: result.id,
		securityQuestion: result.securityQuestion,
		securityAnswer: result.securityAnswer,
		password: result.password,
		repeatedPassword: result.repeatedPassword
	}
	
	return result;
}
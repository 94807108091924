import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {withRouter} from "react-router-dom";
import {getPageActions} from "Core/helpers/redux";
import * as pageConfig from "./config";
import * as actions from './actions';
import {app_login_page_router_path, icon_font_close_symbol} from "Config/app";
import {getString, isset} from "Core/helpers/data";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import {connect} from "react-redux";
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {AsyncMountError} from "Core/errors";
import {focusSelector} from "Core/helpers/dom";
import logoImg from "Images/logo_for_content.png";

class IpUnblockPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			/** @type {UnblockIpDataObject|undefined|null} */
			data: undefined,

			/**
			 * Flag that specifies if user unblock IP was successful
			 */
			successful: false,
		}, {
			layout: 'login',
			domPrefix: 'user-unblock-ip-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
		}, 'page_title');
		
		// Action methods
		this.submit = this.submit.bind(this);

		// Render methods
		this.renderCloseButton = this.renderCloseButton.bind(this);
		this.renderError = this.renderError.bind(this);
		this.renderSuccess = this.renderSuccess.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError} Throw this error or reject a promise with it in order to try running another mount
	 * method if multiple mount calls were made for this page.
	 */
	loadPageData() {
		const {fetchUnblockIpSecurityQuestionAction, match} = this.props;
		const urlId = getString(match, 'params.userId');
		
		if (urlId) {
			this.executeAbortableAction(fetchUnblockIpSecurityQuestionAction, urlId)
				.then(res => {
					if (!isset(res)) throw new AsyncMountError();
					else return res;
				})
				.then(this.setData);
		}
	}

	// DataValueValidation methods --------------------------------------------------------------------------------------
	/**
	 * Page data validation method
	 * 
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('securityAnswer', 'required');
		
		const validationErrors = dataValidation.run(dataToValidate);
		if(validationErrors) this.setValidationErrors('' ,validationErrors).then();
		
		return !validationErrors;
	}
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Submit answer for unblocking IP address
	 */
	submit() {
		const {submitUnblockIpSecurityAnswerAction, match} = this.props;
		const urlId = getString(match, 'params.userId');
		const dataToSave = this.getData();
		
		// Clear validation errors and error messages, before validating input value.
		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => {
				if (this.validate()) {
					this.executeAbortableAction(submitUnblockIpSecurityAnswerAction, urlId, dataToSave)
						.then(successful => this.setState({successful}))
						// Set focus to log in button if user unblock IP was successful
						.then(state => { if (state.successful) focusSelector('.to-login-button') });
				}
			});
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render the main close button
	 * @note Clicking the close button will redirect the user to login page.
	 *
	 * @type {Object} [buttonProps={}] - Button props override.
	 * @return {JSX.Element}
	 */
	renderCloseButton(buttonProps = {}) {
		return (
			<Button
				className="error-close-btn"
				big={true}
				icon={icon_font_close_symbol}
				label={this.t('Close', 'general')}
				displayStyle={BUTTON_STYLE.SUBTLE}
				displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
				onClick={() => { this.redirectTo(app_login_page_router_path); }}
				{...buttonProps}
			/>
		);
	}

	/**
	 * Error to render
	 * @note Only custom errors will be rendered using this method. Other errors will be displayed as standard global
	 * errors.
	 *
	 * @return {JSX.Element}
	 */
	renderError(title, description) {
		return (
			<>
				<div className="user-unblock-ip-page-header">
					<div className="app-name"><img src={logoImg} alt={this.t('title', 'App')} /></div>
					<Label
						element="p"
						elementProps={{className: 'page-notice-title error-color'}}
						content={title}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={description}
					/>
				</div>

				<div className="user-unblock-ip-page-actions">
					{this.renderCloseButton()}
				</div>
			</>
		);
	}

	/**
	 * Content to render when user unblock IP was successful
	 * @return {JSX.Element}
	 */
	renderSuccess() {
		return (
			<>
				<div className="user-unblock-ip-page-header">
					<div className="app-name"><img src={logoImg} alt={this.t('title', 'App')} /></div>
					<Label
						element="p"
						elementProps={{className: 'page-notice-title success-color'}}
						content={this.t('success')}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={this.t('success_desc')}
					/>
				</div>

				<div className="user-unblock-ip-page-actions">
					{this.renderCloseButton({
						className: 'to-login-button',
						icon: 'unlock-alt',
						label: this.t('Login', 'Login'),
						displayStyle: BUTTON_STYLE.ACTION,
						displayType: BUTTON_DISPLAY_TYPE.SOLID,
					})}
				</div>
			</>
		);
	}
	
	render() {
		/** @type {UnblockIpDataObject} */
		const data = this.getData();
		const {successful} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					successful ?
						this.renderSuccess()
					: data === null ?
						this.renderError(this.t('invalid_request_error'), this.t('invalid_request_error_desc'))
					: isset(data) ?
						<>
							<div className="user-unblock-ip-page-header">
								<div className="app-name"><img src={logoImg} alt={this.t('title', 'App')} /></div>
								<div className="form-title">
									<Label content={this.t('page_title')} />
									<Label
										element="div"
										elementProps={{className: 'form-desc'}}
										content={this.t('page_short_description')}
										supportHtml={true}
									/>
								</div>
							</div>
							
							<FormWrapper className="user-unblock-ip-page-content">
								<FormField
									label={this.t('question_label')}
									labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
								>
									<Label 
										element="div" 
										elementProps={{className: 'question notice'}} 
										content={this.getValue('securityQuestion')} 
									/>
								</FormField>
								<FormField
									required={true}
									label={this.t('answer_label')}
									labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
									errorMessages={this.getValidationErrors('securityAnswer')}
								>
									<TextInput
										name="securityAnswer"
										value={this.getValue('securityAnswer')}
										placeholder={this.t('security_answer_placeholder')}
										onChange={this.handleInputChange}
										onEnterKey={this.submit}
										inputProps={{
											autoFocus: true,
										}}
									/>
								</FormField>
							</FormWrapper>

							<div className="user-unblock-ip-page-actions">
								<Button
									big={true}
									icon="send"
									label={this.t('submit_btn')}
									displayStyle={BUTTON_STYLE.ACTION}
									onClick={this.submit}
								/>
							</div>
						</>
					: null
				}
			</div>
		), 'user-unblock-ip-page-layout', undefined, {
			showHeader: false,
			footerJustifyContent: 'center',
			showRightSection: false
		});
	}
}

export * from "./config";
export default withRouter(connect(null, getPageActions(actions))(IpUnblockPage));
/**
 * Patient/study info data object
 */
import {PATIENT_ACTION} from "../../const";

export class PatientStudyInfoDataObject {
	/**
	 * @param {string} patientRefId - Patient's reference ID (read-only).
	 * @param {string} [patientId] - Patient's ID. This is only used for update when user selects an existing patient.
	 * @param {string} patientFirstName - Patient's first name.
	 * @param {string} [patientMiddleName=''] - Patient's middle name.
	 * @param {string} patientLastName - Patient's last name.
	 * @param {Date} patientDateOfBirth - Patient's birthdate.
	 * @param {Gender} patientGender - Patient's gender.
	 * @param {string} [patientOptionalId] - Patient's ID gender.
	 * @param {PatientAction} [patientAction='UPDATE'] - Action to perform on patient if user changed patient info.
	 * @param {string} studyTypeId - Study type ID.
	 * @param {string} [studyNight1=''] - First study night memo.
	 * @param {string} [studyNight2=''] - Second study night memo.
	 * @param {string} [studyNight3=''] - Third study night memo.
	 * @param {string} [physicianId=''] - Ordering physician's ID. If empty, new ordering physician will be created using
	 * the 'newPhysician' param.
	 * @param {{credentials: string, firstName: string, lastName: string}|null} [newPhysician=null] - Params of the new 
	 * physician if one should be crated ('physicianId' must be empty).
	 * @param {string} [savedDate1=''] - (read-only) Original study recorded date for the first night.
	 * @param {string} [savedDate2=''] - (read-only) Original study recorded date for the second night.
	 * @param {string} [savedDate3=''] - (read-only) Original study recorded date for the third night.
	 * @param {string} [manualDate1=''] - Manually set study recorded date (overwrites original) for the first night.
	 * @param {string} [manualTimeH1=''] - Manually set study recorded hour for the first night.
	 * @param {string} [manualTimeM1=''] - Manually set study recorded hour for the first night.
	 * @param {string} [manualDate2=''] - Manually set study recorded date (overwrites original) for the second night.
	 * @param {string} [manualTimeH2=''] - Manually set study recorded hour for the second night.
	 * @param {string} [manualTimeM2=''] - Manually set study recorded hour for the second night.
	 * @param {string} [manualDate3=''] - Manually set study recorded date (overwrites original) for the third night.
	 * @param {string} [manualTimeH3=''] - Manually set study recorded hour for the third night.
	 * @param {string} [manualTimeM3=''] - Manually set study recorded hour for the third night.
	 * @param {Date} patientMaxDateOfBirth - (read-only) Date after witch patient birthdate should display a waring 
	 * message for underage patients (less than 18 years old).
	 * @param {boolean} [yearWarning=false] - (read-only) Flag that specifies if patient exceeds the 
	 * 'patientMaxDateOfBirth' (less than 18 years old). If this flag is returned as true on IO update, warning message 
	 * should appear after update success message.
	 */
	constructor(
		patientRefId, patientId, patientFirstName, patientMiddleName = '', patientLastName, patientDateOfBirth, 
		patientGender, patientOptionalId = '', patientAction = PATIENT_ACTION.UPDATE, studyTypeId, studyNight1 = '', 
		studyNight2 = '', studyNight3 = '', physicianId, newPhysician = null, savedDate1 = '', savedDate2 = '', 
		savedDate3 = '', manualDate1 = '', manualTimeH1 = '', manualTimeM1 = '', manualDate2 = '', manualTimeH2 = '', 
		manualTimeM2 = '', manualDate3 = '', manualTimeH3 = '', manualTimeM3 = '', patientMaxDateOfBirth, 
		yearWarning = false,
	) {
		this.patientRefId = patientRefId;
		this.patientId = patientId;
		this.patientFirstName = patientFirstName;
		this.patientMiddleName = patientMiddleName;
		this.patientLastName = patientLastName;
		this.patientDateOfBirth = patientDateOfBirth;
		this.patientGender = patientGender;
		this.patientOptionalId = patientOptionalId;
		this.patientAction = patientAction;
		this.studyTypeId = studyTypeId; // API study/fetch-study-types
		this.studyNight1 = studyNight1;
		this.studyNight2 = studyNight2;
		this.studyNight3 = studyNight3;
		this.physicianId = physicianId; // API study/fetch-ordering-physicians-by-study
		this.newPhysician = newPhysician;
		this.savedDate1 = savedDate1;
		this.savedDate2 = savedDate2;
		this.savedDate3 = savedDate3;
		this.manualDate1 = manualDate1;
		this.manualTimeH1 = manualTimeH1;
		this.manualTimeM1 = manualTimeM1;
		this.manualDate2 = manualDate2;
		this.manualTimeH2 = manualTimeH2;
		this.manualTimeM2 = manualTimeM2;
		this.manualDate3 = manualDate3;
		this.manualTimeH3 = manualTimeH3;
		this.manualTimeM3 = manualTimeM3;
		this.patientMaxDateOfBirth = patientMaxDateOfBirth;
		this.yearWarning = yearWarning;
	}
}
import {cloneDeep, get} from "lodash";
import {getInteger, getString} from "Core/helpers/data";
import {TicketsListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TicketsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new TicketsListItemDataObject(
		getString(result, 'id'),
		getString(result, 'ticketId'),
		getString(result, 'status'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getInteger(result, 'commentsNumber')
	);

	return result;
}

// @note There is no output function since list is read only.
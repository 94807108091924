import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {ApplicationParametersItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ApplicationParametersItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ApplicationParametersItemDataObject(
		getString(result, 'id'),
		getString(result, 'applicationName'),
		getString(result, 'paramName'),
		getString(result, 'paramValue'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {ApplicationParametersItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		applicationName: result.applicationName,
		paramName: result.paramName,
		paramValue: result.paramValue,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
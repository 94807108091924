import {PATIENT_DIALOG_TYPE_SELECT, PATIENT_DIALOG_TYPE_UPDATE} from "../../const";

export const PATIENT_EXISTS_ERROR_CODE = '125007060';
export const PATIENT_CHANGED_ERROR_CODE = '125007070';

/**
 * Map patient error codes to dialog types
 * @type {Object<PATIENT_EXISTS_ERROR_CODE | PATIENT_CHANGED_ERROR_CODE, PatientDialogType>}
 */
export const PATIENT_ERROR_CODE_DIALOG_TYPE_MAP = {
	[PATIENT_EXISTS_ERROR_CODE]: PATIENT_DIALOG_TYPE_SELECT,
	[PATIENT_CHANGED_ERROR_CODE]: PATIENT_DIALOG_TYPE_UPDATE,
};

import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {ActivityLogListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ActivityLogListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ActivityLogListItemDataObject(
		getString(result, 'id'),
		getString(result, 'type'),
		getString(result, 'message'),
		getString(result, 'module'),
		getString(result, 'username'),
		getString(result, 'status'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(
			get(result, 'authenticationSessionData.creationDate', null), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		getDate(
			get(result, 'authenticationSessionData.lastUpdated', null), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
	);

	return result;
}

// @note There is no output function since list is read only.
import React from "react";
import DataComponent from "Core/components/DataComponent";
import {DEVICE_TYPES} from "../../../pages/apps/default/const";
import SelectInput from "Core/components/input/SelectInput";

/**
 * Select input for device types
 * @note Currently options are hardcoded so this component does not have any special or useful functionality. It was 
 * created like this because, at some point, device types might be provided by the API meaning option will need to be 
 * loaded asynchronous using IO. If and when this happens, changing this one component will update every device type 
 * select input in the app.
 */
class DeviceTypeSelectInput extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				deviceTypes: [],
				deviceTypesLoading: false,
			}
		}, {
			disableLoad: true,
		});
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);
		
		// Device type options are loaded into local state asynchronously even thought they are currently hardcoded 
		// using constants because they might be retrieved from the API at some point.
		await this.setValue('deviceTypesLoading', true)
			.then(() => this.setValue('deviceTypes', DEVICE_TYPES.map(o => ({label: o, value: o}))))
			.then(() => this.setValue('deviceTypesLoading', false));

		return Promise.resolve();
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return (
			<SelectInput
				options={this.getValue('deviceTypes')}
				isLoading={this.getValue('deviceTypesLoading')}
				{...this.props}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DeviceTypeSelectInput.propTypes = {
	...SelectInput.propTypes
};

export default DeviceTypeSelectInput;
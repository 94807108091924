import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import DataValueValidation from "Core/validation";
import Label from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import * as actions from './actions';
import PropTypes from "prop-types";
import {omit} from "lodash";
import {icon_font_save_symbol} from "Config/app";
import {getString, isset} from "Core/helpers/data";
import * as pageConfig from "../../config";
import {isSuccessful} from "Core/helpers/io";
import ACL from "../../../../../../acl";
import {AsyncMountError} from "Core/errors";

class ChangeSecurityQuestion extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				password: '',
				currentQuestion: '',
				question: '',
				answer: ''
			}
		}, {
			translationPath: `${pageConfig.translationPath}.Components.ChangeSecurityQuestion`,
			disableLoad: true
		});
		
		// Action methods
		this.saveData = this.saveData.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		const {fetchSecurityQuestionAction} = this.props;
		
		// If this method was not called by the override method from the child class
		if (!override) {
			await super.asyncComponentDidMount(true)
				.then(() => this.executeAbortableAction(fetchSecurityQuestionAction))
				.then(response => {
					if (!isset(response)) throw new AsyncMountError();
					else return response;
				})
				.then(response => this.setValue('currentQuestion', getString(response, 'data')));
		}
		// If this method was called by the override method from the child class
		else {
			await super.asyncComponentDidMount(override);
		}

		return Promise.resolve();
	}
	

	// DataValueValidation methods --------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('password', 'required');
		dataValidation.addRule('question', 'required');
		dataValidation.addRule('answer', 'required');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset user's password
	 */
	saveData() {
		const {changeSecurityQuestionAction, addSuccessMessageAction} = this.props;

		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => this.validate())
			.then(valid => {
				if(valid) {
					this.executeAbortableAction(changeSecurityQuestionAction, this.getData())
						.then(response => {
							if(!isSuccessful(response)){
								return;
							}
							addSuccessMessageAction(this.t('change_security_question_data_success_msg'));							
							return this.setValue('currentQuestion', getString(this.getData(), 'question'))
						});
				}
			});
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {styleName, className, buttonProps, renderToTop, renderToBottom} = this.props;
		const error = this.getErrorMessage();

		return(
			<div 
				id={this.getDomId()} 
				className={`change-security-question password-reset-component ${styleName}-style ${className}`}
			>
				{renderToTop ? renderToTop : null}

				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="change-question-content">
					<Label 
						element="div"
						elementProps={{className: 'notice'}}
						content={this.t('current_question', '', '', {
							currentQuestion: this.getValue('currentQuestion')
						})}
					/>
					
					<FormWrapper>
						<FormField
							required={true}
							label={this.t('passwordField')}
							errorMessages={this.getValidationErrors('password')}
						>
							<TextInput
								type="password"
								name="password"
								value={this.getValue('password')}
								showLabel={false}
								placeholder={this.t('current_password_placeholder')}
								onChange={this.handleInputChange}
								autoComplete="new-password"
							/>
						</FormField>

						<FormField
							required={true}
							label={this.t('questionField')}
							errorMessages={this.getValidationErrors('question')}
						>
							<TextInput
								name="question"
								value={this.getValue('question')}
								showLabel={false}
								placeholder={this.t('question_placeholder')}
								onChange={this.handleInputChange}
								autoComplete="new-password"
							/>
						</FormField>

						<FormField
							required={true}
							label={this.t('answerField')}
							errorMessages={this.getValidationErrors('answer')}
						>
							<TextInput
								name="answer"
								value={this.getValue('answer')}
								showLabel={false}
								placeholder={this.t('answer_placeholder')}
								onChange={this.handleInputChange}
								autoComplete="new-password"
							/>
						</FormField>
					</FormWrapper>
				</div>

				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="save-data-actions">
					<FormField label=''>
						<Button
							className={`change-question-btn ${getString(buttonProps, 'className')}`}
							label={this.t('change_question')}
							onClick={this.saveData}
							disabled={ACL.isGuest(ACL)}
							{...ChangeSecurityQuestion.defaultProps.buttonProps}
							{...omit(buttonProps, ['className', 'onClick'])}
						/>
					</FormField>
				</div>

				{renderToBottom ? renderToBottom : null}

				{error ? <p className={`component-error error-color`}>{error}</p> : null}
			</div>
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ChangeSecurityQuestion.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Change question component wrapper element ID class attribute
	id: PropTypes.string,
	// Change question component wrapper element class attribute
	className: PropTypes.string,

	// Main button component props
	buttonProps: PropTypes.object,
	// Main submit function
	action: PropTypes.func, // Arguments: username, password

	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
};

/**
 * Define component default values for own props
 */
ChangeSecurityQuestion.defaultProps = {
	styleName: 'default',
	className: '',
	buttonProps: {
		icon: icon_font_save_symbol,
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},

	renderToTop: null,
	renderToBottom: null,
};

export default connect(null, getPageActions(actions))(ChangeSecurityQuestion);
import styles from "../index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {clone, find, get, isEmpty, isObject, isString} from "lodash";
import {getArray, getBoolean, getString} from "Core/helpers/data";
import CheckboxInput from "Core/components/input/CheckboxInput";
import {cssStyleStringToObject} from "Core/helpers/string";
import {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable/const";
import DataTableCell from "../DataTableCell";

class DataTableRow extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'DataTable',
			optimizedUpdate: true,
		});

		// Render methods
		this.getRowHighlightProps = this.getRowHighlightProps.bind(this);
	}

	/**
	 * Get a props (CSS class name, style, ...) for rows that should be highlighted
	 * @note This function should be called for each main data row.
	 * 
	 * @param {'className'|'style'|string} prop - Prop to get.
	 * @param {Object} row - Main data row to get the highlight props for.
	 * @param {{className: string, style: Object|string, rows: Object[]}[]} highlightsArray - Row highlight data.
	 * @return {any} Prop value.
	 */
	getRowHighlightProps(prop, row, highlightsArray) {
		const {primaryKeyColumn} = this.props;

		let result;
		if(Array.isArray(highlightsArray) && highlightsArray.length > 0){
			highlightsArray.forEach(item => {
				const isHighlightRowAnObject = isObject(get(item, 'rows[0]'));
				if (
					(isHighlightRowAnObject && (isEmpty(result) && find(item.rows, row)))
					||
					(!isHighlightRowAnObject && (isEmpty(result) && item.rows.includes(get(row, primaryKeyColumn))))
				) {
					switch (prop) {
						case 'className':
							result = '';
							result = get(item, 'className', '');
							break;
						case 'style':
							result = {};
							let style = get(item, 'style');
							if (isString(style)) style = cssStyleStringToObject(style);
							if (!isEmpty(style)) result = clone(style);
							break;
						default:
							result = get(item, prop);
					}
				}
			});
		}
		return result;
	}
	
	render() {
		const {
			data, index, selectable, isRowSelected, primaryKeyColumn, highlightedRows, showRowNumber, perPage, pageNo, 
		} = this.props;
		const columns = getArray(this.props, 'columns');

		// Extract single row data used for rendering
		const isActionCell = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTION);
		const isActionsCell = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTIONS);
		const isColumnHidden = column => getBoolean(column, 'hide');
		const columnSortName = column => getString(column, 'sortName');
		const isColumnSortable = column => !!columnSortName(column);
		
		return (
			<tr
				id={`data-table-row-${primaryKeyColumn ? get(data, primaryKeyColumn) : index}`}
				className={(isRowSelected ? 'selected' : '')}
				onClick={() => executeComponentCallback(this.props.onClick, data, index)}
			>
				{selectable ?
					<td
						className={
							`select ${styles['select']} ` + this.getRowHighlightProps('className', data, highlightedRows)
						}
						style={this.getRowHighlightProps('style', data, highlightedRows)}
						onClick={e => { e.stopPropagation() }}
					>
						<div className={`content ${styles['content']}`}>
							<CheckboxInput
								className={`select-checkbox ${styles['select-checkbox']}`}
								size={18}
								checked={isRowSelected}
								onChange={checked => executeComponentCallback(this.props.onSelect, checked, data)}
							/>
						</div>
					</td> : null
				}

				{
					showRowNumber ?
						<td
							className={this.getRowHighlightProps('className', data, highlightedRows)}
							style={this.getRowHighlightProps('style', data, highlightedRows)}
						>
							<div className={`content ${styles['content']}`}>{(perPage * (pageNo - 1)) + index + 1}</div>
						</td>
						: null
				}

				{columns.map((column, columnIndex) =>
					!isColumnHidden(column) ?
						<td
							key={columnIndex}
							className={
								(isActionCell(column) ? ` action ${styles['action-cell']}` : '') +
								(isActionsCell(column) ? ` actions ${styles['actions-cell']}` : '') +
								(isColumnSortable(column) ? ` sortable ${styles['sortable']}` : '') +
								' ' + getString(this.getRowHighlightProps('className', data, highlightedRows))
							}
							style={this.getRowHighlightProps('style', data, highlightedRows)}
							onClick={
								isActionCell(column) || isActionsCell(column) ? e => {
									e.stopPropagation(); e.nativeEvent.stopImmediatePropagation();
								} : e => {
									if(getBoolean(column, 'stopPropagation') === true) {
										e.stopPropagation(); e.nativeEvent.stopImmediatePropagation();
									}
								}
							}
						>
							<DataTableCell
								row={data}
								value={get(data, column.name)}
								defaultValue={get(column, 'defaultValue')}
								type={get(column, 'dataType')}
								typeOptions={get(column, 'dataTypeOptions')}
							/>
						</td>
						: null
				)}
			</tr>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DataTableRow.propTypes = {
	// Row data
	data: PropTypes.object,
	// Row index
	index: PropTypes.number,
	// Flag that specifies if row is selectable
	selectable: PropTypes.bool,
	// Flag that specifies if row is selected
	isRowSelected: PropTypes.bool,
	// Data table columns
	columns: PropTypes.array,
	
	// Name of the primary key column
	// @note This is required if rows need to be selected ('selectable' prop is true). Column must exist in 'columns' 
	// prop.
	primaryKeyColumn: PropTypes.string,
	// Row highlight specification 
	highlightedRows: PropTypes.arrayOf(
		PropTypes.shape({
			// Class name that will be added to all rows in the 'rows' property
			// @note This class name represents the highlight which means that skin should already have CSS styles for it.
			className: PropTypes.string,
			// Style object that will be added to all rows in the 'rows' property
			style: PropTypes.oneOfType([
				// Style object that cna be used in JSX (camelCase keys)
				// @example {backgroundColor: '#ccc', color: '#333'}
				PropTypes.object,
				// String CSS style declaration that will be converted to the object
				// @example 'background-color: #ccc; color: #333;'
				PropTypes.string
			]),
			// Rows to get the 'className' property
			// @note This is an array of row objects from 'data' prop.
			rows: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.object),
				PropTypes.arrayOf(PropTypes.string),
				PropTypes.arrayOf(PropTypes.number)
			])
		})
	),
	// If true, a new first column will be rendered with row numbers
	// @note Row numbers start from 1.
	showRowNumber: PropTypes.bool,

	// Pagination
	pageNo: PropTypes.number,
	perPage: PropTypes.number,

	// Events
	onClick: PropTypes.func, // Arguments: row data, index
	onSelect: PropTypes.func, // Arguments: checked, row data
};

export default DataTableRow;
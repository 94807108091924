import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Un-archive studies
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string[]} studyIds - List of study IDs to un-archive.
 * @return {function(*): *}
 */
export const unarchiveStudiesAction = (abortCallback, studyIds) => dispatch => {
	const loading = showLoading(".layout-page");
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/unarchive-studies',
		{ids: studyIds},
		hideLoadingFunction(loading),
	)(dispatch);
};
import {cloneDeep, get} from "lodash";
import {getBool, getString} from "Core/helpers/data";
import {PatientStudyInfoDataObject} from "./dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {GENDER_PATIENT_GENDER_MAP, PATIENT_GENDER_GENDER_MAP} from '../../const';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientStudyInfoDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientStudyInfoDataObject(
		getString(result, 'subjectData.medicalRecordId'),
		getString(result, 'subjectId'),
		getString(result, 'subjectData.firstName'),
		getString(result, 'subjectData.middleName'),
		getString(result, 'subjectData.lastName'),
		getDate(get(result, 'subjectData.dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(PATIENT_GENDER_GENDER_MAP, getString(result, 'subjectData.gender')),
		getString(result, 'subjectData.patientId'),
		getString(result, 'subjectData.subjectAction'),
		getString(result, 'studyType.id'),
		getString(result, 'night1Memo'),
		getString(result, 'night2Memo'),
		getString(result, 'night3Memo'),
		getString(result, 'orderingPhysicianId'),
		undefined,
		getString(result, 'savedRecordingDate1'),
		getString(result, 'savedRecordingDate2'),
		getString(result, 'savedRecordingDate3'),
		getString(result, 'manuallyAddedRecordingDate1'),
		getString(result, 'manuallyAddedRecordingTimeH1'),
		getString(result, 'manuallyAddedRecordingTimeM1'),
		getString(result, 'manuallyAddedRecordingDate2'),
		getString(result, 'manuallyAddedRecordingTimeH2'),
		getString(result, 'manuallyAddedRecordingTimeM2'),
		getString(result, 'manuallyAddedRecordingDate3'),
		getString(result, 'manuallyAddedRecordingTimeH3'),
		getString(result, 'manuallyAddedRecordingTimeM3'),
		getDate(get(result, 'dateOfBirthPosition', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getBool(result, 'yearWarning')
	);

	return result;
}

/**
 * Get output data from input data
 * 
 * @param {PatientStudyInfoDataObject} inputData - Input data.
 * @param {string} studyId - ID of the study.
 * @return {Object}
 */
export const output = (inputData, studyId) => {
	let result = cloneDeep(inputData);
	
	result = {
		studyId,
		subjectId: result.patientId,
		subjectData: {
			id: result.patientId,
			firstName: result.patientFirstName,
			lastName: result.patientLastName,
			middleName: result.patientMiddleName,
			dateOfBirth: getDateString(result.patientDateOfBirth, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			gender: GENDER_PATIENT_GENDER_MAP[result.patientGender],
			patientId: result.patientOptionalId,
			subjectAction: result.patientAction,
		},
		studyType: {
			id: result.studyTypeId,
		},
		orderingPhysicianId: result.physicianId,
		orderingPhysicians: (
			result.physicianId ?
				{
					id: result.physicianId
				}
				:
				{
					id: '',
					firstName: result.newPhysician.firstName,
					lastName: result.newPhysician.lastName,
					credentials: result.newPhysician.credentials
				}
		),
		night1Memo: result.studyNight1,
		night2Memo: result.studyNight2,
		night3Memo: result.studyNight3,
		manuallyAddedRecordingDate1: result.manualDate1,
		manuallyAddedRecordingDate2: result.manualDate2,
		manuallyAddedRecordingDate3: result.manualDate3,
		manuallyAddedRecordingTimeH1: result.manualTimeH1,
		manuallyAddedRecordingTimeH2: result.manualTimeH2,
		manuallyAddedRecordingTimeH3: result.manualTimeH3,
		manuallyAddedRecordingTimeM1: result.manualTimeM1,
		manuallyAddedRecordingTimeM2: result.manualTimeM2,
		manuallyAddedRecordingTimeM3: result.manualTimeM3,
	};

	return result;
}
import {cloneDeep, get} from "lodash";
import {getBool, getString} from "Core/helpers/data";
import {DevicesListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DevicesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new DevicesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceSerialNumber'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'deviceStatus'),
		getDate(get(result, 'deletedDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getBool(result, 'deleted')
	);

	return result;
}

// @note There is no output function since list is read only.
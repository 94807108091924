import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import * as pageActions from "../../../../actions";
import {BUTTON_DISPLAY_TYPE} from "Core/components/action/DownloadButton";
import {getString} from "Core/helpers/data";
import {STUDY_ACTION_ICON, STUDY_ACTIONS} from "../../const";
import {icon_font_symbol_class_prefix} from "Config/app";
import Button from "Core/components/display/Button";
import LoadingDialog from "../../../../../../../../components/dialogs/LoadingDialog";

class ExportEdfAction extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'ExportEdfAction',
		});
		
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Handle clicking the action button
	 * @param {MouseEvent} event - Mouse click event.
	 */
	handleClick(event) {
		const {
			action, studyRef, actionDropdownRef, exportEdfAction, openDialogAction, closeDialogAction, 
			showErrorMessageAction
		} = this.props;

		// Close the dropdown
		if (actionDropdownRef) actionDropdownRef.closeDropdown();
		
		const dialogGUIID = openDialogAction('', LoadingDialog, {
			title: this.t('title'),
			message: this.t('message'),
			action: () => {
				this.executeAbortableActionMount(exportEdfAction, action, studyRef)
					.then(() => { closeDialogAction(dialogGUIID); })
					.catch(e => {
						closeDialogAction(dialogGUIID);
						if (e.message === 'FAILED_DOWNLOAD') showErrorMessageAction(this.t('download_error'));
						else showErrorMessageAction(this.t('error'));
					});
			},
		}, {
			id: 'prepare-edf-dialog',
			closeOnEscape: false,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 450
		});
	}

	render() {
		const {className, action} = this.props;

		return (
			<Button
				id={this.getDomId()}
				className={className}
				icon={getString(STUDY_ACTION_ICON, [action.action, 'symbol'])}
				iconProps={{
					symbolPrefix: getString(
						STUDY_ACTION_ICON,
						[action.action, 'symbolPrefix'],
						icon_font_symbol_class_prefix
					)
				}}
				label={this.t(action.action, 'AppsSection.DefaultApp.StudiesPage.study_action')}
				displayStyle={BUTTON_DISPLAY_TYPE.NONE}
				onClick={this.handleClick}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExportEdfAction.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Action 
	action: PropTypes.shape({
		studyId: PropTypes.string,
		// @type {StudyAction}
		action: PropTypes.oneOf(STUDY_ACTIONS).isRequired,
		id: PropTypes.string,
	}).isRequired,
	// Study reference number
	studyRef: PropTypes.string,
	// Action dropdown component ref
	actionDropdownRef: PropTypes.object,
};

/**
 * Define component default values for own props
 */
ExportEdfAction.defaultProps = {
	id: '',
	className: '',
};

export default connect(null, getGlobalActions(pageActions))(ExportEdfAction);
/**
 * Data object representing the devices list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class DevicesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} deviceSerialNumber - Device's serial number.
	 * @param {Date} creationDate - Device's creation date.
	 * @param {DeviceStatus} status - Device's status.
	 * @param {Date} terminationDate - Device's termination date.
	 * @param {boolean} deleted - Flag that specifies if device is deleted.
	 */
	constructor(id, deviceSerialNumber, creationDate, status, terminationDate, deleted) {
		this.id = id;
		this.deviceSerialNumber = deviceSerialNumber;
		this.creationDate = creationDate;
		this.status = status;
		this.terminationDate = terminationDate;
		this.deleted = deleted;
	}
}

/**
 * Data object representing the devices item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class DevicesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [deviceSerialNumber=''] - Device's serial number.
	 */
	constructor(id = null, deviceSerialNumber = '') {
		this.id = id;
		this.deviceSerialNumber = deviceSerialNumber;
	}
}
/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import {closeAllFloatPopupsAction, closeFloatPopupAction, openFloatPopupAction} from "../components/global/FloatPopup";
import reduxStore from "../store";

/**
 * Open float popup
 *
 * @param {string} [GUIID=''] - Float popup's unique GUI ID. If not specified, unique GUI ID will be generated and used.
 * @param {React.Component} component - Component to render inside the opened float popup.
 * @param {object} [props={}] - Props to send to the component rendered inside the opened float popup.
 * @param {FloatPopupOptionsDataObject|{}} [options={}] - Opened float popup options.
 *	@return Float popup's unique GUI ID.
 */
export const openFloatPopup = (GUIID = '', component, props = {}, options = {}) => openFloatPopupAction(
	GUIID, component, props, options
)(reduxStore.dispatch);

/**
 * Close opened float popup
 *
 * @param {string} GUIID - Float popup unique GUIID.
 */
export const closeFloatPopup = GUIID => closeFloatPopupAction(GUIID)(reduxStore.dispatch);

/**
 * Close all opened float popups
 */
export const closeAllFloatPopups = ()  => closeAllFloatPopupsAction()(reduxStore.dispatch);

/**
 * Function returning a promise that will resolve when a specific float popup has been closed
 * @param {string} floatPopupGUIID - GUI ID of the float popup that was closed.
 * @return {Promise<string>} Promise that will resolve with the closed float popup's GUI ID.
 */
export const afterFloatPopupClose = floatPopupGUIID => {
	return new Promise(resolve => {
		const controller = new AbortController();
		window.addEventListener(
			`floatPopupClosed:${floatPopupGUIID}`,
			() => { resolve(floatPopupGUIID); controller.abort(); },
			{signal: controller.signal}
		);
	});
};
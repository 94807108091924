import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import * as pageConfig from "../../../../config";
import {STUDY_ACTION_ICON, STUDY_ACTIONS} from "../../const";
import DownloadButton, {BUTTON_DISPLAY_TYPE} from "Core/components/action/DownloadButton";
import {getIOUrl} from "Core/io/helper";
import {getString} from "Core/helpers/data";
import {icon_font_symbol_class_prefix} from "Config/app";
import {getFileNameForStudyAction} from "./helper";

class ExportAction extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.study_action`
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {id, className, action, studyRef, ...otherProps} = this.props;
		
		return (
			<DownloadButton
				id={this.getDomId()}
				className={className}
				icon={getString(STUDY_ACTION_ICON, [action.action, 'symbol'])}
				iconProps={{
					symbolPrefix: getString(
						STUDY_ACTION_ICON,
						[action.action, 'symbolPrefix'],
						icon_font_symbol_class_prefix
					)
				}}
				label={this.t(action.action)}
				displayStyle={BUTTON_DISPLAY_TYPE.NONE}
				url={getIOUrl('defaultAuthorizedApi', 'study/export-by-action')}
				filename={getFileNameForStudyAction(action.action, studyRef)}
				data={action}
				{...otherProps}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExportAction.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Action 
	action: PropTypes.shape({
		studyId: PropTypes.string,
		// @type {StudyAction}
		action: PropTypes.oneOf(STUDY_ACTIONS).isRequired,
		id: PropTypes.string,
	}).isRequired,
	// Study reference number
	studyRef: PropTypes.string,
};

/**
 * Define component default values for own props
 */
ExportAction.defaultProps = {
	id: '',
	className: '',
};

export default ExportAction;
import {ioJsonAction, ioJsonFetchAction} from "Core/store/actions/io";
import {isSuccessful} from "Core/helpers/io";
import {getString} from "Core/helpers/data";
import {hideLoadingFunction, showPageLoading} from "Core/helpers/loading";
import {get} from "lodash";

/**
 * Fetch current security question for current user from database.
 *
 * @param abortCallback
 * @returns {function(*): *}
 */
export const fetchSecurityQuestionAction = (abortCallback) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'profile/fetch-security-question-for-current-user'

	)(dispatch)
		.then(responseData => {
			if(isSuccessful(responseData)) {
				return ({
					...responseData,
					data: getString(responseData, 'data')
				});
			}

			return responseData;
		});
}

/**
 * Change security question for current user
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
 * an argument.
 * @param {Object} questionData - Data object containing information that will be saved in database.
 * @returns {function(*): *}
 */
export const changeSecurityQuestionAction = (abortCallback, questionData) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'profile/change-security-question',
		{
			currentPassword: get(questionData, 'password', ''),
			securityQuestion: get(questionData, 'question', ''),
			securityAnswer: get(questionData, 'answer', '')
		},
		hideLoadingFunction(loading)
	)(dispatch);
};
import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {LibrariesListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {LibrariesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new LibrariesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'libraryVersion'),
		getDate(get(result, 'libraryReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
	);

	return result;
}

// @note There is no output function since list is read only.
import {cloneDeep, get} from "lodash";
import {getBoolean, getString, resetObjectValues} from "Core/helpers/data";
import {AccountsItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AccountsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountsItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'idAccount'),
		getBoolean(result, 'blockDevices'),
		getBoolean(result, 'autoArchiveStudies'),
		getBoolean(result, 'systemAutoArchive'),
		getString(result, 'processingControl'),
		get(result, 'defaultUser'),
		getString(result, 'defaultUser.id'),
		getBoolean(result, 'deleteRawFiles'),
		getString(result, 'parentAccountId')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountsListItemDataObject|AccountsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		name: result.name,
		idAccount: result.idAccount,
		blockDevices: result.blockDevices,
		autoArchiveStudies: result.autoArchiveStudies,
		systemAutoArchive: result.systemAutoArchive,
		processingControl: result.processingControl,
		defaultUser: result.defaultUser,
		defaultUserId: result.defaultUserId,
		deleteRawFiles: result.deleteRawFiles,
		parentAccountId: result.parentAccountId
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
import {ioJsonManualAction, ioJsonSaveAction} from "Core/store/actions/io";
import {showLoading, hideLoadingFunction, hideLoading, showPageLoading} from "Core/helpers/loading";
import {getBool} from "Core/helpers/data";
import * as dataMap from "./dataMap/userActivationData";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch user account activation request data
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID value from browser URL, received in email.
 * @return {function(*): Promise<
 * 	{requestId: string, firstName: string, lastName: string, username: string} | undefined | null
 * >}
 */
export const fetchUserAccountActivationRequestAction = (abortCallback, id) => dispatch => {
	const loading = showPageLoading(false);
	return ioJsonManualAction(
		abortCallback,
		'authApi',
		'activate-user/fetch-request-by-id',
		{id},
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			hideLoading(loading);
			return (isSuccessful(responseData) ? responseData.data : null);
		})
		// Just hide the loading overlay since error will be handled on the page itself
		.catch(error => {
			hideLoading(loading);

			if (error.name === 'AbortError') return undefined;
			else return null;
		});
}

/**
 * Submit user account activation request
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID value from browser URL, received in email.
 * @param {UserAccountActivationDataObject} data - User account activation request data.
 * @return {function(*): Promise<boolean>}
 */
export const submitUserAccountActivationRequestAction = (abortCallback, id, data) => dispatch => {
	const loading = showLoading('#user-account-activation-page');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'authApi',
		'activate-user/save-user-data',
		{
			id: id,
			data: dataMap.output(data)
		},
		undefined,
		false,
		hideLoadingFunction(loading)
	)(dispatch)
		// Return success flag from API
		.then(response => getBool(response, 'success'));
}
import React from "react";
import DataComponent from "../../DataComponent";
import {connect} from "react-redux";
import {map} from "lodash";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {getArray} from "../../../helpers/data";
import {waitingFunction} from "../../../helpers/function";
import {selectors} from "../../../store/reducers";
import Dialog from "../Dialog";

import style from "./index.module.css";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	dialogs: selectors.dialog.getDialogs(state)
});

class Dialogs extends DataComponent {
	constructor(props) {
		super(props, {}, {
			dataPropAlias: 'dialogs',
			enableLoadOnDataPropChange: true,
		});

		// CSS transition methods
		this.handleTransitionEnter = this.handleTransitionEnter.bind(this);
		this.handleTransitionExited = this.handleTransitionExited.bind(this);
	}

	/**
	 * Handle CSS transition 'onEnter' callback
	 */
	handleTransitionEnter() {
		waitingFunction(() => {
			const componentNode = document.getElementById(`dialogs-${this.getId()}`);
			if (componentNode && componentNode.hasChildNodes() && componentNode.classList.contains('dialogs-empty')) {
				componentNode.classList.remove('dialogs-empty', style['empty']);
				componentNode.classList.add('dialogs', style['dialogs']);
			}
		}, 10, 100);
	}

	/**
	 * Handle CSS transition 'onExited' callback
	 * @param {Object} dialog - Dialog object.
	 */
	handleTransitionExited(dialog) {
		waitingFunction(() => {
			const componentNode = document.getElementById(`dialogs-${this.getId()}`);
			if (componentNode && !componentNode.hasChildNodes() && componentNode.classList.contains('dialogs')) {
				componentNode.classList.remove('dialogs', style['dialogs']);
				componentNode.classList.add('dialogs-empty', style['empty']);
				
				const dialogClosedEvent = new Event(`dialogClosed:${dialog.GUIID}`);
				window.dispatchEvent(dialogClosedEvent);
				
				return true;
			}
			return false;
		}, 10, 200);
	}

	render() {
		let dialogs = getArray(this.getData());

		// Add or remove 'dialogs-visible' CSS class to the body element depending on opened dialogs
		if (dialogs.length > 0) document.body.classList.add('dialogs-visible');
		else document.body.classList.remove('dialogs-visible');

		// Add 'nodeRef' props to each dialogs
		// @note This is done so that CSSTransition component will work properly.
		dialogs = map(dialogs, dialog => ({...dialog, nodeRef: React.createRef()}));
		
		return (
			<TransitionGroup
				id={`dialogs-${this.getId()}`}
				className={`dialogs-empty ${style['empty']}`}
			>
				{dialogs.map(dialog =>
					<CSSTransition
						key={dialog.GUIID}
						timeout={{appear: 200, enter: 200, exit: 100}}
						classNames="dialog-transitions"
						nodeRef={dialog.nodeRef}
						onEnter={this.handleTransitionEnter}
						onExited={() => this.handleTransitionExited(dialog)}
					>
						<Dialog
							GUIID={dialog.GUIID}
							innerComponent={dialog.component}
							innerComponentProps={dialog.props}
							options={dialog.options}
							forwardedRef={dialog.nodeRef}
						/>
					</CSSTransition>
				)}
			</TransitionGroup>
		);
	}
}

export default connect(mapStateToProps, null)(Dialogs);
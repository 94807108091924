import React from "react";
import PropTypes from "prop-types";
import SelectInput from "../SelectInput";
import Select from "react-select";
import {getBooleanFromTinyInt, getTinyIntFormBoolean} from "../../../helpers/data";
import {executeComponentCallback} from "../../BaseComponent";
import {main_layout_element} from "../../../../config";
import {omit} from "lodash";

class BooleanSelectComponent extends SelectInput {
	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Get value object from boolean value
	 * @param {boolean} value - Boolean value.
	 * @return {Object} Value that can be used in 'react-select' component.
	 */
	getValue(value) {
		const {optionsTranslationPath} = this.props;
		if (value === true || value === false) {
			return {
				label: value ? this.t('True', optionsTranslationPath) : this.t('False', optionsTranslationPath), 
				value: getTinyIntFormBoolean(value)
			};
		} else {
			return null;
		}
	}

	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support simple values.
	 *
	 * @param {Object} selectedValue - 'react-select' selected value.
	 */
	onChange(selectedValue) {
		const {onChange} = this.props;
		const selectedOption = getBooleanFromTinyInt(selectedValue, 'value', null);
		executeComponentCallback(onChange, selectedOption);
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, classNamePrefix, value, defaultValue, noOptionsMessage, placeholder, getOptionValue, onChange,
			formControlStyle, optionsTranslationPath, menuRenderInLayout, styles, ...otherProps
		} = this.props;

		// Prepare portal related props
		let portalProps = {};
		if (menuRenderInLayout) {
			const element = this.getDomElement();
			const fontSize = (element ? getComputedStyle(this.getDomElement()).getPropertyValue('font-size') : '');
			portalProps.styles = (
				styles ?
					{...styles, menuPortal: base => ({ ...base, zIndex: 9999, fontSize })} :
					{menuPortal: base => ({ ...base, zIndex: 9999, fontSize })}
			);
			portalProps.menuPortalTarget = document.querySelector(main_layout_element);
			portalProps.menuPlacement = 'auto';
		}

		return (
			<Select
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${className} ${formControlStyle ? 'form-control' : ''}`}
				classNamePrefix={`select-input${classNamePrefix ? ' ' + classNamePrefix : ''}`}
				value={this.getValue(value)}
				defaultValue={this.getValue(defaultValue)}
				placeholder={placeholder ? placeholder : this.t('Select ...')}
				getOptionValue={this.optionValueGetter}
				options={[
					{label: this.t('True', optionsTranslationPath), value: '1'},
					{label: this.t('False', optionsTranslationPath), value: '0'}
				]}
				onChange={this.onChange}
				onKeyDown={this.onKeyDown}
				{...portalProps}
				{...omit(otherProps, ['id', 'getOptionValue', 'onChange', 'onKeyDown'])}
				ref={node => this.element = node}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
BooleanSelectComponent.propTypes = {
	// The id to set on the SelectContainer component
	id: PropTypes.string,
	// Apply a className to the control
	className: PropTypes.string,
	// Apply classNames to inner elements with the given prefix
	classNamePrefix: PropTypes.string,
	// Disable the control
	isDisabled: PropTypes.bool,
	// Allow the user to search for matching options
	isSearchable: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Placeholder for the select value
	placeholder: PropTypes.string,
	// Control the current value
	value: PropTypes.bool,
	// Set the initial value of the control
	defaultValue: PropTypes.any,
	// Props used by the supported custom label component
	// @see 'options' folder for available custom label components or create your own.
	labelProps: PropTypes.object,
	// Props used by the supported custom single value component
	// @see 'singleValues' folder for available custom single value components or create your own.
	singleValueProps: PropTypes.object,
	// Is the select value clearable
	isClearable: PropTypes.bool,
	// Translate path of the 'True' and 'False' options.
	optionsTranslationPath: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	// Flag that determines if menu will be rendered in main layout element (see 'main_layout_element' config value) 
	// using a portal
	menuRenderInLayout: PropTypes.bool,

	// Events
	onChange: PropTypes.func, // Arguments: selected option (object or value depending on 'simpleValue' prop)
	onEnterKey: PropTypes.func, // Arguments: keypress event
	// ... react-select prop types (@link https://react-select.com/props)
};

/**
 * Define component default values for own props
 */
BooleanSelectComponent.defaultProps = {
	id: '',
	className: '',
	classNamePrefix: '',
	isDisabled: false,
	isMulti: false,
	isSearchable: true,
	formControlStyle: true,
	primaryKey: 'value',
	simpleValue: true,
	isClearable: false,
	optionsTranslationPath: 'general',
	menuRenderInLayout: true,
};

export default BooleanSelectComponent;
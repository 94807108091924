// Some useful date and time formats
export const STANDARD_DATE_TIME_FORMAT = {
	// ISO 8601 date
	ISO_DATE: "yyyy-MM-dd",
	// ISO 8601 date and time
	ISO_DATE_TIME: "yyyy-MM-dd'T'HH:mm",
	// ISO 8601 date and time with seconds
	ISO_DATE_TIME_S: "yyyy-MM-dd'T'HH:mm:ss",
	// ISO 8601 date and time with milliseconds
	ISO_DATE_TIME_MS: "yyyy-MM-dd'T'HH:mm:ss:SSS",

	// MySQL date
	MYSQL_DATE: "yyyy-MM-dd",
	// MySQL date and time
	MYSQL_DATE_TIME: "yyyy-MM-dd HH:mm",
	// MySQL date and time with seconds
	MYSQL_DATE_TIME_S: "yyyy-MM-dd HH:mm:ss",
	// MySQL date and time with milliseconds
	MYSQL_DATE_TIME_MS: "yyyy-MM-dd HH:mm:ss:SSS",

	// Seconds timestamp 
	TIMESTAMP_S: "t",
	// Milliseconds timestamp
	TIMESTAMP_MS: "T",

	// Time with hours only
	TIME_H: "HH",
	// Time with hours and minutes
	TIME: "HH:mm",
	// Time with hours, minutes and seconds
	TIME_S: "HH:mm:ss",
	// Time with hours, minutes, seconds and milliseconds
	TIME_MS: "HH:mm:ss:SSS",
};
/** @typedef {string} StudySensorConfiguration */
import {GENDER} from "Core/const/global";

export const STUDY_SENSOR_CONFIGURATION_SLEEP_PROFILER = 'SLEEP_PROFILER';
export const STUDY_SENSOR_CONFIGURATION_PSG2 = 'PSG2';
export const STUDY_SENSOR_CONFIGURATION_PM3 = 'PM3';
/** @enum {StudySensorConfiguration} */
export const STUDY_SENSOR_CONFIGURATION = {
	SLEEP_PROFILER: STUDY_SENSOR_CONFIGURATION_SLEEP_PROFILER,
	PSG2: STUDY_SENSOR_CONFIGURATION_PSG2,
	PM3: STUDY_SENSOR_CONFIGURATION_PM3,
};
/** @type {StudySensorConfiguration[]} */
export const STUDY_SENSOR_CONFIGURATIONS = [
	STUDY_SENSOR_CONFIGURATION_SLEEP_PROFILER,
	STUDY_SENSOR_CONFIGURATION_PSG2,
	STUDY_SENSOR_CONFIGURATION_PM3,
];

/** @typedef {string} StudyState */
export const STUDY_STATE_AVAILABLE = 'CURRENT';
export const STUDY_STATE_ARCHIVED = 'ARCHIVED';
export const STUDY_STATE_ALL = 'ALL';
/** @enum {StudyState} */
export const STUDY_STATE = {
	AVAILABLE: STUDY_STATE_AVAILABLE,
	ARCHIVED: STUDY_STATE_ARCHIVED,
	ALL: STUDY_STATE_ALL,
};
/** @type {StudyState[]} */
export const STUDY_STATES = [
	STUDY_STATE_AVAILABLE,
	STUDY_STATE_ARCHIVED,
	STUDY_STATE_ALL,
];

/** @typedef {string} PatientAction */
export const PATIENT_ACTION_UPDATE = 'UPDATE';
export const PATIENT_ACTION_CREATE_NEW = 'CREATE_NEW';
export const PATIENT_ACTION_FORCE_UPDATE = 'FORCE_UPDATE';
export const PATIENT_ACTION_USE_OTHER_SUBJECT = 'USE_OTHER_SUBJECT';
/** @enum {PatientAction} */
export const PATIENT_ACTION = {
	UPDATE: PATIENT_ACTION_UPDATE,
	CREATE_NEW: PATIENT_ACTION_CREATE_NEW,
	FORCE_UPDATE: PATIENT_ACTION_FORCE_UPDATE,
	USE_OTHER_SUBJECT: PATIENT_ACTION_USE_OTHER_SUBJECT,
};
/** @type {PatientAction[]} */
export const PATIENT_ACTIONS = [
	PATIENT_ACTION_UPDATE,
	PATIENT_ACTION_CREATE_NEW,
	PATIENT_ACTION_FORCE_UPDATE,
	PATIENT_ACTION_USE_OTHER_SUBJECT,
];

/** @typedef {string} PatientDialogType */
export const PATIENT_DIALOG_TYPE_UPDATE = 'UPDATE';
export const PATIENT_DIALOG_TYPE_SELECT = 'SELECT';
/** @enum {PatientDialogType} */
export const PATIENT_DIALOG_TYPE = {
	UPDATE: PATIENT_DIALOG_TYPE_UPDATE,
	SELECT: PATIENT_DIALOG_TYPE_SELECT,
};
/** @type {PatientDialogType[]} */
export const PATIENT_DIALOG_TYPES = [
	PATIENT_DIALOG_TYPE_UPDATE,
	PATIENT_DIALOG_TYPE_SELECT,
];

/** @typedef {number} PatientGender */
export const PATIENT_GENDER_MALE = 1;
export const PATIENT_GENDER_FEMALE = 2;
/** @enum {PatientGender} */
export const PATIENT_GENDER = {
	MALE: PATIENT_GENDER_MALE,
	FEMALE: PATIENT_GENDER_FEMALE,
}
/** @type {PatientGender[]} */
export const PATIENT_GENDERS = [
	PATIENT_GENDER_MALE,
	PATIENT_GENDER_FEMALE,
];
/**
 * Map patient gender to app gender
 */
export const PATIENT_GENDER_GENDER_MAP = {
	[PATIENT_GENDER.MALE]: GENDER.MALE,
	[PATIENT_GENDER.FEMALE]: GENDER.FEMALE,
}
/**
 * Map app gender to patient gender
 */
export const GENDER_PATIENT_GENDER_MAP = {
	[GENDER.MALE]: PATIENT_GENDER.MALE,
	[GENDER.FEMALE]: PATIENT_GENDER.FEMALE,
}

/** @typedef {string} EdfProcessingRequestStatus */
export const EDF_PROCESSING_REQUEST_STATUS_WAITING_TO_BE_PROCESSED = 'WAITING_TO_BE_PROCESSED';
export const EDF_PROCESSING_REQUEST_STATUS_IN_PROCESS = 'IN_PROCESS';
export const EDF_PROCESSING_REQUEST_STATUS_FINISHED = 'FINISHED';
export const EDF_PROCESSING_REQUEST_STATUS_INI_FILE_NOT_FOUND = 'INI_FILE_NOT_FOUND';
export const EDF_PROCESSING_REQUEST_STATUS_REGISTER_SCREEN = 'REGISTER_SCREEN';
export const EDF_PROCESSING_REQUEST_STATUS_GENERAL_ERROR = 'GENERAL_ERROR';
export const EDF_PROCESSING_REQUEST_STATUS_BAD_EDF_HANDLE = 'BAD_EDF_HANDLE';
export const EDF_PROCESSING_REQUEST_STATUS_ERROR_CLOSING_EDF_HANDLE = 'ERROR_CLOSING_EDF_HANDLE';
export const EDF_PROCESSING_REQUEST_STATUS_SIGNAL_DOES_NOT_EXIST = 'SIGNAL_DOES_NOT_EXIST';
export const EDF_PROCESSING_REQUEST_STATUS_ERROR_OPENING_EDF_FILE = 'ERROR_OPENING_EDF_FILE';
export const EDF_PROCESSING_REQUEST_STATUS_ERROR_READING_EDF_FILE_HEADER = 'ERROR_READING_EDF_FILE_HEADER';
export const EDF_PROCESSING_REQUEST_STATUS_EDF_IS_NOT_ORIGINAL_ONE = 'EDF_IS_NOT_ORIGINAL_ONE';
export const EDF_PROCESSING_REQUEST_STATUS_ERROR_IN_EDF_CONTENT = 'ERROR_IN_EDF_CONTENT';
export const EDF_PROCESSING_REQUEST_STATUS_ERROR_PARSING_INPUT_SIGNALS = 'ERROR_PARSING_INPUT_SIGNALS';
/** @enum {EdfProcessingRequestStatus} */
export const EDF_PROCESSING_REQUEST_STATUS = {
	WAITING_TO_BE_PROCESSED: EDF_PROCESSING_REQUEST_STATUS_WAITING_TO_BE_PROCESSED,
	IN_PROCESS: EDF_PROCESSING_REQUEST_STATUS_IN_PROCESS,
	FINISHED: EDF_PROCESSING_REQUEST_STATUS_FINISHED,
	INI_FILE_NOT_FOUND: EDF_PROCESSING_REQUEST_STATUS_INI_FILE_NOT_FOUND,
	REGISTER_SCREEN: EDF_PROCESSING_REQUEST_STATUS_REGISTER_SCREEN,
	GENERAL_ERROR: EDF_PROCESSING_REQUEST_STATUS_GENERAL_ERROR,
	BAD_EDF_HANDLE: EDF_PROCESSING_REQUEST_STATUS_BAD_EDF_HANDLE,
	ERROR_CLOSING_EDF_HANDLE: EDF_PROCESSING_REQUEST_STATUS_ERROR_CLOSING_EDF_HANDLE,
	SIGNAL_DOES_NOT_EXIST: EDF_PROCESSING_REQUEST_STATUS_SIGNAL_DOES_NOT_EXIST,
	ERROR_OPENING_EDF_FILE: EDF_PROCESSING_REQUEST_STATUS_ERROR_OPENING_EDF_FILE,
	ERROR_READING_EDF_FILE_HEADER: EDF_PROCESSING_REQUEST_STATUS_ERROR_READING_EDF_FILE_HEADER,
	EDF_IS_NOT_ORIGINAL_ONE: EDF_PROCESSING_REQUEST_STATUS_EDF_IS_NOT_ORIGINAL_ONE,
	ERROR_IN_EDF_CONTENT: EDF_PROCESSING_REQUEST_STATUS_ERROR_IN_EDF_CONTENT,
	ERROR_PARSING_INPUT_SIGNALS: EDF_PROCESSING_REQUEST_STATUS_ERROR_PARSING_INPUT_SIGNALS,
};
/** @type {EdfProcessingRequestStatus[]} */
export const EDF_PROCESSING_REQUEST_STATUSES = [
	EDF_PROCESSING_REQUEST_STATUS_WAITING_TO_BE_PROCESSED,
	EDF_PROCESSING_REQUEST_STATUS_IN_PROCESS,
	EDF_PROCESSING_REQUEST_STATUS_FINISHED,
	EDF_PROCESSING_REQUEST_STATUS_INI_FILE_NOT_FOUND,
	EDF_PROCESSING_REQUEST_STATUS_REGISTER_SCREEN,
	EDF_PROCESSING_REQUEST_STATUS_GENERAL_ERROR,
	EDF_PROCESSING_REQUEST_STATUS_BAD_EDF_HANDLE,
	EDF_PROCESSING_REQUEST_STATUS_ERROR_CLOSING_EDF_HANDLE,
	EDF_PROCESSING_REQUEST_STATUS_SIGNAL_DOES_NOT_EXIST,
	EDF_PROCESSING_REQUEST_STATUS_ERROR_OPENING_EDF_FILE,
	EDF_PROCESSING_REQUEST_STATUS_ERROR_READING_EDF_FILE_HEADER,
	EDF_PROCESSING_REQUEST_STATUS_EDF_IS_NOT_ORIGINAL_ONE,
	EDF_PROCESSING_REQUEST_STATUS_ERROR_IN_EDF_CONTENT,
	EDF_PROCESSING_REQUEST_STATUS_ERROR_PARSING_INPUT_SIGNALS,
];
import {cloneDeep, get} from "lodash";
import {getBool, getNumber, getString, isset} from "Core/helpers/data";
import {QuestionnaireDataObject, QuestionnairePatientDataObject, QuestionnaireResultDataObject} from "./dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {GENDER_PATIENT_GENDER_MAP, PATIENT_GENDER_GENDER_MAP} from "../../const";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {QuestionnaireDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	const getBoolFromString = (source, path) => {
		const value = get(source, path);
		if (typeof value === 'string') {
			if (value.toLowerCase() === 'true') return true;
			if (value.toLowerCase() === 'false') return false;
		}
		return undefined;
	}
	
	result = new QuestionnaireDataObject(
		getString(result, 'questionnaireId'),
		
		getString(result, 'subjectId'),
		getString(result, 'medicalRecordId'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'idNumber'),
		getDate(get(result, 'dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'dateCompleted', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(PATIENT_GENDER_GENDER_MAP, getString(result, 'gender')),
		getNumber(result, 'heightFeet', () => (
			isset(getNumber(result, 'heightInch', () => undefined)) ? 0 : undefined
		)),
		getNumber(result, 'heightInch', () => (
			isset(getNumber(result, 'heightFeet', () => undefined)) ? 0 : undefined
		)),
		getNumber(result, 'heightCentimeter', () => undefined),
		getNumber(result, 'weightPound', () => undefined),
		getNumber(result, 'weightKilogram', () => undefined),
		getNumber(result, 'neckCircumferenceInch', () => undefined),
		getNumber(result, 'neckCircumferenceCentimeter', () => undefined),
		getBool(result, 'changeCurrentSubjectData'),
		
		getBoolFromString(result, 'highBloodPressure'),
		getBoolFromString(result, 'restlessLeg'),
		getBoolFromString(result, 'narcolepsy'),
		getBoolFromString(result, 'headTrauma'),
		getBoolFromString(result, 'painfulCondition'),
		getBoolFromString(result, 'heartDisease'),
		getBoolFromString(result, 'sleepApnea'),
		getBoolFromString(result, 'depression'),
		getBoolFromString(result, 'stroke'),
		getBoolFromString(result, 'morningHeadaches'),
		getBoolFromString(result, 'diabetes'),
		getBoolFromString(result, 'insomnia'),
		getBoolFromString(result, 'ptsd'),
		getBoolFromString(result, 'neurologicalDisorder'),
		getBoolFromString(result, 'nightSweats'),
		
		getString(result, 'dozeChanceSittingReading'),
		getString(result, 'dozeChanceWatchingTV'),
		getString(result, 'dozeChanceSittingPublicPlace'),
		getString(result, 'dozeChanceCarPassenger'),
		getString(result, 'dozeChanceAfternoonRest'),
		getString(result, 'dozeChanceSittingTalking'),
		getString(result, 'dozeChanceSittingAfterLunch'),
		getString(result, 'dozeChanceCarStopped'),
		
		getString(result, 'difficultyFallingAsleep'),
		getString(result, 'difficultyStayingAsleep'),
		getString(result, 'wakingUpEarly'),
		getString(result, 'insomniaSatisfied'),
		getString(result, 'insomniaNoticable'),
		getString(result, 'insomniaWorried'),
		getString(result, 'insomniaInterfere'),
		getString(result, 'insomniaAlcohol'),
		getString(result, 'insomniaCaffeine'),
		
		getString(result, 'snoring'),
		getString(result, 'chokingGasping'),
		getString(result, 'stopBreathing'),
		getString(result, 'legsStill'),
		getString(result, 'vividNightmares'),
		getString(result, 'prescriptionFallAsleep'),
		getString(result, 'overCounterFallAsleep'),
		
		getString(result, 'phqLittleInterestPleasure'),
		getString(result, 'phqFeelingDepressed'),
		getString(result, 'phqTroubleFallingToSleep'),
		getString(result, 'phqTiredLittleEnergy'),
		getString(result, 'phqPoorAppetiteOvereating'),
		getString(result, 'phqFeelingBadAboutYourself'),
		getString(result, 'phqTroubleConcentrating'),
		getString(result, 'phqMovingSpeakingSlowly'),
		getString(result, 'phqSuicidalThoughts'),

		getString(result, 'gadNervousAnxious'),
		getString(result, 'gadNonStopWorry'),
		getString(result, 'gadToMuchWorryDifferentThings'),
		getString(result, 'gadTroubleRelaxing'),
		getString(result, 'gadRestlessHardToSitStill'),
		getString(result, 'gadEasilyAnnoyedIrritable'),
		getString(result, 'gadAfraidSomethingAwful'),
		
		getBoolFromString(result, 'medicationPain'),
		getBoolFromString(result, 'medicationHeartDisease'),
		getBoolFromString(result, 'medicationAntihistamine'),
		getBoolFromString(result, 'medicationHighBloodPressure'),
		getBoolFromString(result, 'medicationAntidepressant'),
		getBoolFromString(result, 'medicationAntianxiety'),
		getBoolFromString(result, 'medicationSteroid'),
		getBoolFromString(result, 'medicationParkinson'),
		getBoolFromString(result, 'medicationStimulant'),
		getBoolFromString(result, 'medicationAmbien'),
		getBoolFromString(result, 'medicationRozerem'),
		getBoolFromString(result, 'medicationIntermezzo'),
		getBoolFromString(result, 'medicationLunesta'),
		getBoolFromString(result, 'medicationSonata'),
		getBoolFromString(result, 'medicationSilenor'),
		getBoolFromString(result, 'medicationHalcion'),
		getBoolFromString(result, 'medicationRestorill'),
		getBoolFromString(result, 'medicationXanax'),
		
		getString(result, 'deviceTypeLabel'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {QuestionnaireDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	const getStringFromBool = value => (value === true ? 'true' : (value === false ? 'false' : ''));
	
	result = {
		questionnaireId: result.id,
		
		subjectId: result.patientId,
		medicalRecordId: result.patientRefId,
		firstName: result.firstName,
		middleName: result.middleName,
		lastName: result.lastName,
		idNumber: result.idNumber,
		dateOfBirth: getDateString(result.dateOfBirth, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		dateCompleted: getDateString(result.dateCompleted, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		gender: GENDER_PATIENT_GENDER_MAP[result.gender],
		heightFeet: result.heightFoot,
		heightInch: result.heightInch,
		heightCentimeter: result.heightCentimeter,
		weightPound: result.weightPound,
		weightKilogram: result.weightKilogram,
		neckCircumferenceInch: result.neckCircumferenceInch,
		neckCircumferenceCentimeter: result.neckCircumferenceCentimeter,
		changeCurrentSubjectData: result.changePatient,

		highBloodPressure: getStringFromBool(result.highBloodPressure),
		restlessLeg: getStringFromBool(result.restlessLeg),
		narcolepsy: getStringFromBool(result.narcolepsy),
		headTrauma: getStringFromBool(result.headTrauma),
		painfulCondition: getStringFromBool(result.painfulCondition),
		heartDisease: getStringFromBool(result.heartDisease),
		sleepApnea: getStringFromBool(result.sleepApnea),
		depression: getStringFromBool(result.depression),
		stroke: getStringFromBool(result.stroke),
		morningHeadaches: getStringFromBool(result.morningHeadaches),
		diabetes: getStringFromBool(result.diabetes),
		insomnia: getStringFromBool(result.insomnia),
		ptsd: getStringFromBool(result.ptsd),
		neurologicalDisorder: getStringFromBool(result.neurologicalDisorder),
		nightSweats: getStringFromBool(result.nightSweats),
		
		dozeChanceSittingReading: result.dozeChanceSittingReading ? result.dozeChanceSittingReading : null,
		dozeChanceWatchingTV: result.dozeChanceWatchingTV ? result.dozeChanceWatchingTV : null,
		dozeChanceSittingPublicPlace: result.dozeChanceSittingPublicPlace ? result.dozeChanceSittingPublicPlace : null,
		dozeChanceCarPassenger: result.dozeChanceCarPassenger ? result.dozeChanceCarPassenger : null,
		dozeChanceAfternoonRest: result.dozeChanceAfternoonRest ? result.dozeChanceAfternoonRest : null,
		dozeChanceSittingTalking: result.dozeChanceSittingTalking ? result.dozeChanceSittingTalking : null,
		dozeChanceSittingAfterLunch: result.dozeChanceSittingAfterLunch ? result.dozeChanceSittingAfterLunch : null,
		dozeChanceCarStopped: result.dozeChanceCarStopped ? result.dozeChanceCarStopped : null,

		difficultyFallingAsleep: result.difficultyFallingAsleep ? result.difficultyFallingAsleep : null,
		difficultyStayingAsleep: result.difficultyStayingAsleep ? result.difficultyStayingAsleep : null,
		wakingUpEarly: result.wakingUpEarly ? result.wakingUpEarly : null,
		insomniaSatisfied: result.insomniaSatisfied ? result.insomniaSatisfied : null,
		insomniaNoticable: result.insomniaNoticable ? result.insomniaNoticable : null,
		insomniaWorried: result.insomniaWorried ? result.insomniaWorried : null,
		insomniaInterfere: result.insomniaInterfere ? result.insomniaInterfere : null,
		insomniaAlcohol: result.insomniaAlcohol ? result.insomniaAlcohol : null,
		insomniaCaffeine: result.insomniaCaffeine ? result.insomniaCaffeine : null,

		snoring: result.snoring ? result.snoring : null,
		chokingGasping: result.chokingGasping ? result.chokingGasping : null,
		stopBreathing: result.stopBreathing ? result.stopBreathing : null,
		legsStill: result.legsStill ? result.legsStill : null,
		vividNightmares: result.vividNightmares ? result.vividNightmares : null,
		prescriptionFallAsleep: result.prescriptionFallAsleep ? result.prescriptionFallAsleep : null,
		overCounterFallAsleep: result.overCounterFallAsleep ? result.overCounterFallAsleep : null,

		phqLittleInterestPleasure: result.phqLittleInterestPleasure ? result.phqLittleInterestPleasure : null,
		phqFeelingDepressed: result.phqFeelingDepressed ? result.phqFeelingDepressed : null,
		phqTroubleFallingToSleep: result.phqTroubleFallingToSleep ? result.phqTroubleFallingToSleep : null,
		phqTiredLittleEnergy: result.phqTiredLittleEnergy ? result.phqTiredLittleEnergy : null,
		phqPoorAppetiteOvereating: result.phqPoorAppetiteOvereating ? result.phqPoorAppetiteOvereating : null,
		phqFeelingBadAboutYourself: result.phqFeelingBadAboutYourself ? result.phqFeelingBadAboutYourself : null,
		phqTroubleConcentrating: result.phqTroubleConcentrating ? result.phqTroubleConcentrating : null,
		phqMovingSpeakingSlowly: result.phqMovingSpeakingSlowly ? result.phqMovingSpeakingSlowly : null,
		phqSuicidalThoughts: result.phqSuicidalThoughts ? result.phqSuicidalThoughts : null,

		gadNervousAnxious: result.gadNervousAnxious ? result.gadNervousAnxious : null,
		gadNonStopWorry: result.gadNonStopWorry ? result.gadNonStopWorry : null,
		gadToMuchWorryDifferentThings: result.gadToMuchWorryDifferentThings ? result.gadToMuchWorryDifferentThings : null,
		gadTroubleRelaxing: result.gadTroubleRelaxing ? result.gadTroubleRelaxing : null,
		gadRestlessHardToSitStill: result.gadRestlessHardToSitStill ? result.gadRestlessHardToSitStill : null,
		gadEasilyAnnoyedIrritable: result.gadEasilyAnnoyedIrritable ? result.gadEasilyAnnoyedIrritable : null,
		gadAfraidSomethingAwful: result.gadAfraidSomethingAwful ? result.gadAfraidSomethingAwful : null,

		medicationPain: getStringFromBool(result.medicationPain),
		medicationHeartDisease: getStringFromBool(result.medicationHeartDisease),
		medicationAntihistamine: getStringFromBool(result.medicationAntihistamine),
		medicationHighBloodPressure: getStringFromBool(result.medicationHighBloodPressure),
		medicationAntidepressant: getStringFromBool(result.medicationAntidepressant),
		medicationAntianxiety: getStringFromBool(result.medicationAntianxiety),
		medicationSteroid: getStringFromBool(result.medicationSteroid),
		medicationParkinson: getStringFromBool(result.medicationParkinson),
		medicationStimulant: getStringFromBool(result.medicationStimulant),
		medicationAmbien: getStringFromBool(result.medicationAmbien),
		medicationRozerem: getStringFromBool(result.medicationRozerem),
		medicationIntermezzo: getStringFromBool(result.medicationIntermezzo),
		medicationLunesta: getStringFromBool(result.medicationLunesta),
		medicationSonata: getStringFromBool(result.medicationSonata),
		medicationSilenor: getStringFromBool(result.medicationSilenor),
		medicationHalcion: getStringFromBool(result.medicationHalcion),
		medicationRestorill: getStringFromBool(result.medicationRestorill),
		medicationXanax: getStringFromBool(result.medicationXanax),

		deviceTypeLabel: result.deviceTypeLabel,
	};

	return result;
}

/**
 * Get patient input data from raw data
 * @note Used for fetching patient data from a specific study to be used when creating a new questionnaire.
 * 
 * @param {Object} rawData - Raw data received from IO.
 * @return {QuestionnairePatientDataObject}
 */
export const patientInput = rawData => {
	let result = cloneDeep(rawData);

	result = new QuestionnairePatientDataObject(
		getString(result, 'id'),
		getString(result, 'medicalRecordId'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'patientId'),
		getDate(get(result, 'dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(PATIENT_GENDER_GENDER_MAP, getString(result, 'gender')),
		getBool(result, 'changeCurrentSubjectData')
	);
	
	return result;	
}

/**
 * Get patient output data from input data
 * @note Used for checking patient updates.
 * 
 * @param {QuestionnaireDataObject} inputData - Input data.
 * @return {Object}
 */
export const patientOutput = inputData => {
	return {
		subjectId: inputData.patientId,
		firstName: inputData.firstName,
		middleName: inputData.middleName,
		lastName: inputData.lastName,
		patientId: inputData.idNumber,
		gender: GENDER_PATIENT_GENDER_MAP[inputData.gender],
		dateOfBirth: getDateString(inputData.dateOfBirth, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		changeCurrentSubjectData: inputData.changePatient,
	};
};

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {QuestionnaireResultDataObject}
 */
export const resultInput = rawData => {
	let result = cloneDeep(rawData);

	// subjectData
	result = new QuestionnaireResultDataObject(
		getString(result, 'questionnaireId'),
		getString(result, 'osaRiskMessage'),
		getString(result, 'reportPath'),
	);

	return result;
}
import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../../../reducer";
import * as pageConfig from "../../../../config";
import {getGlobalActions} from "Core/helpers/redux";
import * as actions from "./actions.js"
import Button, {BUTTON_DISPLAY_TYPE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import PropTypes from "prop-types";
import {isMaxSelectionExceeded} from "../../helper";
import {getArray, getNumber} from "Core/helpers/data";
import acl from "../../../../../../../../acl";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	selection: selectors[reducerStoreKey].getStudiesListSelection(state),
});

class ExportStudyMetricsItem extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
		});

		this.isEnabled = this.isEnabled.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Check if this item should be enabled
	 * @return {boolean}
	 */
	isEnabled() {
		const maxSelection = getNumber(this.props, 'maxSelection');
		const selection = getArray(this.props, 'selection');

		if (acl.isGuest() || isMaxSelectionExceeded(maxSelection, selection) || selection.length < 1) return false;
		else return selection.every(i => ['30', '32', '33', '35', '37', '40', '45'].includes(i.studyStatusCode));
	}

	/**
	 * Handle item click
	 * @param {MouseEvent} event - Mouse click event.
	 */
	handleClick(event) {
		const {exportStudyMetricsAction} = this.props;
		const selection = getArray(this.props, 'selection');
		
		this.executeAbortableAction(exportStudyMetricsAction, selection.map(i => i.id)).then();
	}

	render() {
		return (
			<>
				<Button
					tabIndex={0}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					className="toolbar-button"
					disabled={!this.isEnabled()}
					onClick={this.handleClick}
				>
					<Label
						icon="file-excel-o"
						element="span"
						content={this.t('export_study_metrics_action')}
						supportHtml={true}
					/>
				</Button>
				<div className="separator" />
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExportStudyMetricsItem.propTypes = {
	// Max. number of selected studies
	maxSelection: PropTypes.number,
	// Studies table ref
	studiesTableRef: PropTypes.object,
	// Function that reloads the studies table
	// @note Calling this function is the same as clicking on the reload data button in the top right corner.
	// @return {Promise<*>}
	reloadStudies: PropTypes.func, // No arguments
};

export default connect(
	mapStateToProps, getGlobalActions(actions), null, {forwardRef: true}
)(ExportStudyMetricsItem);
import {USER_TYPE} from "./const";
import {v4} from "uuid";

/**
 * Data object representing the users list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class UsersListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} [userName] - List item's username.
	 * @param {string} [userType] - List item's user type.
	 * @param {string} [firstName] - List item's first name.
	 * @param {string} [lastName] - List item's last name.
	 * @param {string} [email] - List item's email address.
	 * @param {boolean} [activated] - List item's is activated flag.
	 * @param {boolean} [enabled] - List item's flag defining if item been enabled.
	 * @param {boolean} [acceptedTerms] - List item's flag defining if item has accepted Terms&Conditions.
	 * @param {boolean} [consentGDPR] - List item's flag defining if item has GDPR consent.
	 * @param {string} [defaultAccountDisplayName] - List item's default account name.
	 * @param {boolean} [psg2]
	 */
	constructor(
		id, userName, userType, firstName, lastName, email, activated = false, enabled = false, acceptedTerms = false, 
		consentGDPR = false, defaultAccountDisplayName = '', psg2 = false,
	) {
		this.id = id;
		this.userName = userName;
		this.userType = userType;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.activated = activated;
		this.enabled = enabled;
		this.acceptedTerms = acceptedTerms;
		this.consentGDPR = consentGDPR;
		this.defaultAccountDisplayName = defaultAccountDisplayName;
		this.psg2 = psg2;
	}
}

/**
 * Data object representing the users list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class UsersItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item ID.
	 * @param {UserType} [userType='OPERATOR'] - Type of the user.
	 * @param {string} [firstName=''] - Contact's first name.
	 * @param {string} [middleName=''] - Contact's middle name.
	 * @param {string} [lastName=''] - Contact's last name.
	 * @param {string} [credentials=''] - Contact's credentials (dr, msc, ...).
	 * @param {string} [username=''] - Username.
	 * @param {string} [email=''] - Username.
	 * @param {boolean} [changePassword=false] - Flag that specifies if password should be changed.
	 * @param {string} [password=''] - New password (relevant only if 'changePassword' is true). 
	 * @param {string} [passwordConfirm=''] - Confirmed new password (relevant only if 'changePassword' is true).
	 * @param {string|null} [defaultAccount=null] - User's default account.
	 * @param {boolean} [enabled=false] - Flag that specifies if user is enabled.
	 * @param {boolean} [psg2=false]
	 * @param {boolean} [passwordDoesNotExpire=false] - Flag that specifies if user's password can expire.
	 * @param {boolean|null} [activated] - Flag that specifies if user is activated.
	 * @param {boolean} [acceptedTerms=false] - Flag that specifies if user has accepted terms and conditions.
	 * @param {boolean} [consentGdpr=false] - Flag that specifies if user has accepted GDPR.
	 * @param {UserRoleItemDataObject[]} [accountRoles=[]] - Roles assigned to each account.
	 * @param {UserRoleItemStudyStatusPrivilegeDataObject[]} [studyStatusPrivileges=[]] - Study status privileges.
	 * @param {UserRoleItemPrivilegePropagationDataObject[]} [privilegePropagation=[]] - Privilege propagation list.
	 */
	constructor(
		// User info
		id = null, userType = USER_TYPE.OPERATOR, firstName = '', middleName = '', lastName = '', credentials = '', 
		username = '', email = '', changePassword = false, password = '', passwordConfirm = '', defaultAccount = null, 
		enabled = false, psg2 = false, passwordDoesNotExpire = false, activated = null, acceptedTerms = false, 
		consentGdpr = false,
		
		// Roles
		accountRoles = [],
		
		// Study status privileges
		studyStatusPrivileges = [],
		
		// Privilege propagation
		privilegePropagation = [],
	) {
		this.id = id;
		this.userType = userType;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.credentials = credentials;
		this.username = username;
		this.email = email;
		this.changePassword = changePassword;
		this.password = password;
		this.passwordConfirm = passwordConfirm;
		this.defaultAccount = defaultAccount;
		this.enabled = enabled;
		this.psg2 = psg2;
		this.passwordDoesNotExpire = passwordDoesNotExpire;
		this.activated = activated;
		this.acceptedTerms = acceptedTerms;
		this.consentGdpr = consentGdpr;
		this.accountRoles = accountRoles;
		this.studyStatusPrivileges = studyStatusPrivileges;
		this.privilegePropagation = privilegePropagation;
	}
}

/**
 * Data object representing roles assigned to each account
 */
export class UserRoleItemDataObject {
	/**
	 * @param {UserRoleItemAccountDataObject} [account=null] - Account to specify the list of supported roles for.
	 * @param {UserRoleItemRoleDataObject[]} [roleList=[]] - List of roles associated to the 'account'.
	 * @param {string} [GUIID] - GUI ID used by the GUI. If not set, default uuid will be generated.
	 */
	constructor(account = null, roleList = [], GUIID = v4()) {
		this.account = account;
		this.roleList = roleList;
		this.GUIID = GUIID;
	}
}
export class UserRoleItemAccountDataObject {
	/**
	 * @param {string} [id='']
	 * @param {string} [name='']
	 * @param {string} [abmId]
	 * @param {string} [displayName='']
	 */
	constructor(id = '', name = '', abmId = '', displayName = '') {
		this.id = id;
		this.name = name;
		this.abmId = abmId;
		this.displayName = displayName;
	}
}
export class UserRoleItemRoleDataObject {
	/**
	 * @param {string} [id=''] - Role ID.
	 * @param {string} [name=''] - Role name.
	 */
	constructor(id = '', name = '') {
		this.id = id;
		this.name = name;
	}
}

/**
 * Data object representing user item's study status privileges
 */
export class UserRoleItemStudyStatusPrivilegeDataObject {
	/**
	 * @param {string} [permission=''] - Privilege name.
	 * @param {string} [description=''] - Privilege description.
	 * @param {string} [resource=''] - Privilege resource.
	 * @param {boolean} [hasPrivilege=false] - Flag that specifies if the privilege permission is allowed.
	 */
	constructor(permission = '', description = '', resource = '', hasPrivilege = false) {
		this.permission = permission;
		this.description = description;
		this.resource = resource;
		this.hasPrivilege = hasPrivilege;
	}
}

/**
 * Data object representing user item's privilege propagation
 */
export class UserRoleItemPrivilegePropagationDataObject {
	/**
	 * @param {string} [resource=''] - Name of this resource this privilege is assigned to.
	 * @param {string} [privilege=''] - Privilege name.
	 * @param {boolean} [hasPrivilege=false] - Flag that specifies if the privilege permission is allowed.
	 */
	constructor(resource = '', privilege = '', hasPrivilege = false) {
		this.resource = resource;
		this.privilege = privilege;
		this.hasPrivilege = hasPrivilege;
	}
}
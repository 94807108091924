/**
 * Abstract float popup component used to create other float popup components and is extended from the DataComponent
 */

import React from "react";
import DataComponent, {executeComponentCallbackPromise} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";

class FloatPopupDataComponent extends DataComponent {
	/**
	 * Class constructor
	 *
	 * @param {Object} [props] - Component props.
	 * @param {Object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {FloatPopupDataComponentOptions} [options={}] - Component options from child class that will override the
	 * default options.
	 */
	constructor(props, initialState = {}, options = {}) {
		// Initialize initial state
		const _initialState = {
			...(initialState ? cloneDeep(initialState) : {}),
		};
		
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {FloatPopupDataComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Flag that specifies if actions section wrapper should stick to the bottom of the float popup
			 * @type {boolean}
			 */
			stickyActions: true,
			
			...cloneDeep(options)
		}

		super(props, _initialState, _options);

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Float popup methods
		this.close = this.close.bind(this);
		this.submit = this.submit.bind(this);

		// Render methods
		this.renderActionButtons = this.renderActionButtons.bind(this);
		this.renderFloatPopup = this.renderFloatPopup.bind(this);
	}


	// Float popup methods ----------------------------------------------------------------------------------------------
	/**
	 * Float popup close method
	 * @note This method should be called when float popup's close button is clicked. This method does not actually close
	 * the float popup. That should be done by the parent component.
	 */
	close() {
		const {floatPopupGUIID, floatPopupCloseAction} = this.props;
		floatPopupCloseAction(floatPopupGUIID);
	}

	/**
	 * Submit float popup
	 * @note This is called by the default submit button and will perform the data validation.
	 * @return {Promise<*>} Promise that will resolve with whatever is the result of the onSubmit function or null if 
	 * onSubmit is not defined or validation fails.
	 */
	submit() {
		const {floatDialogGUIID} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			// Trigger component's onSubmit event
			return executeComponentCallbackPromise(this.props.onSubmit, data, floatDialogGUIID);
		} else {
			return Promise.resolve(null);
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render action buttons
	 *
	 * @param {Object[]} buttons - Array of buttons, defined using Button component prop types, to render.
	 * @param {string} [className=''] - Actions section wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div 
					className={`float-popup-actions ${this.getOption('stickyActions') ? 'sticky' : ''} ${className}`}
				>
					{buttons.map((button, index) => <Button key={index} {...button} />)}
				</div>
				: null
		);
	}
	
	/**
	 * Use this method to render float popup structure with action buttons based on float popup type
	 *
	 * @param {JSX.Element|Element|string} [content] - Float popup content to render.
	 * @param {Object[]} [buttons=null] - Custom buttons to render. If not specified, default 'Submit' button will be 
	 * rendered.
	 * @param {string} [buttonsClassName=''] - Actions section wrapper additional CSS class.
	 * @return {JSX.Element} - Float popup JSX with action buttons based on float popup type.
	 */
	renderFloatPopup(content, buttons = null, buttonsClassName = '') {
		return (
			<>
				<div className={`float-popup-content-component`}>
					<div className="content">{content}</div>
					{
						buttons && Array.isArray(buttons) ?
							(buttons.length > 0 ? this.renderActionButtons(buttons) : null)
							:
							this.renderActionButtons([{
								displayStyle: BUTTON_STYLE.ACTION,
								icon: 'paper-plane-o',
								label: this.t('Submit', 'general'),
								onClick: this.submit
							}], buttonsClassName)
					}
				</div>
			</>
		);
	}
}

// Type definitions
/**
 * @typedef {Object} FloatPopupDataComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load'
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need
 * to have a different prop field for original data, like input components that use 'originalValue' instead of
 * 'originalData'.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load
 * instead of 'data' prop or 'dataPropAlias' options.
 * @property {boolean} [enableDataChangeHistory=false] - Flag that specifies if data change history will be enabled and
 * used. If change history is enabled some additional methods will be available, like undoData and redoData.
 * @property {boolean} [stickyActions=true] - Flag that specifies if actions section wrapper should stick to the bottom 
 * of the float popup.
 */

/**
 * Define component's own props that can be passed to it by parent components
 */
FloatPopupDataComponent.propTypes = {
	// Unique GUI ID of the float popup
	// @note This is automatically sent by the global FloatPopup component.
	floatPopupGUIID: PropTypes.string,
	// Float popup options
	// @note This is automatically sent by the global FloatPopup component.
	floatPopupOptions: PropTypes.object,
	// Action used to close the float popup
	// @note This is automatically sent by the global FloatPopup component.
	floatPopupCloseAction: PropTypes.func,
	
	// Component's input data
	data: PropTypes.any,
	// Component's original data used to determine if data has changed
	originalData: PropTypes.any,

	// Events
	onSubmit: PropTypes.func,
};

export {executeComponentCallback, executeComponentCallbackPromise} from "./DataComponent";
export default FloatPopupDataComponent;
import * as locale from "../i18n/locale";

/**
 * List of all countries
 * @type {CountryObj[]}
 */
let countryList = [];

/**
 * United States
 * @type {CountryObj}
 */
export const united_states = {
	code: 'USA',
	defaultLocale: locale.englishUs,
	allowedLocales: [locale.englishUs],
	customValues: {}
};
countryList.push(united_states);

export default countryList;

// Type definitions
/**
 * @typedef {Object} CountryObj
 * @property {string} code - ISO 3166-1 alpha-3 three-letter uppercase country code (ex: 'SRB')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
 * @property {LocaleObj} defaultLocale - Default country locale.
 * @property {LocaleObj[]} allowedLocales - Locales allowed for the country (will show in locale / language change 
 * dropdown).
 * @property {Object} [customValues] - Any other custom values.
 */
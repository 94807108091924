/**
 * Data object representing the language files list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class LanguageFilesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} deviceType - List item's device type.
	 * @param {string} version - List item's name.
	 * @param {Date} releaseDate - List item's name.
	 * @param {LanguagesCodebookItemDataObject} [language=null] - List item's language ID (from languages codebook).
	 */
	constructor(id, deviceType, version, releaseDate, language) {
		this.id = id;
		this.deviceType = deviceType;
		this.version = version;
		this.releaseDate = releaseDate;
		this.language = language;
	}
}

/**
 * Data object representing the language files item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class LanguageFilesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [deviceType=''] - Item's device type.
	 * @param {string} [version=''] - Item's name.
	 * @param {Date} [releaseDate=null] - Item's name.
	 * @param {string} [notes=''] - Item's notes.
	 * @param {LanguagesCodebookItemDataObject} [language=null] - Item's language ID (from languages codebook).
	 * @param {string} [executableVoiceBinId=''] - Item's executable voice bin ID.
	 * @param {Object} [executableVoiceBin=null] - Item's executable voice bin.
	 * @param {string} [checksum=''] - Item's checksum.
	 * @param {FormData} [executableVoiceBinFormData=null] - Selected executable voice bin file.
	 */
	constructor(
		id = null, deviceType = '', version = '', releaseDate = null, notes = '', language = null, 
		executableVoiceBinId = '', executableVoiceBin = null, checksum = '', 
		executableVoiceBinFormData = null
	) {
		this.id = id;
		this.deviceType = deviceType;
		this.version = version;
		this.releaseDate = releaseDate;
		this.notes = notes;
		this.language = language;

		// File read properties
		// @desc Properties used to display current file(s) already saved in the database.
		this.executableVoiceBinId = executableVoiceBinId;
		this.executableVoiceBin = executableVoiceBin;
		this.checksum = checksum;

		// File write properties
		// @desc Properties used to store selected file(s) that will be saved in the database.
		this.executableVoiceBinFormData = executableVoiceBinFormData;
	}
}
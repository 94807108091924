import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {cloneDeep, orderBy, get} from "lodash";
import {icon_font_close_symbol, icon_font_save_symbol} from "Config/app";
import {getArray, getBool, isset} from "Core/helpers/data";
import {BUTTON_STYLE} from "Core/components/display/Button";
import PatientSelectionDialog from "../../dialogs/PatientSelectionDialog";
import {PATIENT_ERROR_CODE_DIALOG_TYPE_MAP, PATIENT_EXISTS_ERROR_CODE} from "./const";
import {isSuccessful} from "Core/helpers/io";
import Label from "Core/components/display/Label";
import {setStorageValue, STORAGE_TYPE} from "Core/storage";
import {PATIENT_ACTION} from "../../const";
import ACL from "../../../../../../acl";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({

});

class PatientStudyInfoPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.PatientStudyInfoPopup`,
			domPrefix: 'patient-study-info-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		}

		this.state = cloneDeep(this.initialState);

		/**
		 * Define the global component variable to store the current dynamic values
		 * @note Global component is used because there is no need to update the component when these values change. 
		 * Updating is handled by the 'updateDynamics' method.
		 * @type {{studyId: string}}
		 */
		this.currentDynamicValues = {
			studyId: props.initialStudyId,
		};

		// Action methods
		this.forceSave = this.forceSave.bind(this);
		this.save = this.save.bind(this);
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {string} [studyId] - Study ID used to load popup main tab data.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({studyId}) {
		this.currentDynamicValues.studyId = studyId;
		return super.dynamicActionButtons({studyId});
	}

	/**
	 * Update dynamic tabs that depend on current state and props
	 * @param {string} [studyId] - Study ID used to load popup main tab data.
	 * @return {Promise<void>}
	 */
	dynamicTabs({studyId}) {
		this.currentDynamicValues.studyId = studyId;
		return super.dynamicTabs({studyId});
	}
	
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const {initialStudyId} = this.props;
		
		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
			new PopupActionDataObject({
				id: 'save',
				action: this.save,
				buttonProps: {
					label: 'general.Save',
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION,
				},
				disabled: ACL.isGuest(ACL),
				ordinal: 1
			}),
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({studyId: initialStudyId});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			})
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({studyId: initialStudyId});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Force save patient/study info (second save after patient dialog action is selected)
	 * 
	 * @param {PatientStudyInfoDataObject} dataToSave - Data to save with all action related updates.
	 * @param {string} saveDialogDomId - DOM ID of the patient action dialog.
	 * @param {string} saveDialogGUIID - GUIID of the patient action dialog.
	 * @return {Promise<*>}
	 */
	forceSave(dataToSave, saveDialogDomId, saveDialogGUIID) {
		const {updateStudyInfoAction, closeDialogAction, addSuccessMessageAction, addWarningMessageAction} = this.props;
		
		return this.executeAbortableAction(
			updateStudyInfoAction, 
			this.currentDynamicValues.studyId, 
			dataToSave, 
			`#${saveDialogDomId} .dialog-content-component > .content`
		)
			.then(response => {
				if (isSuccessful(response)) {
					closeDialogAction(saveDialogGUIID);
					this.close();
					addSuccessMessageAction(this.t('update_success_msg'));
					
					// Add a year warning global message if needed and store its reference into memory
					// @note Page component should hide this message using the stored reference when sub-url changes or when 
					// leaving the page. 
					if (getBool(response, 'data.yearWarning')) {
						const yearWarningMsg = addWarningMessageAction(
							(<Label key="y_warning" content={this.t('year_warning_msg')} supportHtml={true}/>),
							-1
						);
						setStorageValue('yearWarningMsg', yearWarningMsg, STORAGE_TYPE.MEMORY);
					}
				}
			});
	}
	
	/**
	 * Save patient/study info
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event) {
		const {updateStudyInfoAction, openDialogAction, addSuccessMessageAction, addWarningMessageAction} = this.props;
		const patientSelectionDialogDomId = 'patient-selection-dialog';
		
		return this.getTabRef('MainTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {PatientStudyInfoDataObject} */
					const dataToSave = get(allTabsData, 'MainTab');
					
					// Try to save patient/study info
					return this.executeAbortableAction(updateStudyInfoAction, this.currentDynamicValues.studyId, dataToSave)
						// Handle patient update error codes
						// @description There are two custom error codes returned by the API when it detects that the patient 
						// info has been changed. Both error codes should raise a dialog so that the user can select which 
						// action to perform (create new patient, update the existing one, ...).
						.then(response => new Promise(resolve => {
							// If 'success' response param is explicitly set to false (action should make sure that custom 
							// patient related error codes always return a response with 'success' set to false).
							if (response && response?.success === false) {
								// Open a patient select dialog so that users can choose which action to perform
								openDialogAction(patientSelectionDialogDomId, PatientSelectionDialog, {
									type: PATIENT_ERROR_CODE_DIALOG_TYPE_MAP[response.errorCode],
									data: (response.errorCode === PATIENT_EXISTS_ERROR_CODE ?
										getArray(response, 'data') :
										null
									),
									onSelectExisting: (dialogGUIID, patientId) => this.forceSave(
										{...dataToSave, patientAction: PATIENT_ACTION.USE_OTHER_SUBJECT, patientId},
										patientSelectionDialogDomId,
										dialogGUIID
									).then(() => resolve()),
									onCreateNew: dialogGUIID => this.forceSave(
										{...dataToSave, patientAction: PATIENT_ACTION.CREATE_NEW, patientId: ''},
										patientSelectionDialogDomId,
										dialogGUIID
									).then(() => resolve()),
									onUpdateCurrent: dialogGUIID => this.forceSave(
										{...dataToSave, patientAction: PATIENT_ACTION.FORCE_UPDATE},
										patientSelectionDialogDomId,
										dialogGUIID
									).then(() => resolve()),
								}, {
									id: patientSelectionDialogDomId,
									className: 'bordered-title',
									closeOnEscape: false,
									closeOnClickOutside: false,
									hideCloseBtn: true,
									maxWidth: 900
								});
							}
							
							// Hande successful save without any patient info changes
							else if (isSuccessful(response)) {
								this.close();
								addSuccessMessageAction(this.t('update_success_msg'));

								// Add a year warning global message if needed and store its reference into memory
								// @note Page component should hide this message using the stored reference when sub-url changes 
								// or when leaving the page. 
								if (getBool(response, 'data.yearWarning')) {
									const yearWarningMsg = addWarningMessageAction(
										(<Label key="y_warning" content={this.t('year_warning_msg')} supportHtml={true}/>),
										-1
									);
									setStorageValue('yearWarningMsg', yearWarningMsg, STORAGE_TYPE.MEMORY);
								}
								
								resolve();
							}
							
							// Handle any other response (usually when there are some errors)
							else resolve();
						}));
				}
			});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientStudyInfoPopup.propTypes = {
	// ID of the study initially set when opening the popup
	// @note Study ID can change dynamically after the popup is opened using the 'updateDynamics' method.
	initialStudyId: PropTypes.string,
	
	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(PatientStudyInfoPopup);
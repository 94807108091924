import {getArray, getBool} from "Core/helpers/data";
import {ROLE_PERMISSION_PRIVILEGES_BY_RESOURCE, ROLE_PERMISSION_RESOURCES_FOR_GUI} from "./const";
import {forOwn, set} from "lodash";

/**
 * Data object representing the roles list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class RolesListItemDataObject {
	/**
	 * @param {string} [id] - List item's ID.
	 * @param {string} [name] - List item's name.
	 * @param {string} [description] - List item's description.
	 */
	constructor(id, name, description) {
		this.id = id;
		this.name = name;
		this.description = description;
	}
}

/**
 * Data object representing the roles item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class RolesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [name=''] - Item's name.
	 * @param {string} [description=''] - Item's description.
	 * @param {boolean} [global=false] - Item's global flag.
	 * @param {Object<RolePermissionResource, Object<RolePermissionPrivilege, boolean>>} [resourcePrivileges] - All 
	 * privileges grouped by resource.
	 */
	constructor(id = null, name = '', description = '', global = false, resourcePrivileges) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.global = global;

		this.resourcePrivileges = {};
		forOwn(ROLE_PERMISSION_PRIVILEGES_BY_RESOURCE, (privileges, resource) => {
			if (ROLE_PERMISSION_RESOURCES_FOR_GUI.includes(resource) && privileges.length > 0) {
				let privilegesToSet = {};
				getArray(privileges).forEach(p => set(privilegesToSet, p, getBool(resourcePrivileges, [resource, p])));
				set(this.resourcePrivileges, resource, privilegesToSet);
			}
		});
	}
}
import {ioJsonAction, ioJsonFetchAction} from "Core/store/actions/io";
import * as itemDataMap from "./dataMap/data";
import {hideLoadingFunction, showPageLoading} from "Core/helpers/loading";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch current user personal data from database.
 *
 * @param abortCallback
 * @returns {function(*): *}
 */
export const fetchPersonalDataAction = abortCallback => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'profile/fetch-personal-data-for-current-user',
		'',
	)(dispatch)
		// Get mapped data from response data
		.then(response => {
			if (isSuccessful(response)) return itemDataMap.input(response.data);
			else return undefined;
		});
};

/**
 *
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
 * an argument.
 * @param {Object} personalData - Current users personal data that will be saved in database.
 * @returns {function(*): *}
 */
export const changePersonalDataAction = (abortCallback, personalData) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'profile/save-personal-data-for-current-user',
		{data: itemDataMap.output(personalData)},
		hideLoadingFunction(loading)
	)(dispatch);
};
import styles from "./index.module.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from "Core/components/DialogComponent";
import {connect} from "react-redux";
import * as actions from "../../../../../../actions";
import * as pageConfig from "../../../../../../config";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import RadioInput, {RADIO_INPUT_LAYOUT} from "Core/components/input/RadioInput";
import {getPageActions} from "Core/helpers/redux";
import {isset} from "Core/helpers/data";
import Spinner from "Core/components/display/Spinner";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import Label from "Core/components/display/Label";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {RE_PROCESS_STUDY_CRITERIA} from "./const";
import ACL from "../../../../../../../../../../acl";
import {AsyncMountError} from "Core/errors";
import CheckboxInput from "Core/components/input/CheckboxInput";
import PropTypes from "prop-types";

class ReProcessDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.dialogs.ReProcessDialog`,
			domPrefix: 're-process-dialog',
			dialogType: DIALOG_TYPE.CONFIRM,
		});

		// Initialize local component state
		this.state = {
			/**
			 * List of loaded criteria
			 * @note It is set to 'undefined' to differentiate between loaded empty lists and not yet loaded.
			 * @type {ReProcessStudyCriteria[]}
			 */
			criteria: undefined,
			/**
			 * Criteria selected by user
			 * @type {ReProcessStudyCriteria}
			 */
			selectedCriteria: RE_PROCESS_STUDY_CRITERIA.STANDARD,
			/**
			 * Flag used when generating reports
			 * @note Almost newer used so checkbox is hidden and only shows in the top right corner of the dialog (inside 
			 * the dialog title) if the dialog is opened by clicking the 'Re-Process study' action item while Shift key is 
			 * pressed.
			 * @type {boolean}
			 */
			nuseSubtracted: false,
		};

		// Data loading methods
		this.loadCriteria = this.loadCriteria.bind(this);
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();

		await this.loadCriteria();
	}
	

	// Data loading methods ---------------------------------------------------------------------------------------------
	loadCriteria() {
		const {fetchStudiesListItemActionReProcessCriteriaAction} = this.props;
		return this.executeAbortableAction(fetchStudiesListItemActionReProcessCriteriaAction)
			.then(response => {
				if (!isset(response)) throw new AsyncMountError();
				else return response;
			})
			.then(criteria => this.setState({criteria}));
	}


	/**
	 * Dialog yes method
	 * @note This method should be called when dialog's yes button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 */
	yes() {
		const {dialogGUIID} = this.props;
		const {selectedCriteria, nuseSubtracted} = this.state;

		// Trigger component's onOk event
		executeComponentCallback(this.props.onYes, dialogGUIID, selectedCriteria, nuseSubtracted);
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render confirm dialog action buttons
	 * @note Confirm dialog has yes and no style buttons. Yes button will trigger onYes event and no button will trigger
	 * onNo event.
	 *
	 * @param {string|null} [yesLabel] - Label used for yes button. Default value will be loaded from translation file.
	 * @param {string|null} [yesIcon] - Optional icon used for yes button.
	 * @param {string|null} [noLabel] - Label used for no button. Default value will be loaded from translation file.
	 * @param {string|null} [noIcon] - Optional icon used for no button.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderConfirmActionButtons(yesLabel = undefined, yesIcon = undefined, noLabel = undefined, noIcon = undefined) {
		let buttons = [];

		buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (isset(yesLabel) && yesLabel !== null ? yesLabel : this.t('Yes', 'general')),
			icon: (yesIcon ? yesIcon : ''),
			onClick: this.yes,
			disabled: ACL.isGuest(ACL),
		});

		buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (isset(noLabel) && noLabel !== null ? noLabel : this.t('No', 'general')),
			icon: (noIcon ? noIcon : ''),
			onClick: this.no,
		});

		return this.renderActionButtons(buttons);
	}
	
	/**
	 * Use this method to render dialog structure with action buttons based on dialog type
	 *
	 * @param {Element|string} [title=null] - Main dialog title to render inside the standard dialog structure. Any
	 * render title method can be used to generate this value (@see renderTitle, renderInfoTitle, renderHelpTitle, ...
	 * methods).
	 * @param {Element|string} [content] - Main dialog content to render inside the standard dialog structure.
	 * @param {any} [buttonOptions] - Action button options used by the appropriate render action buttons method.
	 * @return {JSX.Element} - Dialog JSX with action buttons based on dialog type.
	 */
	renderDialog(title = null, content, ...buttonOptions) {
		const {className} = this.props;
		const {criteria} = this.state;
		const dialogType = this.getOption('dialogType');
		const alignContent = this.getOption('alignContent');

		return (
			<div className={`dialog-content-component type-${dialogType}`}>
				{title ? title : null}

				<div className="content" style={{textAlign: alignContent}}>
					{content}
				</div>

				{
					isset(criteria) ? 
						this.renderConfirmActionButtons(...buttonOptions) 
						:
						<div className={`buttons ${className}`} />
				}
			</div>
		);
	}


	/**
	 * Render standard dialog title
	 * @description Use this method to render a dialog title with standard CSS class and structure.
	 *
	 * @param {any} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Standard dialog title element or null if 'label' is not specified or is empty.
	 */
	renderTitle(label, className = 'title') {
		const {showNuseSubtracted} = this.props;
		const {nuseSubtracted} = this.state;
		
		return (
			label ? 
				<div className={className}>
					{label}
					{showNuseSubtracted ?
						<div className={`${styles['titleActions']}`}>
							<CheckboxInput
								className="nuseSubtracted"
								checked={!!nuseSubtracted}
								onChange={nuseSubtracted => this.setState({nuseSubtracted})}
							/>
						</div>
						: null
					}
				</div>
				: null
		);
	}

	render() {
		const {criteria, selectedCriteria} = this.state;

		return this.renderDialog(
			this.renderTitle(this.t('title'), `${styles['title']} title`),
			(
				<div className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
					{
						!isset(criteria) ?
							<div className="text-center"><Spinner /></div>
							:
							<FormWrapper>
								<FormField 
									label={this.t('criteriaLabel')}
									labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
								>
									<RadioInput
										className={styles['radio']}
										buttonProps={{
											className: styles['radioOption'],
											displayStyle: BUTTON_STYLE.DEFAULT,
										}}
										layout={RADIO_INPUT_LAYOUT.ALIGNED}
										value={selectedCriteria}
										options={criteria.map(c => ({label: this.tt(c, 'criteria'), value: c}))}
										onChange={selectedCriteria => this.setState({selectedCriteria})}
									/>
								</FormField>
								
								<Label content={this.t('message')} />
							</FormWrapper>
					}
				</div>
			), 
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ReProcessDialog.propTypes = {
	...DialogComponent.propTypes,
	
	showNuseSubtracted: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
ReProcessDialog.defaultProps = {
	showNuseSubtracted: false,
};

export default connect(null, getPageActions(actions))(ReProcessDialog);
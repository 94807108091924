import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {isEqual, each} from "lodash";
import {
	startOfDay, startOfMonth, startOfYear, startOfDecade, endOfDay, endOfMonth, endOfYear, endOfDecade, isValid, toDate
} from "date-fns";
import {DATETIME_ROUND_TO, getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {getDateLocaleByCode, getLocaleCode, getLocaleDateFormat, getLocaleTimeFormat} from "Core/helpers/locale";
import {selectors} from "../../../store/reducers";
import DatePicker from 'react-date-picker';
import {LOCALE_DATE_FORMAT_NAME, LOCALE_DATE_FORMAT_NAMES, LOCALE_TIME_FORMAT_NAMES} from "Core/const/locale";
import Icon from "Core/components/display/Icon";
import {icon_font_close_symbol} from "Config/app";
import DateLabel from "Core/components/display/DateLabel";
import {getArray, getString} from "Core/helpers/data";
import {areElementsSiblings} from "Core/helpers/dom";
import {isAnyInputInvalid} from "./helper";
import TextInput, {TEXT_INPUT_TOOLBAR_POSITION} from "Core/components/input/TextInput";
import Label from "Core/components/display/Label";
import {BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "Core/components/display/Button";
import InsertValueButton from "Core/components/advanced/InsertValueButton";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "Core/components/advanced/InsertValueButton/const";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state)
});

/**
 * Date picker input component
 * @description Input with date-picker functionality.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 *
 * This component uses 'react-date-picker' component and 'date-fns' library.
 */
class DateInput extends DataComponent {
	/**
	 * Calendar wrapper element ref
	 */
	calendarRef = null;

	/**
	 * Calendar component ref
	 */
	calendarComponentRef = null;

	/**
	 * Component ref of the text input used for insert values
	 */
	insertInputRef = null;
	
	constructor(props) {
		super(props, {
			/**
			 * Currently selected date
			 * @type {Date|string}
			 */
			data: null,

			/**
			 * Calendar will open to this date if no date was selected
			 * @note If not specified, today will be used.
			 */
			openToDate: startOfMonth(new Date()),
		}, {
			domPrefix: 'date-input-component',
			enableLoadOnDataPropChange: true,
			dataPropAlias: 'value'
		});
		
		// Event handlers
		this.handleCalendarInputBlur = this.handleCalendarInputBlur.bind(this);
		this.handleCalendarInputKeydown = this.handleCalendarInputKeydown.bind(this);

		// Custom component methods
		this.getValue = this.getValue.bind(this);
		this.getValueLocale = this.getValueLocale.bind(this);
		this.getValueFormat = this.getValueFormat.bind(this);
		this.getRenderLocale = this.getRenderLocale.bind(this);
		this.getRenderFormat = this.getRenderFormat.bind(this);
		this.getMinValue = this.getMinValue.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);
		this.getOpenToDate = this.getOpenToDate.bind(this);
		this.roundValue = this.roundValue.bind(this);
		
		// Insert value methods
		this.containsInsertValue = this.containsInsertValue.bind(this);

		// GUI methods
		this.handleBlur = this.handleBlur.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		// Load initial 'openToDate'
		const {openToDate} = this.props;
		if (openToDate) {
			let newOpenToDate = this.getDataToLoad(openToDate);
			if (!isValid(newOpenToDate)) newOpenToDate = this.getMaxValue();
			if (!isValid(newOpenToDate)) newOpenToDate = startOfMonth(new Date());
			await this.setState({openToDate: newOpenToDate});
		}

		return Promise.resolve();
	}
	
	componentDidMount() {
		super.componentDidMount();

		const inputs = getArray(this.calendarRef.querySelectorAll('input'));

		// Prevent scroll events in calendar inputs
		// @note This is done for performance reasons because date and time picker component triggers onChange 
		// every time any value change and values change on mouse wheel event. Third party date picker component 
		// used does not have an option to disable mouse wheel events, so it is done manually here. This is 
		// however not a perfect solution because mouse wheel event will be completely disabled when the mouse is
		// over the calendar input fields.
		if (this.calendarRef && this.getProp('preventInputScroll') === true) {
			inputs.forEach(input => input.addEventListener("wheel", e => e.preventDefault(), {passive: false}));
		}

		// Handle DatePicker input element focus to allow 'blur' detection of the whole DateInput component
		if (this.calendarRef) {
			each(this.calendarRef.querySelectorAll('.react-date-picker__inputGroup input'),
				/** @param {Element|HTMLElement} input */
				input => {
					input.addEventListener('blur', this.handleCalendarInputBlur);
					input.addEventListener('keydown', this.handleCalendarInputKeydown);
				});
		}

		// Set 'readOnly' attribute to input fields if 'preventInput' prop is true
		if (this.calendarRef && this.getProp('preventInput') === true) {
			inputs.forEach(input => input.setAttribute("readOnly", true));
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {openToDate} = this.props;
		
		// Handle 'openToDate' prop changes
		if (!isEqual(openToDate, prevProps.openToDate)) {
			let newOpenToDate = this.getDataToLoad(openToDate);
			if (!isValid(newOpenToDate)) newOpenToDate = this.getMaxValue();
			if (!isValid(newOpenToDate)) newOpenToDate = startOfMonth(new Date());
			this.setState({openToDate: newOpenToDate}).then();
		}
		
		// Load data
		if (this.getOption('enableLoadOnDataPropChange', false)) {
			const dataPropName = this.getDataPropName();
			const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
			const prevData = (dataPropName ? prevProps[this.getDataPropName()] : prevProps);

			// If prop data changes load it into local component's state
			// NOTE: Only crude comparison is done (no custom data class support) because load method will check that 
			// before changing local state. This simple check is done just to prevent calling load method on every prop
			// change for optimization.
			if(!isEqual(data, prevData)) {
				return this.load(data)
					.then(() => {
						const valueDate = this.getData();
						if (isValid(valueDate)) this.setState({openToDate: valueDate}).then();
					});
			}
		}
		return Promise.resolve(this.state);
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();

		// Remove calendar inputs event listeners
		if (this.calendarRef) {
			if (this.getProp('preventInputScroll') === true) {
				getArray(this.calendarRef.querySelectorAll('input')).forEach(input => {
					input.removeEventListener("wheel", e => e.preventDefault(), {passive: false});
				});
			}

			each(this.calendarRef.querySelectorAll('.react-date-picker__inputGroup input'),
				/** @param {Element|HTMLElement} input */
				input => {
					input.addEventListener('blur', this.handleCalendarInputBlur);
					input.addEventListener('keydown', this.handleCalendarInputKeydown);
				});
		}
	}
	
	
	// Event handlers ---------------------------------------------------------------------------------------------------
	/**
	 * Handle calendar input element blur event
	 * @param {FocusEvent} event - Calendar input element blur event.
	 */
	handleCalendarInputBlur(event) {
		const target = event.target;
		const nextTarget = event.relatedTarget;

		// Only trigger component blur event if the next target (the next element receiving the 
		// focus) is not a sibling of the target element or if it is, it is not an input element. 
		// This is done because all DatePicker component inputs (year, month, day, ...) are siblings,
		// and we don't want to trigger the components global 'blur' event while any of those inputs 
		// are still in focus.
		if (!areElementsSiblings(target, nextTarget) || nextTarget.tagName.toLowerCase() !== 'input') {
			this.handleBlur().then();
		}
	}

	/**
	 * Handle calendar input element key down event
	 * @param {KeyboardEvent} event - Calendar input element key down event.
	 */
	handleCalendarInputKeydown(event) {
		const input = event.target;
		
		// Handle Escape key press
		if(event.key === 'Escape') {
			// Call blur handler, blur inputs and close the calendar
			// @note Blur handler is called to reset invalid values if necessary.
			this.handleBlur()
				.then(() => {
					input.blur();
					if (this.calendarComponentRef) this.calendarComponentRef.closeCalendar();
				});
		}
		// Handle Enter key press
		else if(event.key === 'Enter') {
			// Call blur handler, close the calendar and trigger 'onEnterKey' event
			// @note Blur handler is called to reset invalid values if necessary.
			this.handleBlur()
				.then(() => {
					if (this.calendarComponentRef) this.calendarComponentRef.closeCalendar();
					executeComponentCallback(this.props.onEnterKey, event);
				});
		}
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		if (typeof rawData === 'string' && this.containsInsertValue(rawData)) return rawData;
		else return getDate(rawData, this.getValueFormat(), this.getValueLocale());
	}
	

	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Get locale used for converting received value date string into Date
	 * @return {string} Locale code (IETF).
	 */
	getValueLocale() {
		const {useAppLocale, valueLocale, appLocale} = this.props;

		let result = '';
		if (valueLocale) result = valueLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}

	/**
	 * Get value format used for converting received value date string into Date
	 * @return {string}
	 */
	getValueFormat() {
		const {valueFormat, appLocale} = this.props;
		
		if (Array.isArray(valueFormat)) {
			if (valueFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(valueFormat[0]) ?
						getLocaleDateFormat(appLocale, valueFormat[0]) : valueFormat[0]
				);
				const dateTimeSeparator = valueFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(valueFormat[2]) ?
						getLocaleTimeFormat(appLocale, valueFormat[2]) : valueFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return '';
			}
		} else {
			return (
				LOCALE_DATE_FORMAT_NAMES.includes(valueFormat) ?
					getLocaleDateFormat(appLocale, valueFormat) : valueFormat
			);
		}
	}

	/**
	 * Get locale used for rendering the date input element
	 * @return {string} Locale code (IETF).
	 */
	getRenderLocale() {
		const {useAppLocale, renderLocale, appLocale} = this.props;

		let result = '';
		if (renderLocale) result = renderLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}

	/**
	 * Get date format used to render the date input element
	 * @return {string}
	 */
	getRenderFormat() {
		const {renderFormat, useAppLocale, outputLocale, appLocale} = this.props;
		const defaultFormat = (!outputLocale && useAppLocale ?
			getLocaleDateFormat(appLocale, LOCALE_DATE_FORMAT_NAME.SHORT) : STANDARD_DATE_TIME_FORMAT.MYSQL_DATE
		);
		
		if (Array.isArray(renderFormat)) {
			if (renderFormat.length === 3) {
				const dateFormat = (
					LOCALE_DATE_FORMAT_NAMES.includes(renderFormat[0]) ?
						getLocaleDateFormat(appLocale, renderFormat[0]) : renderFormat[0]
				);
				const dateTimeSeparator = renderFormat[1];
				const timeFormat = (
					LOCALE_TIME_FORMAT_NAMES.includes(renderFormat[2]) ?
						getLocaleTimeFormat(appLocale, renderFormat[2]) : renderFormat[2]
				);
				return dateFormat + dateTimeSeparator + timeFormat;
			} else {
				return defaultFormat;
			}
		} else {
			return (
				typeof renderFormat !== 'undefined' ?
					(
						LOCALE_DATE_FORMAT_NAMES.includes(renderFormat) ?
							getLocaleDateFormat(appLocale, renderFormat) : renderFormat
					) : 
					defaultFormat
			);
		}
	}

	/**
	 * Get minimal allowed date to select
	 * @return {Date}
	 */
	getMinValue() {
		const {minValue} = this.props;
		return getDate(minValue, this.getValueFormat(), this.getValueLocale());
	}

	/**
	 * Get maximal allowed date to select
	 * @return {Date}
	 */
	getMaxValue() {
		const {maxValue} = this.props;
		return getDate(maxValue, this.getValueFormat(), this.getValueLocale());
	}

	/**
	 * Get open to date
	 * @return {Date} It will always return the start of the month do display. This is done because the third-party 
	 * datepicker component expects the start of the month.
	 */
	getOpenToDate() {
		const {openToDate} = this.state;
		return startOfMonth(getDate(openToDate, this.getValueFormat(), this.getValueLocale()));
	}

	/**
	 * Round value based on 'maxDetail' and 'roundTo' props
	 *
	 * @param {Date} value - Date to round.
	 * @return {Date}
	 */
	roundValue(value) {
		const {maxDetail, roundTo} = this.props;
		
		if (value instanceof Date) {
			if (roundTo === DATETIME_ROUND_TO.START) {
				switch (maxDetail) {
					case 'month': return startOfDay(value);
					case 'year': return startOfMonth(value);
					case 'decade': return startOfYear(value);
					case 'century': return startOfDecade(value);
					default: return value;
				}
			} else if (roundTo === DATETIME_ROUND_TO.END) {
				switch (maxDetail) {
					case 'month': return endOfDay(value);
					case 'year': return endOfMonth(value);
					case 'decade': return endOfYear(value);
					case 'century': return endOfDecade(value);
					default: return value;
				}
			} else {
				return value;
			}
		} else {
			return value;
		}
	}
	
	
	// Insert value methods ---------------------------------------------------------------------------------------------
	/**
	 * Check if string contains any insert value
	 * @param {string} string - String to check
	 * @return {boolean}
	 */
	containsInsertValue(string) {
		const {insertValueType, insertValueTypeOptions} = this.props;
		switch (insertValueType) {
			case INSERT_VALUE_BUTTON_TYPE.DIALOG:
				/** @type {InsertValueDialogSectionDataObject[]} */
				const sections = getArray(insertValueTypeOptions, 'dialogProps.sections');
				for (let i of sections) if (i.contains(string)) return true;
				return false;
				
			case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
				// TODO: dropdown insert value
				return false;
				
			default:
				return false;
		}
	}
	

	// GUI methods ------------------------------------------------------------------------------------------------------
	/**
	 * Handle component's 'blur' event
	 * @note This component manually detects 'blur' event when all internal input elements loose focus.
	 * @return {Promise<*>}
	 */
	handleBlur() {
		// Reset invalid values
		if (isAnyInputInvalid(this.calendarRef, true)) {
			const currentData = this.getData();
			// Reset to the previous valid date
			if (currentData instanceof Date) return this.setData(null).then(() => this.setData(toDate(currentData)));
			// Clear the value if there was no previous valid date
			else {
				const minDate = this.getMinValue();
				const maxDate = this.getMaxValue();
				const dummyDate = (minDate ? minDate : (maxDate ? maxDate : new Date()));

				// IMPORTANT! This is a 'dirty' fix for the 'react-date-picker' third-party component. As of this release 
				// 'react-date-picker' does not handle clearing invalid input values properly so we must set some dummy 
				// valid date first before we can clear it with null.
				// @see https://github.com/wojtekmaj/react-datetime-picker/issues/91
				// TODO: Change this if 'react-date-picker' component gets fixed.
				return this.setData(dummyDate)
					// Clear datetime picker
					.then(() => this.setData(null));
			}
		}
		return Promise.resolve();
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, calendarClassName, name, readOnly, disabled, disableCalendar, formControlStyle, closeOnSelect, 
			showLeadingZeros, onCalendarOpen, onCalendarClose, maxDetail, dayPlaceholder, monthPlaceholder, 
			yearPlaceholder, showInsertValueButton, insertValueButtonProps, insertValueType, insertValueTypeOptions,
			insertValueMethod
		} = this.props;
		const data = this.getData();
		const hasInsertValue = (typeof data === 'string' && this.containsInsertValue(data));

		return (
			!readOnly ?
				hasInsertValue ?
					<>
						{
							!disabled && showInsertValueButton ?
								<InsertValueButton
									buttonProps={{
										displayStyle: BUTTON_STYLE.NONE,
										className: 'input-toolbar-button',
										...insertValueButtonProps
									}}
									insertType={insertValueType}
									insertTypeOptions={insertValueTypeOptions}
									parentSelector={`.${this.getDomId()} .input-toolbar.position-left`}
									onInsert={
										insertValueMethod === 'insert' ? v => this.insertInputRef?.insertValue(v) :
										insertValueMethod === 'update' ? v => this.insertInputRef?.updateValue(v) :
										null
									}
									onDialogClose={() => this.insertInputRef?.inputRef.focus()}
								/>
								: null
						}
						<TextInput
							wrapperClassName={
								`${this.getDomId()} ${this.getOption('domPrefix')} datepicker-text-input ` +
								`insert-value-input`
							}
							className={
								`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className}`
							}
							name={name}
							value={data}
							onChange={e => {
								const newValue = getString(e, 'target.value');
								if (this.containsInsertValue(newValue)) {
									this.setData(newValue).then(() => executeComponentCallback(this.props.onChange, newValue));
								} else {
									this.setData('').then(() => executeComponentCallback(this.props.onChange, ''));
								}
							}}
							inputToolbarButtons={
								!disabled ?
									[
										{
											key: 'calendar',
											className: 'input-toolbar-button',
											position: TEXT_INPUT_TOOLBAR_POSITION.LEFT,
											icon: 'calendar',
											displayType: BUTTON_DISPLAY_TYPE.NONE,
											onClick: () => this.clearData()
												.then(() => this.update()
												.then(() => this.calendarComponentRef.openCalendar()))
										},
										{
											key: 'clear',
											className: 'input-toolbar-button',
											position: TEXT_INPUT_TOOLBAR_POSITION.RIGHT,
											icon: 'times',
											displayType: BUTTON_DISPLAY_TYPE.NONE,
											onClick: () => this.clearData().then(() => this.update())
										}
									] 
									: 
									[]
							}
							ref={node => { this.insertInputRef = node; }}
						/>
					</>
					:
					<>
						{
							!disabled && showInsertValueButton ?
								<InsertValueButton
									buttonProps={{
										displayStyle: BUTTON_STYLE.NONE,
										className: 'input-toolbar-button',
										...insertValueButtonProps
									}}
									insertType={insertValueType}
									insertTypeOptions={insertValueTypeOptions}
									parentSelector={`.${this.getDomId()} .react-date-picker__wrapper`}
									onInsert={this.setData}
									onDialogClose={() => this.insertInputRef?.inputRef.focus()}
								/>
								: null
						}
						<DatePicker
							maxDetail={maxDetail}
							calendarClassName={`calendar ${calendarClassName}`}
							calendarIcon={!disabled ? <Icon symbol="calendar" /> : null}
							clearIcon={!disabled ? <Icon symbol={icon_font_close_symbol} /> : null}
							className={
								`${this.getOption('domPrefix')} datepicker ${formControlStyle ? 'form-control' : ''} ` +
								`${className} ${this.getDomId()}`
							}
							closeCalendar={closeOnSelect}
							disabled={disabled}
							disableCalendar={disableCalendar}
							locale={this.getRenderLocale()}
							maxDate={this.getMaxValue()}
							minDate={this.getMinValue()}
							format={this.getRenderFormat()}
							name={name}
							value={isValid(data) ? data : null}
							activeStartDate={this.getOpenToDate()}
							onActiveStartDateChange={
								({activeStartDate}) => this.setState({openToDate: activeStartDate})
							}
							showLeadingZeros={showLeadingZeros}
							dayPlaceholder={dayPlaceholder}
							monthPlaceholder={monthPlaceholder}
							yearPlaceholder={yearPlaceholder}
							onCalendarOpen={onCalendarOpen}
							onCalendarClose={onCalendarClose}
							onChange={v => executeComponentCallback(this.props.onChange, this.roundValue(v))}
							ref={node => {
								if (node) {
									this.calendarRef = node.wrapper;
									this.calendarComponentRef = node;
								}
							}}
						/>
					</>
				:
				<div
					className={
						`${this.getOption('domPrefix')} datepicker readonly ${className} ` +
						`${formControlStyle ? ' form-control ' : ''}`
					}
				>
					{
						hasInsertValue ?
							<Label content={data} />
							:
							<DateLabel
								inputDate={data}
								outputFormat={this.getRenderFormat()}
								outputLocale={getDateLocaleByCode(this.getRenderLocale())}
							/>
					}
				</div>
		);
	}
	
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DateInput.propTypes = {
	// Datepicker class attribute
	className: PropTypes.string,
	// Calendar class attribute
	calendarClassName: PropTypes.string,
	// Input name attribute
	name: PropTypes.string,
	// Datepicker value
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Min. allowed value that can be selected
	minValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Max. allowed value that can be selected
	maxValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Open calendar to the specific date without selecting it
	openToDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	// Flag specifying if datepicker should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if the datepicker should be disabled
	disabled: PropTypes.bool,
	// When set to true, will remove the calendar and the button toggling its visibility
	disableCalendar: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// When true, once the value has been selected, the datepicker will be automatically closed
	closeOnSelect: PropTypes.bool,
	// Flag that determines if current app locale will be used for both input and output
	useAppLocale: PropTypes.bool,
	// Value datetime format
	// @note This supports LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale 
	// formats (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If array, first item should be date 
	// format, second item should be date-time separator and third item should be time format.
	valueFormat: PropTypes.string,
	// Locale code (IETF) to use for converting received value date string into Date
	// @note If specified, 'useAppLocale' prop will be ignored.
	valueLocale: PropTypes.string,
	// Datetime format used to render the date input element
	// @note This supports LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale 
	// formats (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If array, first item should be date 
	// format, second item should be date-time separator and third item should be time format.
	renderFormat: PropTypes.string,
	// Locale code (IETF) to use for rendering the date input element
	// @note If specified, 'useAppLocale' prop will be ignored.
	renderLocale: PropTypes.string,
	// Whether leading zeros should be rendered in date inputs
	showLeadingZeros: PropTypes.bool,
	// The most detailed calendar view that the user shall see
	maxDetail: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
	// Specify if value should be rounded to the start or end of the 'maxDetail' (see DATETIME_ROUND_TO const)
	// @note If not specified, 'start' will be used.
	// @see https://date-fns.org/v2.16.1/docs/parse#arguments -> 'referenceDate'
	roundTo: PropTypes.oneOf(['start', 'end']),
	// Placeholder for the day input
	dayPlaceholder: PropTypes.string,
	// Placeholder for the month input
	monthPlaceholder: PropTypes.string,
	// Placeholder for the year input
	yearPlaceholder: PropTypes.string,
	// Prevent input scroll event form triggering
	// @note This can be useful if component onChange event triggers some IO action or other expensive operation to 
	// prevent it from triggering when date input is in focus. Default functionality of third-party date picker component
	// used as a basis for this component, changes the value of the focused date input section (day, month, ...) on mouse 
	// wheel scroll event.
	preventInputScroll: PropTypes.bool,
	// Prevent text input into the input fields by making them read-only (setting 'readOnly' attribute)
	preventInput: PropTypes.bool,
	
	// Flag that determines if insert value button component (InsertValueButton) will be rendered inside the input
	showInsertValueButton: PropTypes.bool,
	// Insert value button props
	insertValueButtonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is 
		// passed to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 
		// 'dangerouslySetInnerHTML' to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Insert type
	insertValueType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES),
	// Insert type options
	// @note Options depend on 'insertValueType'.
	insertValueTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]),
	// Specify if value should be inserted at the cursor position ('insert') or replaced the current value ('update')
	insertValueMethod: PropTypes.oneOf(['insert', 'update']),

	// Events
	onChange: PropTypes.func, // Arguments: {Date} - New value
	onCalendarOpen: PropTypes.func, // Arguments: no arguments
	onCalendarClose: PropTypes.func, // Arguments: no arguments
	onEnterKey: PropTypes.func, // Arguments: keypress event
};

/**
 * Define component default values for own props
 */
DateInput.defaultProps = {
	className: '',
	calendarClassName: '',
	name: '',
	value: null,
	minValue: null,
	maxValue: null,
	readOnly: false,
	disabled: false,
	disableCalendar: false,
	formControlStyle: true,
	closeOnSelect: true,
	useAppLocale: true,
	valueFormat: STANDARD_DATE_TIME_FORMAT.MYSQL_DATE,
	showLeadingZeros: false,
	maxDetail: 'month',
	roundTo: DATETIME_ROUND_TO.START,
	dayPlaceholder: 'dd',
	monthPlaceholder: 'mm',
	yearPlaceholder: 'yyyy',
	preventInputScroll: true,
	preventInput: false,
	showInsertValueButton: false,
	insertValueMethod: 'insert',
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(DateInput);
import styles from "./index.module.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE} from "Core/components/DialogComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {reducerStoreKey} from "../../popups/QuestionnairePopup/reducer";
import {selectors} from "Core/store/reducers";
import {getString, isset} from "Core/helpers/data";
import Label from "Core/components/display/Label";
import {icon_font_loading_symbol, icon_font_spin_class} from "Config/app";
import {clearQuestionnaireResultAction} from "../../popups/QuestionnairePopup/actions";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	questionnaireResult: selectors[reducerStoreKey].getQuestionnaireResult(state),
});

class QuestionnaireResultDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			dialogType: DIALOG_TYPE.MESSAGE,
			translationPath: `${pageConfig.translationPath}.QuestionnairePopup.QuestionnaireResult`,
		});
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
	
		const {clearQuestionnaireResultAction} = this.props;
		clearQuestionnaireResultAction();
	}

	render() {
		/** @type {QuestionnaireResultDataObject} */
		const questionnaireResult = this.getProp('questionnaireResult');
		const osaRiskMessage = getString(questionnaireResult, 'osaRiskMessage');
		const noOsaRisk = (osaRiskMessage === 'There is no OSA risk.');
		const isLowOsaRisk = (osaRiskMessage === 'There is a low OSA risk. Consider a sleep study.'); 
		const isHighOsaRisk = (osaRiskMessage === 'High Risk - A sleep study is recommended.'); 

		return this.renderDialog(
			(
				questionnaireResult === null ? this.renderCustomIconTitle(icon_font_loading_symbol, '', `title ${icon_font_spin_class}`) :
				isset(questionnaireResult) ? this.renderSuccessTitle('') :
				this.renderErrorTitle('')
			),
			(
				questionnaireResult === null ?
					<div className={`${styles['wrapper']}`}>
						<Label
							element="div"
							elementProps={{className: styles['loading']}}
							content={this.t('loading_label')}
							supportHtml={true}
						/>
					</div>
				: isset(questionnaireResult) ?
					<div className={`${styles['wrapper']}`}>
						<Label
							element="div"
							elementProps={{className: styles['dialogTitle']}}
							content={this.t('thank_you')}
						/>

						<Label
							element="div"
							elementProps={{className: styles['sectionTitle']}}
							content={this.t('osa_section_title')}
							supportHtml={true}
						/>
						<Label
							element="div"
							elementProps={{
								className: 
									`${styles['osaRiskMessage']} ${noOsaRisk ? 'success-color' : ''} ` +
									`${isLowOsaRisk ? 'warning-color' : ''} ${isHighOsaRisk ? 'error-color' : ''}`
							}}
							content={(
								this.hasTranslation(osaRiskMessage, p => `${p}.osaRiskMessage`) ?
									this.tt(osaRiskMessage, 'osaRiskMessage') :
									this.t('no_osa_risk_message')
							)}
						/>
					</div>
				:
					<div className={`${styles['wrapper']}`}>
						<Label
							element="div"
							elementProps={{className: styles['dialogErrorTitle']}}
							content={this.t('failed')}
						/>
					</div>
			)
		);
	}
}

export default connect(mapStateToProps, {clearQuestionnaireResultAction})(QuestionnaireResultDialog);
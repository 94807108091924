import {get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'ticket_float_popup';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@ticket_float_popup/reset',
	SET: '@ticket_float_popup/set',
	CLEAR: '@ticket_float_popup/clear',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	set: data => ({type: REDUCER_TYPES.SET, data}),
	clear: () => ({type: REDUCER_TYPES.CLEAR}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {NewTicketDataObject} */
	data: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return {...initialState};

		case REDUCER_TYPES.SET: return {...state, data: {...action.data}};
		case REDUCER_TYPES.CLEAR: return {...state, data: {...initialState.data}};
		
		default: return state;
	}
};

// Selectors
export const selectors = {
	getTicketFloatPopupData: state => get(state, [reducerStoreKey, 'data']),
};

export default reducer;
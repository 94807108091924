import styles from "./index.module.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from "Core/components/DialogComponent";
import PropTypes from "prop-types";
import * as pageConfig from "../../config";
import Label from "Core/components/display/Label";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {getAppLocaleDateFormat, getAppLocaleDatetimeFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "Core/const/locale";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import {Tooltip} from "react-tippy";
import {icon_font_close_symbol, icon_font_edit_symbol} from "Config/app";
import {PATIENT_DIALOG_TYPES, PATIENT_GENDER_GENDER_MAP} from "../../const";

class PatientSelectionDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			dialogType: DIALOG_TYPE.NONE,
			translationPath: `${pageConfig.translationPath}.dialogs.PatientSelectionDialog`,
		});
		
		// Render methods
		this.renderButtons = this.renderButtons.bind(this);
		this.renderSELECT = this.renderSELECT.bind(this);
		this.renderUPDATE = this.renderUPDATE.bind(this);
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render action buttons
	 * @note Custom action buttons are render and styled so that the dialog will look more like it did on the old portal.
	 * 
	 * @return {JSX.Element}
	 */
	renderButtons() {
		const {dialogGUIID} = this.props;
		
		return (
			<div className={`${styles['buttons']}`}>
				<Button
					icon={icon_font_close_symbol}
					label={this.t('Close', 'general')}
					onClick={this.close}
					tabIndex={1}
				/>
				<Tooltip
					title={this.t('newPatientBtnTooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={true}
				>
					<Button
						icon="user-plus"
						label={this.t('newPatientBtn')}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => executeComponentCallback(this.props.onCreateNew, dialogGUIID)}
						tabIndex={2}
					/>
				</Tooltip>
				<Tooltip
					title={this.t('updatePatientBtnTooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={true}
				>
					<Button
						icon={icon_font_edit_symbol}
						label={this.t('updatePatientBtn')}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => executeComponentCallback(this.props.onUpdateCurrent, dialogGUIID)}
						tabIndex={2}
					/>
				</Tooltip>
			</div>
		);
	}
	
	/**
	 * Render patient select dialog content
	 * @note This will be rendered when user changes the patient info and there is another patient in the system with the 
	 * same info.
	 * 
	 * @return {JSX.Element}
	 */
	renderSELECT() {
		const {type, data, dialogGUIID} = this.props;

		return (
			<div className={`${styles['wrapper']}`}>
				<Label element="p" content={this.tt('message', type)} supportHtml={true} />
				<ul>
					<Label element="li" content={this.tt('option1', type)} supportHtml={true} />
					<Label element="li" content={this.tt('option2', type)} supportHtml={true} />
					<Label element="li" content={this.tt('option3', type)} supportHtml={true} />
				</ul>
				
				<DataTable
					id="select-patient-table"
					highlightOnHover={true}
					columns={[
						{
							name: 'firstName',
							label: this.tt('firstName', type),
							widthLessThanLabel: true,
						},
						{
							name: 'lastName',
							label: this.tt('lastName', type),
							widthLessThanLabel: true,
						},
						{
							name: 'middleName',
							label: this.tt('middleName', type),
							widthLessThanLabel: true,
						},
						{
							name: 'medicalRecordId',
							label: this.tt('refId', type),
							width: 1,
						},
						{
							name: 'dateOfBirth',
							label: this.tt('dateOfBirth', type),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT),
								whiteSpace: 'nowrap',
							},
							width: 1,
						},
						{
							name: 'gender',
							label: this.tt('gender', type),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: row => <Label 
									content={this.t(PATIENT_GENDER_GENDER_MAP[row.gender], 'constants.gender')}
								/>
							}
						},
						{
							name: 'creationDate',
							label: this.tt('creationDate', type),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								inputFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.SHORT, 
									LOCALE_TIME_FORMAT_NAME.SHORT
								),
								whiteSpace: 'nowrap',
							},
							width: 1,
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								content: row => (
									<Button 
										className={`${styles['patientSelectBtn']}`}
										label={this.tt('patientSelectBtn', type)}
										displayStyle={BUTTON_STYLE.ACTION}
										onClick={() => executeComponentCallback(this.props.onSelectExisting, dialogGUIID, row.id)}
										tabIndex={3}
									/>
								),
								standardWrapper: false,
								whiteSpace: 'nowrap',
							},
							width: 1,
							stopPropagation: true,
						}
					]}
					data={data}
					paginationType={PAGINATION_TYPE.NONE}
				/>
				{this.renderButtons()}
			</div>
		);
	}

	/**
	 * Render patient update dialog content
	 * @note This will be rendered when user changes the patient info and there is no patient in the system with the same 
	 * info.
	 *
	 * @return {JSX.Element}
	 */
	renderUPDATE() {
		const {type} = this.props;
		
		return (
			<div className={`${styles['wrapper']}`}>
				<Label element="p" content={this.tt('message', type)} supportHtml={true} />
				<ul>
					<Label element="li" content={this.tt('option1', type)} supportHtml={true} />
					<Label element="li" content={this.tt('option2', type)} supportHtml={true} />
				</ul>
				{this.renderButtons()}
			</div>
		);
	}
	
	render() {
		const {type} = this.props;
		return this.renderDialog(this.renderWarningTitle(), this[`render${type}`]());
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientSelectionDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,
	
	type: PropTypes.oneOf(PATIENT_DIALOG_TYPES).isRequired,
	data: PropTypes.array,
	
	// Events
	onSelectExisting: PropTypes.func,
	onCreateNew: PropTypes.func,
	onUpdateCurrent: PropTypes.func,
};

export default PatientSelectionDialog;
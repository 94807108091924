import {cloneDeep} from "lodash";
import {getString, getBoolean, getNumber, getBoolFromTinyInt, getTinyIntFormBool} from "Core/helpers/data";
import {AccountSettingsItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AccountSettingsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountSettingsItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'abmAccountId'),
		getBoolean(result, 'sendEmailWhenStatusTechReviewRequired'),
		getString(result, 'emailsStatusTechReviewRequired'),
		getBoolean(result, 'sendEmailWhenStatusTechReviewCompleted'),
		getString(result, 'emailsStatusTechReviewCompleted'),
		getBoolean(result, 'sendEmailToInterpretingPhysicians'),
		getString(result, 'interpretingPhysicianEmail'),
		getBoolean(result, 'sendEmailWhenStatusInterpretationCompleted'),
		getString(result, 'emailsStatusInterpretationCompleted'),
		getBoolean(result, 'sendEmailWhenStatusFinalReportAvailable'),
		getString(result, 'emailsStatusFinalReportAvailable'),
		getNumber(result, 'desatSetting'),
		getNumber(result, 'selectedProcessingSetting'),
		getBoolFromTinyInt(result, 'selectedL2'),
		getBoolFromTinyInt(result, 'selectedAbnormalN3'),
		getBoolFromTinyInt(result, 'selectedNRSwH'),
		getBoolean(result, 'includeReportLetterhead'),
		getString(result, 'reportLetterheadPath'),
		getString(result, 'letterHeadFileName')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountSettingsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		desatSetting: result.desatSetting,
		sendEmailToInterpretingPhysicians: result.sendEmailToInterpretingPhysicians,
		interpretingPhysicianEmail: result.interpretingPhysicianEmail,
		sendEmailWhenStatusInterpretationCompleted: result.sendEmailInterpretationCompleted,
		emailsStatusInterpretationCompleted: result.emailsInterpretationCompleted,
		sendEmailWhenStatusTechReviewRequired: result.sendEmailTechReviewRequired,
		emailsStatusTechReviewRequired: result.emailsTechReviewRequired,
		sendEmailWhenStatusTechReviewCompleted: result.sendEmailTechReviewCompleted,
		emailsStatusTechReviewCompleted: result.emailsTechReviewCompleted,
		sendEmailWhenStatusFinalReportAvailable: result.sendEmailFinalReportAvailable,
		emailsStatusFinalReportAvailable: result.emailsFinalReportAvailable,
		includeReportLetterhead: result.includeLetterhead,
		reportLetterheadPath: result.letterheadPath,
		selectedL2: getTinyIntFormBool(result, 'selectedL2', 0, false, false),
		selectedAbnormalN3: getTinyIntFormBool(result, 'selectedAbnormalN3', 0, false, false),
		selectedNRSwH: getTinyIntFormBool(result, 'selectedNRSwH', 0, false, false),
		selectedProcessingSetting: result.selectedProcessingSetting,
		
		// Form data properties
		shouldSaveReportLetterheadImage: result.shouldSaveLetterhead, 
		multipartLetterheadFile: result.shouldSaveLetterhead ? result.letterheadFormData : '',
	};

	// Set proper empty values expected by the API
	// @note This is commented out since requests that use FormData have backend issues with string values cleared by 
	// null values instead of empty strings.
	//result = resetObjectValues(result);

	return result;
}
import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import Label from "Core/components/display/Label";
import {getApplicationMaintenanceMessage} from "../../../helpers/applicationParameters";

class MaintenanceMessage extends BaseComponent {
	constructor(props) {
		super(props);

		// Initiate component's state
		this.state = {
			/**
			 * Maintenance message
			 * @type {string}
			 */
			message: '',
		};
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();
		
		// Get maintenance message and set it in local state
		await getApplicationMaintenanceMessage()
			.then(message => this.setState({message}));

		return Promise.resolve();
	}

	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const {message} = this.state;
		
		// Do not render component if maintenance message is empty or undefined
		return !!message;
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {className, element} = this.props;
		const {message} = this.state;
		
		return (
			<Label
				element={element}
				elementProps={{id: this.getDomId(), className}}
				content={message}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MaintenanceMessage.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	element: PropTypes.string,
};

/**
 * Define component default values for own props
 */
MaintenanceMessage.defaultProps = {
	id: '',
	className: '',
	element: 'div',
};

export default MaintenanceMessage; 
import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {cloneDeep, get, omit, uniqBy} from "lodash";
import AsyncSelect from 'react-select/async';
import {getArray, getBoolean, getString, isset} from "Core/helpers/data";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "Core/components/advanced/InsertValueButton/const";
import InsertValueButton from "Core/components/advanced/InsertValueButton";
import Button, {BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "Core/components/display/Button";
import * as ReactDOM from "react-dom";
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from "./const";
import {SELECT_INPUT_TOOLBAR_POSITIONS} from "Core/components/input/SelectInput/const";
import {getCssSizeString, getCumulativeScroll, getElementAbsolutePos} from "Core/helpers/dom";
import {main_layout_element} from "Config/app";
import {Tooltip} from "react-tippy";

/**
 * Asynchronous select component
 * @description Select component where options are loaded asynchronously usually based on user input (auto-suggest).
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 *
 * This component uses 'react-select/async' component.
 */
class SelectAsyncInput extends BaseComponent {
	constructor(props) {
		super(props, { 
			translationPath: 'SelectAsyncInput',
			domPrefix: 'select-input-component',
			domManipulationIntervalTimeout: 1,
		});
		
		// Initiate component's state
		this.state = {
			/**
			 * Menu top position using alternative position calculation
			 * @note This is used only if 'forceDefaultMenuPlacement' prop is false.
			 * @type {number|undefined}
			 */
			menuTop: undefined
		};

		// Custom component methods
		this.onKeyDown = this.onKeyDown.bind(this);

		// Insert value methods
		this.containsInsertValue = this.containsInsertValue.bind(this);

		// Render methods
		this.renderInsertButton = this.renderInsertButton.bind(this);
		this.renderToolbar = this.renderToolbar.bind(this);
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		// Handle menu position
		// @note This is added because AsyncSelect component does not calculate auto menu placement properly and is only 
		// used if 'menuRenderInLayout' prop is true.
		const {forceDefaultMenuPlacement, menuRenderInLayout} = this.props;
		if (!forceDefaultMenuPlacement && menuRenderInLayout) {
			const element = this.getDomElement();
			const menuParentElement = document.querySelector(main_layout_element);
			const menuElement = menuParentElement?.querySelector(
				`:scope > [class*='__menu-portal'] > [class*='__menu']`
			);
			if (menuElement) {
				const elementPos = getElementAbsolutePos(element, menuParentElement);
				const elementScroll = getCumulativeScroll(element);
				const elementViewportOffset = element.getBoundingClientRect();
				const predictedMenuTop = elementViewportOffset.top + element.offsetHeight + menuElement.offsetHeight;
				const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
				
				const calculatedMenuTop = (
					predictedMenuTop > viewportHeight ?
						// If menu is outside the layout move it above the select element
						elementPos.top - elementScroll.top - menuElement.offsetHeight :
						// If menu is inside the layout move it below the select element
						elementPos.top - elementScroll.top + element.offsetHeight
				);
				
				if (this.state.menuTop !== calculatedMenuTop) this.setState({menuTop: calculatedMenuTop}).then();
			} else if (isset(this.state.menuTop)) {
				this.setState({menuTop: undefined}).then();
			}
		} else if (isset(this.state.menuTop)) {
			this.setState({menuTop: undefined}).then();
		}
	}


	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support Enter key event and better handle focus on Escape key event.
	 *
	 * @param {KeyboardEvent} event - Keydown event.
	 */
	onKeyDown(event) {
		const {isMulti, onChange} = this.props;
		const menuIsOpen = getBoolean(this, 'element.props.menuIsOpen');

		// Trigger standard keydown event
		executeComponentCallback(this.props.onKeyDown, event);

		// Trigger Enter key event if menu is closed since when it is opened Enter key is used to select a value.
		if (!menuIsOpen && event.key === 'Enter') executeComponentCallback(this.props.onEnterKey, event);

		// Add all filtered items on Shift+Enter
		if (isMulti && menuIsOpen && event.key === 'Enter' && event.shiftKey) {
			// Prevent default Enter key press form adding the selected value
			event.preventDefault();
			
			// Add all filtered values
			const updatedValue = [
				...getArray(this.element?.getValue()),
				...getArray(this.element?.getCategorizedOptions())
					.filter(co => (!co.isDisabled && co.type === 'option'))
					.map(o => o.data)
			];
			onChange(updatedValue);
			// Clear input because default Enter key press was prevented, and it was responsible for clearing it
			this.element?.onInputChange('', {action: 'input-change', prevInputValue: this.element?.inputRef.value});
		}

		// Stop propagation of the Escape key if menu is opened
		// @description This is done to prevent parent elements from catching the Escape key down while menu is opened
		// because we explicitly want Escape key to just close the menu. For example, this is necessary to prevent closing
		// a dialog, that closes on Escape key press, when Escape key is pressed on a focused input.
		if (menuIsOpen && event.key === 'Escape') {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
		}
		// Stop propagation of the Escape key and blur the element if it is focused
		else if (!menuIsOpen && event.key === 'Escape') {
			// Stop propagation of the Escape key if menu is opened
			if (document.activeElement === document.querySelector(`#${this.getDomId()}-input`)) {
				event.stopPropagation();
				event.nativeEvent.stopImmediatePropagation();
				this.element.blur();
			}
		}
	}


	// Insert value methods ---------------------------------------------------------------------------------------------
	/**
	 * Check if string contains any insert value
	 * @param {string} string - String to check
	 * @return {boolean}
	 */
	containsInsertValue(string) {
		const {insertValueType, insertValueTypeOptions} = this.props;
		switch (insertValueType) {
			case INSERT_VALUE_BUTTON_TYPE.DIALOG:
				/** @type {InsertValueDialogSectionDataObject[]} */
				const sections = getArray(insertValueTypeOptions, 'dialogProps.sections');
				for (let i of sections) if (i.contains(string)) return true;
				return false;

			case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
				// TODO: dropdown insert value
				return false;

			default:
				return false;
		}
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render insert button
	 * @return {JSX.Element}
	 */
	renderInsertButton() {
		const {value, onChange, isMulti, insertValueButtonProps, insertValueType, insertValueTypeOptions} = this.props;
		return (
			<InsertValueButton
				buttonProps={{
					displayStyle: BUTTON_STYLE.NONE,
					className: 'input-toolbar-button',
					...insertValueButtonProps
				}}
				insertType={insertValueType}
				insertTypeOptions={insertValueTypeOptions}
				onInsert={v => {
					if (isMulti) {
						let values = getArray(cloneDeep(value));
						const insertValues = getArray(v);
						insertValues.forEach(iv => values.push({label: iv, value: iv}));

						// Remove duplicates
						values = uniqBy(values, 'value');

						onChange(values);
					} else {
						onChange({label: v, value: v});
					}
				}}
				onDialogClose={() => {
					this.element.focus();
					if (isMulti) {
						let ev = new Event('mousedown', { bubbles: true });
						ev.simulated = true;
						document.querySelector(`.${this.getDomId()} .select-input__control`)
							.dispatchEvent(ev);
					}
				}}
			/>
		);
	}

	/**
	 * Render a toolbar inside the select
	 * @param {AsyncSelectInputToolbarPosition} position - Position of the toolbar.
	 * @return {ReactNode|null}
	 */
	renderToolbar(position) {
		const {toolbarButtons, isDisabled, showInsertValueButton} = this.props;
		const positionToolbarButtons = toolbarButtons.filter(b => b.position === position);
		const portalElement = document.querySelector(`.${this.getDomId()} .select-input__control`);

		if (portalElement) {
			return ReactDOM.createPortal(
				(
					<div className={`input-toolbar position-${position}`}>
						{positionToolbarButtons.map((btnProps, index) => {
							const ButtonComponent = get(btnProps, 'component', Button);
							return (
								getString(btnProps, 'tooltip') ?
									<Tooltip
										key={index}
										tag="span"
										title={getString(btnProps, 'tooltip')}
										size="small"
										position="top-center"
										arrow={true}
										interactive={false}
									>
										<ButtonComponent
											{...omit(btnProps, ['className', 'onClick', 'component', 'tooltip'])}
											className={`${getString(btnProps, 'className')} input-toolbar-button`}
											onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
										/>
									</Tooltip>
									:
									<ButtonComponent
										key={index}
										{...omit(btnProps, ['className', 'onClick', 'component', 'tooltip'])}
										className={`${getString(btnProps, 'className')} input-toolbar-button`}
										onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
									/>
							);
						})}
						{
							position === ASYNC_SELECT_INPUT_TOOLBAR_POSITION.LEFT && !isDisabled && showInsertValueButton ?
								this.renderInsertButton() :
								null
						}
					</div>
				),
				portalElement
			);
		}
		return null;
	}
	
	render() {
		const {
			className, classNamePrefix, value, noOptionsMessage, placeholder, onChange, formControlStyle, isMulti,
			isDisabled, showInsertValueButton, insertValueButtonProps, insertValueType, insertValueTypeOptions, 
			isClearable, menuRenderInLayout, styles, loadingMessage, ...otherProps
		} = this.props;
		const {menuTop} = this.state;

		// Prepare portal related props
		let portalProps = {};
		if (menuRenderInLayout) {
			const element = this.getDomElement();
			const layoutElem = document.querySelector(main_layout_element);
			const fontSize = (element ? getComputedStyle(this.getDomElement()).getPropertyValue('font-size') : '');
			const top = (menuTop ? {top: getCssSizeString(menuTop)} : {});
			portalProps.styles = (
				styles ?
					{...styles, menuPortal: base => ({...base, zIndex: 9999, fontSize, ...top})} :
					{menuPortal: base => ({...base, zIndex: 9999, fontSize, ...top})}
			);
			portalProps.menuPortalTarget = layoutElem;
		}
		
		return (
			<>
				{this.renderToolbar(ASYNC_SELECT_INPUT_TOOLBAR_POSITION.LEFT)}
				<AsyncSelect
					inputId={`${this.getDomId()}-input`}
					id={this.getDomId()}
					className={
						`${this.getOption('domPrefix')} async ${className} ${formControlStyle ? 'form-control' : ''} ` +
						`${this.getDomId()} ${showInsertValueButton ? 'has-insert-btn' : ''}`
					}
					classNamePrefix={`select-input${classNamePrefix ? ' ' + classNamePrefix : ''}`}
					noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => this.t('No options')}
					loadingMessage={loadingMessage ? loadingMessage : () => this.t('Loading ...')}
					placeholder={placeholder ? placeholder : this.t('Select ...')}
					value={value}
					isDisabled={isDisabled}
					isClearable={isClearable}
					isMulti={isMulti}
					onChange={onChange}
					onKeyDown={this.onKeyDown}
					{...portalProps}
					{...omit(otherProps, ['inputId', 'id', 'onKeyDown'])}
					ref={node => this.element = node}
				/>
				{this.renderToolbar(ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT)}
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SelectAsyncInput.propTypes = {
	// The id to set on the SelectContainer component
	id: PropTypes.string,
	// Apply a className to the control
	className: PropTypes.string,
	// Apply classNames to inner elements with the given prefix
	classNamePrefix: PropTypes.string,
	// Disable the control
	isDisabled: PropTypes.bool,
	// Allow the user to select multiple values
	isMulti: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Text to display when there are no options
	noOptionsMessage: PropTypes.func,
	// Text to display when loading options
	loadingMessage: PropTypes.func,
	// Placeholder for the select value
	placeholder: PropTypes.string,
	// Control the current value
	value: PropTypes.any,
	// Is the select value clearable
	isClearable: PropTypes.bool,
	// Flag that determines if menu will be rendered in main layout element (see 'main_layout_element' config value) 
	// using a portal
	// @note If you encounter issues with menu placement try setting 'forceDefaultMenuPlacement' to true.
	menuRenderInLayout: PropTypes.bool,
	// Flag that determines if default AsyncSelect menu placement will be used instead of alternative one calculated by
	// this component. Enable this if you encounter issues with menu placement. 
	// @note This is considered only if 'menuRenderInLayout' is true.
	forceDefaultMenuPlacement: PropTypes.bool,

	// Toolbar buttons that will be shown in the select
	// @note If custom component is used this should contain props for that component.
	toolbarButtons: PropTypes.arrayOf(PropTypes.shape({
		// Position where button will be rendered (left or right)
		position: PropTypes.oneOf(SELECT_INPUT_TOOLBAR_POSITIONS),
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,
		// Component to render instead of the Button
		component: PropTypes.elementType,
		// Tooltip content
		// @note HTML is not supported.
		tooltip: PropTypes.string,

		// Events
		onClick: PropTypes.func,
	})),

	// Flag that determines if insert value button component (InsertValueButton) will be rendered inside the input
	showInsertValueButton: PropTypes.bool,
	// Insert value button props
	insertValueButtonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is 
		// passed to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Insert type
	insertValueType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES),
	// Insert type options
	// @note Options depend on 'insertValueType'.
	insertValueTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]),

	// Events
	onChange: PropTypes.func, // Arguments: selected option object
	onEnterKey: PropTypes.func, // Arguments: keypress event
	// ... react-select prop types (@link https://react-select.com/props)
};

/**
 * Define component default values for own props
 */
SelectAsyncInput.defaultProps = {
	id: '',
	className: '',
	classNamePrefix: '',
	isDisabled: false,
	isMulti: false,
	formControlStyle: true,
	isClearable: false,
	menuRenderInLayout: true,
	forceDefaultMenuPlacement: false,
	toolbarButtons: [],
	showInsertValueButton: false,
};

export default SelectAsyncInput;
import {
	auth_default_auto_token,
	io_default_request_options,
	io_default_response_data_type,
	messages_default_auto_hide_after,
	pagination_default_per_page
} from "Config/index";
import {getIOUrl} from "Core/io/helper";
import {addErrorMessageAction} from "Core/components/global/Message";
import {ioFormDataRequest, ioJsonRequest, ioStandardRequest, ioUploadRequest} from "Core/io";
import {SORT_ORDER} from "Core/const/global";
import {getBool} from "Core/helpers/data";

/**
 * Low-level action used for making raw (URL based) IO requests
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} url - IO url.
 * @param {any} data - Data to send in the request.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored. 
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method,
 * so you need to be EXTRA CAREFUL when using this option.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @return {function(*): *}
 */
export const rawIoAction = ({
	requestFunction, url, data, method = 'post', autoToken = auth_default_auto_token, token = '', 
	options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}, manualCatch = false, 
	hideLoadingFunction
}) => dispatch => {
	return requestFunction({
		url,
		data,
		method,
		autoToken,
		token,
		options,
		headers,
		responseType,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction,
		isDownload,
		downloadFilename,
		handleDownloadError,
		...otherRequestOptions
	})
		.then(responseData => {
			// Hide loading overlay if specified
			if (hideLoadingFunction) hideLoadingFunction();
			// Return response data
			return responseData;
		})
		.catch(error => {
			if (manualCatch) throw error;
			else {
				// Render error if it is not 'AbortError' or 'hidden' flag is not true
				// @note Error should be an object with 'message' field already translated and ready for display.
				if (error.name !== 'AbortError' && !getBool(error, 'hidden')) {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
				// Hide loading overlay if specified
				if (hideLoadingFunction) hideLoadingFunction();
			}
		});
}

/**
 * Low-level action used for making IO requests
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api='defaultApi'] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method,
 * so you need to be EXTRA CAREFUL when using this option.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @return {function(*): *}
 */
export const ioAction = ({
	requestFunction, api = 'defaultApi', endpoint, data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type,
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}, manualCatch = false,
	hideLoadingFunction
}) => dispatch => {
	const url = getIOUrl(api, endpoint);
	return requestFunction({
		url,
		api,
		endpoint,
		data,
		method,
		autoToken,
		token,
		options,
		headers,
		responseType,
		abortCallback,
		canPause,
		useCaptcha,
		captchaAction,
		isDownload,
		downloadFilename,
		handleDownloadError,
		...otherRequestOptions
	})
		.then(responseData => {
			// Hide loading overlay if specified
			if (hideLoadingFunction) hideLoadingFunction();
			// Return response data
			return responseData;
		})
		.catch(error => {
			if (manualCatch) throw error;
			else {
				// Render error if it is not 'AbortError' or 'hidden' flag is not true
				// @note Error should be an object with 'message' field already translated and ready for display.
				if (error.name !== 'AbortError' && !getBool(error, 'hidden')) {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
				// Hide loading overlay if specified
				if (hideLoadingFunction) hideLoadingFunction();
			}
		});
}

/**
 * Action used for fetching IO data
 * @note Usually used for table data.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*): *}
 */
export const ioFetchAction = ({
	requestFunction, api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, 
	perPage = pagination_default_per_page, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	headers = null, responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false, 
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, 
	data: { query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir, ...additionalData},
	hideLoadingFunction, autoToken, token, method, options, headers, responseType, abortCallback, useCaptcha, 
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for finding IO data
 * @note Usually used for autosuggestion options.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method,
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFindAction = ({
	requestFunction, api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, 
	hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post', 
	options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', 
	handleDownloadError = false, otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, 
	data: { query, maxResults: (maxResults > -1 ? maxResults : ''), sortBy, sortDir, ...additionalData }, 
	hideLoadingFunction, autoToken, token, method, options, headers, responseType, abortCallback, useCaptcha, 
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for saving IO data
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioSaveAction = ({
	requestFunction, api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	headers = null, responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false, 
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: {...data, requestSavedData, ...additionalData}, hideLoadingFunction, autoToken,
	token, method, options, headers, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for deleting IO data
 * @note Usually used for deleting data selected in a table.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioDeleteAction = ({
	requestFunction, api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, headers = null, 
	responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false, captchaAction = '', 
	isDownload = false, downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}, 
	manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: { ids: (Array.isArray(ids) && ids.length > 0 ? ids : []), ...additionalData}, 
	hideLoadingFunction, autoToken, token, method, options, headers, responseType, abortCallback, useCaptcha, 
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for fetching one item from IO
 * @note Usually used for deleting data selected in a table.
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFetchItemAction = ({
	requestFunction, api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, headers = null, 
	responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false, captchaAction = '', 
	isDownload = false, downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}, 
	manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: {id, ...additionalData}, hideLoadingFunction, autoToken, token, method, 
	options, headers, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, 
	handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for finding one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioFindItemAction = ({
	requestFunction, api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	headers = null, responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioAction({
	requestFunction, api, endpoint, data: { filter, advancedFilter, ...additionalData }, hideLoadingFunction, autoToken, 
	token, method, options, headers, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for creating/saving one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioSaveItemAction = ({
	requestFunction, api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	headers = null, responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioSaveAction({
	requestFunction, api, endpoint, data: {...data, id}, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, headers, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);

/**
 * Action used for deleting one item from IO
 *
 * @param {Function} requestFunction - Function that makes the request and returns a promise (like ioRequest,
 * ioStandardRequest, ioJsonRequest, ...).
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @param {boolean} [manualCatch=false] - If true, request will not catch exceptions automatically displaying
 * appropriate error message to the user. This means that exceptions must be caught manually using the 'catch' method, 
 * so you need to be EXTRA CAREFUL when using this option.
 * @return {function(*=): *}
 */
export const ioDeleteItemAction = ({
	requestFunction, api, endpoint, ids='', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	headers = null, responseType = io_default_response_data_type, abortCallback = () => {}, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}, manualCatch = false
}) => dispatch => ioDeleteAction({
	requestFunction, api, endpoint, ids: (Array.isArray(ids) ? ids : [ids]), additionalData, hideLoadingFunction, 
	autoToken, token, method, options, headers, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions, manualCatch
})(dispatch);




// Standard request ----------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using a standard request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioStandardAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options, 
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions
})(dispatch);

/**
 * Same as 'ioStandardAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioStandardManualAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false, 
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioStandardRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions, manualCatch: true
})(dispatch);

/**
 * Action used for fetching IO data using a standard request
 * @note Usually used for table data.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioStandardFetchAction = (
	abortCallback = () => {},
	api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, perPage = pagination_default_per_page, 
	sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioFetchAction({
	requestFunction: ioStandardRequest, api, endpoint, query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir, 
	additionalData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, 
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for finding IO data using a standard request
 * @note Usually used for autosuggestion options.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFindAction = (
	abortCallback = () => {},
	api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindAction({
	requestFunction: ioStandardRequest, api, endpoint, query, maxResults, sortBy, sortDir, additionalData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, 
	isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for saving IO data using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardSaveAction = (
	abortCallback = () => {},
	api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveAction({
	requestFunction: ioStandardRequest, api, endpoint, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting IO data using a standard request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardDeleteAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type, 
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioDeleteAction({
	requestFunction: ioStandardRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, 
	handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for fetching one item from IO using a standard request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFetchItemAction = (
	abortCallback = () => {},
	api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioFetchItemAction({
	requestFunction: ioStandardRequest, api, endpoint, id, additionalData, hideLoadingFunction, autoToken, token, method,
	options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions
})(dispatch);

/**
 * Action used for finding one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardFindItemAction = (
	abortCallback = () => {},
	api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindItemAction({
	requestFunction: ioStandardRequest, api, endpoint, filter, advancedFilter, additionalData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for creating/saving one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardSaveItemAction = (
	abortCallback = () => {},
	api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveItemAction({
	requestFunction: ioStandardRequest, api, endpoint, id, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting one item from IO using a standard request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioStandardDeleteItemAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioDeleteItemAction({
	requestFunction: ioStandardRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, 
	handleDownloadError, otherRequestOptions
})(dispatch);




// JSON request --------------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using a JSON request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioJsonAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioJsonRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options, 
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions
})(dispatch);

/**
 * Same as 'ioJsonAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to send in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioJsonManualAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioJsonRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions, manualCatch: true
})(dispatch);


/**
 * Action used for fetching IO data using a JSON request
 * @note Usually used for table data.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioJsonFetchAction = (
	abortCallback = () => {},
	api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, perPage = pagination_default_per_page,
	sortBy = '', sortDir = SORT_ORDER.ASC, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, 
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioFetchAction({
	requestFunction: ioJsonRequest, api, endpoint, query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir, 
	additionalData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, 
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for finding IO data using a JSON request
 * @note Usually used for autosuggestion options.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFindAction = (
	abortCallback = () => {},
	api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindAction({
	requestFunction: ioJsonRequest, api, endpoint, query, maxResults, sortBy, sortDir, additionalData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, 
	isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for saving IO data using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonSaveAction = (
	abortCallback = () => {},
	api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveAction({
	requestFunction: ioJsonRequest, api, endpoint, data, additionalData, requestSavedData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting IO data using a JSON request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonDeleteAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioDeleteAction({
	requestFunction: ioJsonRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, method, 
	options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions
})(dispatch);

/**
 * Action used for fetching one item from IO using a JSON request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFetchItemAction = (
	abortCallback = () => {},
	api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioFetchItemAction({
	requestFunction: ioJsonRequest, api, endpoint, id, additionalData, hideLoadingFunction, autoToken, token, method, 
	options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError, 
	otherRequestOptions
})(dispatch);

/**
 * Action used for finding one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonFindItemAction = (
	abortCallback = () => {},
	api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindItemAction({
	requestFunction: ioJsonRequest, api, endpoint, filter, advancedFilter, additionalData, hideLoadingFunction, 
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, 
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for creating/saving one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonSaveItemAction = (
	abortCallback = () => {},
	api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction, 
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options, 
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveItemAction({
	requestFunction: ioJsonRequest, api, endpoint, id, data, additionalData, requestSavedData, 
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, 
	isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting one item from IO using a JSON request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioJsonDeleteItemAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', 
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false, 
	otherRequestOptions = {}
) => dispatch => ioDeleteItemAction({
	requestFunction: ioJsonRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, 
	handleDownloadError, otherRequestOptions
})(dispatch);




// Form data request ---------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using a FormData request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} data - Data to send in the request. Object will be converted into FormData using the 'jsonToFormData'
 * function.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioFormDataAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioFormDataRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions
})(dispatch);

/**
 * Same as 'ioFormDataAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} data - Data to send in the request. Object will be converted into FormData using the 'jsonToFormData'
 * function.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioFormDataManualAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioFormDataRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions, manualCatch: true
})(dispatch);


/**
 * Action used for fetching IO data using a FormData request
 * @note Usually used for table data.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {number} [pageNo=1] - Current page number used in pagination (starts from 1).
 * @param {number} [perPage] - Items per page used in pagination.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioFormDataFetchAction = (
	abortCallback = () => {},
	api, endpoint, query = '', filter = null, advancedFilter = null, pageNo = 1, perPage = pagination_default_per_page,
	sortBy = '', sortDir = SORT_ORDER.ASC, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token,
	token = '', method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioFetchAction({
	requestFunction: ioFormDataRequest, api, endpoint, query, filter, advancedFilter, pageNo, perPage, sortBy, sortDir,
	additionalData, hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha,
	captchaAction, isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for finding IO data using a FormData request
 * @note Usually used for autosuggestion options.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [query=''] - Search query.
 * @param {number} [maxResults=-1] - Maximal number of result to get.
 * @param {string} [sortBy=''] - Sort column.
 * @param {string} [sortDir='asc'] - Sort direction ('asc' or 'desc').
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataFindAction = (
	abortCallback = () => {},
	api, endpoint, query = '', maxResults = -1, sortBy = '', sortDir = 'asc', additionalData = {}, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindAction({
	requestFunction: ioFormDataRequest, api, endpoint, query, maxResults, sortBy, sortDir, additionalData,
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction,
	isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for saving IO data using a FormData request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {any} data - Data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataSaveAction = (
	abortCallback = () => {},
	api, endpoint, data, additionalData = {}, requestSavedData = false, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveAction({
	requestFunction: ioFormDataRequest, api, endpoint, data, additionalData, requestSavedData, hideLoadingFunction,
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload,
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting IO data using a FormData request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string[]} ids - IDs of IO items to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataDeleteAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '',
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioDeleteAction({
	requestFunction: ioFormDataRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token, 
	method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, 
	handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for fetching one item from IO using a FormData request
 * @note Usually used for deleting data selected in a table.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} id - ID of an IO item to fetch.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataFetchItemAction = (
	abortCallback = () => {},
	api, endpoint, id, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '',
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioFetchItemAction({
	requestFunction: ioFormDataRequest, api, endpoint, id, additionalData, hideLoadingFunction, autoToken, token, method,
	options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions
})(dispatch);

/**
 * Action used for finding one item from IO using a FormData request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} [filter=null] - Simple filter object with 'column: value' pairs.
 * @param {Object} [advancedFilter=null] - Advance filter.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataFindItemAction = (
	abortCallback = () => {},
	api, endpoint, filter = null, advancedFilter = null, additionalData = {}, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioFindItemAction({
	requestFunction: ioFormDataRequest, api, endpoint, filter, advancedFilter, additionalData, hideLoadingFunction,
	autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload,
	downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for creating/saving one item from IO using a FormData request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string} [id=''] - ID of the IO item to save. If empty IO should create a new item.
 * @param {any} data - Item data to save.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataSaveItemAction = (
	abortCallback = () => {},
	api, endpoint, id = '', data, additionalData = {}, requestSavedData = false, hideLoadingFunction,
	autoToken = auth_default_auto_token, token = '', method = 'post', options = io_default_request_options,
	responseType = io_default_response_data_type, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false, otherRequestOptions = {}
) => dispatch => ioSaveItemAction({
	requestFunction: ioFormDataRequest, api, endpoint, id, data, additionalData, requestSavedData,
	hideLoadingFunction, autoToken, token, method, options, responseType, abortCallback, useCaptcha, captchaAction,
	isDownload, downloadFilename, handleDownloadError, otherRequestOptions
})(dispatch);

/**
 * Action used for deleting one item from IO using a FormData request
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {string|number|string[]|number[]} [ids=''] - ID or array of IDs of the IO item to delete.
 * @param {object} [additionalData={}] - Any additional data that should be passed in the request.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*=): *}
 */
export const ioFormDataDeleteItemAction = (
	abortCallback = () => {},
	api, endpoint, ids, additionalData = {}, hideLoadingFunction, autoToken = auth_default_auto_token, token = '',
	method = 'post', options = io_default_request_options, responseType = io_default_response_data_type,
	useCaptcha = false, captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioDeleteItemAction({
	requestFunction: ioFormDataRequest, api, endpoint, ids, additionalData, hideLoadingFunction, autoToken, token,
	method, options, responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename,
	handleDownloadError, otherRequestOptions
})(dispatch);




// Upload request ------------------------------------------------------------------------------------------------------
/**
 * Low-level action used for making IO requests using an upload request
 * @note You should probably use one of the other actions defined below :)
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} data - Data to send in the request. Object will be converted into FormData using the 'jsonToFormData'
 * function.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioUploadAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioUploadRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions
})(dispatch);

/**
 * Same as 'ioUploadAction' but without automatic exception catching
 * IMPORTANT: You will have to catch exception manually.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [api] - API to use (key of the 'io_base_urls' io config object).
 * @param {string} endpoint - IO endpoint (just the endpoint, base IO url will be added).
 * @param {Object} data - Data to send in the request. Object will be converted into FormData using the 'jsonToFormData'
 * function.
 * @param {Function} [hideLoadingFunction] - Function that hides the loading animation if it was previously shown.
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {object} [options] - Other request options tha will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @param {Object} [otherRequestOptions={}] - Any other request option that 'requestFunction' can accept.
 * @return {function(*): *}
 */
export const ioUploadManualAction = (
	abortCallback = () => {},
	api, endpoint, data, hideLoadingFunction, autoToken = auth_default_auto_token, token = '', method = 'post',
	options = io_default_request_options, responseType = io_default_response_data_type, useCaptcha = false,
	captchaAction = '', isDownload = false, downloadFilename = 'file', handleDownloadError = false,
	otherRequestOptions = {}
) => dispatch => ioAction({
	requestFunction: ioUploadRequest, api, endpoint, data, hideLoadingFunction, autoToken, token, method, options,
	responseType, abortCallback, useCaptcha, captchaAction, isDownload, downloadFilename, handleDownloadError,
	otherRequestOptions, manualCatch: true
})(dispatch);
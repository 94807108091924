import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Fetch all public portal documents that are not links and are valid
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [loadingSelector] - DOM selector of the element to display the loading overlay in.
 * @return {function(*): *}
 */
export const fetchHelpDocumentsAction = (abortCallback, loadingSelector) => dispatch => {
	const loading = loadingSelector ? showLoading(loadingSelector) : null;
	return ioJsonAction(
		abortCallback,
		'defaultApi',
		'public-document/fetch-all',
		null,
		(loading ? hideLoadingFunction(loading) : undefined)
	)(dispatch);
};
import PropTypes from "prop-types";
import PopupComponent, {
	POPUP_ACTION_BUTTON_LOCATION,
	PopupActionDataObject,
	PopupTabDataObject
} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, get, orderBy} from "lodash";
import {icon_font_close_symbol, icon_font_edit_symbol} from "Config/app";
import {getString, isset} from "Core/helpers/data";
import {TICKET_STATUS} from "../../../const";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../reducer";
import {BUTTON_STYLE} from "Core/components/display/Button";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	status: get(selectors[reducerStoreKey].getMyTicketsItem(state), 'status'),
});

class ItemPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.ItemPopup`,
			domPrefix: 'item-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Action methods
		this.resolveTicket = this.resolveTicket.bind(this);
		this.reopenTicket = this.reopenTicket.bind(this);

	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {status} = this.props;
		
		// Update dynamic actions that depend on status
		if (status !== prevProps.status) this.dynamicActionButtons({status}).then();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {Object} [dynamicValues={}] - Dynamic current state and props values action buttons depend on.
	 * @return {Promise<unknown>}
	 */
	async dynamicActionButtons({status}) {
		// Remove actions before deciding which ones to display
		await this.removeActions(['resolve-ticket-status', 're-open-ticket-status']);
		
		if (status === TICKET_STATUS.RESOLVED) {
			await this.addAction(new PopupActionDataObject({
				id: 're-open-ticket-status',
				location: POPUP_ACTION_BUTTON_LOCATION.GLOBAL,
				action: this.reopenTicket,
				buttonProps: {
					label: this.getTranslationPath('reopen_ticket_status_action'),
					icon: icon_font_edit_symbol,
					displayStyle: BUTTON_STYLE.ACTION,
				}
			}));
		} else if (status === TICKET_STATUS.PROCESSING) {
			await this.addAction(new PopupActionDataObject({
				id: 'resolve-ticket-status',
				location: POPUP_ACTION_BUTTON_LOCATION.GLOBAL,
				action: this.resolveTicket,
				buttonProps: {
					label: this.getTranslationPath('resolve_ticket_status_action'),
					icon: 'check',
					displayStyle: BUTTON_STYLE.SUCCESS,
				}
			}));
		}
	}
	
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const {status} = this.props;
		
		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({status});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			}),
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs();

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Resolve ticket by changing its status
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	resolveTicket(allTabsData, event, actionId) {
		const {changeMyTicketStatusAction, addSuccessMessageAction} = this.props;
		const ticketId = getString(allTabsData, 'MainTab.id');
		
		return this.executeAbortableAction(changeMyTicketStatusAction, ticketId, TICKET_STATUS.RESOLVED)
			.then(response => {
				if (response) addSuccessMessageAction(this.t('resolve_ticket_success_msg'));
				return response;
			});
	}
	
	/**
	 * Re-open ticket by changing its status
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	reopenTicket(allTabsData, event, actionId) {
		const {changeMyTicketStatusAction, addSuccessMessageAction} = this.props;
		const ticketId = getString(allTabsData, 'MainTab.id');
		
		return this.executeAbortableAction(changeMyTicketStatusAction, ticketId, TICKET_STATUS.PROCESSING)
			.then(response => {
				if (response) addSuccessMessageAction(this.t('reopen_ticket_success_msg'));
				return response;
			});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ItemPopup.propTypes = {
	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(ItemPopup);
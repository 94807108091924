import {
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioUploadAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch account settings list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchAccountSettingsListAction = (
	abortCallback, isSystemAdmin = false, filter = null, pageNo = 1, perPage, sortBy, sortDir,
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}account-settings/search`,
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load account settings list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadAccountSettingsListAction = (
	abortCallback, isSystemAdmin = false, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchAccountSettingsListAction(
		abortCallback, isSystemAdmin, filter, pageNo, perPage, sortBy, sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setAccountSettingsListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear account settings list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearAccountSettingsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearAccountSettingsListData());
}

/**
 * Fetch account settings item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string|number} id - DB ID of the account settings item to fetch.
 * @return {function(*=): Promise<AccountSettingsItemDataObject>}
 */
export const fetchAccountSettingsItemAction = (abortCallback, isSystemAdmin = false, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}account-settings/fetch-by-id`,
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load account settings item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - DB ID of the account settings item to load.
 * @return {function(*=): Promise<AccountSettingsItemDataObject>}
 */
export const loadAccountSettingsItemAction = (abortCallback, isSystemAdmin = false, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchAccountSettingsItemAction(abortCallback, isSystemAdmin, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setAccountSettingsItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear account settings item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountSettingsItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearAccountSettingsItem());
};

/**
 * Update account settings item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AccountSettingsItemDataObject} item - Account settings item to save.
 * @return {function(*=): Promise<AccountSettingsItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateAccountSettingsItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioUploadAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'account-settings/update-by-id',
		itemDataMap.output(item),
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setAccountSettingsItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => { 
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setAccountSettingsItem(updatedItem));
			return updatedItem; 
		});
};
import React from "react";
import {Switch} from "react-router-dom";
import {AppPageRoute} from "../../core/App";

// Import section pages
import * as defaultApp from "./default"

const AppsSection = () => (
	<div id="apps-section">
		<Switch>
			<>
				<AppPageRoute page={defaultApp} />
			</>
		</Switch>
	</div>
);

export default AppsSection;
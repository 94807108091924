import {cloneDeep} from "lodash";
import {resetObjectValues} from "Core/helpers/data";

/**
 * Get output data from input data
 * @param {NewTicketDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		subject: result.subject,
		name: result.name,
		phoneNumber: result.contact,
		body: result.body
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
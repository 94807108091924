/**
 * Data object representing the tickets list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class TicketsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} ticketId - List item's ID that will be displayed to user.
	 * @param {string} status - List item's status.
	 * @param {string} firstName - List item's first name.
	 * @param {string} lastName - List item's last name.
	 * @param {Date} creationDate - List item's creation date.
	 * @param {number} commentsNumber - List item's number of comments.
	 */
	constructor(id, ticketId, status, firstName, lastName, creationDate, commentsNumber) {
		this.id = id;
		this.ticketId = ticketId;
		this.status = status;
		this.firstName = firstName;
		this.lastName = lastName;
		this.creationDate = creationDate;
		this.commentsNumber = commentsNumber;
	}
}

/**
 * Data object representing the tickets item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class TicketsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [ticketId=''] - Item's ticket ID that will be displayed to the user.
	 * @param {string} [status=''] - Item's status.
	 * @param {string} [firstName=''] - Item's first name.
	 * @param {string} [lastName=''] - Item's last name.
	 * @param {Date} [creationDate=null] - Item's creation date.
	 * @param {TicketsItemCommentsDataObject[]} [comments=[]] - Item's array of comments.
	 */
	constructor(
		id = null, ticketId = '', status = '', firstName = '', lastName = '', creationDate = null, comments = []
	) {
		this.id = id;
		this.ticketId = ticketId;
		this.status = status;
		this.firstName = firstName;
		this.lastName = lastName;
		this.creationDate = creationDate;
		this.comments = comments;
	}
}


/**
 * Data object representing a singe comment of the tickets item
 */
export class TicketsItemCommentsDataObject {
	/**
	 * @param {string} id - Comment's DB ID.
	 * @param {Date} creationDate - Comment's creation date.
	 * @param {string} username - Username of the user that created the comment.
	 * @param {string} [title=''] - Comment's title entered by the user.
	 * @param {string} [name=''] - Comment's contact name entered by the user.
	 * @param {string} [contact=''] - Comment's contact information (phone number, etc.) entered by the user.
	 * @param {string} message - Comment message entered by the user.
	 */
	constructor(id, creationDate, username, title = '', name = '', contact = '', message) {
		this.id = id;
		this.creationDate = creationDate;
		this.username = username;
		this.title = title;
		this.name = name;
		this.contact = contact;
		this.message = message;
	}
}
import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {find, get} from "lodash";
import {getArray, getString} from "Core/helpers/data";
import AdvancedDropdown, {ADVANCED_DROPDOWN_POSITION} from "Core/components/display/AdvancedDropdown";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";
import {main_layout_element} from "Config/app";

class MainTableStudyEdits extends DataComponent {
	constructor(props) {
		super(props, {
			data: null,
			dataLoading: false,
			tokensLoading: [],
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'study-edits-dropdown',
			disableLoad: true,
		});
		
		// Refs
		/** @type {AdvancedDropdown} */
		this.dropdownRef = null;

		// Data methods
		this.loadStudyEditList = this.loadStudyEditList.bind(this);
		this.clearAllData = this.clearAllData.bind(this);

		// Action methods
		this.handleStudyEditItemClick = this.handleStudyEditItemClick.bind(this);
	}

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load the list of study edits
	 *
	 * @return {Promise<Object>}
	 */
	loadStudyEditList() {
		const {fetchStudiesListItemStudyEditListAction} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');

		return this.setState({dataLoading: true})
			.then(() => this.executeAbortableAction(
				{id: 'fetch-study-edits', action: fetchStudiesListItemStudyEditListAction},
				study.id
			))
			.then(response => this.setData(getArray(response, 'data')))
			.then(() => this.setState({dataLoading: false}));
	}

	/**
	 * Clear all component's relevant internal data
	 *
	 * @return {Promise<Object>}
	 */
	clearAllData() {
		// Abort fetching study edits
		this.abortAction('fetch-study-edits');
		
		return this.clearData()
			.then(() => this.setState({dataLoading: false, tokensLoading: []}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Handle study edit dropdown item click
	 * 
	 * @param {MouseEvent} event - Selected study edit dropdown item mouse click event.
	 * @param {Object} studyEdit - Selected study edit.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handleStudyEditItemClick(event, studyEdit) {
		const {fetchStudyEditTokenAction} = this.props;
		const {tokensLoading} = this.state;
		const isLoading = (get(find(tokensLoading, {id: studyEdit.id}), 'value') === true);
			
		// Only fetch the token if it is not loading and href is not set. Once the href is set (token has been fetched), 
		// regular click event should take over.
		if (!isLoading && !event.target.href) {
			return this.setStateArrayItem('tokensLoading', {id: studyEdit.id}, {value: true})
				.then(() => this.executeAbortableAction(fetchStudyEditTokenAction, studyEdit.id))
				// Set study edit dropdown item href and simulate click
				.then(token => {
					event.target.href = process.env.REACT_APP_SPECIFIC_SLEEP_PROFILER_APP_STUDY_EDITOR_LINK_PREFIX + token;
					event.target.click();
				})
				.then(() => this.setStateArrayItem('tokensLoading', {id: studyEdit.id}, {value: false}))
				// Close the dropdown
				.then(() => { if (this.dropdownRef) this.dropdownRef.closeDropdown(); });
		}
		return Promise.resolve(this.state);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {disabled} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');
		const {dataLoading, tokensLoading} = this.state;
		const data = this.getData();
		
		return (
			<AdvancedDropdown
				className={this.getOption('domPrefix')}
				defaultPosition={ADVANCED_DROPDOWN_POSITION.LEFT}
				label={(
					<Button
						big={true}
						className="action-btn"
						displayStyle={disabled ? BUTTON_STYLE.SUBTLE : BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="heartbeat"
						iconProps={{symbolPrefix: 'icofont-'}}
					/>
				)}
				parent={main_layout_element}
				boundRect={document.querySelector(`.layout-page`)?.getBoundingClientRect()}
				loading={dataLoading}
				contentClassName="no-padding"
				contentSize="0.8rem"
				onOpen={this.loadStudyEditList}
				onClosed={this.clearAllData}
				disabled={disabled}
				ref={node => { this.dropdownRef = node; }}
			>
				{
					Array.isArray(data) ?
						<div
							className={`${this.getOption('domPrefix')}-item text-left`}
							data-group-label={true}
						>
							<Label
								content={this.t(
									(
										data.length === 0 ?
											'no_study_edits_label' :
											(data.length === 1 ? 'available_study_edit_label' : 'available_study_edits_label')
									),
									'',
									'',
									{count: data.length}
								)}
							/>
						</div>
						: null
				}
				{
					Array.isArray(data) && data.length > 0 ?
						data.map(i =>
							// eslint-disable-next-line
							<a
								key={`${study.id}_${getString(i, 'id')}`}
								className={`${this.getOption('domPrefix')}-item`}
								data-inactive={
									!find(tokensLoading, {id: i.id}) || 
									get(find(tokensLoading, {id: i.id}), 'value') === true
								}
								onClick={(e) => this.handleStudyEditItemClick(e, i)}
							>
								{
									get(find(tokensLoading, {id: i.id}), 'value') === true ?
										<Spinner size="1em" weight={1} className="item-loading" />
										:
										<Icon symbol="heartbeat" symbolPrefix="icofont-" className="item-icon" />
								}
								<Label
									//icon={get(i, 'finalStudyEdit') === true ? 'racing-flag-alt' : ''}
									//iconSymbolPrefix="icofont-"
									//iconClassName="final-icon"
									//iconPosition={LABEL_ICON_POSITION.RIGHT}
									content={getString(i, 'title')}
								/>
							</a>
						)
						: null
				}
			</AdvancedDropdown>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainTableStudyEdits.propTypes = {
	study: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
};

export default connect(null, getPageActions(actions))(MainTableStudyEdits);
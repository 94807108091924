/**
 * Data object for current user of the app
 */
export class CurrentUserDataObject {
	/**
	 * @param {string} id - User's ID in the database.
	 * @param {CurrentUserType} accountType - User account type.
	 * @param {string} username - Username of the user.
	 * @param {string} firstName - User's first name.
	 * @param {string} lastName - User's last name.
	 * @param {string} credentials - User's credentials.
	 * @param {boolean} isGuest - Flag that specifies if this is a guest user (guest login).
	 * @param {boolean} passwordExpires - Flag that specifies if password is set to expire for this user. If false, 
	 * 'passwordExpiresInDays' can be ignored. 
	 * @param {number|null} passwordExpiresInDays - Number of days until user's password expires. This is relevant only 
	 * if 'passwordExpires' flag is true.
	 */
	constructor(
		id, accountType, username, firstName, lastName, credentials, isGuest, passwordExpires, passwordExpiresInDays
	) {
		this.id = id;
		this.accountType = accountType;
		this.username = username;
		this.firstName = firstName;
		this.lastName = lastName;
		this.credentials = credentials;
		this.isGuest = isGuest;
		this.passwordExpires = passwordExpires;
		this.passwordExpiresInDays = passwordExpiresInDays;
	}
}
import {ioJsonSaveAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {isset} from "Core/helpers/data";
import * as itemDataMap from "./dataMap/item";
import {actionCreators} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import {hideOverlay, showOverlay} from "Core/helpers/overlay";

/**
 * Set ticket data to Redux store
 * @note This is done so that the ticket float popup data input by the user will persist when navigating through the app 
 * (changing pages). Data should be cleared when dialog closes.
 * 
 * @param {NewTicketDataObject} data - Ticket data entered by the user.
 * @return {(function(*): void)|*}
 */
export const setTicketDataAction = data => dispatch => {
	dispatch(actionCreators[reducerStoreKey].set(data));
};

/**
 * Clear ticket data from Redux store
 * @return {(function(*): void)|*}
 */
export const clearTicketDataAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clear());
}

/**
 * Create ticket item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {NewTicketDataObject} item - Ticket data to save.
 * @return {function(*=): Promise<NewTicketDataObject>} Promise that will resolve with the created item
 * received from IO or undefined if creation failed.
 */
export const createTicketItemAction = (abortCallback, item) => dispatch => {
	const popupHeaderOverlay = showOverlay('#floatPopup-CreateTicket .float-popup-header');
	const popupContentLoading = showLoading('#floatPopup-CreateTicket .float-popup-content-component');
	const floatButtonLoading = showLoading('body > .create-ticket-btn .icon',true, false, 12);
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'tickets/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		() => {
			hideOverlay(popupHeaderOverlay);
			hideLoading(popupContentLoading);
			hideLoading(floatButtonLoading);
		},
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? responseData.data : undefined);
};
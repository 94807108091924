export class NewTicketDataObject {
	/**
	 * @param {string} [subject=''] - New ticket's subject.
	 * @param {string} [name=''] - User specified name.
	 * @param {string} [contact=''] - User specified contact information (Phone number or email address).
	 * @param {string} [body=''] - New ticket's message body.
	 */
	constructor(subject = '', name = '', contact = '', body = '') {
		this.subject = subject;
		this.name = name;
		this.contact = contact;
		this.body = body;
	}
}
/** @typedef {string} RolePermissionResource */
export const ROLE_PERMISSION_RESOURCE_STUDY = 'STUDY';
export const ROLE_PERMISSION_RESOURCE_STUDY_STATUS = 'STUDY_STATUS';
export const ROLE_PERMISSION_RESOURCE_SYSTEM = 'SYSTEM';
export const ROLE_PERMISSION_RESOURCE_ACCOUNT = 'ACCOUNT';
export const ROLE_PERMISSION_RESOURCE_ACCOUNT_USER = 'ACCOUNT_USER';
export const ROLE_PERMISSION_RESOURCE_ROLE = 'ROLE';
export const ROLE_PERMISSION_RESOURCE_QUESTIONNAIRE = 'QUESTIONNAIRE';
export const ROLE_PERMISSION_RESOURCE_CBTI_STUDY = 'CBTI_STUDY';
export const ROLE_PERMISSION_RESOURCE_DEVICE = 'DEVICE';
export const ROLE_PERMISSION_RESOURCE_ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS';
/** @enum {RolePermissionResource} */
export const ROLE_PERMISSION_RESOURCE = {
	STUDY: ROLE_PERMISSION_RESOURCE_STUDY,
	STUDY_STATUS: ROLE_PERMISSION_RESOURCE_STUDY_STATUS,
	SYSTEM: ROLE_PERMISSION_RESOURCE_SYSTEM,
	ACCOUNT: ROLE_PERMISSION_RESOURCE_ACCOUNT,
	ACCOUNT_USER: ROLE_PERMISSION_RESOURCE_ACCOUNT_USER,
	ROLE: ROLE_PERMISSION_RESOURCE_ROLE,
	QUESTIONNAIRE: ROLE_PERMISSION_RESOURCE_QUESTIONNAIRE,
	CBTI_STUDY: ROLE_PERMISSION_RESOURCE_CBTI_STUDY,
	DEVICE: ROLE_PERMISSION_RESOURCE_DEVICE,
	ACCOUNT_SETTINGS: ROLE_PERMISSION_RESOURCE_ACCOUNT_SETTINGS,
};
/** @type {RolePermissionResource[]} */
export const ROLE_PERMISSION_RESOURCES = [
	ROLE_PERMISSION_RESOURCE_STUDY,
	ROLE_PERMISSION_RESOURCE_STUDY_STATUS,
	ROLE_PERMISSION_RESOURCE_SYSTEM,
	ROLE_PERMISSION_RESOURCE_ACCOUNT,
	ROLE_PERMISSION_RESOURCE_ACCOUNT_USER,
	ROLE_PERMISSION_RESOURCE_ROLE,
	ROLE_PERMISSION_RESOURCE_QUESTIONNAIRE,
	ROLE_PERMISSION_RESOURCE_CBTI_STUDY,
	ROLE_PERMISSION_RESOURCE_DEVICE,
	ROLE_PERMISSION_RESOURCE_ACCOUNT_SETTINGS,
];
/**
 * @note Only resources used in roles popup are defined here. This is done because some resource privileges are defined 
 * by the system and cannot be changes in the GUI.
 * @type {RolePermissionResource[]}
 */
export const ROLE_PERMISSION_RESOURCES_FOR_GUI = [
	ROLE_PERMISSION_RESOURCE_STUDY,
	ROLE_PERMISSION_RESOURCE_QUESTIONNAIRE,
	ROLE_PERMISSION_RESOURCE_DEVICE,
	ROLE_PERMISSION_RESOURCE_ACCOUNT,
	ROLE_PERMISSION_RESOURCE_ACCOUNT_USER,
	ROLE_PERMISSION_RESOURCE_ACCOUNT_SETTINGS,
];


/** @typedef {string} RolePermissionPrivilege */
export const ROLE_PERMISSION_PRIVILEGE_CREATE = 'CREATE';
export const ROLE_PERMISSION_PRIVILEGE_EDIT = 'EDIT';
export const ROLE_PERMISSION_PRIVILEGE_EDIT_INFO = 'EDIT_INFO';
export const ROLE_PERMISSION_PRIVILEGE_DOWNLOAD_INFO = 'DOWNLOAD_INFO';
export const ROLE_PERMISSION_PRIVILEGE_CHANGE_STATUS = 'CHANGE_STATUS';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_EDF = 'EXPORT_EDF';
export const ROLE_PERMISSION_PRIVILEGE_DEFAULT_ORDERING_PHYSICIAN = 'DEFAULT_ORDERING_PHYSICIAN';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_CUSTOM_EDF = 'EXPORT_CUSTOM_EDF';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH = 'EXPORT_EPOCH_BY_EPOCH';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH_DETAILS = 'EXPORT_EPOCH_BY_EPOCH_DETAILS';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_EVENTS = 'EXPORT_EVENTS';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_STUDY_SUMMARY = 'EXPORT_STUDY_SUMMARY';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_QUALITY_FILE = 'EXPORT_QUALITY_FILE';
export const ROLE_PERMISSION_PRIVILEGE_EXPORT_SYSTEM_DAT_FILE = 'EXPORT_SYSTEM_DAT_FILE';
export const ROLE_PERMISSION_PRIVILEGE_ADMIN = 'ADMIN';
export const ROLE_PERMISSION_PRIVILEGE_PSG2 = 'PSG2';
export const ROLE_PERMISSION_PRIVILEGE_VIEW = 'VIEW';
export const ROLE_PERMISSION_PRIVILEGE_MANAGE = 'MANAGE';
export const ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_SUBACCOUNTS = 'ASSIGN_TO_SUBACCOUNTS';
export const ROLE_PERMISSION_PRIVILEGE_BLOCK_SUBACCOUNT_DEVICES = 'BLOCK_SUBACCOUNT_DEVICES';
export const ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_MULTIPLE_SUBACCOUNTS = 'ASSIGN_TO_MULTIPLE_SUBACCOUNTS';
/** @enum {RolePermissionPrivilege} */
export const ROLE_PERMISSION_PRIVILEGE = {
	CREATE: ROLE_PERMISSION_PRIVILEGE_CREATE,
	EDIT: ROLE_PERMISSION_PRIVILEGE_EDIT,
	EDIT_INFO: ROLE_PERMISSION_PRIVILEGE_EDIT_INFO,
	DOWNLOAD_INFO: ROLE_PERMISSION_PRIVILEGE_DOWNLOAD_INFO,
	CHANGE_STATUS: ROLE_PERMISSION_PRIVILEGE_CHANGE_STATUS,
	EXPORT_EDF: ROLE_PERMISSION_PRIVILEGE_EXPORT_EDF,
	DEFAULT_ORDERING_PHYSICIAN: ROLE_PERMISSION_PRIVILEGE_DEFAULT_ORDERING_PHYSICIAN,
	EXPORT_CUSTOM_EDF: ROLE_PERMISSION_PRIVILEGE_EXPORT_CUSTOM_EDF,
	EXPORT_EPOCH_BY_EPOCH: ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH,
	EXPORT_EPOCH_BY_EPOCH_DETAILS: ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH_DETAILS,
	EXPORT_EVENTS: ROLE_PERMISSION_PRIVILEGE_EXPORT_EVENTS,
	EXPORT_STUDY_SUMMARY: ROLE_PERMISSION_PRIVILEGE_EXPORT_STUDY_SUMMARY,
	EXPORT_QUALITY_FILE: ROLE_PERMISSION_PRIVILEGE_EXPORT_QUALITY_FILE,
	EXPORT_SYSTEM_DAT_FILE: ROLE_PERMISSION_PRIVILEGE_EXPORT_SYSTEM_DAT_FILE,
	ADMIN: ROLE_PERMISSION_PRIVILEGE_ADMIN,
	PSG2: ROLE_PERMISSION_PRIVILEGE_PSG2,
	VIEW: ROLE_PERMISSION_PRIVILEGE_VIEW,
	MANAGE: ROLE_PERMISSION_PRIVILEGE_MANAGE,
	ASSIGN_TO_SUBACCOUNTS: ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_SUBACCOUNTS,
	BLOCK_SUBACCOUNT_DEVICES: ROLE_PERMISSION_PRIVILEGE_BLOCK_SUBACCOUNT_DEVICES,
	ASSIGN_TO_MULTIPLE_SUBACCOUNTS: ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_MULTIPLE_SUBACCOUNTS,
};
/** @type {RolePermissionPrivilege[]} */
export const ROLE_PERMISSION_PRIVILEGES = [
	ROLE_PERMISSION_PRIVILEGE_CREATE,
	ROLE_PERMISSION_PRIVILEGE_EDIT,
	ROLE_PERMISSION_PRIVILEGE_EDIT_INFO,
	ROLE_PERMISSION_PRIVILEGE_DOWNLOAD_INFO,
	ROLE_PERMISSION_PRIVILEGE_CHANGE_STATUS,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_EDF,
	ROLE_PERMISSION_PRIVILEGE_DEFAULT_ORDERING_PHYSICIAN,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_CUSTOM_EDF,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH_DETAILS,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_EVENTS,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_STUDY_SUMMARY,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_QUALITY_FILE,
	ROLE_PERMISSION_PRIVILEGE_EXPORT_SYSTEM_DAT_FILE,
	ROLE_PERMISSION_PRIVILEGE_ADMIN,
	ROLE_PERMISSION_PRIVILEGE_PSG2,
	ROLE_PERMISSION_PRIVILEGE_VIEW,
	ROLE_PERMISSION_PRIVILEGE_MANAGE,
	ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_SUBACCOUNTS,
	ROLE_PERMISSION_PRIVILEGE_BLOCK_SUBACCOUNT_DEVICES,
	ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_MULTIPLE_SUBACCOUNTS,
];

/**
 * Map privileges to permission resources
 * @note The order of the privileges determines the order in which they will be rendered.
 * @type {Object<RolePermissionResource, RolePermissionPrivilege[]>}
 */
export const ROLE_PERMISSION_PRIVILEGES_BY_RESOURCE = {
	[ROLE_PERMISSION_RESOURCE_STUDY]: [
		ROLE_PERMISSION_PRIVILEGE_CREATE, 
		ROLE_PERMISSION_PRIVILEGE_EDIT, 
		ROLE_PERMISSION_PRIVILEGE_CHANGE_STATUS,
		ROLE_PERMISSION_PRIVILEGE_DOWNLOAD_INFO, 
		ROLE_PERMISSION_PRIVILEGE_EDIT_INFO, 
		ROLE_PERMISSION_PRIVILEGE_DEFAULT_ORDERING_PHYSICIAN, 
		ROLE_PERMISSION_PRIVILEGE_EXPORT_EDF, 
		ROLE_PERMISSION_PRIVILEGE_EXPORT_CUSTOM_EDF, 
		ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH,
		ROLE_PERMISSION_PRIVILEGE_EXPORT_EPOCH_BY_EPOCH_DETAILS, 
		ROLE_PERMISSION_PRIVILEGE_EXPORT_STUDY_SUMMARY,
		ROLE_PERMISSION_PRIVILEGE_EXPORT_QUALITY_FILE,
		ROLE_PERMISSION_PRIVILEGE_EXPORT_SYSTEM_DAT_FILE,
		ROLE_PERMISSION_PRIVILEGE_EXPORT_EVENTS,
	],
	[ROLE_PERMISSION_RESOURCE_QUESTIONNAIRE]: [
		ROLE_PERMISSION_PRIVILEGE_CREATE,
		ROLE_PERMISSION_PRIVILEGE_EDIT,
	],
	[ROLE_PERMISSION_RESOURCE_DEVICE]: [
		ROLE_PERMISSION_PRIVILEGE_VIEW,
		ROLE_PERMISSION_PRIVILEGE_MANAGE,
		ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_SUBACCOUNTS,
		ROLE_PERMISSION_PRIVILEGE_ASSIGN_TO_MULTIPLE_SUBACCOUNTS,
	],
	[ROLE_PERMISSION_RESOURCE_ACCOUNT]: [
		ROLE_PERMISSION_PRIVILEGE_CREATE,
		ROLE_PERMISSION_PRIVILEGE_BLOCK_SUBACCOUNT_DEVICES,
	],
	[ROLE_PERMISSION_RESOURCE_ACCOUNT_USER]: [
		ROLE_PERMISSION_PRIVILEGE_CREATE,
	],
	[ROLE_PERMISSION_RESOURCE_ACCOUNT_SETTINGS]: [
		ROLE_PERMISSION_PRIVILEGE_CREATE,
	],
};


import {hideLoadingFunction, showDialogLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Unlock IP for the specified username
 * @description Email will be sent to the user with the specified username containing instructions with a link to the IP
 * unblock page.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} username - Username of the user requesting the IP unlock.
 * @return {function(*): *}
 */
export const unlockIpAction = (abortCallback, username) => dispatch => {
	const loading = showDialogLoading('ip-blocked-dialog');
	return ioJsonAction(
		abortCallback,
		'defaultApi',
		'send-unblock-ip-email',
		{username},
		hideLoadingFunction(loading),
	)(dispatch);
};
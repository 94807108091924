import * as applicationConfig from "../config";
import Icon from "Core/components/display/Icon";
import {rtrimChar} from "Core/helpers/string";
import {icon_font_symbol_class_prefix} from "Config/app";
import {AclCheckDataObject} from "Core/acl";
import {getArray} from "Core/helpers/data";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = <Icon symbol="hospital" symbolPrefix={`${'icofont-' ? 'icofont-' : icon_font_symbol_class_prefix}`} />

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject(
	[...getArray(applicationConfig, 'access.permissions'), 'ACCOUNT-CREATE'],
	[...getArray(applicationConfig, 'access.userTypes'), ],
	undefined
);

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `${rtrimChar(applicationConfig.routerPath, '/')}/account/list`;

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `${applicationConfig.translationPath}.AccountsPage`;


// Custom config options -----------------------------------------------------------------------------------------------
/**
 * Flag that specifies if block devices functionality will be enabled on this page
 * @description This functionality is implemented both on GUI and in API but can be enabled/disable on the GUI by using 
 * this flag. Disabling this functionality on GUI will effectively not render the related components. When creating a 
 * new account, default value (see AccountsItemDataObject) will be used for block devices flag. When updating an 
 * existing account, block devices value received from the API will just be passed along on update without change.
 * @type {boolean}
 */
export const enableBlockDevices = false;
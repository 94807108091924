import {ioUploadManualAction} from "Core/store/actions/io";
import {output} from "./dataMap";
import {hideLoading, hideLoadingFunction, showDialogLoading} from "Core/helpers/loading";
import {getBool} from "Core/helpers/data";
import {addErrorMessageAction} from "Core/components/global/Message";
import {messages_default_auto_hide_after} from "Config/app";
import StandardJsonResponseError from "../../../../../../../../core/errors/StandardJsonResponseError";

/**
 * Generate comparison report for selected studies
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {StudiesComparisonDataObject} data - Data used to generate the report.
 * @return {function(*): *}
 */
export const generateStudiesComparisonReportAction = (abortCallback, data) => dispatch => {
	const loading = showDialogLoading('studies-comparison-dialog');
	return ioUploadManualAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/generate-comparison-report',
		output(data),
		hideLoadingFunction(loading),
	)(dispatch)
		.catch(error => {
			// Render error if it is not 'AbortError' or 'hidden' flag is not true
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError' && !getBool(error, 'hidden')) {
				// Return the response if error is a standard Json response error
				// @note Errors will be rendered inside the StudiesComparisonDialog component.
				if (error instanceof StandardJsonResponseError) {
					hideLoading(loading);
					// Return the error response with the 'errorMessage' replaced with the translated error
					return {...error.response, errorMessage: error.message};
				}
				// Add a standard global error message for all other errors
				else {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
			}
			hideLoading(loading);
		});
}
import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {get} from "lodash";
import {getArray, getString} from "Core/helpers/data";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import {STUDY_ACTION, STUDY_TROUBLESHOOTING} from "./const";
import {main_layout_element} from "Config/app";
import AdvancedDropdown, {ADVANCED_DROPDOWN_POSITION} from "Core/components/display/AdvancedDropdown";
import LinkAction from "./actionItems/LinkAction";
import ExportAction from "./actionItems/ExportAction";
import ReProcessAction from "./actionItems/ReProcess";
import DeviceManagerLink from "./actionItems/DeviceManagerLink";
import ExportEdfAction from "./actionItems/ExportEdfAction";

class MainTableActions extends DataComponent {
	constructor(props) {
		super(props, {
			/** 
			 * @type {{
			 * 	possibleActions: {studyId: string, action: StudyAction, [id]: string}[], 
			 * 	possibleTroubleshoots: StudyTroubleshooting[]
			 * } | null
			 * }
			 * @note Action 'id' will only be used for specific actions like EDIT_QUESTIONNAIRE and EDIT_STUDY_DIARY.
			 */
			data: null,
			/** @type {boolean} */
			dataLoading: false,
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'study-actions-dropdown',
			disableLoad: true,
		});
		
		// Refs
		this.dropdownRef = null;

		// Data methods
		this.loadActionsData = this.loadActionsData.bind(this);
		this.clearAllData = this.clearAllData.bind(this);
	}

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load the list of actions and troubleshooting
	 *
	 * @return {Promise<Object>}
	 */
	loadActionsData() {
		const {fetchStudiesListItemActionListAndTroubleshootingAction} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');

		return this.setState({dataLoading: true})
			.then(() => this.executeAbortableAction(
				{id: 'fetch-actions', action: fetchStudiesListItemActionListAndTroubleshootingAction}, 
				study.id
			))
			.then(response => this.setData(get(response, 'data')))
			.then(() => this.setState({dataLoading: false}));
	}
	
	/**
	 * Clear all component's relevant internal data
	 *
	 * @return {Promise<Object>}
	 */
	clearAllData() {
		// Abort fetching actions and troubleshooting
		this.abortAction('fetch-actions');
		
		return this.clearData()
			.then(() => this.setState({dataLoading: false}));
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {reloadMainList, disabled} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');
		const {dataLoading} = this.state;
		const data = this.getData();
		const actions = getArray(data, 'possibleActions');
		const troubleshooting = getArray(data, 'possibleTroubleshoots');

		return (
			<AdvancedDropdown
				className={`${this.getOption('domPrefix')} no-select`}
				defaultPosition={ADVANCED_DROPDOWN_POSITION.LEFT}
				label={(
					<Button
						big={true}
						className="action-btn"
						displayStyle={disabled ? BUTTON_STYLE.SUBTLE : BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="tasks"
					/>
				)}
				parent={main_layout_element}
				boundRect={document.querySelector(`.layout-page`)?.getBoundingClientRect()}
				loading={dataLoading}
				contentClassName={`${this.getOption('domPrefix')}-content no-padding no-select`}
				contentSize="0.8rem"
				onOpen={this.loadActionsData}
				onClosed={this.clearAllData}
				disabled={disabled}
				ref={node => { this.dropdownRef = node; }}
			>
				{/* Actions -------------------------------------------------------------------------------------------- */}
				{
					data !== null && actions.length > 0 ?
						<div className={`text-left text-upper`} data-group-label={true}>
							<Label content={this.t('study_actions_label')} />
						</div>
						: null
				}
				{
					data !== null && actions.length > 0 ?
						actions.map(a =>
							// Questionnaire ----------------------------------------------------------------------------------
							a.action === STUDY_ACTION.NEW_QUESTIONNAIRE ?
								<LinkAction 
									key={a.action} 
									action={a} 
									to={`${pageConfig.routerPath}/questionnaire/new/${study.id}/${study.accountId}`} 
								/>
							: a.action === STUDY_ACTION.EDIT_QUESTIONNAIRE ?
								<LinkAction 
									key={a.action} 
									action={a} 
									to={`${pageConfig.routerPath}/questionnaire/item/${a.id}/${study.id}`}
								/>

							// Sleep diary ------------------------------------------------------------------------------------
							: a.action === STUDY_ACTION.NEW_STUDY_DIARY ?
								<LinkAction 
									key={a.action} 
									action={a} 
									to={`${pageConfig.routerPath}/sleep_diary/new/${study.id}`} 
								/>
							: a.action === STUDY_ACTION.EDIT_STUDY_DIARY ?
								<LinkAction 
									key={a.action} 
									action={a} 
									to={`${pageConfig.routerPath}/sleep_diary/item/${study.id}/${a.id}`}
								/>

							// Study info -------------------------------------------------------------------------------------
							: a.action === STUDY_ACTION.EDIT_STUDY_INFO ?
								<LinkAction key={a.action} action={a} to={`${pageConfig.routerPath}/edit/${study.id}`} />
							
							// Exports ----------------------------------------------------------------------------------------
							: a.action === STUDY_ACTION.EXPORT_EDF ?
								<ExportEdfAction 
									key={a.action} 
									action={a} 
									studyRef={getString(study, 'referenceNumber')} 
									actionDropdownRef={this.dropdownRef} 
								/>
							: [
								STUDY_ACTION.EXPORT_ORIGINAL_EDF,
								STUDY_ACTION.EXPORT_EPOCH,
								STUDY_ACTION.EXPORT_EPOCH_DETAILED,
								STUDY_ACTION.EXPORT_EVENTS,
								STUDY_ACTION.EXPORT_SUMMARY,
								STUDY_ACTION.EXPORT_QUALITY,
								STUDY_ACTION.EXPORT_DAT,
							].includes(a.action) ?
								<ExportAction key={a.action} action={a} studyRef={getString(study, 'referenceNumber')} />
							: null
						)
						: null
				}

				{/* Troubleshooting ------------------------------------------------------------------------------------ */}
				{
					data !== null && troubleshooting.length > 0 ?
						<div className={`text-left text-upper`} data-group-label={true}>
							<Label content={this.t('study_troubleshooting_label')} />
						</div>
						: null
				}
				{
					data !== null && troubleshooting.length > 0 ?
						troubleshooting.map(a =>
							a === STUDY_TROUBLESHOOTING.RE_UPLOAD ?
								<DeviceManagerLink key={a} action={a} studyId={study.id} actionDropdownRef={this.dropdownRef} />
							: a === STUDY_TROUBLESHOOTING.RE_PROCESS ?
								<ReProcessAction key={a} action={a} studyId={study.id} reloadMainList={reloadMainList} />
							: null
						)
						: null
				}
			</AdvancedDropdown>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainTableActions.propTypes = {
	study: PropTypes.object.isRequired,
	reloadMainList: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default connect(null, getPageActions(actions))(MainTableActions);
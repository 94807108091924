import {actionCreators} from "./reducer";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {sidebar_menu_shrank_cookie_group} from "Config/app";
import {getBoolFromTinyInt, getTinyIntFormBool} from "Core/helpers/data";

/**
 * Show main sidebar
 */
export const showMainSidebarAction = () => dispatch => { dispatch(actionCreators.show()); };

/**
 * Hide main sidebar
 */
export const hideMainSidebarAction = () => dispatch => { dispatch(actionCreators.hide()); };

/**
 * Toggle main sidebar
 */
export const toggleMainSidebarAction = () => dispatch => { dispatch(actionCreators.toggle()); };

/**
 * Shrink main sidebar
 */
export const shrinkMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`sidebar_menu_shrank`, '1', STORAGE_TYPE.LOCAL);
	}

	dispatch(actionCreators.shrink());
};

/**
 * Enlarge main sidebar
 */
export const enlargeMainSidebarAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(`sidebar_menu_shrank`, '0', STORAGE_TYPE.LOCAL);
	}

	dispatch(actionCreators.enlarge());
};

/**
 * Toggle main sidebar size
 */
export const toggleMainSidebarSizeAction = () => dispatch => {
	// Get menu shrank cookie settings
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);

	if (CookieConsent.isAllowed(menuShrankCookie)) {
		setStorageValue(
			`sidebar_menu_shrank`,
			getTinyIntFormBool(
				getBoolFromTinyInt(getStorageValue(`sidebar_menu_shrank`, STORAGE_TYPE.LOCAL)),
				'',
				'0',
				true
			),
			STORAGE_TYPE.LOCAL
		);
	}

	dispatch(actionCreators.toggleSize());
};
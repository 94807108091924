import CoreAuth from "../core/auth";
import {deleteStorageKey, STORAGE_TYPE} from "Core/storage";
import {
	access_token_storage_field_name,
	access_token_storage_type,
	auth_api,
	auth_api_endpoints,
	auth_api_request_type,
	auth_api_response_type, 
	auth_broadcast_channel, 
	auth_broadcast_message_logout,
	current_user_storage_field_name,
	decoded_access_token_storage_field_name,
	refresh_token_storage_field_name,
	refresh_token_storage_type,
	refreshing_tokens_storage_field_name,
	temporary_access_token_storage_field_name
} from "Config/auth";
import {acl_storage_type, acl_storage_var} from "Config/acl";
import {
	io_default_request_options,
	io_pause_requests_storage_field_name,
	io_use_country_request_processor_for_auth, json_response_error_code_consent
} from "Config/io";
import {RESPONSE_DATA_TYPE} from "Core/io/const";
import {getString} from "Core/helpers/data";
import {getIOUrl, rawRequest} from "Core/io/helper";
import {ResponseCodeError} from "Core/errors";
import {refreshTokensWait} from "Core/auth/helper";
import StandardJsonResponseError from "../core/errors/StandardJsonResponseError";
import {user_activity_storage_path} from "Config/app";

/**
 * App auth class
 * @note This class must extend CoreAuth class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class Auth extends CoreAuth {
	/**
	 * Ping method for authorized user
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument.
	 * @param {boolean} [tryTokenRefresh=false] - Flag that specifies this function will try to refresh the token if ping
	 * fails (returns 401). This will pause all other requests until tokens refresh request is done.
	 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @param {CoreAuth} [_this=this] - Current class in the class tree. This is used when extending the CoreAuth class
	 * and overwriting this method to make sure that other overwritten child methods will be used properly.
	 * @return {Promise<any>} Promise that resolves if ping was successful. Errors should be handled by response
	 * processors and the function that calls this method, not here.
	 * 
	 * @override Overridden to support consent errors.
	 */
	static authorizedPing = (
		abortCallback, tryTokenRefresh = false, options = io_default_request_options, headers = null, _this = this
	) => {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.authorized_ping, 'api') ? auth_api_endpoints.authorized_ping.api : auth_api
		);

		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.authorized_ping.path),
			api: api,
			endpoint: auth_api_endpoints.authorized_ping.path,
			data: {...auth_api_endpoints.authorized_ping.params},
			method: auth_api_endpoints.authorized_ping.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		})
			// Try to refresh tokens if 'tryTokenRefresh' flag is true and ping returns the 401 response.
			.catch(error => {
				// Consent StandardJsonResponseError is not actually an error so just return the resolved promise since user
				// is actually logged in. Consent dialog will be shown by the standard JSON error response processor.
				// @override
				if (
					error instanceof StandardJsonResponseError && 
					getString(error, 'response.errorCode') === json_response_error_code_consent
				) {
					return Promise.resolve();
				} else if (error instanceof ResponseCodeError && error.code === 401 && tryTokenRefresh) {
					return _this.refreshTokens(abortCallback, undefined, undefined, _this)
						.then(refreshTokenResponse => {
							// If tokens are already refreshing
							if (refreshTokenResponse === 'tokens_already_refreshing') {
								// Wait for tokens to refresh and repeat the ping request without trying to refresh tokens. 
								// Return the result from the repeated ping request.
								return refreshTokensWait()
									.then(() => _this.authorizedPing(abortCallback, false, options, headers, _this));
							}
							// If tokens refreshed successfully
							else {
								// Repeat the ping request to be sure that tokens were refreshed properly. Return the result 
								// from the repeated ping request.
								return _this.authorizedPing(abortCallback, false, options, headers, _this);
							}
						})
						// If refreshing tokens failed, throw the original ping request error
						.catch(() => { throw error; });
				}
				throw error;
			});
	}
	
	/**
	 * Logout the user
	 * @description This method will just remove tokens from storage.
	 * @return {Promise<void>}
	 *
	 * @override Deleted some additional cookies.
	 */
	static logout = () => {
		deleteStorageKey(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
		deleteStorageKey(access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(refresh_token_storage_field_name, refresh_token_storage_type);
		deleteStorageKey(refreshing_tokens_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(decoded_access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(acl_storage_var, acl_storage_type);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.REDUX);
		deleteStorageKey(user_activity_storage_path, STORAGE_TYPE.LOCAL);
		
		deleteStorageKey('studies_list_advanced_search_visible', STORAGE_TYPE.SESSION);
		deleteStorageKey('do_not_show_password_expiration', STORAGE_TYPE.SESSION);
		
		// Broadcast logout so that all other opened tabs that need to will redirect to login page
		const bChannel = new BroadcastChannel(auth_broadcast_channel);
		bChannel.postMessage(auth_broadcast_message_logout);
		bChannel.close();
		
		return Promise.resolve();
	}
}

export default Auth;
import styles from "./index.module.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {getArray, getString, isset} from "Core/helpers/data";
import {sortBy} from "lodash";
import Html from "Core/components/display/Html";
import {scrollToSelector} from "Core/helpers/dom";
import Label from "Core/components/display/Label";
import {AsyncMountError} from "Core/errors";

class HelpVideosComponent extends DataComponent {
	constructor(props) {
		super(props, {
			/**
			 * @typedef {Object} HelpVideo
			 * @property {string} id - Video's guid.
			 * @property {string} name - Video's name.
			 * @property {string} title - Video's title.
			 * @property {string} description - Video's description.
			 * @property {boolean} linkEmbeded - Video's embedded link.
			 * @property {number} displayOrdinal - Position of the video in the list.
			 *
			 * @type {HelpVideo[]}
			 */
			data: [],
			currentVideoIframe: '',
		}, {
			domPrefix: 'help-videos-component',
			disableLoad: true,
		});

		// Action methods
		this.handleVideoLinkClick = this.handleVideoLinkClick.bind(this);
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		const {loadingSelector, fetchHelpVideosAction} = this.props;

		// If this method was not called by the override method from the child class
		if (!override) {
			await super.asyncComponentDidMount(true)
				.then(() => this.executeAbortableAction(fetchHelpVideosAction, loadingSelector))
				.then(response => {
					if (isset(response)) return this.defaultLoad(sortBy(getArray(response, 'data'), ['displayOrdinal']));
					else throw new AsyncMountError();
				})
				// Set the first video to be rendered by default
				.then(() => this.setState({currentVideoIframe: getString(this.getData(), '[0].linkEmbeded')}));
		}
		// If this method was called by the override method from the child class
		else {
			await super.asyncComponentDidMount(override);
		}

		return Promise.resolve();
	}

	/**
	 * Handle clicking on a video link
	 * 
	 * @param {HelpVideo} linkItem - Video link object.
	 * @param {MouseEvent} clickEvent - Mouse click event.
	 * @return {Promise<Object>}
	 */
	handleVideoLinkClick(linkItem, clickEvent) {
		return this.setState({currentVideoIframe: linkItem.linkEmbeded})
			.then(() => { 
				scrollToSelector(
					`#${this.getDomId()}-video-wrapper`, 
					false, 
					999,
					`.layout-public.videos .layout-content > .layout-content-inner .page-content`
				); 
			});
	}
	
	render() {
		const {className, header, headerClassName} = this.props;
		const {currentVideoIframe} = this.state;
		/** @type {HelpVideo[]} */
		const data = getArray(this.getData());
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${className} ${styles['wrapper']}`}>
				<div id={`${this.getDomId()}-video-wrapper`} className={`${styles['videoWrapper']}`}>
					<Html content={currentVideoIframe} />
				</div>
				
				<div className={`${styles['videoLinks']}`}>
					{header ? <div className={`${styles['header']} ${headerClassName}`}>{header}</div> : null}
					{data.map(i =>
						<div
							key={i.id}
							className={
								`${styles['item']} ` +
								`${i.linkEmbeded === currentVideoIframe ? styles['current'] : ''} `
							}
							onClick={e => this.handleVideoLinkClick(i, e)}
						>
							<div className={`${styles['content']}`}>
								<div className={styles['label']} title={i.title}> <Label icon="play" content={i.name} /></div>
								{i.description ? <div className={styles['description']}> {i.description}</div> : null}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
HelpVideosComponent.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	header: PropTypes.element,
	headerClassName: PropTypes.string,
	loadingSelector: PropTypes.string,
};

/**
 * Define component default values for own props
 */
HelpVideosComponent.defaultProps = {
	id: '',
	className: '',
	header: null,
	headerClassName: '',
	loadingSelector: '.layout',
};

export default connect(null, getGlobalActions(actions))(HelpVideosComponent);
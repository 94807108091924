import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {AppStarterItemDataObject} from "../dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AppStarterItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AppStarterItemDataObject(
		getString(result, 'id'),
		getString(result, 'jarType'),
		getString(result, 'jarVersion'),
		getDate(get(result, 'jarReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S)
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AppStarterItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		jarType: result.type,
		jarReleaseDate: getDateString(result.releaseDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		jarVersion: result.version,

		// Form data properties
		appStartJar: result.appStartFormData
	};

	// Set proper empty values expected by the API
	// @note This is commented out since requests that use FormData have backend issues with string values cleared by 
	// null values instead of empty strings.
	//result = resetObjectValues(result);

	return result;
}
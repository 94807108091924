import {cloneDeep, omit} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {SleepDiaryDataObject} from "./dataObjects";
import {SLEEP_DIARY_NUMBER10_VALUE, SLEEP_DIARY_NUMBER4_VALUE} from "./const";

/**
 * Get input data from raw data
 * @param {{
 * 	id: string, 
 * 	night1Data: SleepDiaryNightDataObject, 
 * 	night2Data: SleepDiaryNightDataObject, 
 * 	night3Data: SleepDiaryNightDataObject
 * }} rawData - Raw data received from IO.
 * @return {SleepDiaryDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new SleepDiaryDataObject(
		getString(result, 'id'),
		[
			// @note 'amPm' is omitted since it is not used by the app
			resetObjectValues(
				omit(result.night1Data, ['amPm']), 
				{
					alcoholBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE, 
					caffeineBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE,
					wokenUpNumber: SLEEP_DIARY_NUMBER10_VALUE.NONE,
				},
				undefined,
				true
			),
			resetObjectValues(
				omit(result.night2Data, ['amPm']),
				{
					alcoholBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE,
					caffeineBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE,
					wokenUpNumber: SLEEP_DIARY_NUMBER10_VALUE.NONE,
				},
				undefined,
				true
			),
			resetObjectValues(
				omit(result.night3Data, ['amPm']),
				{
					alcoholBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE,
					caffeineBeforeBedtime: SLEEP_DIARY_NUMBER4_VALUE.NONE,
					wokenUpNumber: SLEEP_DIARY_NUMBER10_VALUE.NONE,
				},
				undefined,
				true
			)
		]
	);

	return result;
}

/**
 * Get output data from input data
 * @param {SleepDiaryDataObject} inputData - Input data.
 * @return {{id: string, night1Data: {}, night2Data: {}, night3Data: {}}}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		// @note 'amPm' is added since backend expects it, but it is not currently used so the value is set to null. 
		night1Data: resetObjectValues(
			{...result.nights[0], amPm: null}, 
			{alcoholBeforeBedtime: null, caffeineBeforeBedtime: null, wokenUpNumber: null}, 
			undefined, 
			true
		),
		night2Data: resetObjectValues(
			{...result.nights[1], amPm: null},
			{alcoholBeforeBedtime: null, caffeineBeforeBedtime: null, wokenUpNumber: null},
			undefined,
			true
		),
		night3Data: resetObjectValues(
			{...result.nights[2], amPm: null},
			{alcoholBeforeBedtime: null, caffeineBeforeBedtime: null, wokenUpNumber: null},
			undefined,
			true
		),
	};

	return result;
}
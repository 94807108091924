/** @typedef {string} StudyAction */
// Actions
export const STUDY_ACTION_NEW_QUESTIONNAIRE = 'NEW_QUESTIONNAIRE';
export const STUDY_ACTION_EDIT_QUESTIONNAIRE = 'EDIT_QUESTIONNAIRE';
export const STUDY_ACTION_NEW_STUDY_DIARY = 'NEW_STUDY_DIARY';
export const STUDY_ACTION_EDIT_STUDY_DIARY = 'EDIT_STUDY_DIARY';
export const STUDY_ACTION_EDIT_STUDY_INFO = 'EDIT_INFO';
export const STUDY_ACTION_EXPORT_ORIGINAL_EDF = 'EXPORT_ORIGINAL_EDF';
export const STUDY_ACTION_EXPORT_EDF = 'EXPORT_CUSTOM_EDF';
export const STUDY_ACTION_EXPORT_EPOCH = 'EXPORT_EPOCH_BY_EPOCH';
export const STUDY_ACTION_EXPORT_EPOCH_DETAILED = 'EXPORT_EPOCH_BY_EPOCH_DETAILS';
export const STUDY_ACTION_EXPORT_EVENTS = 'EXPORT_EVENTS';
export const STUDY_ACTION_EXPORT_SUMMARY = 'EXPORT_STUDY_SUMMARY';
export const STUDY_ACTION_EXPORT_QUALITY = 'EXPORT_QUALITY_FILE';
export const STUDY_ACTION_EXPORT_DAT = 'EXPORT_SYSTEM_DAT';
/** @enum {StudyAction} */
export const STUDY_ACTION = {
	// Actions
	NEW_QUESTIONNAIRE: STUDY_ACTION_NEW_QUESTIONNAIRE,
	EDIT_QUESTIONNAIRE: STUDY_ACTION_EDIT_QUESTIONNAIRE,
	NEW_STUDY_DIARY: STUDY_ACTION_NEW_STUDY_DIARY,
	EDIT_STUDY_DIARY: STUDY_ACTION_EDIT_STUDY_DIARY,
	EDIT_STUDY_INFO: STUDY_ACTION_EDIT_STUDY_INFO,
	EXPORT_ORIGINAL_EDF: STUDY_ACTION_EXPORT_ORIGINAL_EDF,
	EXPORT_EDF: STUDY_ACTION_EXPORT_EDF,
	EXPORT_EPOCH: STUDY_ACTION_EXPORT_EPOCH,
	EXPORT_EPOCH_DETAILED: STUDY_ACTION_EXPORT_EPOCH_DETAILED,
	EXPORT_EVENTS: STUDY_ACTION_EXPORT_EVENTS,
	EXPORT_SUMMARY: STUDY_ACTION_EXPORT_SUMMARY,
	EXPORT_QUALITY: STUDY_ACTION_EXPORT_QUALITY,
	EXPORT_DAT: STUDY_ACTION_EXPORT_DAT,
}
/** @type {StudyAction[]} */
export const STUDY_ACTIONS = [
	// Actions
	STUDY_ACTION_NEW_QUESTIONNAIRE,
	STUDY_ACTION_EDIT_QUESTIONNAIRE,
	STUDY_ACTION_NEW_STUDY_DIARY,
	STUDY_ACTION_EDIT_STUDY_DIARY,
	STUDY_ACTION_EDIT_STUDY_INFO,
	STUDY_ACTION_EXPORT_ORIGINAL_EDF,
	STUDY_ACTION_EXPORT_EDF,
	STUDY_ACTION_EXPORT_EPOCH,
	STUDY_ACTION_EXPORT_EPOCH_DETAILED,
	STUDY_ACTION_EXPORT_EVENTS,
	STUDY_ACTION_EXPORT_SUMMARY,
	STUDY_ACTION_EXPORT_QUALITY,
	STUDY_ACTION_EXPORT_DAT,
];
export const STUDY_ACTION_ICON = {
	[STUDY_ACTION_NEW_QUESTIONNAIRE]: {symbol: 'id-card-o'},
	[STUDY_ACTION_EDIT_QUESTIONNAIRE]: {symbol: 'id-card-o'},
	[STUDY_ACTION_NEW_STUDY_DIARY]: {symbol: 'book'},
	[STUDY_ACTION_EDIT_STUDY_DIARY]: {symbol: 'book'},
	[STUDY_ACTION_EDIT_STUDY_INFO]: {symbol: 'pencil'},
	[STUDY_ACTION_EXPORT_ORIGINAL_EDF]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_EDF]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_EPOCH]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_EPOCH_DETAILED]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_EVENTS]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_SUMMARY]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_QUALITY]: {symbol: 'download'},
	[STUDY_ACTION_EXPORT_DAT]: {symbol: 'download'},
};

/** @typedef {string} StudyTroubleshooting */
export const STUDY_TROUBLESHOOTING_UPLOAD_STUDY = 'RE_UPLOAD_STUDY';
export const STUDY_TROUBLESHOOTING_RE_PROCESS = 'REPROCESS_STUDY';
/** @enum {StudyTroubleshooting} */
export const STUDY_TROUBLESHOOTING = {
	RE_UPLOAD: STUDY_TROUBLESHOOTING_UPLOAD_STUDY,
	RE_PROCESS: STUDY_TROUBLESHOOTING_RE_PROCESS,
};
/** @type {StudyTroubleshooting[]} */
export const STUDY_TROUBLESHOOTINGS = [
	STUDY_TROUBLESHOOTING_UPLOAD_STUDY,
	STUDY_TROUBLESHOOTING_RE_PROCESS,
];
export const STUDY_TROUBLESHOOTING_ICON = {
	[STUDY_TROUBLESHOOTING_UPLOAD_STUDY]: {symbol: 'upload'},
	[STUDY_TROUBLESHOOTING_RE_PROCESS]: {symbol: 'refresh'},
};
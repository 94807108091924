import {ioJsonAction, ioJsonFetchAction, ioJsonSaveItemAction} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showDialogLoading, showLoading} from "Core/helpers/loading";
import {getArray, getBoolFromTinyInt, getString, getTinyIntFormBool} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {EDF_PROCESSING_REQUEST_STATUS} from "./const";
import {ioRequest} from "Core/io";
import {getFileNameForStudyAction} from "./components/MainTableActions/actionItems/ExportAction/helper";
import {STUDY_ACTION} from "./components/MainTableActions/const";
import {REQUEST_TYPE} from "Core/io/const";
import {getIOUrl} from "Core/io/helper";

/**
 * Fetch studies list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchStudiesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/search-studies',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load studies list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadStudiesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading(".layout-page");
	return fetchStudiesListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setStudiesListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear studies list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearStudiesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearStudiesListData());
}

/**
 * Toggle studies list advanced search
 * @return {(function(*): void)|*}
 */
export const toggleStudiesListAdvancedSearchAction = () => dispatch => {
	// Get advanced search visibility cookie settings
	const advancedSearchVisibilityCookie = new CookieData(
		'necessary', 'studies_list_advanced_search_visible', STORAGE_TYPE.SESSION
	);

	if (CookieConsent.isAllowed(advancedSearchVisibilityCookie)) {
		setStorageValue(
			'studies_list_advanced_search_visible',
			getTinyIntFormBool(
				getBoolFromTinyInt(
					getStorageValue('studies_list_advanced_search_visible', STORAGE_TYPE.SESSION)
				),
				'',
				'0',
				true
			),
			STORAGE_TYPE.SESSION
		);
	}
	
	dispatch(actionCreators[reducerStoreKey].toggleAdvancedSearch());
}

/**
 * Update studies list selection in Redux store
 * @param {StudiesListItemDataObject[]} selection - New selection.
 * @return {(function(*))|*}
 */
export const updateStudiesListSelectionAction = selection => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setStudiesListSelection(selection));
};

/**
 * Fetch the list of study statuses for the studies list item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudiesListItemStatusListAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-possible-study-statuses-for-change',
		{id: studyId},
	)(dispatch);
}

/**
 * Update studies list item status
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of that studies list item to update the status for.
 * @param {string} statusCode - Code of the new study status to set.
 * @return {function(*): *}
 */
export const updateStudiesListItemStatusAction = (abortCallback, studyId, statusCode) => dispatch => {
	return ioJsonSaveItemAction(
		abortCallback, 
		'defaultAuthorizedApi', 
		'study/change-study-status',
		studyId,
		{
			data: { statusCode }
		},
	)(dispatch);
};

/**
 * Fetch the list of study edits for the studies list item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudiesListItemStudyEditListAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-exists-study-edits',
		{id: studyId},
	)(dispatch);
}

/**
 * Fetch the link token for study edit
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyEditId - ID of the study edit to get the token for.
 * @return {function(*): Promise<string>}
 */
export const fetchStudyEditTokenAction = (abortCallback, studyEditId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-study-edit-token-by-id',
		{id: studyEditId},
	)(dispatch)
		// Get the actual token from the response
		.then(response => getString(response, 'data'));
};

/**
 * Fetch the list of reports for the studies list item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudiesListItemReportListAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-possible-reports',
		{id: studyId},
	)(dispatch);
}

/**
 * Fetch the list of actions and troubleshooting for the studies list item
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudiesListItemActionListAndTroubleshootingAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-possible-actions',
		{id: studyId},
	)(dispatch);
};

/**
 * Fetch the link token for re-uploading study
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudiesListItemActionReUploadStudyTokenAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-re-upload-study-token',
		{id: studyId},
	)(dispatch)
		// Get the actual token from the response
		.then(response => getString(response, 'data'));
};

/**
 * Fetch the list of study re-process criteria
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<string|undefined>}
 */
export const fetchStudiesListItemActionReProcessCriteriaAction = abortCallback => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/fetch-reprocess-study-criteria-values'
	)(dispatch)
		.then(response => {
			if (isSuccessful(response)) return getArray(response, 'data');
			else return undefined;
		});
};

/**
 * Re-process study with the selected criteria 
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @param {string} reProcessCriteria - Re-process criteria selected by the user (@see ReProcessDialog).
 * @param {any} [nuseSubtracted=false] - Set by the hidden checkbox in ReProcessDialog.
 * @return {function(*): *}
 */
export const studiesListItemActionReProcessStudyAction = (
	abortCallback, studyId, reProcessCriteria, nuseSubtracted = false
) => dispatch => {
	const loading = showDialogLoading('re-process-dialog');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/reprocess-study',
		{studyId, reProcessCriteria, nuseSubtracted},
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Fetch patient data for a specific study
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the study.
 * @return {function(*): *}
 */
export const fetchStudyPatientAction = (abortCallback, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/questionnaire/fetch-patient-by-study-id',
		{id: studyId}
	)(dispatch);
};

/**
 * Export custom EDF file
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {{studyId: string, action: StudyAction, [id]: string}} action
 * @param {string} [studyId]
 * @return {function(*): Promise<boolean>}
 */
export const exportEdfAction = (abortCallback, action, studyId) => dispatch => {
	return new Promise(async (resolve, reject) => {
		// Create a processing request
		const processingRequestId = await ioJsonAction(
			abortCallback, 
			'defaultAuthorizedApi', 
			'study/create-custom-edf-processing-request',
			{studyId: action.studyId}
		)(dispatch).then(res => getString(res, 'data.processingRequestId'));
		
		// Resolve with undefined if processing request was not created successfully
		if (!processingRequestId) {
			reject(new Error('FAILED_CREATE_PROCESSING_REQUEST'));
			return;
		}
		
		// Start checking if processing request is finished
		let checking = false;
		let doneChecking = false;
		const checkInterval = setInterval(() => {
			if (doneChecking) {
				clearInterval(checkInterval);
			} else if (!checking) {
				checking = true;
				
				ioJsonAction(
					abortCallback,
					'defaultAuthorizedApi',
					'study/check-custom-edf-processing-request',
					{processingRequestId}
				)(dispatch)
					.then(res => {
						const status = getString(res, 'data.status');
						if (!!status && ![
							EDF_PROCESSING_REQUEST_STATUS.WAITING_TO_BE_PROCESSED,
							EDF_PROCESSING_REQUEST_STATUS.IN_PROCESS,
						].includes(status)) {
							doneChecking = true;
							if (status === EDF_PROCESSING_REQUEST_STATUS.FINISHED) resolve();
							else reject(new Error('FAILED_PROCESSING_REQUEST'));
						}
						checking = false;
					});
			}
		}, 1000);
	})
		// Download EDF file
		.then(() => {
			return ioRequest({
				processRequest: true,
				processResponse: true,
				isDownload: true,
				downloadFilename: getFileNameForStudyAction(STUDY_ACTION.EXPORT_EDF, studyId),
				type: REQUEST_TYPE.JSON,
				url: getIOUrl('defaultAuthorizedApi', 'study/export-by-action'),
				data: action,
				method: 'POST',
			})
				.then(res => {
					if (!!res) return true;
					else throw Error('FAILED_DOWNLOAD');
				})
				.catch(() => { throw Error('FAILED_DOWNLOAD'); });
		});
};
import {cloneDeep} from "lodash";
import {getBool, getNumber, getString} from "Core/helpers/data";
import {CurrentUserDataObject} from "./dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {CurrentUserDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new CurrentUserDataObject(
		getString(result, 'userAccountId'),
		getString(result, 'userAccountType'),
		getString(result, 'username'),
		getString(result, 'userFirstName'),
		getString(result, 'userLastName'),
		getString(result, 'userCredentials'),
		getBool(result, 'userIsGuest'),
		getBool(result, 'passwordExpires'),
		(getBool(result, 'passwordExpires') ? getNumber(result, 'passwordExpiresInDays', null) : null),
	);

	return result;
}

// @note There is no output function since list is read only.
import {
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import * as itemRolesDataMap from './dataMap/itemRoles'
import * as subAccountsDataMap from './dataMap/itemSubAccounts'
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";


// Main page table actions ---------------------------------------------------------------------------------------------
/**
 * Fetch accounts list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchAccountsListAction = (
	abortCallback, isSystemAdmin, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/search`,
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load accounts list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadAccountsListAction = (
	abortCallback, isSystemAdmin, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchAccountsListAction(abortCallback, isSystemAdmin, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setAccountsListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear accounts list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearAccountsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearAccountsListData());
}

/**
 * Action to block devices for accounts item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AccountsListItemDataObject} account - Account's info data.
 * @param {boolean} [requestSavedData=false] - Flag defining if saved data should be returned after successful save.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const blockDevicesAccountsItemAction = (abortCallback, account, requestSavedData = false) => dispatch => {
	const loading = showLoading('#block-devices-dialog .dialog-content-component .buttons');
	return ioJsonSaveAction(
		abortCallback,
		'defaultAuthorizedApi',
		`admin/account/block-devices`,
		{
			id: account.id,
			data: itemDataMap.output({...account, blockDevices: !account.blockDevices}),
		},
		undefined,
		requestSavedData,
		hideLoadingFunction(loading)
	)(dispatch);
};


// Main popup tab actions ----------------------------------------------------------------------------------------------
/**
 * Fetch accounts item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string|number} id - DB ID of the accounts item to fetch.
 * @return {function(*=): Promise<AccountsItemDataObject>}
 */
export const fetchAccountsItemAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-account-general-data`,
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load accounts item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - DB ID of the accounts item to load.
 * @return {function(*=): Promise<AccountsItemDataObject>}
 */
export const loadAccountsItemAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchAccountsItemAction(abortCallback, isSystemAdmin, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setAccountsItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear accounts item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountsItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearAccountsItem());
};

/**
 * Create accounts item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {AccountsItemDataObject} item - Accounts item to save.
 * @return {function(*=): Promise<AccountsItemDataObject>} Promise that will resolve with the created item
 * received from IO or undefined if creation failed.
 */
export const createAccountsItemAction = (abortCallback, isSystemAdmin, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/create-account`,
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update accounts item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - DB ID of the accounts item to update.
 * @param {AccountsItemDataObject} item - Accounts item to save.
 * @return {function(*=): Promise<AccountsItemDataObject>} Promise that will resolve with the updated item
 * received from IO or undefined if updating failed.
 */
export const updateAccountsItemAction = (abortCallback, isSystemAdmin, id, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/edit-account`,
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setAccountsItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setAccountsItem(updatedItem));
			return updatedItem;
		});
};


// Account roles popup tab actions -------------------------------------------------------------------------------------
/**
 * Fetch roles for the specified account
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @return {function(*=): Promise<AccountsItemRoleDataObject[]>}
 */
export const fetchAccountsItemRolesAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-account-roles-by-account`,
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(response => {
			if (isset(response)) return getArray(response, 'data').map(item => itemRolesDataMap.input(item));
			return undefined;
		});
};

/**
 * Load roles for the specified account into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @return {function(*=): Promise<AccountsItemRoleDataObject[]>}
 */
export const loadAccountsItemRolesAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	const loading = showLoading('#item-popup-roles-tab');
	return fetchAccountsItemRolesAction(abortCallback, isSystemAdmin, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setItemRoles(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear accounts item roles from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountsItemRolesAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearItemRoles());
};

/**
 * Fetch all users for select input field from database.
 *
 * @param abortCallback
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @returns {function(*): *}
 */
export const fetchAllUsersAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-possible-users-by-account`,
		id
	)(dispatch)
		// Return response data
		.then(response => (isSuccessful(response) ? getArray(response, 'data') : undefined));
};

/**
 * Fetch all roles for select input field from database.
 *
 * @param abortCallback
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @returns {function(*): *}
 */
export const fetchAllRolesAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-possible-roles-by-account`,
		id
	)(dispatch)
		// Return response data
		.then(response => (isSuccessful(response) ? getArray(response, 'data') : undefined));
}

/**
 * Save roles data for selected account
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @param {AccountsItemRoleDataObject[]} roles - Array of defined roles for selected account.
 * @returns {function(*): *}
 */
export const saveSelectedRolesAction = (abortCallback, isSystemAdmin, id, roles) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/save-account-roles-for-account`,
		{
			id: id,
			data: roles.map(item => itemRolesDataMap.output(item))
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(response => isset(response) ? getArray(response, 'data').map(i => itemRolesDataMap.input(i)) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItems => {
			if (isset(updatedItems)) dispatch(actionCreators[reducerStoreKey].setItemRoles(roles));
			return updatedItems;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItems => {
			if (isset(updatedItems)) dispatch(actionCreators[reducerStoreKey].setItemRoles(updatedItems));
			return updatedItems;
		});
}


// Sub-accounts popup tab actions --------------------------------------------------------------------------------------
/**
 * Fetch all accounts for selected account item from database.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @returns {function(*): *}
 */
export const fetchAllAccountsForSelectedItemAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-accounts-for-sub-accounts-by-account`,
		id
	)(dispatch)
		// Return response data
		.then(response => (isSuccessful(response) ? getArray(response, 'data') : undefined));
};

/**
 * Fetch the list of saved sub-accounts for selected account from database.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @returns {function(*): Promise<AccountsItemSubAccountsDataObject[]>}
 */
export const fetchAccountsItemSubAccountsAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/fetch-sub-accounts-by-account`,
		id
	)(dispatch)
		// Get mapped data from response data
		.then(response => {
			if (isset(response)) return getArray(response, 'data').map(item => subAccountsDataMap.input(item));
			return undefined;
		});
}

/**
 * Load accounts sub-accounts into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @returns {function(*): Promise<AccountsItemSubAccountsDataObject[]>}
 */
export const loadAccountsItemSubAccountsAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	const loading = showLoading('#item-popup-sub-accounts-tab');
	return fetchAccountsItemSubAccountsAction(abortCallback, isSystemAdmin, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setItemSubAccounts(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear accounts item sub-accounts from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearAccountsItemSubAccountsAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearItemSubAccounts());
};

/**
 * Saves sub-accounts for selected account
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin=false] - Flag that defines if current user is a system admin.
 * @param {string} id - Account ID.
 * @param {AccountsItemSubAccountsDataObject[]} subAccounts - Array of defined sub-accounts for selected account.
 * @returns {function(*): *}
 */
export const saveSelectedSubAccountsAction = (abortCallback, isSystemAdmin, id, subAccounts) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? 'admin' : 'member'}/account/save-sub-accounts-for-account`,
		{
			accountId: id,
			subAccountIds: subAccounts.map(i => i.id)
		}, 
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(response => isset(response) ? getArray(response, 'data').map(i => subAccountsDataMap.input(i)) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItems => {
			if (isset(updatedItems)) dispatch(actionCreators[reducerStoreKey].setItemSubAccounts(subAccounts));
			return updatedItems;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItems => {
			if (isset(updatedItems)) dispatch(actionCreators[reducerStoreKey].setItemSubAccounts(updatedItems));
			return updatedItems;
		});
}
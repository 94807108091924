import React from "react";
import {isString} from "lodash";

import styles from "./index.module.css";

const Spinner = ({id = '', className = '', size = 16, weight = 2, element = 'span', ...otherProps}) => {
	// Convert camelCase (in this case lowercase) prop name into PascalCase
	// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
	// rendered React JSX component must be capitalized (PascalCase).
	const Element = element;
	
	return (
		<Element
			id={id}
			className={`spinner ${className} ${styles['spinner']}`}
			style={{
				height: isString(size) ? size : `${size.toString()}px`,
				width: isString(size) ? size : `${size.toString()}px`,
				borderWidth: isString(weight) ? weight : `${weight.toString()}px`
			}}
			{...otherProps}
		/>
	);
};

export default Spinner;
import {
	SLEEP_DIARY_BOOL_SELECT_VALUE,
	SLEEP_DIARY_DAY_PERIOD,
	SLEEP_DIARY_NUMBER10_VALUE,
	SLEEP_DIARY_NUMBER4_VALUE
} from "./const";
import {getString} from "Core/helpers/data";

export class SleepDiaryDataObject {
	/**
	 * @param {string|null} [id=null] - Sleep diary ID.
	 * @param {SleepDiaryNightDataObject[]} [nights] - List of sleep diary nights. Currently only 3 nights are supported 
	 * by the API. If not specified (for example when using this data object for creating a new diary) 3 empty nights 
	 * will be added to the list.
	 */
	constructor(
		id = null, 
		nights = [new SleepDiaryNightDataObject(), new SleepDiaryNightDataObject(), new SleepDiaryNightDataObject()]
	) {
		this.id = id;
		this.nights = nights;
	}
}

export class SleepDiaryNightDataObject {
	/**
	 * @param {SleepDiaryBoolSelectValue} [prescriptionMedication] - Did you take a prescription medication to help you 
	 * fall or stay asleep?
	 * @param {SleepDiaryNumber4Value} [alcoholBeforeBedtime] - How many alcoholic beverages did you consume within 4 
	 * hours before bedtime?
	 * @param {SleepDiaryNumber4Value} [caffeineBeforeBedtime] - How many caffeinated beverages did you consume within 4 
	 * hours before bedtime?
	 * @param {number} [timeTurnedLightsOutHour=-1] - What time did you turn the lights off and begin trying to fall 
	 * asleep (hour)?
	 * @param {number} [timeTurnedLightsOutMinutes=-1] - What time did you turn the lights off and begin trying to fall 
	 * asleep (minutes)?
	 * @param {SleepDiaryDayPeriod} [timeTurnedLightsOutAmPm] - What time did you turn the lights off and begin trying to
	 * fall asleep (day period)?
	 * @param {number} [timeNeededToFallAsleepHour=-1] - How long do you think it took you to fall asleep (hours)?
	 * @param {number} [timeNeededToFallAsleepMinutes=-1] - How long do you think it took you to fall asleep (minutes)?
	 * @param {SleepDiaryNumber10Value} [wokenUpNumber] - How many times do you think you woke up between the time you 
	 * first fell asleep and your final awakening?
	 * @param {number} [timeTotalAwakeHour=-1] - What was the total time you think you were awake after you initially 
	 * fell asleep until you ended your night of sleep (hours)?
	 * @param {number} [timeTotalAwakeMinutes=-1] - What was the total time you think you were awake after you initially 
	 * fell asleep until you ended your night of sleep (minutes)?
	 * @param {number} [timeFinalAwakeningHour-1] - What time did you turn the device off for your final awakening that 
	 * ended your night of sleep (hour)?
	 * @param {number} [timeFinalAwakeningMinutes-1] - What time did you turn the device off for your final awakening 
	 * that ended your night of sleep (minutes)?
	 * @param {SleepDiaryDayPeriod} [timeFinalAwakeningAmPm] - What time did you turn the device off for your final 
	 * awakening that ended your night of sleep (day period)?
	 * @param {number} [timeGetOutOfBedHour=-1] - What time did you get out of bed with no further attempt at sleeping 
	 * (hour)?
	 * @param {number} [timeGetOutOfBedMinutes=-1] - What time did you get out of bed with no further attempt at sleeping
	 * (minutes)?
	 * @param {SleepDiaryDayPeriod} [timeGetOutOfBedAmPm] - What time did you get out of bed with no further attempt at 
	 * sleeping (day period)?
	 * @param {number} [timeAsleepApproximatelyHour=-1} - Approximately how long did you sleep last night (hours)?
	 * @param {number} [timeAsleepApproximatelyMinutes=-1} - Approximately how long did you sleep last night (minutes)?
	 * @param {SleepDiaryBoolSelectValue} [wearSleepProfiler] - When you wore the Sleep Profiler device, was it turned 
	 * ON the entire time you were asleep?
	 */
	constructor(
		prescriptionMedication = SLEEP_DIARY_BOOL_SELECT_VALUE.NONE,alcoholBeforeBedtime = SLEEP_DIARY_NUMBER4_VALUE.NONE, 
		caffeineBeforeBedtime = SLEEP_DIARY_NUMBER4_VALUE.NONE, timeTurnedLightsOutHour = -1, 
		timeTurnedLightsOutMinutes = -1, timeTurnedLightsOutAmPm = SLEEP_DIARY_DAY_PERIOD.NONE,
		timeNeededToFallAsleepHour = -1, timeNeededToFallAsleepMinutes = -1, 
		wokenUpNumber = SLEEP_DIARY_NUMBER10_VALUE.NONE, timeTotalAwakeHour = -1, timeTotalAwakeMinutes = -1,
		timeFinalAwakeningHour = -1, timeFinalAwakeningMinutes = -1, timeFinalAwakeningAmPm = SLEEP_DIARY_DAY_PERIOD.NONE,
		timeGetOutOfBedHour = -1, timeGetOutOfBedMinutes = -1, timeGetOutOfBedAmPm = SLEEP_DIARY_DAY_PERIOD.NONE,
		timeAsleepApproximatelyHour = -1, timeAsleepApproximatelyMinutes = -1, 
		wearSleepProfiler = SLEEP_DIARY_BOOL_SELECT_VALUE.NONE,
	) {
		this.prescriptionMedication = prescriptionMedication;
		this.alcoholBeforeBedtime = getString(alcoholBeforeBedtime, '', SLEEP_DIARY_NUMBER4_VALUE.NONE, true);
		this.caffeineBeforeBedtime = getString(caffeineBeforeBedtime, '', SLEEP_DIARY_NUMBER4_VALUE.NONE, true);
		this.timeTurnedLightsOutHour = timeTurnedLightsOutHour;
		this.timeTurnedLightsOutMinutes = timeTurnedLightsOutMinutes;
		this.timeTurnedLightsOutAmPm = timeTurnedLightsOutAmPm;
		this.timeNeededToFallAsleepHour = timeNeededToFallAsleepHour;
		this.timeNeededToFallAsleepMinutes = timeNeededToFallAsleepMinutes;
		this.wokenUpNumber = getString(wokenUpNumber, '', SLEEP_DIARY_NUMBER10_VALUE.NONE, true);
		this.timeTotalAwakeHour = timeTotalAwakeHour;
		this.timeTotalAwakeMinutes = timeTotalAwakeMinutes;
		this.timeFinalAwakeningHour = timeFinalAwakeningHour;
		this.timeFinalAwakeningMinutes = timeFinalAwakeningMinutes;
		this.timeFinalAwakeningAmPm = timeFinalAwakeningAmPm;
		this.timeGetOutOfBedHour = timeGetOutOfBedHour;
		this.timeGetOutOfBedMinutes = timeGetOutOfBedMinutes;
		this.timeGetOutOfBedAmPm = timeGetOutOfBedAmPm;
		this.timeAsleepApproximatelyHour = timeAsleepApproximatelyHour;
		this.timeAsleepApproximatelyMinutes = timeAsleepApproximatelyMinutes;
		this.wearSleepProfiler = wearSleepProfiler;
	}
}
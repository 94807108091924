/**
 * Data object representing the app starter list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class AppStarterListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} type - List item's name.
	 * @param {string} version - List item's name.
	 * @param {Date} releaseDate - List item's name.
	 */
	constructor(id, type, version, releaseDate) {
		this.id = id;
		this.type = type;
		this.version = version;
		this.releaseDate = releaseDate;
	}
}

/**
 * Data object representing the app starter item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class AppStarterItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [type=''] - Item's name.
	 * @param {string} [version=''] - Item's name.
	 * @param {Date} [releaseDate=null] - Item's name.
	 * @param {FormData} [appStartFormData=null] - Selected firmware file. 
	 */
	constructor(id = null, type = '', version = '', releaseDate = null, appStartFormData = null) {
		this.id = id;
		this.type = type;
		this.version = version;
		this.releaseDate = releaseDate;

		// File write properties
		// @desc Properties used to store selected file(s) that will be saved in the database.
		this.appStartFormData = appStartFormData;
	}
}
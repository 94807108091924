/**
 * Data object representing the studies list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class StudiesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} subjectId - List item's subject id.
	 * @param {string} firstName - List item's first name.
	 * @param {string} lastName - List item's last name.
	 * @param {Date} dateOfBirth - List item's date of birth.
	 * @param {StudySensorConfiguration} sensorConfiguration - List item's device sensor configuration.
	 * @param {string} studyTypeCode - List item's study type code.
	 * @param {string} studyTypeName - List item's study type name.
	 * @param {string} studyStatusCode - List item's study status code.
	 * @param {string} studyStatusName - List item's study status name.
	 * @param {Date} createdAt - List item's ordered date.
	 * @param {string} accountId - List item's account ID.
	 * @param {string} accountName - List item's account name.
	 * @param {string} referenceId - List item's reference ID.
	 * @param {string} referenceNumber - List item's reference name.
	 * @param {string} serialNo - List item's device serial number.
	 * @param {boolean} archived - List item's archived flag.
	 * @param {string} archiveStatus - 
	 * @param {string} archiveRequestedDate - 
	 * @param {string} archivationDate - 
	 * @param {string} autoArchivedDate - 
	 * @param {string} deleteRawFilesDate - 
	 * @param {string} deleteEdfFilesDate - 
	 */
	constructor(
		id, subjectId, firstName, lastName, dateOfBirth, sensorConfiguration, studyTypeCode,studyTypeName, 
		studyStatusCode, studyStatusName, createdAt, accountId, accountName, referenceId, referenceNumber, serialNo, 
		archived, archiveStatus, archiveRequestedDate, archivationDate, autoArchivedDate, deleteRawFilesDate, 
		deleteEdfFilesDate
	) {
		this.id = id;
		this.subjectId = subjectId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.dateOfBirth = dateOfBirth;
		this.sensorConfiguration = sensorConfiguration;
		this.studyTypeCode = studyTypeCode;
		this.studyTypeName = studyTypeName;
		this.studyStatusCode = studyStatusCode;
		this.studyStatusName = studyStatusName;
		this.createdAt = createdAt;
		this.accountId = accountId;
		this.accountName = accountName;
		this.referenceId = referenceId;
		this.referenceNumber = referenceNumber;
		this.serialNo = serialNo;
		this.archived = archived;
		this.archiveStatus = archiveStatus;
		this.archiveRequestedDate = archiveRequestedDate;
		this.archivationDate = archivationDate;
		this.autoArchivedDate = autoArchivedDate;
		this.deleteRawFilesDate = deleteRawFilesDate;
		this.deleteEdfFilesDate = deleteEdfFilesDate;
	}
}
import {
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch roles list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchRolesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'role/search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load roles list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadRolesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchRolesListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setRolesListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear roles list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearRolesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearRolesListData());
}

/**
 * Fetch roles item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the roles item to fetch.
 * @return {function(*=): Promise<RolesItemDataObject>}
 */
export const fetchRolesItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'role/fetch',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load roles item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the roles item to load.
 * @return {function(*=): Promise<RolesItemDataObject>}
 */
export const loadRolesItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchRolesItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setRolesItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear roles item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearRolesItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearRolesItem());
};

/**
 * Create roles item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {RolesItemDataObject} item - Roles item to save.
 * @return {function(*=): Promise<RolesItemDataObject>} Promise that will resolve with the created item 
 * received from IO or undefined if creation failed.
 */
export const createRolesItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update roles item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the roles item to update.
 * @param {RolesItemDataObject} item - Roles item to save.
 * @return {function(*=): Promise<RolesItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateRolesItemAction = (abortCallback, id, item) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'role/update-by-id',
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setRolesItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setRolesItem(updatedItem));
			return updatedItem;
		});
};
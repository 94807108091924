import styles from "./index.module.css";
import "./index.css";
import logoImg from "Images/logo_for_content.png";

import React from "react";
import {CoreLoginPageComponent} from "Core/pages/login";
import {connect} from "react-redux";
import {app_password_reset_request_page_router_path} from "Config/app";
import {getGlobalActions} from "Core/helpers/redux";
import Login from "Core/components/advanced/Login";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {Tooltip} from "react-tippy";
import MaintenanceMessage from "../../../components/display/MaintenanceMessage";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import auth from "../../../auth";
import {get} from "lodash";
import {isLoginWithoutRedirect, isLoginWithRedirect} from "Core/helpers/login";
import ACL from "../../../acl";
import {getArray, getBool, getString} from "Core/helpers/data";
import * as currentUserDataMap from "../../../helpers/currentUser/dataMap";
import {openDialog} from "Core/helpers/dialog";
import IpBlockedDialog from "../../../components/dialogs/ipBlockedDialog";
import {json_response_error_code_ip_blocked} from "Config/io";
import {AclDataObject} from "Core/acl";
import {auth_broadcast_channel, auth_broadcast_message_login} from "Config/auth";

/**
 * This page handles user login
 * @note It supports both direct login and redirect to authorization API by using the 'directLogin' option.
 * Authorization API is usually used when single-sign-on (SSO) is used to authorize users.
 */
class AppLoginPage extends CoreLoginPageComponent {
	constructor(props) {
		super(props, {
			layout: 'login',
		});
		
		// Refs
		/** @type {Login} */
		this.loginFormRef = null;
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		const {clearMessagesAction} = this.props;
		clearMessagesAction();
	}

	/**
	 * Login user with credentials from login form
	 * @param {string} username - Username from login form.
	 * @param {string} password - Password from login form.
	 * @override Method overridden to store user ACL rules and data.
	 */
	login(username, password) {
		const {showErrorMessageAction} = this.props;

		const loading = showPageLoading();
		this.executeAbortableAction(auth.login, username, password, '')
			// Store newly created tokens
			.then(response => {
				if (response) {
					auth.storeAccessToken(get(response, 'data.access_token'));
					auth.storeRefreshToken(get(response, 'data.refresh_token'));
				}
				return response;
			})

			// Call security init API to handle all server-side generated cookies
			.then(response => (response ? this.executeAbortableAction(auth.securityInit) : response))

			// Try to get the authorization code with the newly created access token if login type uses redirects
			// @note If it is successful, user will be redirected to the app, otherwise login form will be shown.
			.then(initResponse => {
				if (isLoginWithRedirect()) {
					return this.executeAbortableAction(auth.generateAuthorizationCode, auth.getAccessToken())
						.then(() => initResponse);
				}
				return Promise.resolve(initResponse);
			})
			
			// Store current user's ACL rules if login was successful and login type does not use redirects
			.then(initResponse => {
				if (isLoginWithoutRedirect()) {
					ACL.save(new AclDataObject(
						getArray(initResponse, 'data.permissionCodes'),
						getString(initResponse, 'data.userAccountType'),
						getBool(initResponse, 'data.userIsGuest')
					));
				}
				return initResponse;
			})
			
			// Store current user's data if login was successful and login type does not use redirects
			.then(initResponse => {
				if (isLoginWithoutRedirect()) {
					auth.storeCurrentUser(currentUserDataMap.input(get(initResponse, 'data')));
				}
				return initResponse;
			})

			// Go to home page if login was successful and login type does not use redirects
			.then(() => {
				if (isLoginWithoutRedirect()) {
					hideLoading(loading);

					// Broadcast log in so that all other opened tabs that need to will redirect to home page
					const bChannel = new BroadcastChannel(auth_broadcast_channel);
					bChannel.postMessage(auth_broadcast_message_login);
					bChannel.close();
				}
			})

			.catch(error => {
				hideLoading(loading);
				if (getString(error, 'response.errorCode') === json_response_error_code_ip_blocked) {
					// Clear login form
					if (this.loginFormRef) this.loginFormRef.clearData().then();
					
					// Open unlock IP dialog
					openDialog('ip-blocked-dialog', IpBlockedDialog, {
						username,
					}, {
						id: 'ip-blocked-dialog',
						closeOnEscape: false,
						closeOnClickOutside: false,
						hideCloseBtn: true,
						maxWidth: 580
					})
				} else {
					showErrorMessageAction(error.message);
				}
			});
	}
	
	/**
	 * Render login form
	 * @return {JSX.Element|null}
	 */
	renderLoginForm() {
		return this.renderLayout((
			<Login
				styleName="card"
				id={this.getDomId()}
				className={`custom-app-login ${this.getOption('domPrefix')} ${styles['wrapper']}`}
				showAppName={true}
				appName={(
					<>
						<img src={logoImg} alt={this.t('title', 'App')} />
						<MaintenanceMessage className={`${styles['maintenance']} notice warning`} />
					</>
				)}
				loginAction={this.login}
				changePasswordAction={() => this.redirectTo(app_password_reset_request_page_router_path)}
				renderToBottom={(
					<Tooltip
						tag="div"
						title={this.t('guest_login_btn_tooltip', 'CustomLoginPage')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
					>
						<Button
							big={true}
							className={styles['guestLoginBtn']}
							displayStyle={BUTTON_STYLE.ACTION}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							label={this.t('guest_login_btn', 'CustomLoginPage')}
							onClick={() => this.login(
								process.env.REACT_APP_SPECIFIC_GUEST_USERNAME, 
								process.env.REACT_APP_SPECIFIC_GUEST_PASSWORD
							)}
						/>
					</Tooltip>
				)}
				ref={node => { this.loginFormRef = node; }}
			/>
		), undefined, undefined, {
			showHeader: true,
			footerJustifyContent: 'center',
			rightSectionJustifyContent: 'flex-start',
			rightSectionAlignItems: 'flex-start',
		});
	}
}

export default connect(null, getGlobalActions())(AppLoginPage);
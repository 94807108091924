import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getStringForDisplay, isset} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {getAppLocaleDatetimeFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "Core/const/locale";
import {ACTIVITY_LOG_STATUSES, ACTIVITY_LOG_MODULES, ACTIVITY_LOG_STATUS, ACTIVITY_LOG_TYPES} from "./const";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {AsyncMountError} from "Core/errors";
import {icon_font_help_circle_symbol} from "Config/app";
import {DataTableCellAnyTypeOptionsDataObject} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import Icon from "Core/components/display/Icon";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getActivityLogList(state),
	mainListPagination: selectors[reducerStoreKey].getActivityLogListPagination(state),
	mainListSort: selectors[reducerStoreKey].getActivityLogListSort(state),
	mainListFilter: selectors[reducerStoreKey].getActivityLogListFilter(state),
});

class ActivityLogPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'activity-log-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainList, loadActivityLogListAction} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadActivityLogListAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadActivityLogListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadActivityLogListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadActivityLogListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadActivityLogListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadActivityLogListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadActivityLogListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {loadActivityLogListAction, mainListPagination, mainListSort} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadActivityLogListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction
		} = this.props;
		const {limitToAvailableSpace} = this.state;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'username',
							this.t('userNameField')
						),
						new SimpleStaticSearchOptionObject(
							'status',
							this.t('statusField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: ACTIVITY_LOG_STATUSES.map(i => ({
									label: this.tt(i, 'activityLogStatusOptions'),
									value: i
								}))
							}
						),
						new SimpleStaticSearchOptionObject(
							'type',
							this.t('typeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: ACTIVITY_LOG_TYPES.map(i => ({
									label: this.tt(i, 'activityLogTypeOptions'),
									value: i
								}))
							}
						),
						new SimpleStaticSearchOptionObject(
							'module',
							this.t('moduleField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: ACTIVITY_LOG_MODULES.map(i => ({
									label: this.tt(i, 'activityLogModulesOptions'),
									value: i
								}))
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('fromDateField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('toDateField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
							}
						)
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="main-page-table"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							sortName: 'status',
							label: this.t('statusField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									<div className="no-wrap">
										<Icon symbol="circle" className="activity-log-row-status-column" />
										<Label
											element="span"
											elementProps={{className: 'activity-log-row-status-column'}}
											content={this.tt(row.status, 'activityLogStatusOptions')}
										/>
									</div>
								),
							}),
							width: 1,
						},
						{
							sortName: 'type',
							label: this.t('typeField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									<>
										<Label content={getStringForDisplay(
											row.type, 
											this.t('N/A', 'general', 'l'), 
											true, 
											true
										)}/>&nbsp;<Tooltip
											title={row.message}
											size="small"
											position="top-center"
											arrow={true}
											interactive={false}
										>
											<Icon symbol={icon_font_help_circle_symbol} />
										</Tooltip>
									</>
								)
							}),
						},
						{
							name: 'module',
							sortName: 'module',
							label: this.t('moduleField'),
						},
						{
							name: 'userName',
							sortName: 'username',
							label: this.t('userNameField'),
							width: 1,
						},
						{
							name: 'creationDate',
							sortName: 'creationDate',
							label: this.t('creationDateField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.STANDARD, LOCALE_TIME_FORMAT_NAME.SHORT
								),
								whiteSpace: 'nowrap',
							},
							width: 1,
						},
						{
							name: 'sessionStarted',
							sortName: 'sessionStarted',
							label: this.t('sessionStartedField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.STANDARD, LOCALE_TIME_FORMAT_NAME.SHORT
								),
								whiteSpace: 'nowrap',
							},
							width: 1,
						},
						{
							name: 'sessionUpdated',
							sortName: 'sessionUpdated',
							label: this.t('sessionUpdatedField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: {
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.STANDARD, LOCALE_TIME_FORMAT_NAME.SHORT
								),
								whiteSpace: 'nowrap',
							},
							width: 1,
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								standardWrapper: false,
								content: this.renderActions,
							},
							stopPropagation: true,
							width: 1,
						}
					]}
					data={mainList}
					highlightedRows={[
						{
							className: `activity-log-row-status-STARTED`,
							rows: getArray(mainList).filter(i => i.status === ACTIVITY_LOG_STATUS.STARTED),
						},
						{
							className: `activity-log-row-status-SUCCEEDED`,
							rows: getArray(mainList).filter(i => i.status === ACTIVITY_LOG_STATUS.SUCCEEDED),
						},
						{
							className: `activity-log-row-status-FAILED`,
							rows: getArray(mainList).filter(i => i.status === ACTIVITY_LOG_STATUS.FAILED),
						}
					]}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), 'layout-with-table', undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(ActivityLogPage);
export const BUTTON_DISPLAY_TYPE_NONE = 'none';
export const BUTTON_DISPLAY_TYPE_SOLID = 'solid';
export const BUTTON_DISPLAY_TYPE_TRANSPARENT = 'transparent';
export const BUTTON_DISPLAY_TYPE = {
	NONE: BUTTON_DISPLAY_TYPE_NONE,
	SOLID: BUTTON_DISPLAY_TYPE_SOLID,
	TRANSPARENT: BUTTON_DISPLAY_TYPE_TRANSPARENT
};
export const BUTTON_DISPLAY_TYPES = [
	BUTTON_DISPLAY_TYPE_NONE, BUTTON_DISPLAY_TYPE_SOLID, BUTTON_DISPLAY_TYPE_TRANSPARENT
];

export const BUTTON_STYLE_NONE = 'none';
export const BUTTON_STYLE_DEFAULT = 'default';
export const BUTTON_STYLE_SUBTLE = 'subtle';
export const BUTTON_STYLE_ACTION = 'action';
export const BUTTON_STYLE_SUCCESS = 'success';
export const BUTTON_STYLE_WARNING = 'warning';
export const BUTTON_STYLE_ERROR = 'error';
export const BUTTON_STYLE = {
	NONE: BUTTON_STYLE_NONE,
	DEFAULT: BUTTON_STYLE_DEFAULT,
	SUBTLE: BUTTON_STYLE_SUBTLE,
	ACTION: BUTTON_STYLE_ACTION,
	SUCCESS: BUTTON_STYLE_SUCCESS,
	WARNING: BUTTON_STYLE_WARNING,
	ERROR: BUTTON_STYLE_ERROR
};
export const BUTTON_STYLES = [
	BUTTON_STYLE_NONE, 
	BUTTON_STYLE_DEFAULT, 
	BUTTON_STYLE_SUBTLE, 
	BUTTON_STYLE_ACTION, 
	BUTTON_STYLE_SUCCESS, 
	BUTTON_STYLE_WARNING, 
	BUTTON_STYLE_ERROR
];
/** @typedef {string} UserType */
export const USER_TYPE_SUPER_ADMIN = "SUPER_ADMIN";
export const USER_TYPE_OPERATOR = "OPERATOR";
/** @enum {UserType} */
export const USER_TYPE = {
	SUPER_ADMIN: USER_TYPE_SUPER_ADMIN,
	OPERATOR: USER_TYPE_OPERATOR
}
/** @type {UserType[]} */
export const USER_TYPES = [
	USER_TYPE_SUPER_ADMIN,
	USER_TYPE_OPERATOR
];


/** @typedef {string} ResourceType */
export const RESOURCE_TYPE_STUDY = "STUDY";
export const RESOURCE_TYPE_ACCOUNT = "ACCOUNT";
export const RESOURCE_TYPE_ACCOUNT_USER = "ACCOUNT_USER";
export const RESOURCE_TYPE_ROLE = "ROLE";
export const RESOURCE_TYPE_QUESTIONNAIRE = "QUESTIONNAIRE";
export const RESOURCE_TYPE_CBTI_STUDY = "CBTI_STUDY";
export const RESOURCE_TYPE_DEVICE = "DEVICE";
export const RESOURCE_TYPE_ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS";
/** @enum {ResourceType} */
export const RESOURCE_TYPE = {
	STUDY: RESOURCE_TYPE_STUDY,
	ACCOUNT: RESOURCE_TYPE_ACCOUNT,
	ACCOUNT_USER: RESOURCE_TYPE_ACCOUNT_USER,
	ROLE: RESOURCE_TYPE_ROLE,
	QUESTIONNAIRE: RESOURCE_TYPE_QUESTIONNAIRE,
	CBTI_STUDY: RESOURCE_TYPE_CBTI_STUDY,
	DEVICE: RESOURCE_TYPE_DEVICE,
	ACCOUNT_SETTINGS: RESOURCE_TYPE_ACCOUNT_SETTINGS
}
/** @type {ResourceType[]} */
export const RESOURCE_TYPES = [
	RESOURCE_TYPE_STUDY, 
	RESOURCE_TYPE_ACCOUNT, 
	RESOURCE_TYPE_ACCOUNT_USER, 
	RESOURCE_TYPE_QUESTIONNAIRE, 
	RESOURCE_TYPE_ACCOUNT_SETTINGS,
	// RESOURCE_TYPE_ROLE, 
	// RESOURCE_TYPE_CBTI_STUDY, 
	// RESOURCE_TYPE_DEVICE,
];
/** @typedef {string} ReProcessStudyCriteria */
export const RE_PROCESS_STUDY_CRITERIA_STANDARD = 'STANDARD';
export const RE_PROCESS_STUDY_CRITERIA_EEG_ONLY = 'EEG_ONLY';
export const RE_PROCESS_STUDY_CRITERIA_LEOG_ONLY = 'LEOG_ONLY';
export const RE_PROCESS_STUDY_CRITERIA_REOG_ONLY = 'REOG_ONLY';
/** @enum {ReProcessStudyCriteria} */
export const RE_PROCESS_STUDY_CRITERIA = {
	STANDARD: RE_PROCESS_STUDY_CRITERIA_STANDARD,
	EEG: RE_PROCESS_STUDY_CRITERIA_EEG_ONLY,
	LEOG: RE_PROCESS_STUDY_CRITERIA_LEOG_ONLY,
	REOG: RE_PROCESS_STUDY_CRITERIA_REOG_ONLY,
};
/** @type {ReProcessStudyCriteria[]} */
export const RE_PROCESS_STUDY_CRITERIAS = [
	RE_PROCESS_STUDY_CRITERIA_STANDARD,
	RE_PROCESS_STUDY_CRITERIA_EEG_ONLY,
	RE_PROCESS_STUDY_CRITERIA_LEOG_ONLY,
	RE_PROCESS_STUDY_CRITERIA_REOG_ONLY,
];
import {cloneDeep} from "lodash";
import {getArray, getBool, getObject, getString, resetObjectValues} from "Core/helpers/data";
import {RolesItemDataObject} from "../dataObjects";
import {ROLE_PERMISSION_RESOURCE, ROLE_PERMISSION_RESOURCES_FOR_GUI} from "../const";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {RolesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	/**
	 * Reduce function for resource privileges
	 */
	const reducePrivileges = (_prev, _curr) => ({..._prev, [_curr.privilege]: _curr.hasPrivilege});
	
	result = new RolesItemDataObject(
		getString(result, 'roleData.id'),
		getString(result, 'roleData.name'),
		getString(result, 'roleData.description'),
		getBool(result, 'global'),
		ROLE_PERMISSION_RESOURCES_FOR_GUI.reduce((prev, res) => ({
			...prev,
			[res]: (
				res === ROLE_PERMISSION_RESOURCE.STUDY ? getArray(result, 'studyPrivilegeList')
						.reduce(reducePrivileges, {}) :
				res === ROLE_PERMISSION_RESOURCE.QUESTIONNAIRE ? getArray(result, 'questionnairePrivilegeList')
						.reduce(reducePrivileges, {}) :
				res === ROLE_PERMISSION_RESOURCE.DEVICE ? getArray(result, 'devicePrivilegeList')
						.reduce(reducePrivileges, {}) :
				res === ROLE_PERMISSION_RESOURCE.ACCOUNT ? getArray(result, 'createAccountList')
						.reduce(reducePrivileges, {}) :
				res === ROLE_PERMISSION_RESOURCE.ACCOUNT_USER ? getArray(result, 'createUserList')
						.reduce(reducePrivileges, {}) :
				res === ROLE_PERMISSION_RESOURCE.ACCOUNT_SETTINGS ? getArray(result, 'accountSettingsPrivilegeList')
						.reduce(reducePrivileges, {}) :
				{}
			)
		}), {}),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {RolesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	/**
	 * Create resource privileges array
	 * @param {RolePermissionResource} res - Resource.
	 */
	const mapPrivileges = res => Object.keys(getObject(inputData, ['resourcePrivileges', res]))
		.map(p => ({resource: res, privilege: p, hasPrivilege: getBool(inputData, ['resourcePrivileges', res, p])}));
	
	result = {
		global: result.global,
		
		roleData: {
			id: result.id,
			name: result.name,
			description: result.description,
		},
		...Object.keys(getObject(result, 'resourcePrivileges')).reduce((prev, res) => {
			if (ROLE_PERMISSION_RESOURCES_FOR_GUI.includes(res)) {
				switch (res) {
					case ROLE_PERMISSION_RESOURCE.STUDY:
						return {...prev, studyPrivilegeList: mapPrivileges(res)};
					case ROLE_PERMISSION_RESOURCE.QUESTIONNAIRE:
						return {...prev, questionnairePrivilegeList: mapPrivileges(res)};
					case ROLE_PERMISSION_RESOURCE.DEVICE:
						return {...prev, devicePrivilegeList: mapPrivileges(res)};
					case ROLE_PERMISSION_RESOURCE.ACCOUNT:
						return {...prev, createAccountList: mapPrivileges(res)};
					case ROLE_PERMISSION_RESOURCE.ACCOUNT_USER:
						return {...prev, createUserList: mapPrivileges(res)};
					case ROLE_PERMISSION_RESOURCE.ACCOUNT_SETTINGS:
						return {...prev, accountSettingsPrivilegeList: mapPrivileges(res)};
					// no default
				}
			}
			return prev;
		}, {}),
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
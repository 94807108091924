/**
 * Data object representing data required for user activation
 */
export class UserAccountActivationDataObject {
	/**
	 * @param {string} [id=''] - Request ID value from page URL.
	 * @param {string} [securityQuestion=''] - Security question defined by the user.
	 * @param {string} [securityAnswer=''] - Security question defined by the user.
	 * @param {string} [password=''] - Password defined by the user.
	 * @param {string} [repeatedPassword=''] - Repeated password defined by the user.
	 */
	constructor(id = '', securityQuestion = '', securityAnswer = '', password = '', repeatedPassword = '') {
		this.id = id;
		this.securityQuestion = securityQuestion;
		this.securityAnswer = securityAnswer;
		this.password = password;
		this.repeatedPassword = repeatedPassword;
	}
}
import headerStyles from "Layout/elements/PublicHeader/index.module.css";
import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import Label from "Core/components/display/Label";
import HelpVideosComponent from "../../../components/display/helpVideos";
import TeamViewerSupport from "../../../components/display/TeamViewerSupport";

class HelpVideosPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'public',
			domPrefix: 'help-videos-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
		}, null);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className={`${styles['mobileSecondHeader']}`}>
					<Label
						element="div"
						elementProps={{className: styles['title']}}
						content={this.t('page_title')}
					/>
					<TeamViewerSupport className={styles['teamViewer']} itemWrapperClassName={styles['itemWrapper']} />
				</div>
				
				<div className={`${styles['content']}`}>
					<HelpVideosComponent
						className={styles['videosComponent']}
						headerClassName={headerStyles['hideMobile']}
						loadingSelector={`.${styles['wrapper']} .${styles['content']}`}
						header={(
							<Label
								element="div"
								elementProps={{className: `${styles['title']}`}}
								content={this.t('page_title')}
							/>
						)}
					/>
				</div>
			</div>
		), 'videos', undefined,{
			showHeader: true,
			headerProps: {
				showTeamViewerLinks: false,
				showAppLogo: true,
			},
			showFooter: true,
			footerProps: {
				justifyContent: 'center',
			}
		});
	}
}

export * from "./config";
export default withRouter(HelpVideosPage);
import {
	ioJsonAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch users list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchUsersListAction = (
	abortCallback, isSystemAdmin = false, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/search`,
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return responseData;
		});
};

/**
 * Load users list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadUsersListAction = (
	abortCallback, isSystemAdmin = false, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	const loading = showLoading("#main-page-table");
	return fetchUsersListAction(abortCallback, isSystemAdmin, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setUsersListData(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear users list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearUsersListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearUsersListData());
}

/**
 * Fetch users item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {string|number} id - DB ID of the users item to fetch.
 * @return {function(*=): Promise<UsersItemDataObject>}
 */
export const fetchUsersItemAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/fetch-by-id`,
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load users item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {string} id - DB ID of the users item to load.
 * @return {function(*=): Promise<UsersItemDataObject>}
 */
export const loadUsersItemAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchUsersItemAction(abortCallback, isSystemAdmin, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setUsersItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear users item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearUsersItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearUsersItem());
};

/**
 * Create users item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {UsersItemDataObject} item - Users item to save.
 * @return {function(*=): Promise<UsersItemDataObject>} Promise that will resolve with the created item
 * received from IO or undefined if creation failed.
 */
export const createUsersItemAction = (abortCallback, isSystemAdmin, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/create`,
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update users item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {string} id - DB ID of the users item to update.
 * @param {UsersItemDataObject} item - Users item to save.
 * @return {function(*=): Promise<UsersItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updateUsersItemAction = (abortCallback, isSystemAdmin, id, item) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/update-by-id`,
		{
			id: id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setUsersItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedItem => {
			if (isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setUsersItem(updatedItem));
			return updatedItem;
		});
};

/**
 * Send activation email to the user
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @param {string|number} id - ID of the users item to send activation email.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const userItemSendActivationEmailAction = (abortCallback, isSystemAdmin, id) => dispatch => {
	const loading = showLoading('#item-send-activation-email-dialog .dialog-content-component .buttons');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/send-activation-mail`,
		{id},
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Reset terms and conditions flag for all users
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const resetTermsAndConditionsAction = abortCallback => dispatch => {
	const loading = showLoading('#reset-terms-and-conditions-dialog .dialog-content-component .buttons');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'user/reset-accepted-terms-flag',
		null,
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Reset consent flag for all users
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const resetConsentAction = abortCallback => dispatch => {
	const loading = showLoading('#reset-consent-dialog .dialog-content-component .buttons');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'user/reset-consent-flag',
		null,
		hideLoadingFunction(loading)
	)(dispatch);
};

/**
 * Load the map of allowed roles per account into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @return {function(*=): Promise<AccountsItemDataObject>}
 */
export const loadAccountRoleMapAction = (abortCallback, isSystemAdmin) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/fetch-allowed-roles-per-account`,
	)(dispatch)
		.then(responseData => {
			if (responseData) {
				const accountRoleMap = getArray(responseData, 'data').map(itemDataMap.inputAccountRole);
				dispatch(actionCreators[reducerStoreKey].setAccountRoleMap(accountRoleMap));
				return accountRoleMap;
			}
			return undefined;
		});
}

/**
 * Clear the map of allowed roles per account from Redux store
 * @return {(function(*): void)|*}
 */
export const clearAccountRoleMapAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearAccountRoleMap());
};

/**
 * Fetch possible list of study status privileges for new user.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @return {function(*=): Promise<UserRoleItemStudyStatusPrivilegeDataObject|undefined>}
 */
export const fetchUserStatusPermissionAction = (abortCallback, isSystemAdmin) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/fetch-user-status-permission`,
		'',
		{},
		hideLoadingFunction(loading)
	)(dispatch)
		.then(responseData => {
			if (responseData) return getArray(responseData, 'data').map(itemDataMap.inputStatusPermission);
			else return responseData;
		});
}

/**
 * Fetch possible list of privilege propagation for new user.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [isSystemAdmin] - Flag that defines if current user is a system admin.
 * @return {function(*=): Promise<UserRoleItemPrivilegePropagationDataObject[]|undefined>}
 */
export const fetchPrivilegePropagationAction = (abortCallback, isSystemAdmin) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		`${isSystemAdmin ? '' : 'member/'}user/fetch-privilege-propagation-list`,
		'',
		{},
		hideLoadingFunction(loading)
	)(dispatch)
		.then(responseData => {
			if (responseData) return getArray(responseData, 'data').map(itemDataMap.inputResourcePrivilege);
			else return responseData;
		});
}
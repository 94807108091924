import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {clearSleepDiaryAction, loadSleepDiaryAction} from "./popups/SleepDiaryPopup/actions";
import {clearQuestionnaireAction, loadQuestionnaireAction} from "./popups/QuestionnairePopup/actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getString, getStringForDisplay, isset} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import AccountSelectInput from "../../../../components/input/AccountSelectInput";
import StudyStatusSelectInput from "../../../../components/input/StudyStatusSelectInput";
import {getAppLocaleDateFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME} from "Core/const/locale";
import Icon from "Core/components/display/Icon";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import TextInput from "Core/components/input/TextInput";
import {getAdvancedSearchVisibilityFromStorage} from "./helpers";
import RadioInput from "Core/components/input/RadioInput";
import MainTableDynamicToolbar from "./components/MainTableDynamicToolbar";
import {get, omit, filter} from "lodash";
import {STUDY_STATES} from "./const";
import MainTableStatusChange from "./components/MainTableStatusChange";
import MainTableStudyEdits from "./components/MainTableStudyEdits";
import MainTableReports from "./components/MainTableReports";
import MainTableActions from "./components/MainTableActions";
import PatientStudyInfoPopup from "./popups/PatientStudyInfoPopup";
import {getPopup, getPopupInstance} from "Core/helpers/popup";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {matchPath} from "react-router-dom";
import {deleteStorageKey, getStorageValue, STORAGE_TYPE} from "Core/storage";
import SleepDiaryPopup from "./popups/SleepDiaryPopup";
import QuestionnairePopup from "./popups/QuestionnairePopup";
import ACL from "../../../../acl";
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject, 
	DataTableCellTextTypeOptionsDataObject
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import {AclCheckDataObject} from "Core/acl";
import {USER_TYPE} from "../users/const";
import {addDays} from "date-fns";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getStudiesList(state),
	mainListPagination: selectors[reducerStoreKey].getStudiesListPagination(state),
	mainListSort: selectors[reducerStoreKey].getStudiesListSort(state),
	mainListFilter: selectors[reducerStoreKey].getStudiesListFilter(state),
	isMainListAdvancedSearchVisible: getAdvancedSearchVisibilityFromStorage(
		selectors[reducerStoreKey].isStudiesListAdvancedSearchVisible(state)
	),
});

class StudiesPage extends PageDataComponent {
	/**
	 * Maximal number for selected rows in the main data table
	 * @type {number}
	 */
	maxSelection = 20;
	
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},
		}, {
			layout: 'studies',
			domPrefix: 'studies-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
			optimizedUpdate: true,
			customSubUrls: [
				// Patient/study info sub-url
				{path: 'edit/:id', exact: true},
				// Sleep diary sub-urls
				{path: 'sleep_diary/new/:studyId', exact: true},
				{path: 'sleep_diary/item/:studyId/:id', exact: true},
				// Questionnaire sub-urls
				{path: 'questionnaire/new/:studyId/:accountId'},
				{path: 'questionnaire/item/:id/:studyId', exact: true},
			],
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;
		this.mainListRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);
		this.isAdvancedListFilterEmpty = this.isAdvancedListFilterEmpty.bind(this);

		// Router methods
		this.handlePatientStudyInfoUrl = this.handlePatientStudyInfoUrl.bind(this);
		this.handleSleepDiaryCreateUrl = this.handleSleepDiaryCreateUrl.bind(this);
		this.handleSleepDiaryEditUrl = this.handleSleepDiaryEditUrl.bind(this);
		this.handleQuestionnaireCreateUrl = this.handleQuestionnaireCreateUrl.bind(this);
		this.handleQuestionnaireEditUrl = this.handleQuestionnaireEditUrl.bind(this);

		// Popup methods
		this.closePopup = this.closePopup.bind(this);
		this.openPatientStudyInfoPopup = this.openPatientStudyInfoPopup.bind(this);
		this.openSleepDiaryPopup = this.openSleepDiaryPopup.bind(this);
		this.openQuestionnairePopup = this.openQuestionnairePopup.bind(this);

		// Render methods
		this.actionsDisabled = this.actionsDisabled.bind(this);
		this.renderActions = this.renderActions.bind(this);
	}
	
	componentDidMount() {
		super.componentDidMount();
		
		// Reload main list on 'refreshStudies' custom event
		window.addEventListener('refreshStudies', this.reloadMainList, false);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		
		// Clear main table selection
		const {updateStudiesListSelectionAction} = this.props;
		updateStudiesListSelectionAction([]);

		// Remove 'refreshStudies' event listener
		window.removeEventListener('refreshStudies', this.reloadMainList, false);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadStudiesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadStudiesListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(() => this.mainListFilterRef?.reload());
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadStudiesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadStudiesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadStudiesListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadStudiesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {loadStudiesListAction, mainListPagination, mainListSort} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);

		// Clear main table selection
		if (this.mainListRef) this.mainListRef.clearSelection();

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadStudiesListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => scrollToSelector('#main-page-table', false, 80));
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}

	/**
	 * Check if main list advanced filter is applied
	 * @note First and last name filters will be ignored since they are used for the quick search.
	 * @return {boolean}
	 */
	isAdvancedListFilterEmpty() {
		return areAllObjectPropsEmpty(
			omit(this.getProp('mainListFilter'), ['firstName', 'lastName']), true, false
		);
	}
	

	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the patient/study info sub-url of the page
	 *
	 * @param {Object} [urlParams={}] - URL params.
	 * @return {string|Promise<string>}
	 */
	handlePatientStudyInfoUrl(urlParams = {}) {
		const {openDialogAction, closeDialogAction} = this.props;
		
		// Open PatientStudyInfoPopup if page popup it is not already opened
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openPatientStudyInfoPopup(getString(urlParams, 'id'));
			return this.urlComponentGUIID;
		}
		// If any popup is opened
		else {
			// Since popup has changed, show a confirm dialog to confirm leaving the currently opened popup to avoid 
			// loosing unsaved changes.
			return new Promise(resolve => {
				const prevPagePopupId = getString(getPopup(this.urlComponentGUIID), 'props.pagePopupId');
				const studyIdFromUrl = getString(urlParams, 'id');

				// If previously opened popup is also PatientStudyInfoPopup 
				if (prevPagePopupId === 'PatientStudyInfoPopup') {
					const prevPagePopupInstance = getPopupInstance(this.urlComponentGUIID);
					const prevPagePopupStudyId = getString(prevPagePopupInstance, 'currentDynamicValues.studyId');
					
					// If previously opened PatientStudyInfoPopup is for the same study
					// @note This will happen when popup change confirm dialog is dismissed and URL goes back to the previous
					// popup URL.
					if (studyIdFromUrl === prevPagePopupStudyId) {
						// Nothing changed so just return the current popup's GUIID
						resolve(this.urlComponentGUIID);
					} 
					// If previous opened PatientStudyInfoPopup is for a different study
					// @note This is a regular PatientStudyInfoPopup update when study ID changes. This might now happen at 
					// all since, technically, it is not possible to open a different PatientStudyInfoPopup without closing 
					// the currently opened one, but this situation needs to be handled nevertheless.
					else {
						openDialogAction('confirm_patient_study_popup_change_dialog', ConfirmDialog, {
							message: this.tt('confirm_popup_change_message', 'dialogs'),
							onYes: dialogGUIID => {
								// Reload popup for a new study
								prevPagePopupInstance.updateDynamics({studyId: studyIdFromUrl}).then();
								// Since the popup is just reloaded, GUIID will stay the same
								resolve(this.urlComponentGUIID);
								closeDialogAction(dialogGUIID);
							},
							onNo: dialogGUIID => {
								// Cancel URL change and stay on the same page if user does not confirm the change
								this.redirectToSubUrl(`edit/${prevPagePopupStudyId}`);
								resolve(this.urlComponentGUIID);
								closeDialogAction(dialogGUIID);
							}
						}, {
							id: 'confirm-study-popup-change-dialog',
							closeOnEscape: true,
							closeOnClickOutside: true,
							hideCloseBtn: true,
							maxWidth: 500
						});
					}
				}
				// If previously opened popup is not PatientStudyInfoPopup
				else {
					// Close the previously opened popup and open a new one
					this.closePopup();
					this.urlComponentGUIID = this.openPatientStudyInfoPopup(studyIdFromUrl);
					resolve(this.urlComponentGUIID);
				}
			});
		}
	}

	/**
	 * Method that will be called if current URL matches the sleep diary create sub-url of the page
	 *
	 * @param {Object} [urlParams={}] - URL params.
	 * @return {string}
	 */
	handleSleepDiaryCreateUrl(urlParams = {}) {
		return this.openSleepDiaryPopup(getString(urlParams, 'studyId'), true);
	}

	/**
	 * Method that will be called if current URL matches the sleep diary edit sub-url of the page
	 *
	 * @param {Object} [urlParams={}] - URL params.
	 * @return {string|Promise<string>}
	 */
	handleSleepDiaryEditUrl(urlParams = {}) {
		const {loadSleepDiaryAction} = this.props;
		
		// Open sleep diary popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the diary or to 
		// avoid opening another dialog if diary ID in the URL changes programmatically. Dialog data will change because 
		// Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openSleepDiaryPopup(getString(urlParams, 'studyId'));
		} else {
			const sleepDiaryPopup = getPopupInstance(this.urlComponentGUIID);
			sleepDiaryPopup.updateDynamics({isNew: false}).then();
		}
		
		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadSleepDiaryAction, getString(urlParams, 'id'))
					.then(sleepDiary => {
						// If sleep diary is successfully loaded
						if (sleepDiary) {
							return this.urlComponentGUIID;
						}
						// If sleep diary could not be loaded (usually if diary with ID from URL does not exist)
						else {
							// Close sleep diary popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes sleep diary ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}


	/**
	 * Method that will be called if current URL matches the questionnaire create sub-url of the page
	 *
	 * @param {Object} [urlParams={}] - URL params.
	 * @return {string}
	 */
	handleQuestionnaireCreateUrl(urlParams = {}) {
		return this.openQuestionnairePopup(
			getString(urlParams, 'accountId'), getString(urlParams, 'studyId'), true
		);
	}

	/**
	 * Method that will be called if current URL matches the questionnaire edit sub-url of the page
	 *
	 * @param {Object} [urlParams={}] - URL params.
	 * @return {string|Promise<string>}
	 */
	handleQuestionnaireEditUrl(urlParams = {}) {
		const {loadQuestionnaireAction} = this.props;

		// Open questionnaire popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the questionnaire 
		// or to avoid opening another dialog if questionnaire ID in the URL changes programmatically. Dialog data will 
		// change because Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openQuestionnairePopup(
				getString(urlParams, 'accountId'), getString(urlParams, 'studyId')
			);
		} else {
			const questionnairePopup = getPopupInstance(this.urlComponentGUIID);
			questionnairePopup.updateDynamics({isNew: false}).then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadQuestionnaireAction, getString(urlParams, 'id'))
					.then(questionnaire => {
						// If questionnaire is successfully loaded
						if (questionnaire) {
							return this.urlComponentGUIID;
						}
						// If questionnaire could not be loaded (usually if questionnaire with ID from URL does not exist)
						else {
							// Close questionnaire popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes questionnaire ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL is one of the custom sub-urls defined in 'customSubUrls' option.
	 *
	 * @param {{isExact: boolean, params: Object, path: string, url: string}} customUrlMatch - Custom page sub-url match
	 * object.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page URL is
	 * a custom sub-url if such component exists.
	 */
	handleCustomUrl(customUrlMatch, prevLocation) {
		const {hideMessageAction} = this.props;

		// Hide year warning message if it is present and remove its GUIID reference from memory
		const yearWarningMsg = getStorageValue('yearWarningMsg', STORAGE_TYPE.MEMORY);
		if (yearWarningMsg) {
			hideMessageAction(yearWarningMsg);
			deleteStorageKey('yearWarningMsg', STORAGE_TYPE.MEMORY);
		}
		
		switch (customUrlMatch.path) {
			// Patient/study info sub-url
			case 'edit/:id': return this.handlePatientStudyInfoUrl(customUrlMatch.params);

			// Sleep diary sub-urls
			case 'sleep_diary/new/:studyId': return this.handleSleepDiaryCreateUrl(customUrlMatch.params);
			case 'sleep_diary/item/:studyId/:id': return this.handleSleepDiaryEditUrl(customUrlMatch.params);

			// Sleep diary sub-urls
			case 'questionnaire/new/:studyId/:accountId': return this.handleQuestionnaireCreateUrl(customUrlMatch.params);
			case 'questionnaire/item/:id/:studyId': return this.handleQuestionnaireEditUrl(customUrlMatch.params);
			
			default: return '';
		}
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on 
	 * its base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		const {mainList, loadStudiesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);
		
		// Close any page popup first
		this.closePopup();
		
		// Load main page data
		// @note Since 'handleBaseUrl' will be called on page mount, this method can be used instead of the 'loadPageData'
		// to load the initial data. It has an added benefit of loading main data whenever any popup closes. Just be 
		// careful with page sub-urls and make sure that this will work in all possible situations on the page.
		return new Promise(resolve => {			
			// Do not load (just reload) main list if it is already loaded
			if (isset(mainList)) {
				if (prevLocation) {
					const matchNewSleepDiary = matchPath(
						prevLocation.pathname,
						{path: `${this.getOption('routerPath')}/sleep_diary/new/:studyId`, exact: true}
					);
					const matchEditSleepDiary = matchPath(
						prevLocation.pathname,
						{path: `${this.getOption('routerPath')}/sleep_diary/item/:studyId/:id`, exact: true}
					);

					// Do not reload main list if sleep diary popup closes since nothing will change when sleep diary changes
					if (!!matchNewSleepDiary || !!matchEditSleepDiary) resolve();
					// Just reload the main list
					else this.reloadMainList().then(() => { resolve(); });
				}
				// Just reload the main list
				else {
					this.reloadMainList().then(() => { resolve(); });
				}
			}
			// Load main list if it is not already loaded
			else {
				this.setValue('loading', true)
					.then(() => this.executeAbortableAction(loadStudiesListAction, oFilter, pageNo, perPage, sortBy, sortDir))
					.then(res => (isset(res) ? this.setValue('loading', false) : Promise.resolve(this.state)))
					.then(() => { resolve(); });
			}
		});
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		const {hideMessageAction} = this.props;

		// Hide year warning message if it is present and remove its GUIID reference from memory
		const yearWarningMsg = getStorageValue('yearWarningMsg', STORAGE_TYPE.MEMORY);
		if (yearWarningMsg) {
			hideMessageAction(yearWarningMsg);
			deleteStorageKey('yearWarningMsg', STORAGE_TYPE.MEMORY);
		}

		// Close the sub-url popup
		this.closePopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Close currently opened popup
	 * @note Since, on this page, only one popup can be opened at a time there is only one method to close any popup. To
	 * handle custom functionality regarding closing specific popups, please use 'onClose' event of the popup itself.
	 */
	closePopup() {
		const {closePopupAction, closeDialogAction, clearSleepDiaryAction} = this.props;
		
		// Close all dialogs so that patient select and other dialogs will not stay opened once popup has been closed
		closeDialogAction('questionnaire-patient-selection-dialog');
		closeDialogAction('questionnaire-result-dialog');
		closeDialogAction('re-process-dialog');
		closeDialogAction('to-archive-confirm-dialog');
		closeDialogAction('study-status-change-dialog');
		closeDialogAction('confirm_patient_study_popup_change_dialog');
		closeDialogAction('studies-comparison-dialog');
		
		// Force close page popup without triggering its 'onClose' event and clear the component reference related to it
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();
		
		// Clear relevant Redux store data
		clearSleepDiaryAction();
	}
	
	/**
	 * Open patient/study info popup
	 * @param {string} studyId - ID of the study.
	 */
	openPatientStudyInfoPopup(studyId) {
		const {openPopupAction} = this.props;
		return openPopupAction(PatientStudyInfoPopup, {
			// Page popup ID is defined in order to identify different popups that can be opened on this page
			pagePopupId: 'PatientStudyInfoPopup',
			initialStudyId: studyId,
			onClose: () => {
				// Redirect to the page base URL
				// @note 'handleBaseUrl' method should actually close the popup.
				this.redirectToBase();
			},
		});
	}

	/**
	 * Open sleep diary popup
	 * @param {string} studyId - ID of the study.
	 * @param {boolean} [isNew=false] - Flag that specifies if new sleep diary popup should be opened.
	 */
	openSleepDiaryPopup(studyId, isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(SleepDiaryPopup, {
			// Page popup ID is defined in order to identify different popups that can be opened on this page
			pagePopupId: 'SleepDiaryPopup',
			studyId,
			isNew,
			onClose: () => {
				// Redirect to the page base URL
				// @note 'handleBaseUrl' method should actually close the popup.
				this.redirectToBase();
			},
		});
	}

	/**
	 * Open questionnaire popup
	 *
	 * @param {string} accountId - Account ID.
	 * @param {string} studyId - ID of the study.
	 * @param {boolean} [isNew=false] - Flag that specifies if new questionnaire popup should be opened.
	 */
	openQuestionnairePopup(accountId, studyId, isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(QuestionnairePopup, {
			styleName: 'wizard',
			// Page popup ID is defined in order to identify different popups that can be opened on this page
			pagePopupId: 'QuestionnairePopup',
			accountId,
			studyId,
			isNew,
			redirectToBase: this.redirectToBase,
			onClose: () => {
				// Redirect to the page base URL
				// @note 'handleBaseUrl' method should actually close the popup.
				this.redirectToBase();
			},
		}, undefined, 'questionnaire-popup-guiid');
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if actions should be disabled
	 *
	 * @param {StudiesListItemDataObject} row - Data table row.
	 * @return {boolean}
	 */
	actionsDisabled(row) {
		return (
			// Archived studies
			row.archived === true ||
			// Study processing statuses
			['20', '25', 'STUDY_PROCESSING'].includes(row.studyStatusCode)
			// TODO: Add more conditions after API implementation is complete
		);
	}
	
	/**
	 * Render data table actions cell
	 * @param {StudiesListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		const disabled = this.actionsDisabled(row);
		const archived = (row.archived === true || ['ARCHIVE_SUCCEEDED'].includes(row.studyStatusCode)); 
		
		return (
			<div className="actions">
				<Tooltip
					tag="div"
					title={archived ? this.t('archived_tooltip') : this.t('study_edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<MainTableStudyEdits study={row} disabled={disabled} />
				</Tooltip>
				
				<Tooltip
					tag="div"
					title={archived ? this.t('archived_tooltip') : this.t('study_actions_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<MainTableActions study={row} reloadMainList={this.reloadMainList} disabled={disabled} />
				</Tooltip>

				<Tooltip
					tag="div"
					title={archived ? this.t('archived_tooltip') : this.t('study_reports_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<MainTableReports study={row} disabled={disabled} />
				</Tooltip>
			</div>
		);
	}
	
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction,
			toggleStudiesListAdvancedSearchAction, isMainListAdvancedSearchVisible, updateStudiesListSelectionAction
		} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}
				
				<div className={`toolbar standard for-table ${styles['tableToolbar']}`}>
					{
						!isMainListAdvancedSearchVisible ?
							<>
								<Label 
									element="div" 
									elementProps={{className: 'label'}} 
									content={`${this.t('Search', 'general')}:`} 
								/>
								<div>
									<TextInput
										tabIndex={0}
										className={styles['quickSearchInput']}
										value={getString(mainListFilter, 'lastName')}
										onEnterKey={e => this.filterMainList({
											...get(this.props, 'mainListFilter', {}),
											lastName: getString(e, 'target.value'),
											firstName: getString(this.quickSearchFirstNameRef?.getData())
										})}
										placeholder={this.t('lastNameField') + ' ...'}
										ref={node => { this.quickSearchLastNameRef = node; }}
									/>
								</div>
								<div>
									<TextInput
										tabIndex={0}
										className={styles['quickSearchInput']}
										value={getString(mainListFilter, 'firstName')}
										onEnterKey={e => this.filterMainList({
											...get(this.props, 'mainListFilter', {}),
											lastName: getString(this.quickSearchLastNameRef?.getData()),
											firstName: getString(e, 'target.value')
										})}
										placeholder={this.t('firstNameField') + ' ...'}
										ref={node => { this.quickSearchFirstNameRef = node; }}
									/>
								</div>
								<div className="separator" />
								<Button
									tabIndex={0}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									className={`toolbar-button with-tooltip`}
									onClick={() => {
										const lastName = getString(this.quickSearchLastNameRef?.getData());
										const firstName = getString(this.quickSearchFirstNameRef?.getData());
										this.filterMainList({
											...get(this.props, 'mainListFilter', {}),
											lastName,
											firstName
										}).then();
									}}
								>
									<Label
										icon="search"
										iconClassName={styles['quickSearchIcon']}
										tooltip={this.t('Quick search')}
									/>
								</Button>
								<div className="separator" />
								<Button
									tabIndex={0}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									className={`toolbar-button`}
									onClick={toggleStudiesListAdvancedSearchAction}
								>
									<Label icon="sliders" content={this.t('Advanced search')} />
									{
										!this.isAdvancedListFilterEmpty() ?
											<Label 
												element="span"
												elementProps={{className: styles['searchAppliedIconWrapper']}}
												icon="filter"
												iconClassName={styles['searchAppliedIcon']}
												tooltip={this.t('applied', 'SimpleStaticSearchComponent')}
											/>
											: null
									}
								</Button>
								<div className="separator" />
								{
									!this.isMainListFilterEmpty() ?
										<>
											<Button
												tabIndex={0}
												displayType={BUTTON_DISPLAY_TYPE.NONE}
												className={`toolbar-button with-tooltip`}
												onClick={this.removeMainListFilter}
											>
												<Label
													icon="times"
													iconClassName={styles['quickSearchIcon']}
													tooltip={this.t('remove_btn', 'SimpleStaticSearchComponent')}
												/>
											</Button>
											<div className="separator" />
										</>
										: null
								}
							</>
							: null
					}
					<MainTableDynamicToolbar 
						maxSelection={this.maxSelection} 
						studiesTableRef={this.mainListRef}
						reloadStudies={this.reloadMainList}
					/>
				</div>
				
				<DataTable
					id="main-page-table"
					className={`standard sticky-last-column studies-table ${styles['table']}`}
					maxSelection={this.maxSelection}
					limitToAvailableSpace={true}
					highlightOnHover={true}
					selectable={true}
					primaryKeyColumn="id"
					columns={[
						{
							label: this.t('nameField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content:
									row => (
										<div>
											<span className="no-wrap">
												<Tooltip
													html={(
														ACL.check(ACL, new AclCheckDataObject(undefined, [USER_TYPE.SUPER_ADMIN])) ?
															<div className="text-left">
																{this.tt('refIdField', 'tooltipFields')}: {row.referenceId}<br/>
																{this.tt('refNumberField', 'tooltipFields')}: {row.referenceNumber}<br/>
																{this.tt('serialNoField', 'tooltipFields')}: {row.serialNo}<br/>
																{this.tt('accountNameField', 'tooltipFields')}: {row.accountName}
																<hr />
																{this.tt('archiveStatus', 'tooltipFields')}: {getStringForDisplay(row.archiveStatus, undefined, true, true)}<br/>
																{this.tt('archivedRequested', 'tooltipFields')}: {getStringForDisplay(row.archiveRequestedDate, undefined, true, true)}<br/>
																{this.tt('archived', 'tooltipFields')}: {getStringForDisplay(row.archivationDate, undefined, true, true)}<br/>
																{this.tt('autoArchived', 'tooltipFields')}: {getStringForDisplay(row.autoArchivedDate, undefined, true, true)}<br/>
																{this.tt('rawDeleted', 'tooltipFields')}: {getStringForDisplay(row.deleteRawFilesDate, undefined, true, true)}<br/>
																{this.tt('edfDeleted', 'tooltipFields')}: {getStringForDisplay(row.deleteEdfFilesDate, undefined, true, true)}<br/>
															</div>
															:
															<div className="text-left">
																{this.tt('refNumberField', 'tooltipFields')}: {row.referenceNumber}<br/>
																{this.tt('serialNoField', 'tooltipFields')}: {row.serialNo}<br/>
																{this.tt('accountNameField', 'tooltipFields')}: {row.accountName}
															</div>
													)}
													size="small"
													position="top-center"
													arrow={true}
													interactive={true}
												>
													<Icon symbol="info-circle"/>
												</Tooltip> {row.lastName},</span> <span className="no-wrap">
												{row.firstName}&nbsp;
											</span>
										</div>
									)
							}),
						},
						{
							name: 'dateOfBirth',
							label: this.t('dateOfBirthField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.STANDARD),
								whiteSpace: 'nowrap',
							}),
							width: 1,
						},
						{
							name: 'sensorConfiguration',
							label: this.t('sensorConfigurationField'),
							dataType: DATA_TABLE_CELL_TYPE.TEXT,
							dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
								translatePath: this.getTranslationPath('sensorConfiguration'),
							}),
							width: 1,
						},
						{
							label: this.t('studyTypeField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									// Use i18n to get the label but fallback to the study type name field
									this.hasTranslation(row.studyTypeCode, 'constants.studyType')?
										this.t(row.studyTypeCode, 'constants.studyType') :
										row.studyTypeName
								),
							}),
							width: 1,
						},
						{
							name: 'createdAt',
							sortName: 'creationDate',
							label: this.t('creationDateField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.STANDARD),
								whiteSpace: 'nowrap',
							}),
							width: 1,
						},
						{
							sortName: 'status',
							label: this.t('studyStatusField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									!ACL.isGuest(ACL) ?
										<MainTableStatusChange 
											study={row} 
											reloadMainList={this.reloadMainList} 
											disabled={this.actionsDisabled(row)} 
										/>
										:
										<Label
											element="div"
											elementProps={{className: 'no-wrap'}}
											content={
												// Use i18n to get the label but fallback to the study status name field
												this.hasTranslation(row.studyStatusCode, 'constants.studyStatus') ?
													this.t(row.studyStatusCode, 'constants.studyStatus') :
													row.studyStatusName
											} 
										/>
								),
								standardWrapper: ACL.isGuest(ACL),
							}),
							/*width: 1,*/
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: false,
							width: 1,
						}
					]}
					highlightedRows={[
						{
							className: styles['archived'],
							rows: filter(mainList, {archived: true})
						}
					]}
					data={mainList}
					paginationType={PAGINATION_TYPE.DYNAMIC}
					paginationOptions={{maxPages: 10}}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					onSelect={updateStudiesListSelectionAction}
					{...mainListPagination}
					{...mainListSort}
					ref={node => { this.mainListRef = node; }}
				/>
			</div>
		), 'layout-with-table', undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
			contentSidebarRight: (
				isMainListAdvancedSearchVisible ?
					<SimpleStaticSearch
						styleName="none"
						collapsable={false}
						className={`section main-search studies-page-advanced-search ${styles['advancedSearch']}`}
						appliedClassName={styles['applied']}
						clearButtonProps={{className: `${styles['button']} ${styles['clearButton']}`}}
						removeButtonProps={{className: `${styles['button']} ${styles['removeButton']}`}}
						layout={SIMPLE_STATIC_SEARCH_LAYOUT.ALIGNED}
						buttonProps={{
							className: styles['searchButton'],
							big: true,
							label: this.t('Search', 'general'),
						}}
						options={[
							new SimpleStaticSearchOptionObject(
								'lastName',
								this.t('lastNameField'),
							),
							new SimpleStaticSearchOptionObject(
								'firstName',
								this.t('firstNameField'),
							),
							new SimpleStaticSearchOptionObject(
								'deviceSerialNumber',
								this.t('serialNoField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TEXT,
								{},
								null,
								// {
								// 	help: this.t('serialNoFieldNote')
								// }
							),
							new SimpleStaticSearchOptionObject(
								'studyReferenceNumber',
								this.t('refNumberField'),
							),
							new SimpleStaticSearchOptionObject(
								'accountId',
								this.t('accountNameField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{isClearable: true},
								AccountSelectInput
							),
							new SimpleStaticSearchOptionObject(
								'studyStatusIds',
								this.t('studyStatusField'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{isClearable: true, isMulti: true, forFilter: true},
								StudyStatusSelectInput
							),
							new SimpleStaticSearchOptionObject(
								'createdFromDate',
								`${this.t('creationDateField')} ${this.t('From', 'general', 'l')}`,
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									maxValue: addDays(new Date(), 1),
								}
							),
							new SimpleStaticSearchOptionObject(
								'createdToDate',
								`${this.t('creationDateField')} ${this.t('To', 'general', 'l')}`,
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
								{
									valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
									maxValue: addDays(new Date(), 1),
								}
							),
							new SimpleStaticSearchOptionObject(
								'searchArchived',
								this.t('stateLabel'),
								SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
								{
									className: styles['radioGroup'],
									options: STUDY_STATES.map(s => ({label: this.tt(s, 'state'), value: s})),
									emptyValue: 'CURRENT',
									buttonProps: {
										className: styles['radioBtn'],
										displayStyle: BUTTON_STYLE.DEFAULT,
									},
								},
								RadioInput
							),
						]}
						value={mainListFilter}
						title={(
							<>
								<Label
									element="span"
									elementProps={{className: `title-label ${styles['titleLabel']}`}}
									icon="sliders"
									content={this.t('Advanced search')}
								/>
								<Button 
									className={styles['advancedSearchCloseBtn']}
									icon="chevron-right"
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									onClick={toggleStudiesListAdvancedSearchAction}
								/>
							</>
						)}
						applied={!this.isMainListFilterEmpty()}
						enableToolbar={false}
						enableResetButton={false}
						onChange={this.filterMainList}
						onRemove={this.removeMainListFilter}
						ref={node => { this.mainListFilterRef = node; }}
					/>
					: null
			)
		});
	}
}

export * from "./config";
export default connect(
	mapStateToProps, getPageActions(actions, {
		loadSleepDiaryAction, clearSleepDiaryAction, clearQuestionnaireAction, loadQuestionnaireAction
	})
)(StudiesPage);
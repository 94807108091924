import styles from "./index.module.css";
import "./index.css";
import logoImg from "Images/logo_for_content.png";

import React from "react";
import {CorePasswordResetPageComponent} from "Core/pages/passwordReset";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import * as actions from "./actions";
import PasswordReset from "Core/components/advanced/PasswordReset";
import Label from "Core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {app_login_page_router_path, icon_font_close_symbol} from "Config/app";
import {getBool, getString, isset} from "Core/helpers/data";
import {get} from "lodash";
import AppPasswordResetSecurityQuestion from "../../../components/advanced/PasswordResetSecurityQuestion";
import {AsyncMountError} from "Core/errors";

class AppPasswordResetPage extends CorePasswordResetPageComponent {
	constructor(props) {
		super(props, {
			layout: 'login',
		}, {
			/**
			 * Security question loaded from  IO
			 * @note Empty string means that the question is loaded but is empty or loading failed. Null means that 
			 * question was not loaded yet.
			 * @type {string|null}
			 */
			securityQuestion: null,
			/**
			 * Flag that specifies if the answer to the security question is correct
			 * @note Null means that user has not yet submitted the answer (answer was not checked yet).
			 * @type {boolean|null}
			 */
			securityAnswerValid: null,

			/**
			 * Error message title
			 * @type {string|null}
			 */
			errorTitle: null,
			/**
			 * Error message full description
			 * @type {string|null}
			 */
			errorDescription: null,
		});
		
		// Refs
		this.answerInputRef = null;
		
		// Action methods
		this.loadSecurityQuestion = this.loadSecurityQuestion.bind(this);
		this.submitSecurityQuestionAnswer = this.submitSecurityQuestionAnswer.bind(this);
		
		// Render methods
		this.renderError = this.renderError.bind(this);
		this.renderSuccess = this.renderSuccess.bind(this);
		this.renderSecurityQuestion = this.renderSecurityQuestion.bind(this);
		this.renderChangePassword = this.renderChangePassword.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const hash = this.getUrlParam('t');
		if (hash) return this.loadSecurityQuestion();
		return Promise.resolve();
	}
	

	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		const {clearMessagesAction} = this.props;
		clearMessagesAction();
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Load users security question into local component's state
	 * @return {Promise<IoJsonResponseObject|undefined>}
	 * @throws {AsyncMountError}
	 */
	loadSecurityQuestion() {
		const {fetchSecurityQuestionAction} = this.props;
		const hash = this.getUrlParam('t');
		
		return this.executeAbortableAction(fetchSecurityQuestionAction, hash)
			.then(response => {
				if (response === 'AbortError') throw new AsyncMountError();
				else if (isset(response)) {
					const success = get(response, 'success');
					const errorTitle = (success === false ? this.t('security_question_load_error') : null);
					const errorDescription = (success === false ? getString(response, 'errorMessage') : null);

					return this.setState({
						securityQuestion: getString(response, 'data.securityQuestion'),
						errorTitle: errorTitle,
						errorDescription: errorDescription
					}).then(() => response);
				} else {
					return Promise.resolve(response);
				}
			});
	}

	/**
	 * Submit the answer to the security question provided by the user
	 * @return {Promise<IoJsonResponseObject|undefined>}
	 */
	submitSecurityQuestionAnswer(securityAnswer) {
		const {submitSecurityQuestionAnswerAction, addErrorMessageAction} = this.props;
		const hash = this.getUrlParam('t');

		return this.executeAbortableAction(submitSecurityQuestionAnswerAction, hash, securityAnswer)
			.then(response => {
				if (isset(response)) {
					return this.setState({securityAnswerValid: getBool(response, 'data.securityAnswerValid')})
						.then(() => {
							const {securityAnswerValid} = this.state;
							if (securityAnswerValid !== true) {
								addErrorMessageAction(this.t('wrong_answer_error'));
								if (this.answerInputRef) {
									this.answerInputRef.clearData();
									this.answerInputRef.focus();
								}
							}
						})
						.then(() => response);
				} else {
					return Promise.resolve(response);
				}
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Content to render when a standard JSON response error occurs
	 * @note Other errors will be displayed as standard global errors.
	 *
	 * @param {string} [title] - Error title. If falsy, 'errorTitle' from state will be used.
	 * @param {string} [description] - Error description. If falsy, 'errorDescription' from state will be used.
	 * @return {JSX.Element}
	 */
	renderError(title, description) {
		const errorTitle = (title ? title : this.state.errorTitle);
		const errorDescription = (description ? description : this.state.errorDescription);
		
		return (
			<div className={`${styles['contentWrapper']}`}>
				<div className={`${styles['appName']}`}><img src={logoImg} alt={this.t('title', 'App')} /></div>
				<div className={`${styles['notice']}`}>
					<Label
						element="p"
						elementProps={{className: 'page-notice-title error-color'}}
						content={errorTitle}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={errorDescription}
					/>
				</div>

				<div className={`${styles['actions']}`}>
					<Button
						big={true}
						icon={icon_font_close_symbol}
						label={this.t('Close', 'general')}
						displayStyle={BUTTON_STYLE.SUBTLE}
						displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
						onClick={() => this.redirectTo(app_login_page_router_path)}
					/>
				</div>
			</div>
		);
	}

	/**
	 * Content to render when password was successfully reset
	 * @return {JSX.Element}
	 */
	renderSuccess() {
		return (
			<div className={`${styles['contentWrapper']}`}>
				<div className={`${styles['appName']}`}><img src={logoImg} alt={this.t('title', 'App')} /></div>
				<div className={`${styles['notice']}`}>
					<Label
						element="p"
						elementProps={{className: 'page-notice-title success-color'}}
						content={this.t('success')}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={this.t('success_desc')}
					/>
				</div>

				<div className={`${styles['actions']}`}>
					<Button
						big={true}
						icon="unlock-alt"
						label={this.t('Login', 'Login')}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => this.redirectTo(app_login_page_router_path)}
					/>
				</div>
			</div>
		);
	}

	/**
	 * Content to render when user needs to answer the security question
	 * @note When security question is loaded properly.
	 * @return {JSX.Element}
	 */
	renderSecurityQuestion() {
		const {securityQuestion} = this.state;
		
		return (
			<AppPasswordResetSecurityQuestion
				styleName="card"
				className={`custom-password-reset ${styles['wrapper']}`}
				showAppName={true}
				appName={<img src={logoImg} alt={this.t('title', 'App')} />}
				question={securityQuestion}
				action={this.submitSecurityQuestionAnswer}
				ref={node => { this.answerInputRef = node; }}
			/>
		);
	}

	/**
	 * Content to render when user needs to entre a new password
	 * @note When security question was answered correctly by the user.
	 * @return {JSX.Element}
	 */
	renderChangePassword() {
		return (
			<PasswordReset
				styleName="card"
				className={`custom-password-reset ${styles['wrapper']}`}
				showAppName={true}
				appName={<img src={logoImg} alt={this.t('title', 'App')} />}
				action={this.resetPassword}
				passwordInstructions={this.t('password_instructions', 'App')}
			/>
		);
	}
	
	render() {
		const {successful, errorTitle, securityQuestion, securityAnswerValid} = this.state;
		const hash = this.getUrlParam('t');

		return this.renderLayout((
			!hash ? 
				this.renderError(this.t('missing_hash_error_title'), this.t('missing_hash_error')) 
				:
				errorTitle !== null ? 
					this.renderError() 
					: 
					<>
						{securityQuestion !== null && (securityAnswerValid === null || securityAnswerValid === false) ? this.renderSecurityQuestion() : null}
						{!successful && securityAnswerValid === true ? this.renderChangePassword() : null}
						{successful === true ? this.renderSuccess() : null}
					</>
		), undefined, undefined, {
			showHeader: false,
			footerJustifyContent: 'center',
			showRightSection: false,
		});
	}
}

export default connect(null, getGlobalActions(actions))(AppPasswordResetPage);
import {getIOUrl} from "Core/io/helper";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {getBool, getObject, getString} from "Core/helpers/data";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import {addErrorMessage} from "Core/helpers/message";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {messages_default_auto_hide_after} from "Config/app";
import {ioJsonRequest} from "Core/io";

/**
 * Fetch application parameters used by the GUI (maintenance message, software version, ...) from IO
 * @return {Promise<
 * 	{maintenanceMessage: string, softwareUID: string, softwareVersion: string, currentFooter: string} | null
 * >}
 */
export const fetchApplicationGuiParameters = () => {
	// Get cookie settings
	const cookie = new CookieData('necessary', 'application_parameters', STORAGE_TYPE.LOCAL);
	
	const api = 'defaultApi';
	const endpoint = 'application-parameter/fetch-gui-params';
	const url = getIOUrl(api, endpoint);

	return ioJsonRequest({
		url, api, endpoint, autoToken: false,
	})
		.then(response => {
			if (response && CookieConsent.isAllowed(cookie)) {
				const data = getObject(response, 'data', null);
				setStorageValue(
					'application_parameters', data, STORAGE_TYPE.LOCAL, undefined, true
				);
				return data;
			}
			return null;
		})
		.catch(error => {
			// Render error if it is not 'AbortError' or 'hidden' flag is not true
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError' && !getBool(error, 'hidden')) {
				addErrorMessage(error.message, messages_default_auto_hide_after);
			}
			return null;
		});
};

/**
 * Get all application parameters used by the GUI
 * @note This function will try to load application parameters from local storage first and only if it fails or 
 * 'forceFetch' argument is set to true, it will try to fetch them from IO. Local storage data will be updated with the
 * fetched data.
 * 
 * @param {boolean} [forceFetch=false] - Flag that specifies if IO fetch will be used instead of trying to load 
 * application GUI parameters from local storage first.
 * @return {Promise<
 * 	{maintenanceMessage: string, softwareUID: string, softwareVersion: string, currentFooter: string} | null
 * >}
 */
export const getApplicationGuiParameters = async (forceFetch = false) => {
	// Get cookie settings
	const cookie = new CookieData('necessary', 'application_parameters', STORAGE_TYPE.LOCAL);
	
	let result = null;
		
	// Try to load application GUI parameters from local storage (cookie)
	if (CookieConsent.isAllowed(cookie)) {
		result = getStorageValue(
			'application_parameters', STORAGE_TYPE.LOCAL, true, null
		);
	}
	
	// If application GUI parameters are empty (not loaded from local storage or generally not present)
	if (!result || forceFetch) {
		// Try to fetch application GUI parameters from IO
		result = await fetchApplicationGuiParameters();
		
		// Store fetched application GUI parameters in local storage if allowed by the cookie consent 
		if (CookieConsent.isAllowed(cookie)) {
			setStorageValue(
				'application_parameters', result, STORAGE_TYPE.LOCAL, undefined, true
			);
		}
	}
	
	return result;
};

/**
 * Get a maintenance message from application GUI parameters
 * @note This function will try to load application parameters from local storage first and only if it fails or
 * 'forceFetch' argument is set to true, it will try to fetch them from IO.
 *
 * @param {boolean} [forceFetch=false] - Flag that specifies if IO fetch will be used instead of trying to load
 * application GUI parameters from local storage first.
 * @return {Promise<string>}
 */
export const getApplicationMaintenanceMessage = async (forceFetch = false) => {
	const applicationGuiParameters = await getApplicationGuiParameters(forceFetch);
	return getString(applicationGuiParameters, 'maintenanceMessage');
};

/**
 * Get a software UID from application GUI parameters
 * @note This function will try to load application parameters from local storage first and only if it fails or
 * 'forceFetch' argument is set to true, it will try to fetch them from IO.
 *
 * @param {boolean} [forceFetch=false] - Flag that specifies if IO fetch will be used instead of trying to load
 * application GUI parameters from local storage first.
 * @return {Promise<string>}
 */
export const getApplicationSoftwareUID = async (forceFetch = false) => {
	const applicationGuiParameters = await getApplicationGuiParameters(forceFetch);
	return getString(applicationGuiParameters, 'softwareUID');
};

/**
 * Get a software version from application GUI parameters
 * @note This function will try to load application parameters from local storage first and only if it fails or
 * 'forceFetch' argument is set to true, it will try to fetch them from IO.
 *
 * @param {boolean} [forceFetch=false] - Flag that specifies if IO fetch will be used instead of trying to load
 * application GUI parameters from local storage first.
 * @return {Promise<string>}
 */
export const getApplicationSoftwareVersion = async (forceFetch = false) => {
	const applicationGuiParameters = await getApplicationGuiParameters(forceFetch);
	return getString(applicationGuiParameters, 'softwareVersion');
};

/**
 * Get a footer text from application GUI parameters
 * @note This function will try to load application parameters from local storage first and only if it fails or
 * 'forceFetch' argument is set to true, it will try to fetch them from IO.
 *
 * @param {boolean} [forceFetch=false] - Flag that specifies if IO fetch will be used instead of trying to load
 * application GUI parameters from local storage first.
 * @return {Promise<string>}
 */
export const getApplicationFooter = async (forceFetch = false) => {
	const applicationGuiParameters = await getApplicationGuiParameters(forceFetch);
	return getString(applicationGuiParameters, 'currentFooter');
};
import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {FirmwareListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {get} from "lodash";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {FirmwareListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new FirmwareListItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceType'),
		getString(result, 'firmwareVersion'),
		getDate(get(result, 'firmwareReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S)
	);

	return result;
}

// @note There is no output function since list is read only.
import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {getBoolFromTinyInt, isset} from "Core/helpers/data";

/**
 * Get advanced search visibility flag value form storage
 * @note This function will check cookie access.
 *
 * @param {'0'|'1'} [defaultValue] - Default value if data is not in storage.
 * @return {boolean|undefined} Returns boolean if flag is stored in storage. If default value is not set and data is not
 * in storage, undefined will be returned.
 */
export const getAdvancedSearchVisibilityFromStorage = defaultValue => {
	const advancedSearchVisibilityCookie = new CookieData(
		'necessary', 'studies_list_advanced_search_visible', STORAGE_TYPE.SESSION
	);
	if (CookieConsent.isAllowed(advancedSearchVisibilityCookie)) {
		const fromStorage = getStorageValue('studies_list_advanced_search_visible', STORAGE_TYPE.SESSION);
		if (isset(fromStorage)) return getBoolFromTinyInt(fromStorage);
		else if (defaultValue) return getBoolFromTinyInt(defaultValue);
	}
	return (defaultValue ? getBoolFromTinyInt(defaultValue) : undefined);
}
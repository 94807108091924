import {cloneDeep} from "lodash";
import {getBoolean, getString, resetObjectValues, getArray, isset} from "Core/helpers/data";
import {
	UserRoleItemAccountDataObject,
	UserRoleItemDataObject, UserRoleItemPrivilegePropagationDataObject,
	UserRoleItemRoleDataObject, UserRoleItemStudyStatusPrivilegeDataObject,
	UsersItemDataObject
} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UsersItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new UsersItemDataObject(
		getString(result, 'id'),
		getString(result, 'userType'),
		getString(result, 'contactData.firstName'),
		getString(result, 'contactData.middleName'),
		getString(result, 'contactData.lastName'),
		getString(result, 'contactData.credentials'),
		getString(result, 'userName'),
		getString(result, 'contactData.email'),
		false,
		undefined,
		undefined,
		getString(result, 'contactData.defaultAccountId'),
		getBoolean(result, 'contactData.enabled'),
		getBoolean(result, 'psg2'),
		getBoolean(result, 'contactData.passwordDoesNotExpire'),
		getBoolean(result, 'activated'),
		getBoolean(result, 'contactData.acceptedTerms'),
		getBoolean(result, 'contactData.consentGDPR'),
		getArray(result, 'accountRoleMappings').map(inputAccountRole),
		getArray(result, 'statusPermissionWrappers').map(inputStatusPermission),
		getArray(result, 'resourcePrivilegePairList').map(inputResourcePrivilege)
	);

	return result;
}

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UserRoleItemAccountDataObject}
 */
export const inputAccount = rawData => {
	let result = cloneDeep(rawData);

	result = new UserRoleItemAccountDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'abmId'),
		getString(result, 'displayName')
	);

	return result;
};

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UserRoleItemRoleDataObject}
 */
export const inputRole = rawData => {
	let result = cloneDeep(rawData);

	result = new UserRoleItemRoleDataObject(
		getString(result, 'id'),
		getString(result, 'name')
	);

	return result;
};

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UserRoleItemStudyStatusPrivilegeDataObject}
 */
export const inputStatusPermission = rawData => {
	let result = cloneDeep(rawData);

	result = new UserRoleItemStudyStatusPrivilegeDataObject(
		getString(result, 'permission'),
		getString(result, 'description'),
		getString(result, 'resource'),
		getBoolean(result, 'hasPrivilege')
	);

	return result;
};

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UserRoleItemPrivilegePropagationDataObject}
 */
export const inputResourcePrivilege = rawData => {
	let result = cloneDeep(rawData);

	result = new UserRoleItemPrivilegePropagationDataObject(
		getString(result, 'resource'),
		getString(result, 'privilege'),
		getBoolean(result, 'hasPrivilege')
	);

	return result;
};

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UserRoleItemDataObject}
 */
export const inputAccountRole = rawData => {
	let result = cloneDeep(rawData);
	
	result = new UserRoleItemDataObject(
		(isset(result?.accountIdNameWrapper) ? inputAccount(result.accountIdNameWrapper) : null),
		getArray(result, 'roleList').map(i => inputRole(i))
	);

	return result;
};



/**
 * Get output data from input data
 * @param {UsersItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	/** @type UsersItemDataObject */
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		userName: result.username,
		userType: result.userType,
		contactData: {
			firstName: result.firstName,
			middleName: result.middleName,
			lastName: result.lastName,
			email: result.email,
			passwordDoesNotExpire: result.passwordDoesNotExpire,
			acceptedTerms: result.acceptedTerms,
			consentGDPR: result.consentGdpr,
			credentials: result.credentials,
			enabled: result.enabled,
			defaultAccountId: result.defaultAccount,
		},
		changePassword: result.changePassword,
		password: !result.id || result.changePassword ? result.password : '',
		passwordConfirm: !result.id || result.changePassword ? result.passwordConfirm : '',
		psg2: result.psg2,
		activated: result.activated,
		statusPermissionWrappers: result.studyStatusPrivileges.map(i => ({
			permission: i.permission,
			description: i.description,
			resource: i.resource,
			hasPrivilege: i.hasPrivilege
		})),
		resourcePrivilegePairList: result.privilegePropagation.map(i => ({
			resource: i.resource,
			privilege: i.privilege,
			hasPrivilege: i.hasPrivilege
		})),
		accountRoleMappings: result.accountRoles.filter(i => !!i.account).map(i => ({
			accountIdNameWrapper: {
				id: i.account.id,
				name: i.account.name,
				abmId: i.account.abmId,
				displayName: i.account.displayName
			},
			roleList: i.roleList.map(r => ({
				id: r.id,
				name: r.name
			}))
		}))
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
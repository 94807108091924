import numeral from "../packages/numeral";
import {getAppLocale} from "./locale";
import {getInteger, getString} from "./data";

/**
 * Get locale code used for numbers
 * @note This frameworks uses numeral.js library to handle numbers.
 * 
 * @param {LocaleObj} locale - Locale object.
 * @return {string} Lowercase IETF BCP 47 language tag used in the app's locale object as 'locale' property
 * (ex: 'sr-latn-rs').
 */
export const getNumberLocaleCode = locale => getString(locale, 'locale').toLowerCase();

/**
 * Get app locale code used for numbers
 * @note This frameworks uses numeral.js library to handle numbers.
 * @return {string} Lowercase IETF BCP 47 language tag used in the app's locale object as 'locale' property 
 * (ex: 'sr-latn-rs').
 */
export const getAppNumberLocaleCode = () => getNumberLocaleCode(getAppLocale());

/**
 * Check if value can be parsed as a number
 * 
 * @param {any} value - Any value.
 * @param {string} [localeCode = ''] - Locale code (IETF) to use when detecting numbers in locale formats. You can use
 * 'getAppNumberLocaleCode' function to get the app's current locale code.
 * @return {boolean}
 */
export const canBeNumber = (value, localeCode = '') => {
	const valueParsed = parseNumber(value, localeCode);
	return (typeof value === 'number' ? true : (!isNaN(valueParsed) && valueParsed !== null));
};

/**
 * Check if number is float
 *
 * @param {any} n - Number to check.
 * @return {boolean}
 */
export const isFloat = n => Number(n) === n && n % 1 !== 0;

/**
 * Count the number of decimals in a number
 * @param {number} number
 * @return {number}
 */
export const countDecimals = number => {
	if(Math.floor(number) === number) return 0;
	return number.toString().split(".")[1].length || 0;
}

/**
 * Format number
 * 
 * @param {number|string} number - Raw number to format. String values will be converted to numbers.
 * @param {string} [format=''] - Optional number format (using 'numeral.js' library). If not specified or empty, format 
 * will be determined based on the number type:
 * 	- If 'number' is a float: '0,0.0[0]'
 * 	- If 'number is an integer: '0,0'
 * 	- If 'localeCode' is not set or empty number will be displayed as-is using 'toString' function
 * @param {string} [localeCode] - Locale code (IETF) or a numeral js locale code to use when detecting numbers in
 * locale formats. You can use 'getAppNumberLocaleCode' function to get the app's current locale code. If not specified
 * english locale will be used meaning that decimal separator will be '.' and no thousands' separator will be used.
 * @return {string}
 */
export const formatNumber = (number, format = '', localeCode) => {
	const currentLocale = getString(numeral, 'options.currentLocale', 'en');
	const noLocale = !localeCode;
	
	// Set numeral locale
	numeral.locale(noLocale ? 'en' : localeCode);
	
	const numeralNumber = numeral(number);
	
	// Only number and string values are supported
	if (typeof number !== 'number' && typeof number !== 'string') return '';
	
	// Only support number or a valid number string values
	if (numeralNumber.value() === null) return '';
	
	let result;
	if (noLocale) {
		result = numeralNumber.value().toString();
	} else {
		if (format) result = numeralNumber.format(format);
		else {
			const numberValue = numeralNumber.value();
			if (isFloat(numberValue)) {
				const decimals = countDecimals(numberValue);
				result = numeralNumber.format(`0,0.0${(decimals > 1 ? `[${new Array(decimals).join('0')}]` : '')}`);
			} else {
				result = numeralNumber.format('0,0');
			}
		}
	}

	// Revert numeral locale
	numeral.locale(currentLocale);
	
	return result;
};

/**
 * Parse string into a number
 * @param {string} string - String to parse into a number.
 * @param {string} [localeCode='en'] - Locale code to use when parsing the number. This is a lowercase IETF BCP 47 
 * language tag used in the app's locale object as 'locale' property (ex: 'sr-latn-rs') or a numeral js locale code. If 
 * not specified english locale will be used meaning that decimal separator will be '.' and no thousands' separator will 
 * be used.
 * @return {!number}
 */
export const parseNumber = (string, localeCode) => {
	const currentLocale = getString(numeral, 'options.currentLocale', 'en');
	const noLocale = !localeCode;
	
	// Set numeral locale
	numeral.locale(noLocale ? 'en' : localeCode);
	
	const numeralValue = numeral(string);
	const result = (numeralValue && numeralValue.value() !== null ? numeralValue.value() : NaN);

	// Revert numeral locale
	numeral.locale(currentLocale);
	
	return result;
};

/***
 * Get an array of parsed hours, minutes and seconds from elapsed time specified in seconds 
 * @param {string|number} secondsElapsed - Elapsed time in seconds.
 * @return {[number,number,number]} Array where first item represents hours, second represents minutes and third 
 * represents seconds.
 */
export const getParsedElapsedTime = secondsElapsed => {
	const number = numeral(secondsElapsed).format('00:00:00');
	const numberParsed = (number ? number.split(':') : null);
	if (numberParsed) {
		if (numberParsed.length === 0) return [0, 0, 0];
		else if (numberParsed.length === 1) {
			return [0, 0, getInteger(numberParsed, '[0]')];
		} else if (numberParsed.length === 2) {
			return [0, getInteger(numberParsed, '[0]'), getInteger(numberParsed, '[1]')];
		} else if (numberParsed.length === 3) {
			return [
				getInteger(numberParsed, '[0]'), 
				getInteger(numberParsed, '[1]'), 
				getInteger(numberParsed, '[2]')
			];
		}
	} else {
		return [0, 0, 0];
	} 
};

/**
 * Get the integer part of a number by removing any fractional (truncate)
 * @param {number} number - Number to truncate.
 * @return {number} Integer part of a number or NaN.
 */
export const truncNumber = number => {
	if (typeof number === 'number') {
		return (Math.trunc ? Math.trunc(number) : (number < 0 ? Math.ceil(number) : Math.floor(number)));
	} else {
		return NaN;
	}
};

/**
 * Round number to specific number of decimal spaces
 * @param {number} number - Number to round to.
 * @param {number} [decimals] - Decimal precision to round the number to. If not define original number will be 
 * returned.
 * @return {number}
 */
export const roundNumberToDecimals = (number, decimals) => {
	if (typeof decimals === 'undefined') return number;
	else if (decimals <= 0) return Math.round(number);
	else return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

/**
 * Rounding error safe way of adding two numbers
 * @description Fix rounding error of floating point numbers by limiting the number of decimal places to the number with
 * more decimal places.
 * @see https://floating-point-gui.de/
 * 
 * @param {number} number1 - First number to add second number to.
 * @param {number} number2 - Second number to add to the first number.
 * @return {number}
 */
export const safeNumberAdd = (number1, number2) => {
	const num1Dec = countDecimals(number1);
	const num2Dec = countDecimals(number2);
	const dec = (num1Dec > num2Dec ? num1Dec : num2Dec);
	return roundNumberToDecimals(number1 + number2, dec);
}

/**
 * Rounding error safe way of subtract two numbers
 * @description Fix rounding error of floating point numbers by limiting the number of decimal places to the number with
 * more decimal places.
 * @see https://floating-point-gui.de/
 *
 * @param {number} number1 - First number that the second number will be subtracted from.
 * @param {number} number2 - Second number to subtract from the first number.
 * @return {number}
 */
export const safeNumberSub = (number1, number2) => {
	const num1Dec = countDecimals(number1);
	const num2Dec = countDecimals(number2);
	const dec = (num1Dec > num2Dec ? num1Dec : num2Dec);
	return roundNumberToDecimals(number1 - number2, dec);
}

/**
 * Perform a rounding error safe number operation with two numbers
 * @description Fix rounding error of floating point numbers by limiting the number of decimal places to the number with
 * more decimal places.
 * @see https://floating-point-gui.de/
 * 
 * @param {'+'|'-'|'*'|'/'|'%'} operation - Number operation to perform.
 * @param {number} number1 - First number.
 * @param {number} number2 - Second number.
 * @return {number|NaN} Calculated value or NaN if 'operation' was not specified.
 */
export const safeNumberOperation = (operation, number1, number2) => {
	const num1Dec = countDecimals(number1);
	const num2Dec = countDecimals(number2);
	const dec = (num1Dec > num2Dec ? num1Dec : num2Dec);
	switch (operation) {
		case '+': return roundNumberToDecimals(number1 + number2, dec);
		case '-': return roundNumberToDecimals(number1 - number2, dec);
		case '*': return roundNumberToDecimals(number1 * number2, dec);
		case '/': return roundNumberToDecimals(number1 / number2, dec);
		case '%': return roundNumberToDecimals(number1 % number2, dec);
		default: return NaN;
	}
};

/**
 * Generate an array of numbers
 * 
 * @param {number} [start=0] - Number to start with. 
 * @param {number} [end=0] - Number to end with.
 * @param {number} [step=1] - Increment step.
 * @return {number[]}
 */
export const generateNumberArray = (start = 0, end = 0, step = 1) => {
	let values;
	let length;
	try {
		if (end === start) {
			values = [start];
		} else if (end > start) {
			length = Math.ceil(((end - start) + 1) / step);
			values = Array.from(Array(length), (_i, idx) => start + (idx * step));
		} else {
			length = Math.ceil(((end - start) + 1) / step);
			values = Array.from(Array(length), (_i, idx) => end + (idx * step)).reverse();
		}
	} catch (e) {
		console.error('Failed to calculate number values while generating number array', e);
		values = [0];
	}
	return values;
}
import {ioJsonFetchAction, ioJsonFetchItemAction, ioJsonSaveAction} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, getString} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";
import {SORT_ORDER} from "Core/const/global";

/**
 * Fetch my tickets list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy='creationDate'] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir='DESC'] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchMyTicketsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy = 'creationDate', sortDir = SORT_ORDER.DESC
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'tickets/my-tickets',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load my tickets list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadMyTicketsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchMyTicketsListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setMyTicketsListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear my tickets list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearMyTicketsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearMyTicketsListData());
}

/**
 * Fetch my tickets item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the "my tickets" item to fetch.
 * @return {function(*=): Promise<MyTicketsItemDataObject>}
 */
export const fetchMyTicketsItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'tickets/fetch-user-ticket-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load my tickets item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the "my tickets" item to load.
 * @return {function(*=): Promise<MyTicketsItemDataObject>}
 */
export const loadMyTicketsItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchMyTicketsItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setMyTicketsItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Set my tickets item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} ticketsItem - Ticket item data to set.
 * @param {boolean} [mapData=false] - Flag that specifies if data will be mapped using the input method. Data should be
 * mapped if 'ticketsItem' is a raw ticket item data from IO.
 * @return {(function(*))|*}
 */
export const setMyTicketsItemAction = (abortCallback, ticketsItem, mapData = false) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setMyTicketsItem(mapData ? itemDataMap.input(ticketsItem) : ticketsItem));
};

/**
 * Clear my tickets item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearMyTicketsItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearMyTicketsItem());
};

/**
 * Changes ticket status
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of ticket to change the status for.
 * @param {TicketStatus} newStatus - New ticket status to change to.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const changeMyTicketStatusAction = (abortCallback, id, newStatus) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'tickets/change-status',
		{
			id,
			data: {ticketStatus: newStatus}
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Load new item status into Redux store
		.then(response => {
			if (isSuccessful(response)) {
				dispatch(actionCreators[reducerStoreKey].setMyTicketsItemStatus(getString(response, 'data.status')));
				return response;
			}
			return undefined;
		});
}
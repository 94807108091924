import styles from "./index.module.css";

import React from "react";
import {formatDate} from "../../../../../helpers/datetime";
import {Tooltip} from "react-tippy";
import {translate} from "../../../../../i18n";

/**
 * Date format preview
 *
 * @param {string} dateFormat - Date format string.
 * @return {*} Preview function used by 'getPreview' prop of TextInput component.
 */
const preview = dateFormat => {
	const preview = (dateFormat ? formatDate(new Date(), dateFormat) : '');
	return (
		<Tooltip
			tag="div"
			className={`date-preview ${styles['preview']} ${preview === '' ? styles['invalid'] : ''}`}
			title={translate('tooltip', 'TextInput.DatePreview')}
			size="small"
			position="top-center"
			arrow={true}
			interactive={false}
		>
			{preview ? preview : ''}
		</Tooltip>
	);
}

export default preview;
/** @typedef {string} DeviceStatus */
export const DEVICE_STATUS_ACTIVE = 'ACTIVE';
export const DEVICE_STATUS_DELETED = 'DELETED';
/** @enum {DeviceStatus} */
export const DEVICE_STATUS = {
	ACTIVE: DEVICE_STATUS_ACTIVE,
	DELETED: DEVICE_STATUS_DELETED,
};
/** @type {DeviceStatus[]} */
export const DEVICE_STATUSES = [
	DEVICE_STATUS_ACTIVE,
	DEVICE_STATUS_DELETED,
];
import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as actions from "../../../../actions";
import * as pageConfig from "../../../../config";
import Label from "Core/components/display/Label";
import {getString} from "Core/helpers/data";
import {STUDY_TROUBLESHOOTING_ICON} from "../../const";
import {icon_font_symbol_class_prefix} from "Config/app";
import {getPageActions} from "Core/helpers/redux";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";

class DeviceManagerLink extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				tokenLoading: false,
				tokenLoaded: false,
			},
		}, {
			translationPath: `${pageConfig.translationPath}.study_troubleshooting`,
			disableLoad: true,
		});
		
		// Action methods
		this.handleClick = this.handleClick.bind(this);
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Handle component click event
	 *
	 * @param {MouseEvent} event - Mouse click event.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handleClick(event) {
		const {studyId, actionDropdownRef, fetchStudiesListItemActionReUploadStudyTokenAction} = this.props;
		
		// Only fetch the token if it is not loading and href is not set. Once the href is set (token has been  fetched), 
		// regular click event should take over.
		if (!this.getValue('tokenLoaded') && !event.target.href) {
			return this.setValue('tokenLoaded', false)
				.then(() => this.setValue('tokenLoading', true))
				.then(() => this.executeAbortableAction(fetchStudiesListItemActionReUploadStudyTokenAction, studyId))
				// Set href and simulate click
				.then(token => {
					event.target.href = process.env.REACT_APP_SPECIFIC_SLEEP_PROFILER_APP_DEVICE_MANAGER_LINK_PREFIX + token;
					event.target.click();
				})
				.then(() => this.setValue('tokenLoading', false))
				.then(() => this.setValue('tokenLoaded', true))
				// Close the dropdown
				.then(() => { if (actionDropdownRef) actionDropdownRef.closeDropdown(); });
		}
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, action} = this.props;

		return (
			// eslint-disable-next-line
			<a
				className={className}
				data-inactive={!this.getValue('tokenLoaded')}
				onClick={this.handleClick}
			>
				{
					this.getValue('tokenLoading') ?
						<Spinner size="1em" weight={1} className="item-loading" />
						:
						<Icon 
							symbol={getString(STUDY_TROUBLESHOOTING_ICON, [action, 'symbol'])}
							symbolPrefix={getString(
								STUDY_TROUBLESHOOTING_ICON, 
								[action, 'symbolPrefix'], 
								icon_font_symbol_class_prefix
							)}
							className="item-icon"
						/>
				}
				<Label content={this.t(action)} />
			</a>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DeviceManagerLink.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Action 
	// @type {StudyAction}
	action: PropTypes.string.isRequired,
	// Study id
	studyId: PropTypes.string.isRequired,
	// Action dropdown component ref
	actionDropdownRef: PropTypes.object,
};

/**
 * Define component default values for own props
 */
DeviceManagerLink.defaultProps = {
	id: '',
	className: '',
};

export default connect(null, getPageActions(actions))(DeviceManagerLink);
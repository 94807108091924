import styles from "./index.module.css";
import "./index.css";

// Import app pages
import * as studiesPage from "./studies";
import * as documentsPage from "./documents";
import * as devicesPage from "./devices";
import * as activityLogPage from "./activityLog";
import * as accountsPage from "./accounts";
import * as usersPage from "./users";
import * as rolesPage from "./roles";
import * as firmwarePage from "./firmware";
import * as appStarterPage from "./appStarter";
import * as librariesPage from "./libraries";
import * as languageFilesPage from "./languageFiles";
import * as ManageDocumentsPage from "./manageDocuments";
import * as languagesCodebookPage from "./languagesCodebook";
import * as myTicketsPage from "./myTickets";
import * as ticketsPage from "./tickets";
import * as applicationParametersPage from "./applicationParameters";
import * as profilePage from "./profile";
import * as accountSettingsPage from "./accountSettings";

import React from "react";
import AppSectionComponent from "Core/components/AppSectionComponent";
import {connect} from "react-redux";
import ReactDOM from "react-dom";
import * as appConfig from "./config";
import {openFloatPopup} from "Core/helpers/floatPopup";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import TicketFloatPopup from "../../../components/floatPopups/TicketFloatPopup";
import Icon from "Core/components/display/Icon";
import {Tooltip} from "react-tippy";
import {clearTicketDataAction} from "../../../components/floatPopups/TicketFloatPopup/actions";

class DefaultApp extends AppSectionComponent {
	constructor(props) {
		super(props, appConfig, {
			checkLogin: true,
			autoLogout: true,
			appPages: [
				studiesPage,
				documentsPage,
				devicesPage,
				activityLogPage,
				accountsPage,
				usersPage,
				rolesPage,
				firmwarePage,
				appStarterPage,
				librariesPage,
				languageFilesPage,
				ManageDocumentsPage,
				languagesCodebookPage,
				myTicketsPage,
				ticketsPage,
				applicationParametersPage,
				profilePage,
				accountSettingsPage,
			],
		}, 'default-app');
	}

	render() {
		const {clearTicketDataAction} = this.props;
		
		return this.renderApp((
			<>
				{ReactDOM.createPortal((
					<Button
						big={true}
						className={`create-ticket-btn ${styles['createTicketButton']}`}
						displayType={BUTTON_DISPLAY_TYPE.SOLID}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => openFloatPopup('CreateTicket', TicketFloatPopup, {
							onClose: () => { clearTicketDataAction(); },
						}, {
							title: this.t('createTicketBtn'),
							maxWidth: 360,
							maxHeight: '80vh',
						})}
					>
						<Tooltip
							tag="span"
							title={this.t('createTicketBtn')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Icon symbol="support-faq" symbolPrefix="icofont-" className={styles['createTicketButtonIcon']} />
						</Tooltip>
					</Button>
				), document.body)}
			</>
		));
	}
}

export * from "./config";
export default connect(null, {clearTicketDataAction})(DefaultApp);
import {cloneDeep, find, get, reject, set} from "lodash";
import {getArray} from "Core/helpers/data";
import {memory_storage_var} from "Config/app";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'popup';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@popup/reset',
	ADD_POPUP: '@popup/add',
	REMOVE_POPUP: '@popup/remove',
	CLEAR_POPUPS: '@popup/clear',
	SET_POPUP_INSTANCE: '@popup/set_popup_instance',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	openPopup: (GUIID, component, props, options) => ({type: REDUCER_TYPES.ADD_POPUP, GUIID, component, props, options}),
	closePopup: GUIID => ({type: REDUCER_TYPES.REMOVE_POPUP, GUIID}),
	closeAllPopups: () => ({type: REDUCER_TYPES.CLEAR_POPUPS}),
	setPopupInstance: (GUIID, instance) => ({type: REDUCER_TYPES.SET_POPUP_INSTANCE, GUIID, instance})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * List of currently opened popups
	 * @type {{GUIID: string, component: Object, props: Object, options: Object}[]}
	 */
	openedPopups: [],
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	/**
	 * List of popup instances stored in memory
	 * @description Popup instances are stored in memory, so they can be used on-demand usually to call popup component 
	 * methods like 'updateDynamics' outside the popup.
	 * @note Popup instances should be set using the 'SET_POPUP_INSTANCE' reducer. Get them using the 'getPopupInstance' 
	 * popup helper function. 
	 * @important Storage helper functions (setStorageValue, ...) are not used to avoid circular dependencies, but they 
	 * can be used elsewhere to get the list.
	 * @type {Array}
	 */
	const popupInstances = getArray(window, [memory_storage_var, 'popup_instances']);
	
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.ADD_POPUP:
			// Do not add popup if it is already opened.
			if (find(state.openedPopups, { GUIID: action.GUIID })) return state;

			// Add a new popup instance placeholder to the list of popup instances stored in memory
			set(window, [memory_storage_var, 'popup_instances'],
				popupInstances.length > 0 ? 
					[...popupInstances, { GUIID: action.GUIID, instance: null }] : 
					[{ GUIID: action.GUIID, instance: null }]
			);
			
			return {
				...state,
				openedPopups: [
					...state.openedPopups,
					{
						GUIID: action.GUIID,
						component: action.component,
						props: cloneDeep(action.props),
						options: cloneDeep(action.options),
					}
				]
			};
			
		case REDUCER_TYPES.REMOVE_POPUP:
			// Remove popup instance from the list of popup instances stored in memory
			if (popupInstances.length > 0) {
				set(window, [memory_storage_var, 'popup_instances'], 
					reject(popupInstances, {GUIID: action.GUIID})
				);
			} else {
				// @note This should newer happen
				console.warn('Trying to a popup instance from memory when popup instance list is already empty!');
			}
			
			return {
				...state,
				openedPopups: reject(state.openedPopups, { GUIID: action.GUIID }),
			};
			
		case REDUCER_TYPES.CLEAR_POPUPS:
			// Clear popup instance list stored in memory
			set(window, [memory_storage_var, 'popup_instances'], []);
			
			return {
				...state,
				openedPopups: [...initialState.openedPopups],
			}
			
		case REDUCER_TYPES.SET_POPUP_INSTANCE:
			// Update specific popup instance in the list of popup instances stored in memory
			set(window, [memory_storage_var, 'popup_instances'],
				popupInstances.map(i => {
					if (i.GUIID !== action.GUIID) return i;
					return { GUIID: action.GUIID, instance: action.instance };
				})
			);
			return state;

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getPopups: state => get(state, [reducerStoreKey, 'openedPopups'], [...initialState.openedPopups]),
	getPopup: (state, GUIID) => find(state[reducerStoreKey].openedPopups, { GUIID }),
};

export default reducer;
import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Export and download study metrics
 * @note CSV file is expected.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string[]} studyIds - List of study IDs.
 * @return {function(*): *}
 */
export const exportStudyMetricsAction = (abortCallback, studyIds) => dispatch => {
	const loading = showLoading(".layout-page");
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study/export-study-metrics-to-csv',
		{ids: studyIds},
		hideLoadingFunction(loading),
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		true,
		'ReportValues',
		true,
	)(dispatch);
};
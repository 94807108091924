import headerStyles from "Layout/elements/PublicHeader/index.module.css";
import styles from "./index.module.css";

import logo from "Images/logo_for_content.png";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import HelpDocumentsComponent from "../../../components/display/helpDocuments";
import Label from "Core/components/display/Label";
import TeamViewerSupport from "../../../components/display/TeamViewerSupport";

class HelpDocumentsPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'public',
			domPrefix: 'help-documents-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
		}, null);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return this.renderLayout((
			<div 
				id={this.getDomId()} 
				className={`page-component ${this.getOption('domPrefix')} ${styles['wrapper']}`}
			>
				<TeamViewerSupport className={styles['teamViewer']} itemWrapperClassName={styles['itemWrapper']} />
				
				<div className={`page-component-content card`}>
					<div className={`page-component-content-inner card-content`}>
						<div className={`app-name ${headerStyles['hideMobile']}`}>
							<img src={logo} alt={this.t('title', 'App')} />
						</div>
						<Label 
							element="div" 
							elementProps={{className: 'title'}}
							content={this.t('page_title')} 
						/>
						<Label
							element="div"
							elementProps={{className: 'desc'}}
							content={this.t('page_short_description')}
						/>
						<HelpDocumentsComponent loadingSelector={`#${this.getDomId()} .page-component-content`} />
					</div>
				</div>
			</div>
		), undefined, undefined,{
			showHeader: true,
			headerProps: {
				showTeamViewerLinks: false,
				showAppLogo: true,
			},
			showFooter: true,
			footerProps: {
				justifyContent: 'center',
			}
		});
	}
}

export * from "./config";
export default withRouter(HelpDocumentsPage);
import styles from "./index.module.css";

import React from "react";
import PageComponent from "../../../../core/components/PageComponent";
import {connect} from "react-redux";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import {selectors} from "Core/store/reducers";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {getPageActions} from "Core/helpers/redux";
import HelpDocumentsComponent from "../../../../components/display/helpDocuments";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class DocumentsPage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'documents-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
		}, 'page_title');
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<HelpDocumentsComponent 
					className={styles['documents']} 
					itemClassName={styles['document']}
					loadingSelector=".layout .layout-content"
				/>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions())(DocumentsPage);
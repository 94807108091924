/**
 * Data object representing the languages codebook list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class LanguagesCodebookListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} title - List item's title.
	 * @param {string} code - List item's code.
	 * @param {string} description - List item's description.
	 */
	constructor(id, title, code, description) {
		this.id = id;
		this.title = title;
		this.code = code;
		this.description = description;
	}
}

/**
 * Data object representing the languages codebook item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class LanguagesCodebookItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [title=''] - Item's title.
	 * @param {string} [code=''] - Item's code.
	 * @param {string} [description=''] - Item's description.
	 */
	constructor(id = null, title = '', code = '', description = '') {
		this.id = id;
		this.title = title;
		this.code = code;
		this.description = description;
	}
}
import {ioJsonAction, ioJsonFetchItemAction, ioJsonSaveAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {actionCreators} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import * as dataMap from "./dataMap";
import {fetchStudyPatientAction} from "../../actions";
import {get} from "lodash";
import {isset} from "Core/helpers/data";
import {AsyncMountError} from "Core/errors";

/**
 * Fetch a specific questionnaire by ID
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Questionnaire ID.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const fetchQuestionnaireAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/questionnaire/fetch-by-id',
		id
	)(dispatch);
};

/**
 * Load a specific questionnaire into Redux store by ID
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Questionnaire ID.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const loadQuestionnaireAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#questionnaire-popup');
	return fetchQuestionnaireAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].set(dataMap.input(responseData.data)));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Fetch patient data for questionnaire
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - ID of the questionnaire study.
 * @return {function(*): Promise<QuestionnairePatientDataObject>}
 */
export const fetchQuestionnairePatientAction = (abortCallback, studyId) => dispatch => {
	const loading = showLoading('#questionnaire-popup');
	return fetchStudyPatientAction(abortCallback, studyId)(dispatch)
		.then(res => {
			if (isset(res)) return res;
			else throw new AsyncMountError();
		})
		.then(res => get(res, 'data'))
		.then(patientData => {
			hideLoading(loading);
			return (patientData ? dataMap.patientInput(patientData) : patientData);
		});
};

/**
 * Clear questionnaire from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearQuestionnaireAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clear());
	dispatch(actionCreators[reducerStoreKey].clearAllSavedTabData());
	dispatch(actionCreators[reducerStoreKey].clearAllOsaRiskValidation());
};

/**
 * Create questionnaire
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - Study ID.
 * @param {string} accountId - Account ID.
 * @param {QuestionnaireDataObject} questionnaire - Questionnaire to save.
 * @return {function(*=): Promise<QuestionnaireDataObject>} Promise that will resolve with the created questionnaire 
 * received from IO or undefined if creation failed.
 */
export const createQuestionnaireAction = (abortCallback, studyId, accountId, questionnaire) => dispatch => {
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/questionnaire/create',
		{
			id: '',
			data: {...dataMap.output(questionnaire), accountId, studyId, questionnaireId: ''}
		}
	)(dispatch)
		// Load data into Redux store
		.then(response => {
			if (response) dispatch(actionCreators[reducerStoreKey].setResult(dataMap.resultInput(response.data)));
			else dispatch(actionCreators[reducerStoreKey].setResult(undefined));
			return response;
		});
};

/**
 * Update questionnaire
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {QuestionnaireDataObject} questionnaire - Questionnaire item to save.
 * @return {function(*=): Promise<QuestionnaireDataObject>} Promise that will resolve with the updated questionnaire
 * received from IO or undefined if updating failed.
 */
export const updateQuestionnaireAction = (abortCallback, questionnaire) => dispatch => {
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/questionnaire/update-by-id',
		{
			id: questionnaire.id,
			data: dataMap.output(questionnaire)
		}
	)(dispatch)
		// Load data into Redux store
		.then(response => {
			if (response) dispatch(actionCreators[reducerStoreKey].setResult(dataMap.resultInput(response.data)));
			else dispatch(actionCreators[reducerStoreKey].setResult(undefined));
			return response;
		});
};

/**
 * Save questionnaire popup tab data
 * @note Tab data is saved in Redux store because all tabs except for the first one are not mounted until they are
 * opened. This means that any user changes in those tabs would be lost every time that tab is closed/unmounted.
 * 
 * @param {string} tabId - ID of the popup tab.
 * @param {Object} data - Popup tab data to save.
 * @return {(function(*): void)|*}
 */
export const saveQuestionnaireTabDataAction = (tabId, data) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].saveTabData(tabId, data));
};

/**
 * Set OSA risk validation result for a specified tab 
 * @param {string} tabId - ID of the tab.
 * @param {Object<string, string[]>} validationResult - OSA risk validation result.
 * @return {(function(*): void)|*}
 */
export const setOsaRiskValidationForTabAction = (tabId, validationResult) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setOsaRiskValidationForTab(tabId, validationResult));
};

/**
 * Check if questionnaire patient
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {QuestionnaireDataObject} questionnaire - Questionnaire containing patient data.
 * @param {boolean} editMode - Flag that specifies if check is performed on the new or an existing questionnaire.
 * @param {string} studyId - ID of the questionnaire study. 
 * @return {function(*): *}
 */
export const checkQuestionnairePatientAction = (abortCallback, questionnaire, editMode, studyId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/questionnaire/check-subject',
		{editMode, studyId, ...dataMap.patientOutput(questionnaire)},
	)(dispatch)
		.then(res => get(res, 'data'));
};

/**
 * Clear questionnaire result form Redux store
 * @return {(function(*): void)|*}
 */
export const clearQuestionnaireResultAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearResult());
};
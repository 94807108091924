import React from "react";
import {connect} from "react-redux";
import * as languagesCodebookActions from "../../../pages/apps/default/languagesCodebook/actions";
import DataComponent from "Core/components/DataComponent";
import SelectInput from "Core/components/input/SelectInput";
import {getGlobalActions} from "Core/helpers/redux";
import {getArray, isset} from "Core/helpers/data";
import {isSuccessful} from "Core/helpers/io";
import {AsyncMountError} from "Core/errors";

class LanguageSelectInput extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * List of loaded languages
				 * @type {LanguagesCodebookListItemDataObject[]}
				 */
				languages: [],
				/**
				 * Flag that shows if languages are being loaded
				 * @type {boolean}
				 */
				languagesLoading: false,
			}
		}, {
			disableLoad: true,
		});
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		const {options, fetchLanguagesCodebookListAction} = this.props;

		if (!isset(options)) {
			await this.setValue('languagesLoading', true)
				.then(() => this.executeAbortableAction(fetchLanguagesCodebookListAction,null, 1, 9999))
				.then(response => {
					if (!isset(response)) {
						throw new AsyncMountError();
					} else if (isSuccessful(response)) {
						return this.setValue('languages', getArray(response, 'data'))
							.then(() => this.setValue('languagesLoading', false))
					} else if (isset(response)) {
						return this.setValue('languagesLoading', false);
					}
				})
				.catch(async (error) => {
					await this.setValue('languagesLoading', false);

					if (error instanceof AsyncMountError) throw error;
					else return Promise.resolve();
				});
		} else {
			await this.setValue('languages', getArray(options));
		}

		return Promise.resolve();
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return (
			<SelectInput
				primaryKey="id"
				options={this.getValue('languages')}
				isLoading={this.getValue('languagesLoading')}
				getOptionLabel={o => o.title}
				getOptionValue={o => o.id}
				{...this.props}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
LanguageSelectInput.propTypes = {
	...SelectInput.propTypes
};

export default connect(null, getGlobalActions(languagesCodebookActions))(LanguageSelectInput);
import {cloneDeep, get} from "lodash";
import {getBoolean, getString} from "Core/helpers/data";
import {StudiesListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {StudiesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	// subjectData
	result = new StudiesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'subjectData.id'),
		getString(result, 'subjectData.firstName'),
		getString(result, 'subjectData.lastName'),
		getDate(get(result, 'subjectData.dateOfBirth', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'deviceSensorConfiguration'),
		getString(result, 'studyType.code'),
		getString(result, 'studyType.name'),
		getString(result, 'studyStatus.code'),
		getString(result, 'studyStatus.name'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'account.id'),
		getString(result, 'account.name'),
		`${getString(result, 'subjectData.medicalRecordIdOrdinal')}-${getString(result, 'subjectData.medicalRecordIdYear')}`,
		getString(result, 'referenceNumber'),
		getString(result, 'deviceSerialNo'),
		getBoolean(result,  'archived'),
		getString(result, 'archiveStatus'),
		getString(result, 'archiveRequestedDate'),
		getString(result, 'archivationDate'),
		getString(result, 'autoArchivedDate'),
		getString(result, 'deleteRawFilesDate'),
		getString(result, 'deleteEdfFilesDate'),
	);

	return result;
}

// @note There is no output function since list is read only.
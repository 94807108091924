import {ioJsonAction} from "Core/store/actions/io";
import {hideLoadingFunction, showPageLoading} from "Core/helpers/loading";

/**
 *
 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
 * an argument.
 * @param {string} [currentPassword] - Logged users current password.
 * @param {string} [newPassword] - Logged users new password.
 * @param {string} [repeatedPassword] - Logged users repeated new password.
 * @returns {function(*): *}
 */
export const changePasswordAction = (abortCallback, currentPassword, newPassword, repeatedPassword ) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'profile/change-password',
		{
			currentPassword : currentPassword,
			newPassword : newPassword,
			repeatedPassword : repeatedPassword,
		},		
		hideLoadingFunction(loading)
	)(dispatch);
};
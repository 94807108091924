/** @typedef {string} ProcessingControl */
export const PROCESSING_CONTROL_L2 = "L2";
export const PROCESSING_CONTROL_ICU = "ICU";
export const PROCESSING_CONTROL_NDD = "NDD";

/** @enum {ProcessingControl} */
export const PROCESSING_CONTROL = {
	L2: PROCESSING_CONTROL_L2,
	ICU: PROCESSING_CONTROL_ICU,
	NDD: PROCESSING_CONTROL_NDD
}

/** @type {ActivityLogStatus[]} */
export const PROCESSING_CONTROLS = [
	PROCESSING_CONTROL_L2, PROCESSING_CONTROL_ICU, PROCESSING_CONTROL_NDD
]
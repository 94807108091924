import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../../../reducer";
import * as pageConfig from "../../../../config";
import {getGlobalActions} from "Core/helpers/redux";
import Button, {BUTTON_DISPLAY_TYPE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import PropTypes from "prop-types";
import {getArray, getNumber, getString} from "Core/helpers/data";
import {isMaxSelectionExceeded} from "../../helper";
import acl from "../../../../../../../../acl";
import StudiesComparisonDialog from "./dialogs/StudiesComparisonDialog";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	selection: selectors[reducerStoreKey].getStudiesListSelection(state),
});

class ComparisonItem extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
		});

		this.isEnabled = this.isEnabled.bind(this);
		this.openSubmitDialog = this.openSubmitDialog.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Check if this item should be enabled
	 * @return {boolean}
	 */
	isEnabled() {
		const maxSelection = getNumber(this.props, 'maxSelection');
		/** @type {StudiesListItemDataObject[]} */
		const selection = getArray(this.props, 'selection');

		if (acl.isGuest() || isMaxSelectionExceeded(maxSelection, selection) || selection.length !== 2) return false;
		else return selection.every(i => (
			i.subjectId === selection[0].subjectId &&
			i.archived !== true &&
			['30', '35', '40', '45'].includes(i.studyStatusCode)
		));
	}

	/**
	 * Open dialog for submitting a comparisons report request
	 */
	openSubmitDialog() {
		const {openDialogAction} = this.props;
		/** @type {StudiesListItemDataObject[]} */
		const selection = getArray(this.props, 'selection');
		
		openDialogAction('studies-comparison-dialog', StudiesComparisonDialog, {
			study1Id: getString(selection, '[0].id'),
			study2Id: getString(selection, '[1].id'),
		}, {
			id: 'studies-comparison-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 550
		});
	}

	/**
	 * Handle item click
	 * @param {MouseEvent} event - Mouse click event.
	 */
	handleClick(event) {
		this.openSubmitDialog();
	}

	render() {
		return (
			<>
				<Button
					tabIndex={0}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					className="toolbar-button"
					disabled={!this.isEnabled()}
					onClick={this.handleClick}
				>
					<Label icon="compress" element="span" content={this.t('comparison_action')} />
				</Button>
				<div className="separator" />
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ComparisonItem.propTypes = {
	// Max. number of selected studies
	maxSelection: PropTypes.number,
	// Studies table ref
	studiesTableRef: PropTypes.object,
	// Function that reloads the studies table
	// @note Calling this function is the same as clicking on the reload data button in the top right corner.
	// @return {Promise<*>}
	reloadStudies: PropTypes.func, // No arguments
};

export default connect(
	mapStateToProps, getGlobalActions(), null, {forwardRef: true}
)(ComparisonItem);
import "./index.css";

import React from "react";
import DialogComponent from "Core/components/DialogComponent";
import PropTypes from "prop-types";
import Label from "Core/components/display/Label";
import Spinner from "Core/components/display/Spinner";

/**
 * Loading dialog component
 * @description This component is used to render messages if message has 'asDialog' set to true.
 */
class LoadingDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'LoadingDialog',
			alignContent: 'center',
		});

		this.handleKeyDown = this.handleKeyDown.bind(this);
		
		// Register event listeners
		this.registerEventListener('keydown', this.handleKeyDown);
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {action} = this.props;
		if (typeof action === 'function') await action();
		
		return Promise.resolve();
	}

	/**
	 * Keyboard key down event handler
	 *
	 * @param {KeyboardEvent} event - Keyboard key down event.
	 */
	handleKeyDown(event){
		// Disable Tab key
		if (event.key === 'Tab') event.preventDefault();
	}

	/**
	 * Render a custom dialog title with icon
	 * @note Style should be defined by the skin.
	 *
	 * @return {JSX.Element|null} Custom dialog title with icon or null.
	 */
	renderCustomIconTitle() {
		const {title} = this.props;
		
		return this.renderTitle(
			<>
				<span className="icon"><Spinner size={50} weight={3} /></span>
				{title ? <span className="title-label">{title}</span> : null}
			</>,
			`custom title loading-dialog-title no-select`
		);
	}
	
	/**
	 * Use this method to render dialog structure with action buttons based on dialog type
	 *
	 * @param {Element|string} [title=null] - Main dialog title to render inside the standard dialog structure. Any
	 * render title method can be used to generate this value (@see renderTitle, renderInfoTitle, renderHelpTitle, ...
	 * methods).
	 * @param {Element|string} [content] - Main dialog content to render inside the standard dialog structure.
	 * @param {any} [buttonOptions] - Action button options used by the appropriate render action buttons method.
	 * @return {JSX.Element} - Dialog JSX with action buttons based on dialog type.
	 */
	renderDialog(title = null, content, ...buttonOptions) {
		const dialogType = this.getOption('dialogType');
		const alignContent = this.getOption('alignContent');

		return (
			<div className={`dialog-content-component type-${dialogType}`}>
				{title ? title : null}

				<div className="content" style={{textAlign: alignContent}}>
					{content}
				</div>
			</div>
		);
	}

	render() {
		// Do not render the dialog if message object is empty or not defined
		if (!this.props.message) return null;

		const {allowHtml, message} = this.props;

		return this.renderDialog(
			this.renderCustomIconTitle(),
			(
				<div className="loading-dialog-component">
					<Label
						content={message}
						element="p"
						elementProps={{className: `message-content no-select`}}
						supportHtml={allowHtml}
					/>
				</div>
			), this.t('Close')
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
LoadingDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,
	
	// Dialog title
	title: PropTypes.string,
	// Loading message
	message: PropTypes.string,
	// Set to true to support HTML in messages
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	allowHtml: PropTypes.bool,
	// Export action to execute when dialog opens
	action: PropTypes.func,

	// Events
	onClose: PropTypes.func
};

/**
 * Define component default values for own props
 */
LoadingDialog.defaultProps = {
	title: '',
	message: '',
	allowHtml: false,
};

export default LoadingDialog;
import {cloneDeep, get} from "lodash";
import {getArray, getString} from "Core/helpers/data";
import {TicketsItemDataObject} from "../dataObjects";
import {TicketsItemCommentsDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TicketsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new TicketsItemDataObject(
		getString(result, 'id'),
		getString(result, 'ticketId'),
		getString(result, 'status'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getDate(get(result, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getArray(result, 'comments').map(c => new TicketsItemCommentsDataObject(
			getString(c, 'id'),
			getDate(get(c, 'creationDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			getString(c, 'creator'),
			getString(c, 'subject'),
			getString(c, 'name'),
			getString(c, 'phoneNumber'),
			getString(c, 'body')
		))
	);

	return result;
}
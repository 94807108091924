import React from 'react';
import AppComponent, {AppPageRoute} from "./core/App";
import {Route, withRouter} from "react-router-dom";
import {
	app_login_page_router_path,
	app_password_reset_page_router_path,
	app_password_reset_request_page_router_path
} from "./config";

/**
 * Import required resources
 */
import cookieConsentResource, {RESOURCE_KEY as cookieConsentResourceKey} from "Core/resources/cookieConsentResource";
import i18nResource from "Core/resources/i18nResource";
import skinResource from "Core/resources/skinResource";
import applicationParametersResource from "./resources/applicationParametersResource";

/**
 * Import app top-level pages
 */
import AppLoginPage from "./pages/core/login";
import AppPasswordResetPage from "./pages/core/passwordReset";
import AppPasswordResetRequestPage from "./pages/core/passwordResetRequest";

/**
 * Public pages
 */
import * as cookiesInfoPage from "./pages/public/cookiesInfo";
import * as helpDocumentsPage from "./pages/public/helpDocuments";
import * as helpVideosPage from "./pages/public/helpVideos";

/**
 * Custom auth pages
 */
import * as ipUnblockPage from "./pages/auth/ipUnblock";
import * as activateUserAccount from "./pages/auth/activateUserAccount";

/**
 * Import app top-level sections
 */
import * as appsSection from "./pages/apps";

/**
 * Main app component
 */
class App extends AppComponent {
	/**
	 * List of imported resources required before app can be rendered
	 * @type {(function(): Promise<Object<string, *>>)[]}
	 */
	requiredResources = [cookieConsentResource, i18nResource, skinResource, applicationParametersResource];
	/**
	 * List of resource keys of the resources that will be loaded into the memory
	 * @note Resources can be big so be careful when enabling this. Resources are defined in 'requiredResources'.
	 * @type {string[]} Use '*' to load all resources.
	 */
	loadResourcesIntoMemory = [cookieConsentResourceKey];
	
	render() {
		return this.renderApp(null, (
			<>
				<Route path="/" exact={true} component={AppLoginPage} />
				<Route path={app_login_page_router_path} exact={true} component={AppLoginPage} />
				<Route path={app_password_reset_page_router_path} exact={true} component={AppPasswordResetPage} />
				<Route path={app_password_reset_request_page_router_path} exact={true} component={AppPasswordResetRequestPage} />
				
				<Route path={cookiesInfoPage.routerPath} exact={true} component={cookiesInfoPage.default} />
				<Route path={helpDocumentsPage.routerPath} exact={true} component={helpDocumentsPage.default} />
				<Route path={helpVideosPage.routerPath} exact={true} component={helpVideosPage.default} />
				
				<Route path={`${ipUnblockPage.routerPath}/:userId`} component={ipUnblockPage.default}/>
				<Route path={`${activateUserAccount.routerPath}/:urlId`} component={activateUserAccount.default} />
				
				<AppPageRoute page={appsSection} />
			</>
		));
	}
}

export default withRouter(App);
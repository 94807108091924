import styles from "./index.module.css";
import "./index.css";
import logoImg from "Images/logo_for_content.png";

import React from "react";
import {CorePasswordResetRequestComponent} from "Core/pages/passwordResetRequest";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {app_login_page_router_path, icon_font_close_symbol} from "Config/app";
import Label from "Core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import AppPasswordResetRequest from "../../../components/advanced/PasswordResetRequest";
import {isSuccessful} from "Core/helpers/io";

class AppPasswordResetRequestPage extends CorePasswordResetRequestComponent {
	constructor(props) {
		super(props, {
			layout: 'login',
		});
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		const {clearMessagesAction} = this.props;
		clearMessagesAction();
	}
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset account password
	 */
	requestPasswordReset(username) {
		const {requestPasswordResetAction} = this.props;
		
		return this.executeAbortableAction(requestPasswordResetAction, username)
			// Set 'success' flag depending on the password reset request action result
			.then(response => {
				if (response) return this.setState({successful: isSuccessful(response)});
				return Promise.resolve(response);
			});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {successful} = this.state;

		return this.renderLayout((
			!successful ?
				<AppPasswordResetRequest
					styleName="card"
					className={`custom-password-reset-request ${styles['wrapper']}`}
					showAppName={true}
					appName={<img src={logoImg} alt={this.t('title', 'App')} />}
					action={this.requestPasswordReset}
					backAction={() => this.redirectTo(app_login_page_router_path)}
				/>
				:
				<div className={`${styles['successWrapper']}`}>
					<div className={`${styles['appName']}`}><img src={logoImg} alt={this.t('title', 'App')} /></div>
					<div className={`${styles['notice']}`}>
						<Label
							element="p"
							elementProps={{className: 'page-notice-title success-color'}}
							content={this.t('success')}
						/>
						<Label
							element="p"
							elementProps={{className: 'page-notice'}}
							content={this.t('success_desc')}
						/>
					</div>

					<div className={`${styles['actions']}`}>
						<Button
							big={true}
							displayStyle={BUTTON_STYLE.SUBTLE}
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							icon={icon_font_close_symbol}
							label={this.t('Close', 'general')}
							onClick={() => this.redirectTo('/')}
						/>
					</div>
				</div>
		), undefined, undefined, {
			showHeader: true,
			footerJustifyContent: 'center',
			rightSectionJustifyContent: 'flex-start',
			rightSectionAlignItems: 'flex-start',
		});
	}
}

export default connect(null, getGlobalActions(actions))(AppPasswordResetRequestPage);
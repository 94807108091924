import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../../../reducer";
import * as pageConfig from "../../../../config";
import {getGlobalActions} from "Core/helpers/redux";
import * as actions from "./actions.js"
import {updateStudiesListSelectionAction} from "../../../../actions";
import Button, {BUTTON_DISPLAY_TYPE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import PropTypes from "prop-types";
import {getArray, getNumber} from "Core/helpers/data";
import {isMaxSelectionExceeded} from "../../helper";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import acl from "../../../../../../../../acl";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	selection: selectors[reducerStoreKey].getStudiesListSelection(state),
});

class ToArchiveItem extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
		});

		this.isEnabled = this.isEnabled.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Check if this item should be enabled
	 * @return {boolean}
	 */
	isEnabled() {
		const maxSelection = getNumber(this.props, 'maxSelection');
		const selection = getArray(this.props, 'selection');
		
		if (acl.isGuest() || isMaxSelectionExceeded(maxSelection, selection) || selection.length < 1) return false;
		else return selection.every(i => (
			i.archived !== true && 
			['30', '32', '33', '35', '37', '40', '45','50'].includes(i.studyStatusCode)
		));
	}

	/**
	 * Handle item click
	 * @param {MouseEvent} event - Mouse click event.
	 */
	handleClick(event) {
		const {
			studiesTableRef, reloadStudies, archiveStudiesAction, updateStudiesListSelectionAction, 
			showSuccessMessageAction, showErrorMessageAction, openDialogAction, closeDialogAction,
		} = this.props;
		
		openDialogAction('to-archive-confirm-dialog', ConfirmDialog, {
			message: this.t('to_archive_confirm_msg'),
			onYes: dialogGUIID => {
				const selection = getArray(this.props, 'selection');

				this.executeAbortableAction(archiveStudiesAction, selection.map(i => i.id))
					.then(response => {
						closeDialogAction(dialogGUIID);
						
						if (response) {
							const archivedCount = getNumber(response, 'data.processedStudiesCount');
							const notArchivedCount = getNumber(response, 'data.notProcessedStudiesCount');

							// No successfully archived studies
							if (archivedCount === 0) {
								showErrorMessageAction(
									this.t('to_archive_action_fail_msg', '', '', {
										count: notArchivedCount
									})
								);
							}
							// There are both successfully and unsuccessfully archived studies
							else if (notArchivedCount > 0) {
								showSuccessMessageAction(this.t(
									'to_archive_action_partial_success_msg', '', '', {
										successCount: archivedCount,
										failCount: notArchivedCount,
									}
								));

								// Clear selection
								if (studiesTableRef) studiesTableRef.clearSelection();
								updateStudiesListSelectionAction([]);

								// Reload table data
								reloadStudies().then();
							}
							// All studies archived successfully
							else {
								showSuccessMessageAction(this.t(
									'to_archive_action_success_msg', '', '', {
										count: archivedCount
									}
								));

								// Clear selection
								if (studiesTableRef) studiesTableRef.clearSelection();
								updateStudiesListSelectionAction([]);

								// Reload table data
								reloadStudies().then();
							}
						}
					});
			},
		}, {
			id: 'study-archive-confirm-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 550
		});
	}

	render() {
		return (
			<>
				<Button
					tabIndex={0}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					className="toolbar-button highlight-color"
					disabled={!this.isEnabled()}
					onClick={this.handleClick}
				>
					<Label
						icon={['download', 'archive']}
						iconStackClass="to-archive"
						element="span"
						content={this.t('to_archive_action')}
					/>
				</Button>
				<div className="separator" />
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ToArchiveItem.propTypes = {
	// Max. number of selected studies
	maxSelection: PropTypes.number,
	// Studies table ref
	studiesTableRef: PropTypes.object,
	// Function that reloads the studies table
	// @note Calling this function is the same as clicking on the reload data button in the top right corner.
	// @return {Promise<*>}
	reloadStudies: PropTypes.func, // No arguments
};

export default connect(
	mapStateToProps, getGlobalActions(actions, {updateStudiesListSelectionAction}), null, {forwardRef: true}
)(ToArchiveItem);
import * as sectionConfig from "../config";
import {rtrimChar} from "Core/helpers/string";
import {AclCheckDataObject} from "Core/acl";
import {getArray} from "Core/helpers/data";

/**
 * Application name
 * @type {string}
 */
export const name = 'DEFAULT';

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = null;

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject(
	[...getArray(sectionConfig, 'access.permissions'), ],
	[...getArray(sectionConfig, 'access.userTypes'), ],
	undefined
);

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `${rtrimChar(sectionConfig.routerPath, '/')}/members`;

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `${sectionConfig.translationPath}.DefaultApp`;
import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import * as pageConfig from "../../../../config";
import Label from "Core/components/display/Label";
import {getString} from "Core/helpers/data";
import {STUDY_ACTION_ICON, STUDY_ACTIONS} from "../../const";
import {icon_font_symbol_class_prefix} from "Config/app";
import {Link} from "react-router-dom";

class LinkAction extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.study_action`
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {id, className, action, to, ...otherProps} = this.props;

		return (
			<Link id={this.getDomId()} className={className} to={to} {...otherProps}>
				<Label
					icon={getString(STUDY_ACTION_ICON, [action.action, 'symbol'])}
					iconSymbolPrefix={getString(
						STUDY_ACTION_ICON,
						[action.action, 'symbolPrefix'],
						icon_font_symbol_class_prefix
					)}
					content={this.t(action.action)}
				/>
			</Link>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
LinkAction.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Action 
	action: PropTypes.shape({
		studyId: PropTypes.string,
		// @type {StudyAction}
		action: PropTypes.oneOf(STUDY_ACTIONS).isRequired,
		id: PropTypes.string,
	}).isRequired,
	// Link path (same as Link router component 'to' prop)
	to: PropTypes.string.isRequired,
};

/**
 * Define component default values for own props
 */
LinkAction.defaultProps = {
	id: '',
	className: '',
};

export default LinkAction;
import styles from "./index.module.css";
import "./index.css";

import React from "react";
import FloatPopupDataComponent, {executeComponentCallback} from "Core/components/FloatPopupDataComponent";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import TextareaInput from "Core/components/input/TextareaInput";
import {NewTicketDataObject} from "./dataObjects";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import Label from "Core/components/display/Label";
import DataValueValidation, {VALIDATION_FIELD_TYPE, ValidationConstraintObject} from "Core/validation";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import {focusSelector} from "Core/helpers/dom";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	/** @type {NewTicketDataObject} */
	data: selectors[reducerStoreKey].getTicketFloatPopupData(state) ?
		selectors[reducerStoreKey].getTicketFloatPopupData(state) : 
		new NewTicketDataObject(),
});

class TicketFloatPopup extends FloatPopupDataComponent {
	constructor(props) {
		super(props, {
			data: new NewTicketDataObject(),
		}, {
			domPrefix: 'ticket-float-popup',
			translationPath: 'TicketFloatPopup',
			enableLoadOnDataPropChange: true,
		});
	}

	componentDidMount() {
		super.componentDidMount();
		
		focusSelector('input[name=subject]');
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();
		
		dataValidation.addRule('body', 'required');
		dataValidation.addConstraint('body', new ValidationConstraintObject(
			'max', 3000, VALIDATION_FIELD_TYPE.TEXT
		));

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Handle input component changes
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleInputChange(event) {
		const {setTicketDataAction} = this.props;
		
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();

		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setValue(fieldName, value)
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(fieldName) :
						Promise.resolve(state)
				))
				// Set ticket data to Redux store
				// @note This is done so that the ticket float popup data input by the user will persist when navigating 
				// through the app (changing pages). Data should be cleared when dialog closes.
				.then(state => {
					setTicketDataAction(this.getData());
					return state;
				});
		}
		// Update component's main data if field name is not specified
		else return this.setData(value);
	}

	/**
	 * Submit float popup
	 * @note This is called by the default submit button and will perform the data validation.
	 * @return {Promise<*>} Promise that will resolve with whatever is the result of the onSubmit function or null if
	 * onSubmit is not defined or validation fails.
	 */
	submit() {
		const {floatDialogGUIID, createTicketItemAction, addSuccessMessageAction} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();
		
		// If validation is successful
		if (isValid) {
			// Trigger component's onSubmit event
			return this.executeAbortableAction(createTicketItemAction, data, floatDialogGUIID)
				.then(data => {
					if (data) {
						addSuccessMessageAction(this.t('success_msg'));
						executeComponentCallback(this.props.onSubmitSuccess);
						this.close();
					}
				});
		} else {
			return Promise.resolve(null);
		}
	}

	render() {
		return this.renderFloatPopup(
			<div className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<Label 
					element="div" 
					elementProps={{className: `${styles['notice']}`}}
					content={this.t('notice')}
				/>
				
				<FormWrapper>
					<FormField 
						label={this.t('subjectLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('subject')}
					>
						<TextInput
							name="subject"
							value={this.getValue('subject')}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField 
						label={this.t('nameLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('name')}
					>
						<TextInput
							name="name"
							value={this.getValue('name')}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField 
						label={this.t('contactLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('contact')}
					>
						<TextInput
							name="contact"
							value={this.getValue('contact')}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField 
						required={true}
						label={this.t('bodyLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('body')}
					>
						<TextareaInput
							maxLength={3000}
							name="body"
							value={this.getValue('body')}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			</div>
		);
	}
}

export default connect(mapStateToProps, getPageActions(actions))(TicketFloatPopup);
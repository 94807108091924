import {STUDY_ACTION} from "../../const";

/**
 * Export filename map by study action
 * @type {Object<StudyAction: string>}
 */
export const fileNameMap = {
	[STUDY_ACTION.EXPORT_ORIGINAL_EDF]: '##STUDY_ID##_edfExport.zip',
	[STUDY_ACTION.EXPORT_EDF]: '##STUDY_ID##_edfCustomExport.zip',
	[STUDY_ACTION.EXPORT_EPOCH]: '##STUDY_ID##_epochByEpochExport.zip',
	[STUDY_ACTION.EXPORT_EPOCH_DETAILED]: '##STUDY_ID##_EpochByEpochDetail.zip',
	[STUDY_ACTION.EXPORT_EVENTS]: '##STUDY_ID##_Events.zip',
	[STUDY_ACTION.EXPORT_SUMMARY]: 'StudySummary.csv',
	[STUDY_ACTION.EXPORT_QUALITY]: '##STUDY_ID##_QualityExport.zip',
	[STUDY_ACTION.EXPORT_DAT]: '##STUDY_ID##_SystemDatExport.zip',
};
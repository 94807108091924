import {get} from "lodash";
import {getArray, getBoolean, getInteger, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";
import {pagination_default_per_page} from "Config/app";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'studies';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@studies/reset',
	SET_LIST_DATA: '@studies/set_list_data',
	CLEAR_LIST_DATA: '@studies/clear_list_data',
	SET_LIST_SELECTION: '@studies/set_list_selection',
	
	TOGGLE_ADVANCED_SEARCH: '@studies/toggle_advanced_search',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} studiesListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	setStudiesListData: studiesListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: studiesListData
	}),
	clearStudiesListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	setStudiesListSelection: selection => ({type: REDUCER_TYPES.SET_LIST_SELECTION, selection}),
	
	toggleAdvancedSearch: () => ({type: REDUCER_TYPES.TOGGLE_ADVANCED_SEARCH}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,
	/** @type {StudiesListItemDataObject[]} */
	listSelection: [],
	/** @type {boolean} Flag that specifies if advanced search should be visible */
	advancedSearchVisible: false,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};
		case REDUCER_TYPES.SET_LIST_SELECTION: return {...state, listSelection: getArray(action, 'selection')};

		case REDUCER_TYPES.TOGGLE_ADVANCED_SEARCH: return {...state, advancedSearchVisible: !state.advancedSearchVisible};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getStudiesList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getStudiesListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage'], pagination_default_per_page),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo'], 1),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getStudiesListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy'],'creationDate'),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.DESC)
	}),
	getStudiesListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),
	getStudiesListSelection: state => getArray(state, [reducerStoreKey, 'listSelection']),

	isStudiesListAdvancedSearchVisible: state => getBoolean(state, [reducerStoreKey, 'advancedSearchVisible']),
};

export default reducer;
import styles from "./index.module.css";

import React from "react";
import DialogComponent, {DIALOG_TYPE} from "Core/components/DialogComponent";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import Label from "Core/components/display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {isSuccessful} from "Core/helpers/io";
import Icon from "Core/components/display/Icon";
import {icon_font_success_symbol} from "Config/app";

class IpBlockedDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			dialogType: DIALOG_TYPE.NONE,
			translationPath: 'IpBlockedDialog',
		});

		// Initiate component's state
		this.state = {
			success: false,
		}
		
		// Action methods
		this.unblock = this.unblock.bind(this);
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Send an IP unlock request
	 * @return {Promise<*>}
	 */
	unblock() {
		const {username, unlockIpAction} = this.props;
		return this.executeAbortableAction(unlockIpAction, username)
			.then(response => {
				if (isSuccessful(response)) {
					return this.setState({success: true});
				} else {
					this.close();
					return Promise.resolve(response);
				}
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {success} = this.state;
		
		return this.renderDialog(null, (
			<div className={styles['wrapper']}>
				{
					success ?
						<>
							<Icon 
								symbol={icon_font_success_symbol} 
								className={`page-notice-title-icon success-color ${styles['icon']}`} 
							/>
							<Label
								element="p"
								elementProps={{className: styles['successMessage']}}
								content={this.t('successMessage')}
							/>
							<Button
								className={styles['actionBtn']}
								big={true}
								displayStyle={BUTTON_STYLE.SUBTLE}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								label={this.t('Close', 'general')}
								onClick={this.close}
							/>
						</>
						:
						<>
							<Icon
								symbol="exclamation-triangle"
								className={`page-notice-title-icon warning-color ${styles['icon']}`}
							/>
							<Label content={this.t('message')} supportHtml={true} />
							<Button
								className={`${styles['actionBtn']} ${styles['unlockBtn']}`}
								big={true}
								displayStyle={BUTTON_STYLE.ACTION}
								label={this.t('unlockBtn')}
								onClick={this.unblock}
							/>
							<Button
								className={styles['actionBtn']}
								big={true}
								displayStyle={BUTTON_STYLE.SUBTLE}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								label={this.t('Close', 'general')}
								onClick={this.close}
							/>
						</>
				}
			</div>
		));
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
IpBlockedDialog.propTypes = {
	username: PropTypes.string,
};

/**
 * Define component default values for own props
 */
IpBlockedDialog.defaultProps = {
	username: '',
};

export default connect(null, getPageActions(actions))(IpBlockedDialog);
/**
 * Data object representing the manage documents list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class ManageDocumentsListItemDataObject {
	/**
	 * @param {string} id - Document's ID.
	 * @param {string} title - Document's title.
	 * @param {boolean} isLink - Flag that specifies if document is a link.
	 * @param {string} link - Document link used only if 'isLink' is true.
	 * @param {Date} releaseDate - Document's release date only if 'isLink' is false.
	 * @param {number} ordinal - Position of the document in the document list.
	 */
	constructor(id, title, isLink, link, releaseDate, ordinal) {
		this.id = id;
		this.title = title;
		this.isLink = isLink;
		this.link = link;
		this.releaseDate = releaseDate;
		this.ordinal = ordinal;
	}
}

/**
 * Data object representing the manage documents item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class ManageDocumentsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Document's ID.
	 * @param {string} [title=''] - Document's title.
	 * @param {string} [description=''] - Document's description.
	 * @param {boolean} [isLink=false] - Flag that specifies if document is a link.
	 * @param {string} [link=''] - Document link used only if 'isLink' is true.
	 * @param {boolean} [valid=true] - Flag that specifies if document is valid.
	 * @param {boolean} [acceptTermsType=false] - Flag that specifies if this is a terms & conditions document.
	 * @param {boolean} [consentGDPRType=false] - Flag that specifies if this is a GDPR consent document.
	 * @param {Date} [releaseDate=null] - Document's release date only if 'isLink' is false.
	 * @param {number} [ordinal=0] - Position of the document in the document list.
	 * @param {string} [documentFileName=''] - Current document's file name. This is only relevant if 'isLink' is false.
	 * @param {string} [documentFileId=''] - Current document's ID. This is only relevant if 'isLink' is false.
	 * @param {string} [documentThumbnailId=''] - Current document's thumbnail ID.
	 * @param {boolean} [shouldSaveDocument=false] - Flag that specifies if document file should be saved. This is only 
	 * used when updating an existing manage documents item.
	 * @param {*} [documentFormData=null] - Selected document file. This is only relevant if 'isLink' is false.
	 * @param {boolean} [shouldSaveThumbnail=false] - Flag that specifies if document thumbnail should be saved. This is 
	 * only used when updating an existing manage documents item.
	 * @param {*} [documentThumbnailFormData=null] - Selected document thumbnail file. This is only relevant if 'isLink' 
	 * is false.
	 */
	constructor(
		id = null, title = '', description = '', isLink = false, link = '', valid = true, acceptTermsType = false, 
		consentGDPRType = false, releaseDate = null, ordinal = 0, documentFileName = '', documentFileId = '', 
		documentThumbnailId = '', shouldSaveDocument = false, documentFormData = null, shouldSaveThumbnail = false, 
		documentThumbnailFormData = null,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.isLink = isLink;
		this.link = link;
		this.valid = valid;
		this.acceptTermsType = acceptTermsType;
		this.consentGDPRType = consentGDPRType;
		this.releaseDate = releaseDate;
		this.ordinal = ordinal;
		
		// File read properties
		// @desc Properties used to display current files already saved in the database.
		this.documentFileName = documentFileName;
		this.documentFileId = documentFileId;
		this.documentThumbnailId = documentThumbnailId;

		// File write properties
		// @desc Properties used to store selected file(s) that will be saved in the database.
		this.shouldSaveDocument = shouldSaveDocument;
		this.documentFormData = documentFormData;
		this.shouldSaveThumbnail = shouldSaveThumbnail;
		this.documentThumbnailFormData = documentThumbnailFormData;
	}
}
import {get, omit} from "lodash";
import {getBoolean, getInteger, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'tickets';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@tickets/reset',
	SET_LIST_DATA: '@tickets/set_list_data',
	CLEAR_LIST_DATA: '@tickets/clear_list_data',
	SET_ITEM: '@tickets/set_item',
	CLEAR_ITEM: '@tickets/clear_item',
	SET_ITEM_STATUS: '@tickets/set_item_status',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} ticketsListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	
	setTicketsListData: ticketsListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: ticketsListData
	}),
	clearTicketsListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	
	/**
	 * @param {TicketsItemDataObject} ticketsItem
	 * @return {{type: string, item: TicketsItemDataObject}}
	 */
	setTicketsItem: ticketsItem => ({
		type: REDUCER_TYPES.SET_ITEM, 
		item: ticketsItem
	}),
	clearTicketsItem: () => ({type: REDUCER_TYPES.CLEAR_ITEM}),

	/**
	 * @param {TicketStatus} status
	 * @return {{type: string, status: TicketStatus}}
	 */
	setTicketsItemStatus: status => ({type: REDUCER_TYPES.SET_ITEM_STATUS, status}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {TicketsItemDataObject}
	 */
	item: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		case REDUCER_TYPES.SET_ITEM: return {...state, item: action.item};
		case REDUCER_TYPES.CLEAR_ITEM: return {...state, item: initialState.item};

		case REDUCER_TYPES.SET_ITEM_STATUS:
			const item = get(state, 'item');
			return (item ? {...state, item: {...item, status: action.status}} : state);

		default: return state;
	}
};

// Selectors
export const selectors = {
	getTicketsList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getTicketsListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getTicketsListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getTicketsListFilter: state => omit(
		get(state, [reducerStoreKey, 'listData', 'filter'], null), 
		['adminTicketsFilter']
	),

	getTicketsItem: state => get(state, [reducerStoreKey, 'item']),
};

export default reducer;
/** @typedef {string} ActivityLogStatus */
export const ACTIVITY_LOG_STATUS_FAILED = "FAILED";
export const ACTIVITY_LOG_STATUS_SUCCEEDED = "SUCCEEDED";
export const ACTIVITY_LOG_STATUS_STARTED = "STARTED";
/** @enum {ActivityLogStatus} */
export const ACTIVITY_LOG_STATUS = {
	FAILED: ACTIVITY_LOG_STATUS_FAILED,
	SUCCEEDED: ACTIVITY_LOG_STATUS_SUCCEEDED,
	STARTED: ACTIVITY_LOG_STATUS_STARTED
}
/** @type {ActivityLogStatus[]} */
export const ACTIVITY_LOG_STATUSES = [
	ACTIVITY_LOG_STATUS_FAILED,
	ACTIVITY_LOG_STATUS_SUCCEEDED,
	ACTIVITY_LOG_STATUS_STARTED
];

/** @typedef {string} ActivityLogModule */
export const ACTIVITY_LOG_MODULE_PORTAL_LOGIN_PAGE = "Portal Login Page";
export const ACTIVITY_LOG_MODULE_PORTAL_MAIN_PAGE = "Portal Main Page";
export const ACTIVITY_LOG_MODULE_QUESTIONNAIRE = "Questionnaire";
export const ACTIVITY_LOG_MODULE_STUDY_PATIENT_INFO = "Study/Patient Info";
export const ACTIVITY_LOG_MODULE_USER_PROFILE = "User Profile";
export const ACTIVITY_LOG_MODULE_DEVICE_MANAGER = "Device Manager";
export const ACTIVITY_LOG_MODULE_STUDY_EDITOR = "Study Editor";
export const ACTIVITY_LOG_MODULE_WINDOWS_SERVICE = "Windows Service";
export const ACTIVITY_LOG_MODULE_REPORT_GENERATION_WIN_SERVICE = "Report Generation WinService";
export const ACTIVITY_LOG_MODULE_ADMINISTRATION = "Administration";
/** @enum {ActivityLogModule} */
export const ACTIVITY_LOG_MODULE = {
	PORTAL_LOGIN_PAGE: ACTIVITY_LOG_MODULE_PORTAL_LOGIN_PAGE,
	PORTAL_MAIN_PAGE: ACTIVITY_LOG_MODULE_PORTAL_MAIN_PAGE,
	QUESTIONNAIRE: ACTIVITY_LOG_MODULE_QUESTIONNAIRE,
	STUDY_PATIENT_INFO: ACTIVITY_LOG_MODULE_STUDY_PATIENT_INFO,
	USER_PROFILE: ACTIVITY_LOG_MODULE_USER_PROFILE,
	DEVICE_MANAGER: ACTIVITY_LOG_MODULE_DEVICE_MANAGER,
	STUDY_EDITOR: ACTIVITY_LOG_MODULE_STUDY_EDITOR,
	WINDOWS_SERVICE: ACTIVITY_LOG_MODULE_WINDOWS_SERVICE,
	REPORT_GENERATION_WIN_SERVICE: ACTIVITY_LOG_MODULE_REPORT_GENERATION_WIN_SERVICE,
	ADMINISTRATION: ACTIVITY_LOG_MODULE_ADMINISTRATION,
}
/** @type {ActivityLogModule[]} */
export const ACTIVITY_LOG_MODULES = [
	ACTIVITY_LOG_MODULE_PORTAL_LOGIN_PAGE,
	ACTIVITY_LOG_MODULE_PORTAL_MAIN_PAGE,
	ACTIVITY_LOG_MODULE_QUESTIONNAIRE,
	ACTIVITY_LOG_MODULE_STUDY_PATIENT_INFO,
	ACTIVITY_LOG_MODULE_USER_PROFILE,
	ACTIVITY_LOG_MODULE_DEVICE_MANAGER,
	ACTIVITY_LOG_MODULE_STUDY_EDITOR,
	ACTIVITY_LOG_MODULE_WINDOWS_SERVICE,
	ACTIVITY_LOG_MODULE_REPORT_GENERATION_WIN_SERVICE,
	ACTIVITY_LOG_MODULE_ADMINISTRATION,
];

/** @typedef {string} ActivityLogType */
export const ACTIVITY_LOG_TYPE_SIGNING_IN = '001001';
export const ACTIVITY_LOG_TYPE_REQUESTING_NEW_PASSWORD = '001002';
export const ACTIVITY_LOG_TYPE_REQUESTING_UNBLOCKING_OF_IP_ADDRESS = '001003';
export const ACTIVITY_LOG_TYPE_ACTIVATING_USER = '002001';
export const ACTIVITY_LOG_TYPE_LOADING_SEARCH_STUDIES_PAGE = '003000';
export const ACTIVITY_LOG_TYPE_SEARCHING_STUDIES = '003001';
export const ACTIVITY_LOG_TYPE_ADD_STUDIES_TO_ARCHIVE = '003008';
export const ACTIVITY_LOG_TYPE_REMOVE_STUDIES_FROM_ARCHIVE = '003009';
export const ACTIVITY_LOG_TYPE_CREATE_COMPARISON_REPORT = '003010';
export const ACTIVITY_LOG_TYPE_EXPORT_STUDY_METRICS = '003020';
export const ACTIVITY_LOG_TYPE_QUESTIONNAIRE_REPORT_GENERATION = '005004';
export const ACTIVITY_LOG_TYPE_INSERT_PROCESSING_REQUEST = '550503';
/** @enum {ActivityLogType} */
export const ACTIVITY_LOG_TYPE = {
	SIGNING_IN: ACTIVITY_LOG_TYPE_SIGNING_IN,
	REQUESTING_NEW_PASSWORD: ACTIVITY_LOG_TYPE_REQUESTING_NEW_PASSWORD,
	REQUESTING_UNBLOCKING_OF_IP_ADDRESS: ACTIVITY_LOG_TYPE_REQUESTING_UNBLOCKING_OF_IP_ADDRESS,
	ACTIVATING_USER: ACTIVITY_LOG_TYPE_ACTIVATING_USER,
	LOADING_SEARCH_STUDIES_PAGE: ACTIVITY_LOG_TYPE_LOADING_SEARCH_STUDIES_PAGE,
	SEARCHING_STUDIES: ACTIVITY_LOG_TYPE_SEARCHING_STUDIES,
	ADD_STUDIES_TO_ARCHIVE: ACTIVITY_LOG_TYPE_ADD_STUDIES_TO_ARCHIVE,
	REMOVE_STUDIES_FROM_ARCHIVE: ACTIVITY_LOG_TYPE_REMOVE_STUDIES_FROM_ARCHIVE,
	CREATE_COMPARISON_REPORT: ACTIVITY_LOG_TYPE_CREATE_COMPARISON_REPORT,
	EXPORT_STUDY_METRICS: ACTIVITY_LOG_TYPE_EXPORT_STUDY_METRICS,
	QUESTIONNAIRE_REPORT_GENERATION: ACTIVITY_LOG_TYPE_QUESTIONNAIRE_REPORT_GENERATION,
	INSERT_PROCESSING_REQUEST: ACTIVITY_LOG_TYPE_INSERT_PROCESSING_REQUEST,
};
/** @type {ActivityLogType[]} */
export const ACTIVITY_LOG_TYPES = [
	ACTIVITY_LOG_TYPE_SIGNING_IN,
	ACTIVITY_LOG_TYPE_REQUESTING_NEW_PASSWORD,
	ACTIVITY_LOG_TYPE_REQUESTING_UNBLOCKING_OF_IP_ADDRESS,
	ACTIVITY_LOG_TYPE_ACTIVATING_USER,
	ACTIVITY_LOG_TYPE_LOADING_SEARCH_STUDIES_PAGE,
	ACTIVITY_LOG_TYPE_SEARCHING_STUDIES,
	ACTIVITY_LOG_TYPE_ADD_STUDIES_TO_ARCHIVE,
	ACTIVITY_LOG_TYPE_REMOVE_STUDIES_FROM_ARCHIVE,
	ACTIVITY_LOG_TYPE_CREATE_COMPARISON_REPORT,
	ACTIVITY_LOG_TYPE_EXPORT_STUDY_METRICS,
	ACTIVITY_LOG_TYPE_QUESTIONNAIRE_REPORT_GENERATION,
	ACTIVITY_LOG_TYPE_INSERT_PROCESSING_REQUEST,
];
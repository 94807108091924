import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, orderBy, forOwn} from "lodash";
import {icon_font_close_symbol, icon_font_save_symbol} from "Config/app";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {isset} from "Core/helpers/data";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../reducer";
import ACL from "../../../../../../acl";
import {isSystemAdmin} from "../../../../../../helpers/currentUser";
import acl from "../../../../../../acl";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	accountSettingsItem: selectors[reducerStoreKey].getAccountSettingsItem(state),
});

class ItemPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.ItemPopup`,
			domPrefix: 'item-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Action methods
		this.save = this.save.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
			new PopupActionDataObject({
				id: 'update_and_close',
				action: this.saveAndClose,
				buttonProps: {
					label: this.getTranslationPath('update_and_close_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				disabled: ACL.isGuest(ACL),
				ordinal: 1,
			}),
			new PopupActionDataObject({
				id: 'update',
				action: this.save,
				buttonProps: {
					label: this.getTranslationPath('update_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				disabled: ACL.isGuest(ACL),
				ordinal: 2
			}),	
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons();

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
				label: this.tt('tab_name', 'MainTab'),
				icon: 'envelope-o',
			}),
			new PopupTabDataObject({
				id: 'ReportLetterhead',
				label: this.tt('tab_name', 'ReportLetterhead'),
				icon: 'file-text-o',
			}),
			new PopupTabDataObject({
				id: 'DesatSettings',
				label: this.tt('tab_name', 'DesatSettings'),
				icon: 'percent',
				visible: isSystemAdmin() || acl.checkPermission(acl, ['SYSTEM-PSG2']),
			}),
			new PopupTabDataObject({
				id: 'ProcessingSettings',
				label: this.tt('tab_name', 'ProcessingSettings'),
				icon: 'cogs',
			})
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs();

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {accountSettingsItem, updateAccountSettingsItemAction, addSuccessMessageAction} = this.props;

		return Promise.all([
			this.getTabRef('MainTab').validateTab(),
			this.getTabRef('ReportLetterhead').validateTab(),
			this.getTabRef('DesatSettings').validateTab(),
			this.getTabRef('ProcessingSettings').validateTab(),
		])
			.then(validArray => {
				if (!validArray.includes(false)) {
					// Get item by using the loaded item data and updating combined tab data from all tabs
					/** @type {AccountSettingsItemDataObject} */
					let item = cloneDeep(accountSettingsItem);
					forOwn(allTabsData, tabData => { item = {...item, ...cloneDeep(tabData)}; });
					
					if (actionId === 'update') {
						return this.executeAbortableAction(updateAccountSettingsItemAction, item)
							.then(response => {
								if (response) addSuccessMessageAction(this.t('update_success_msg'));
								return response;
							});
					} else {
						console.error(`Invalid account settings item popup save method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Save item and close the popup if successful
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	saveAndClose(allTabsData, event) {
		return this.save(allTabsData, event, 'update')
			.then(response => {
				if (isset(response)) this.close();
				return response;
			});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ItemPopup.propTypes = {
	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(ItemPopup);
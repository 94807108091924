import {cloneDeep, get} from "lodash";
import {getBoolean, getNumber, getString} from "Core/helpers/data";
import {ManageDocumentsItemDataObject} from "../dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ManageDocumentsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ManageDocumentsItemDataObject(
		getString(result, 'id'),
		getString(result, 'title'),
		getString(result, 'description'),
		getBoolean(result, 'linkFlag'),
		getString(result, 'link'),
		getBoolean(result, 'valid'),
		getBoolean(result, 'acceptTermsType'),
		getBoolean(result, 'consentGDPRType'),
		getDate(get(result, 'releaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getNumber(result, 'ordinal', 1),
		getString(result, 'portalDocumentFileName'),
		getString(result, 'portalDocumentFileId'),
		getString(result, 'portalDocumentThumbnailId'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {ManageDocumentsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		title: result.title,
		description: result.description,
		linkFlag: result.isLink,
		link: result.link,
		valid: result.valid,
		acceptTermsType: result.acceptTermsType,
		consentGDPRType: result.consentGDPRType,
		releaseDate: getDateString(result.releaseDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		ordinal: result.ordinal,
		
		// Form data properties
		saveFileDocument: result.shouldSaveDocument,
		document: result.shouldSaveDocument ? result.documentFormData : '',
		saveFileImage: result.shouldSaveThumbnail,
		image: result.shouldSaveThumbnail ? result.documentThumbnailFormData : ''
	};

	// Cleanup unused values
	if (result.linkFlag) {
		// Link does not have a description, document or release date, so they are cleared
		result.description = '';
		result.saveFileDocument = true;
		result.document = '';
		result.releaseDate = '';
	} else {
		// Documents don't have a link, so it is cleared
		result.link = '';
	}
	
	// Set proper empty values expected by the API
	// @note This is commented out since requests that use FormData have backend issues with string values cleared by 
	// null values instead of empty strings.
	//result = resetObjectValues(result);

	return result;
}
import styles from "../index.module.css";

import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {
	DATA_TABLE_CELL_NON_VALUE_TYPES,
	DATA_TABLE_CELL_TYPE,
	DATA_TABLE_CELL_TYPES
} from "Core/components/advanced/DataTable/const";
import {getArray, getBoolean, getString, isset} from "Core/helpers/data";
import {forOwn, get, isFunction} from "lodash";
import {trimChar} from "Core/helpers/string";
import Html from "Core/components/display/Html";
import NumberLabel from "Core/components/display/NumberLabel";
import DateLabel from "Core/components/display/DateLabel";
import Label from "Core/components/display/Label";
import {Tooltip} from "react-tippy";
import {
	DataTableCellActionsTypeOptionsDataObject,
	DataTableCellActionTypeOptionsDataObject, 
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellBoolTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject,
	DataTableCellNumberTypeOptionsDataObject, 
	DataTableCellTemplateTypeOptionsDataObject,
	DataTableCellTextTypeOptionsDataObject, 
	DataTableCellTypeCommonOptionsDataObject
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";

class DataTableCell extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'DataTable',
			optimizedUpdate: true,
		});
		
		// Render methods
		this.renderDefaultCell = this.renderDefaultCell.bind(this);
		this.renderCell_text = this.renderCell_text.bind(this);
		this.renderCell_number = this.renderCell_number.bind(this);
		this.renderCell_date = this.renderCell_date.bind(this);
		this.renderCell_bool = this.renderCell_bool.bind(this);
		this.renderCell_bool_inverted = this.renderCell_bool_inverted.bind(this);
		this.renderCell_action = this.renderCell_action.bind(this);
		this.renderCell_actions = this.renderCell_actions.bind(this);
		this.renderCell_template = this.renderCell_template.bind(this);
		this.renderCell_any = this.renderCell_any.bind(this);
	}

	/**
	 * Render cell when type is not specified or there is no render function to handle the specified type
	 * 
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellTypeCommonOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderDefaultCell(row, value, typeOptions = new DataTableCellTypeCommonOptionsDataObject(undefined)) {
		return (
			<div 
				className={`content ${styles['content']}`}
				style={{
					textAlign: getString(typeOptions, 'alignContent', 'unset'),
					whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
				}}
			>{value}</div>
		);
	}

	/**
	 * Render cell of type text
	 * 
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellTextTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_text(row, value, typeOptions = new DataTableCellTextTypeOptionsDataObject(undefined)) {
		if (isset(typeOptions.trimChar)) {
			value = (
				getString(typeOptions, 'trimChar') !== ' ' ?
					trimChar(value, getString(typeOptions, 'trimChar')) :
					value = (value ? value.trim() : value)
			);
		}
		const text_content = (
			get(typeOptions, 'translatePath') ? this.t(value, typeOptions.translatePath) : value
		);
		return (
			typeOptions.supportHtml ?
				<Html
					content={text_content}
					className={`content ${styles['content']}`}
					element="div"
					elementProps={{
						style: {
							textAlign: getString(typeOptions, 'alignContent', 'unset'),
							whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
						}
					}}
				/>
				:
				<div
					className={`content ${styles['content']}`}
					style={{
						textAlign: getString(typeOptions, 'alignContent', 'unset'),
						whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
					}}
				>{text_content}</div>
		);
	}

	/**
	 * Render cell of type number
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellNumberTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_number(row, value, typeOptions = new DataTableCellNumberTypeOptionsDataObject(undefined)) {
		return (
			<NumberLabel
				number={value}
				defaultNumber={get(typeOptions, 'defaultNumber')}
				prefix={get(typeOptions, 'prefix', '')}
				suffix={get(typeOptions, 'suffix', '')}
				icon={get(typeOptions, 'icon')}
				iconPosition={get(typeOptions, 'iconPosition')}
				iconSpin={get(typeOptions, 'iconSpin')}
				tooltip={get(typeOptions, 'tooltip')}
				useAppLocale={get(typeOptions, 'useAppLocale')}
				useAppLocaleCurrency={get(typeOptions, 'useAppLocaleCurrency')}
				locale={get(typeOptions, 'locale')}
				format={get(typeOptions, 'format')}
				element="div"
				elementProps={{
					className: `content ${styles['content']}`,
					style: {
						textAlign: getString(typeOptions, 'alignContent', 'unset'),
						whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
					}
				}}
			/>
		);
	}

	/**
	 * Render cell of type date
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellDateTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_date(row, value, typeOptions = new DataTableCellDateTypeOptionsDataObject(undefined)) {
		return (
			<DateLabel
				inputDate={value}
				inputFormat={get(typeOptions, 'inputFormat')}
				outputFormat={get(typeOptions, 'outputFormat')}
				defaultDate={get(typeOptions, 'defaultDate')}
				defaultOutput={get(typeOptions, 'defaultOutput')}
				prefix={get(typeOptions, 'prefix', '')}
				suffix={get(typeOptions, 'suffix', '')}
				icon={get(typeOptions, 'icon')}
				iconPosition={get(typeOptions, 'iconPosition')}
				iconSpin={get(typeOptions, 'iconSpin')}
				tooltip={get(typeOptions, 'tooltip')}
				useAppLocale={get(typeOptions, 'useAppLocale')}
				inputLocale={get(typeOptions, 'inputLocale')}
				outputLocale={get(typeOptions, 'outputLocale')}
				element="div"
				elementProps={{
					className: `content ${styles['content']}`,
					style: {
						textAlign: getString(typeOptions, 'alignContent', 'unset'),
						whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
					}
				}}
			/>
		);
	}

	/**
	 * Render cell of type bool
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellBoolTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_bool(row, value, typeOptions = new DataTableCellBoolTypeOptionsDataObject(undefined)) {
		let bool_content = '';
		// If value is defined
		if (isset(value)) {
			// True
			if (getBoolean(value)) {
				const label = getString(typeOptions, 'trueLabel');
				const translatePath = getString(typeOptions, 'translatePath');
				if (label) bool_content = (translatePath ? this.t(label, translatePath) : label);
				else bool_content = this.t('Yes', 'general');
			}
			// False
			else {
				const label = getString(typeOptions, 'falseLabel');
				const translatePath = getString(typeOptions, 'translatePath');
				if (label) bool_content = (translatePath ? this.t(label, translatePath) : label);
				else bool_content = this.t('No', 'general');
			}
		}
		return (
			typeOptions.supportHtml ?
				<Html
					content={bool_content}
					className={`content ${styles['content']}`}
					element="div"
					elementProps={{
						style: {
							textAlign: getString(typeOptions, 'alignContent', 'unset'),
							whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
						}
					}}
				/>
				:
				<div
					className={`content ${styles['content']}`}
					style={{
						textAlign: getString(typeOptions, 'alignContent', 'unset'),
						whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
					}}
				>{bool_content}</div>
		);
	}

	/**
	 * Render cell of type bool_inverted
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellBoolTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_bool_inverted(row, value, typeOptions = new DataTableCellBoolTypeOptionsDataObject(undefined)) {
		let inverse_bool_content = '';
		// If value is defined
		if (isset(value)) {
			// True
			if (getBoolean(value)) {
				const label = getString(typeOptions, 'falseLabel');
				const translatePath = getString(typeOptions, 'translatePath');
				if (label) inverse_bool_content = (translatePath ? this.t(label, translatePath) : label);
				else inverse_bool_content = this.t('No', 'general');
			}
			// False
			else {
				const label = getString(typeOptions, 'trueLabel');
				const translatePath = getString(typeOptions, 'translatePath');
				if (label) inverse_bool_content = (translatePath ? this.t(label, translatePath) : label);
				else inverse_bool_content = this.t('Yes', 'general');
			}
		}
		return (
			typeOptions.supportHtml ?
				<Html
					content={inverse_bool_content}
					className={`content ${styles['content']}`}
					element="div"
					elementProps={{
						style: {
							textAlign: getString(typeOptions, 'alignContent', 'unset'),
							whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
						}
					}}
				/>
				:
				<div
					className={`content ${styles['content']}`}
					style={{
						textAlign: getString(typeOptions, 'alignContent', 'unset'),
						whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
					}}
				>{inverse_bool_content}</div>
		);
	}

	/**
	 * Render cell of type action
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellActionTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_action(row, value, typeOptions = new DataTableCellActionTypeOptionsDataObject(undefined)) {
		const onClick = get(typeOptions, 'onClick');
		const icon = getString(typeOptions, 'icon');
		const label = get(typeOptions, 'label');
		const className = getString(typeOptions, 'className', '', true);
		const tooltip = getString(typeOptions, 'tooltip');
		const conditionFunc = get(typeOptions, 'condition');
		const conditionMet = (conditionFunc ? conditionFunc(row) : true);

		return (
			<div key={typeOptions.key} className={`content ${styles['content']}`}>
				{
					conditionMet && (icon || label) ?
						tooltip ?
							<Tooltip
								tag="span"
								title={tooltip}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<div
									className={`action-btn ${styles['action-btn']} ${className}`}
									style={{
										textAlign: getString(typeOptions, 'alignContent', 'unset'),
										whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
									}}
									onClick={(onClick ? e => onClick(row, e) : null)}
								>
									<Label
										content={label}
										icon={icon}
										supportHtml={typeOptions.supportHtml}
									/>
								</div>
							</Tooltip>
							:
							<div
								className={`action-btn ${styles['action-btn']} ${className}`}
								style={{
									textAlign: getString(typeOptions, 'alignContent', 'unset'),
									whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
								}}
								onClick={(onClick ? e => onClick(row, e) : null)}
							>
								<Label
									content={label}
									icon={icon}
									supportHtml={typeOptions.supportHtml}
								/>
							</div>
						: null
				}
			</div>
		);
	}

	/**
	 * Render cell of type actions
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellActionsTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_actions(row, value, typeOptions = new DataTableCellActionsTypeOptionsDataObject(undefined)) {
		const actions = getArray(typeOptions, 'actions');
		return actions.map((action, idx) => this.renderCell_action(row, value, {...action, key: idx}));
	}

	/**
	 * Render cell of type template
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellTemplateTypeOptionsDataObject} [typeOptions] - Cell type options.
	 * @return {*}
	 */
	renderCell_template(row, value, typeOptions = new DataTableCellTemplateTypeOptionsDataObject(undefined)) {
		const rawTemplate = get(typeOptions, 'template');
		const nullAsEmpty = getBoolean(typeOptions, 'nullAsEmpty');
		const template = (isFunction(rawTemplate) ? rawTemplate(row) : getString(rawTemplate));
		let template_content = template;
		if (template) {
			forOwn(row, (fieldValue, field) => {
				const regexPattern = '{\\$' + field + '\\|?[^}]*}';
				const regex = new RegExp(regexPattern, 'gim');

				template_content = template_content.replace(regex, match => {
					// Remove "{$" from the beginning and "}" from the end of matched item
					match = match.replace(/^{\$|}$/g, '');

					// Trim matched item
					match = match.trim();

					// Replacement params
					// Every match item can have one or more params that can alter the replacement value in some way
					// (for example converting replacement value to lowercase or uppercase).
					const paramsSplit = match.split('|');
					if (paramsSplit.length === 2) {
						let alteredValue = (
							nullAsEmpty ? getString(fieldValue, '', '', true) : fieldValue
						);
						const params = paramsSplit[1].split(' ');
						for (let param of params) {
							switch (param.trim()) {
								case 'l': alteredValue = alteredValue.toLowerCase(); break; // Lowercase
								case 'u': alteredValue = alteredValue.toUpperCase(); break; // Uppercase
								case 't': alteredValue = alteredValue.trim(); break; // Trim
								// no default
							}
						}
						return alteredValue;
					}
					// If there are no altering params use the default replacement value (rowFieldValue)
					else return (
						nullAsEmpty ? getString(fieldValue, '', '', true) : fieldValue
					);
				});
			});
			return (
				typeOptions.supportHtml ?
					<Html
						content={template_content}
						className={`content ${styles['content']}`}
						element="div"
						elementProps={{
							style: {
								textAlign: getString(typeOptions, 'alignContent', 'unset'),
								whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
							}
						}}
					/>
					:
					<div
						className={`content ${styles['content']}`}
						style={{
							textAlign: getString(typeOptions, 'alignContent', 'unset'),
							whiteSpace: getString(typeOptions, 'whiteSpace', 'unset'),
						}}
					>{template_content}</div>
			);
		} else {
			return <div className={`content ${styles['content']}`} />;
		}
	}

	/**
	 * Render cell of type any
	 *
	 * @param {Object} row - Row data.
	 * @param {any} value - Cell value.
	 * @param {DataTableCellAnyTypeOptionsDataObject} [typeOptions] - Cell type options. 
	 * @return {*}
	 */
	renderCell_any(row, value, typeOptions = new DataTableCellAnyTypeOptionsDataObject(undefined)) {
		const rawContent = get(typeOptions, 'content');
		const content = (isFunction(rawContent) ? rawContent(row) : rawContent);
		const rawStandardWrapper = get(typeOptions, 'standardWrapper');
		const standardWrapper = (isFunction(rawStandardWrapper) ? rawStandardWrapper(row) : rawStandardWrapper);
		return (
			getBoolean(standardWrapper, '', true) ?
				<div className={`content ${styles['content']}`}>{content}</div>
				:
				(content ? content : null)
		);
	}
	
	render() {
		const {row, value, defaultValue, type, typeOptions} = this.props;
		
		return (
			(isset(value) && value !== null) || DATA_TABLE_CELL_NON_VALUE_TYPES.includes(type) ?
				this.hasOwnProperty(`renderCell_${type}`) ?
					this[`renderCell_${type}`](row, value, typeOptions) : 
					this.renderDefaultCell(row, value, typeOptions)
				:
				this.renderDefaultCell(row, defaultValue, typeOptions)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DataTableCell.propTypes = {
	// Row data object
	row: PropTypes.object,
	// Row cell raw value
	value: PropTypes.any,
	// Default value if value is undefined or null
	// @note Empty string values will be rendered as is since that is considered to be a valid value.
	defaultValue: PropTypes.any,
	// Cell type
	// @type {DataTableCellType}
	type: PropTypes.oneOf(DATA_TABLE_CELL_TYPES),
	// Optional type options object with type specific options
	typeOptions: PropTypes.object,
};

/**
 * Define component default values for own props
 */
DataTableCell.defaultProps = {
	defaultValue: null,
	type: DATA_TABLE_CELL_TYPE.TEXT, 
	typeOptions: {},
};

export default DataTableCell;
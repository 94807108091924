import {set} from 'lodash';

// Import individual reducers here
import * as mainSidebar from "../../layout/elements/MainSidebar/reducer";
import * as manageDocuments from "../../pages/apps/default/manageDocuments/reducer";
import * as languagesCodebook from "../../pages/apps/default/languagesCodebook/reducer";
import * as applicationParameters from "../../pages/apps/default/applicationParameters/reducer";
import * as firmware from "../../pages/apps/default/firmware/reducer";
import * as appStarter from "../../pages/apps/default/appStarter/reducer";
import * as libraries from "../../pages/apps/default/libraries/reducer";
import * as languageFiles from "../../pages/apps/default/languageFiles/reducer";
import * as accounts from "../../pages/apps/default/accounts/reducer";
import * as users from "../../pages/apps/default/users/reducer";
import * as roles from "../../pages/apps/default/roles/reducer";
import * as myTickets from "../../pages/apps/default/myTickets/reducer";
import * as studies from "../../pages/apps/default/studies/reducer";
import * as sleepDiary from "../../pages/apps/default/studies/popups/SleepDiaryPopup/reducer";
import * as questionnaire from "../../pages/apps/default/studies/popups/QuestionnairePopup/reducer";
import * as devices from "../../pages/apps/default/devices/reducer";
import * as activityLog from "../../pages/apps/default/activityLog/reducer";
import * as tickets from "../../pages/apps/default/tickets/reducer";
import * as accountSettings from "../../pages/apps/default/accountSettings/reducer";
import * as ticketFloatPopup from "../../components/floatPopups/TicketFloatPopup/reducer";

// All imported reducers must be added to this array
const reducerImports = [
	mainSidebar, manageDocuments, languagesCodebook, applicationParameters, firmware, appStarter, libraries, 
	languageFiles, accounts, users, roles, myTickets, studies, sleepDiary, devices, activityLog, questionnaire, tickets,
	accountSettings, ticketFloatPopup, 
];




// ---------------------------------------------------------------------------------------------------------------------
// Don't change anything below this line -------------------------------------------------------------------------------
let importedReducers = {};
let importedSelectors = {};
let importedActionCreators = {};
// Combine all imported reducers. Imported reducers must export 'reducerStoreKey' constant containing unique store key
// associated to that reducer.
reducerImports.forEach(reducerImport => {
	set(importedReducers, reducerImport.reducerStoreKey, reducerImport.default);
	set(importedSelectors, reducerImport.reducerStoreKey, reducerImport.selectors);
	set(importedActionCreators, reducerImport.reducerStoreKey, reducerImport.actionCreators);
});

// Combined selectors and action creators
export { importedReducers as reducers, importedSelectors as selectors, importedActionCreators as actionCreators };
import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {LanguagesCodebookItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {LanguagesCodebookItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new LanguagesCodebookItemDataObject(
		getString(result, 'id'),
		getString(result, 'title'),
		getString(result, 'code'),
		getString(result, 'description'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {LanguagesCodebookItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		title: result.title,
		code: result.code,
		description: result.description,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import reduxStore from '../store';
import {get} from "lodash";
import {selectors, actionCreators} from '../../core/store/reducers';
import {OverlayObject} from "../objects";

/**
 * Get the list of all overlays from Redux store state
 * @note This is the same as reducer selector but it does not require Redux state.
 * @return {OverlayObject[]}
 */
export const getOverlays = () => selectors.overlay.getOverlays(reduxStore.getState());

/**
 * Get the number of overlays assisted with a specific selector
 * @param {string} selector - CSS selector of the target element.
 * @return {number}
 */
export const getOverlayCountForSelector = selector => {
	return selectors.overlay.getOverlayCountForSelector(reduxStore.getState(), selector);
}

// Overlay -------------------------------------------------------------------------------------------------------------
/**
 * Add overlay to the list of overlays
 * @param {OverlayObject} overlay
 * @return Overlay GUI ID.
 */
export const addOverlay = overlay => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay(overlay));
	return get(overlay, 'GUIID');
};
/**
 * Add an overlay to all HTML elements found by the selector
 * 
 * @param {string} selector - CSS selector for target elements.
 * @param {string|JSX.Element|Element} [content=''] - Content display in overlay.
 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be defined
 * by the skin in '/skin/css/common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
 * '/skin/css/common.css' file.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return Overlay GUI ID.
 */
export const showOverlay = (selector, content = '', transparent = true, blur = false, className = '') => {
	return addOverlay(new OverlayObject(selector, content, transparent, blur, className));
}
/**
 * Remove overlay from the list of overlays
 * @param {string} GUIID - Overlay GUI ID.
 */
export const removeOverlay = GUIID => { reduxStore.dispatch(actionCreators.overlay.removeOverlay(GUIID)); };
/**
 * Remove overlay from all HTML elements found by the GUIID
 * @param {string} GUIID - Overlay GUI ID.
 */
export const hideOverlay = GUIID => { removeOverlay(GUIID); }


// Layout page overlay -------------------------------------------------------------------------------------------------
/**
 * Add main layout content element overlay to the list of overlays
 * @param {OverlayObject} overlay
 * @return Overlay GUI ID.
 */
export const addPageOverlay = overlay => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({...overlay, selector: '.layout'}));
	return get(overlay, 'GUIID');
}
/**
 * Add an overlay to main layout content HTML elements ('.layout-content')
 * @note This depend on the layout being properly made with root HTML element with class name 'layout'.
 *
 * @param {string|JSX.Element|Element} [content=''] - Content display in overlay.
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return Overlay GUI ID.
 */
export const showPageOverlay = (content = '', transparent = true, blur = false, className = '') => {
	return showOverlay('.layout', content, transparent, blur, className);
}


// Layout content overlay ----------------------------------------------------------------------------------------------
/**
 * Add page layout content element overlay to the list of overlays
 * @param {OverlayObject} overlay
 * @return Overlay GUI ID.
 */
export const addContentOverlay = overlay => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({...overlay, selector: '.layout-content'}));
	return get(overlay, 'GUIID');
}
/**
 * Add an overlay to main layout content HTML elements ('.layout-content')
 * @note This depend on the layout being properly made with a child HTML element with class name 'layout-content'.
 *
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return Overlay GUI ID.
 */
export const showContentOverlay = (transparent = true, blur = false, className = '') => {
	return showOverlay('.layout-content', transparent, blur, className);
}
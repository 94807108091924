/**
 * Main cookie consent definition file
 * @description All cookies that will be used in the cookie consent must be defined here. Cookies are grouped by purpose
 * (for example: 'necessary', 'preferences', 'analytic', ...) and defined using 'CookieData' objects. There is a default
 * group named 'necessary' that is used by the app to work with necessary cookies.
 */

import {CookieData} from "Core/dataProtection/objects/cookie";
import {STORAGE_TYPE} from "Core/storage";
import {
	access_token_storage_field_name,
	access_token_storage_type,
	acl_storage_type,
	acl_storage_var,
	current_user_storage_field_name,
	decoded_access_token_storage_field_name, io_pause_requests_storage_field_name,
	locale_cookie_group,
	locale_storage_path,
	locale_storage_type,
	refresh_token_storage_field_name,
	refresh_token_storage_type, refreshing_tokens_storage_field_name,
	sidebar_menu_shrank_cookie_group,
	skin_mode_cookie_group, user_activity_storage_path
} from "../config";

/**
 * Group name for necessary cookies
 * @note This is a reserved group name that will be used to determine which cookies are necessary.
 * @type {string}
 */
const NECESSARY_GROUP_NAME = 'necessary';

/**
 * Necessary cookies
 * @type {CookieData[]}
 */
let necessaryCookies = [
	// Studies list
	new CookieData(NECESSARY_GROUP_NAME, `studies_list_advanced_search_visible`, STORAGE_TYPE.SESSION),
	
	// Application parameters
	new CookieData(NECESSARY_GROUP_NAME, `application_parameters`, STORAGE_TYPE.LOCAL),
	
	// Do not show password expiration notice flag
	new CookieData(NECESSARY_GROUP_NAME, `do_not_show_password_expiration`, STORAGE_TYPE.SESSION),
];

/**
 * Preference cookies
 * @note These are turned off for.
 * @type {CookieData[]}
 */
let preferenceCookies = [

];

/**
 * Analytic cookies
 * @type {CookieData[]}
 */
let analyticCookies = [
	
];

/**
 * Marketing cookies
 * @type {CookieData[]}
 */
let marketingCookies = [
	
];

/**
 * System cookies
 * @note These are system cookies generated and used by the core. 
 * @type {CookieData[]}
 */
const systemCookies = [
	// IO
	new CookieData(NECESSARY_GROUP_NAME, io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL),
	
	// App auth
	new CookieData(NECESSARY_GROUP_NAME, access_token_storage_field_name, access_token_storage_type),
	new CookieData(NECESSARY_GROUP_NAME, decoded_access_token_storage_field_name, access_token_storage_type),
	new CookieData(NECESSARY_GROUP_NAME, refresh_token_storage_field_name, refresh_token_storage_type),
	new CookieData(NECESSARY_GROUP_NAME, refreshing_tokens_storage_field_name, STORAGE_TYPE.LOCAL),

	// ACL
	new CookieData(NECESSARY_GROUP_NAME, acl_storage_var, acl_storage_type),
	new CookieData(NECESSARY_GROUP_NAME, current_user_storage_field_name, STORAGE_TYPE.LOCAL),
	new CookieData(NECESSARY_GROUP_NAME, current_user_storage_field_name, STORAGE_TYPE.REDUX),

	// User activity
	new CookieData(NECESSARY_GROUP_NAME, user_activity_storage_path, STORAGE_TYPE.LOCAL),
	
	// Locale
	new CookieData(locale_cookie_group, locale_storage_path, locale_storage_type),
	// Skin
	new CookieData(skin_mode_cookie_group, `skin_mode`, STORAGE_TYPE.LOCAL),
	// Sidebar menu
	new CookieData(sidebar_menu_shrank_cookie_group, `sidebar_menu_shrank`, STORAGE_TYPE.LOCAL),
];

const cookies = [].concat(necessaryCookies, preferenceCookies, analyticCookies, marketingCookies, systemCookies);

export default cookies;
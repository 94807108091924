import {hideLoading, hideLoadingFunction, showPageLoading} from "Core/helpers/loading";
import {ioJsonAction, ioJsonManualAction} from "Core/store/actions/io";
import {auth_api, auth_api_endpoints} from "Config/auth";
import {addErrorMessageAction} from "Core/components/global/Message";
import {messages_default_auto_hide_after} from "Config/app";
import {StandardJsonResponseError} from "Core/errors";

/**
 * Reset user's password
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} hash - Reset password hash received through URL from reset password email.
 * @param {string} password - Password typed by the user.
 * @param {string} confirmPassword - Confirm password typed by the user.
 * @return {function(*)}
 */
export const resetPasswordAction = (abortCallback, hash, password, confirmPassword) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		auth_api_endpoints.password_reset?.api ? auth_api_endpoints.password_reset?.api : auth_api,
		auth_api_endpoints.password_reset.path,
		{
			resetPasswordHash: hash,
			newPassword: password,
			repeatedPassword: confirmPassword
		},
		hideLoadingFunction(loading),
		false, 
		undefined,
		auth_api_endpoints.password_reset?.method ? auth_api_endpoints.password_reset?.method : undefined
	)(dispatch);
};

/**
 * Fetch the users security answer required for resting the password
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} hash - Reset password hash received through URL from reset password email.
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const fetchSecurityQuestionAction = (abortCallback, hash) => dispatch => {
	const loading = showPageLoading();
	return ioJsonManualAction(
		abortCallback,
		'defaultApi',
		'password/fetch-user-security-data',
		{ resetPasswordHash: hash },
		undefined,
		false,
	)(dispatch)
		.then(responseData => {
			hideLoading(loading);
			return responseData;
		})
		.catch(error => {
			// Render error if it is not 'AbortError'
			// @note Error should be an object with 'message' field already translated and ready for display.
			if (error.name !== 'AbortError') {
				if (error instanceof StandardJsonResponseError) {
					hideLoading(loading)
					return error.response;
				} else {
					dispatch(addErrorMessageAction(error.message, messages_default_auto_hide_after));
				}
			}
			hideLoading(loading);
			return 'AbortError';
		});
};

/**
 * Submit user specified answer to the security question
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} hash - Reset password hash received through URL from reset password email.
 * @param {string} answer - Answer provided by the user.
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const submitSecurityQuestionAnswerAction = (abortCallback, hash, answer) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		'defaultApi',
		'password/confirm-answer',
		{ 
			resetPasswordHash: hash, 
			securityAnswer: answer
		},
		hideLoadingFunction(loading),
		false,
	)(dispatch);
};
/**
 * Data object representing data required for unblocking IP address.
 */
export class UnblockIpDataObject {
	/**
	 * @param {string} id - ID of the unblock IP request.
	 * @param {string} securityQuestion - Security question specified by the user.
	 * @param {string} [securityAnswer=''] - User specified answer to security question.
	 */
	constructor(id, securityQuestion, securityAnswer = '') {
		this.id = id;
		this.securityQuestion = securityQuestion;
		this.securityAnswer = securityAnswer;
	}
}
/**
 * Application parameters resource
 *
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the 
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */
import {getApplicationGuiParameters} from "../helpers/applicationParameters";

export const RESOURCE_KEY = 'applicationParameters';

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
async function resourceFunction() {
	return Promise.resolve({[RESOURCE_KEY]: await getApplicationGuiParameters(true)});
}

export default resourceFunction;
import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, isset, trimArray} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol, icon_font_edit_symbol} from "Config/app";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {isSuccessful} from "Core/helpers/io";
import {isSystemAdmin} from "../../../../helpers/currentUser";
import {AsyncMountError} from "Core/errors";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getAccountsList(state),
	mainListPagination: selectors[reducerStoreKey].getAccountsListPagination(state),
	mainListSort: selectors[reducerStoreKey].getAccountsListSort(state),
	mainListFilter: selectors[reducerStoreKey].getAccountsListFilter(state),
});

class AccountsPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'accounts-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);

		// Action methods
		this.blockDevices = this.blockDevices.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any|void>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainList, loadAccountsListAction} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadAccountsListAction, isSystemAdmin()))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<Object|undefined>}
	 */
	reloadMainList() {
		const {loadAccountsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadAccountsListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadAccountsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadAccountsListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadAccountsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadAccountsListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {loadAccountsListAction, mainListPagination, mainListSort} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadAccountsListAction, isSystemAdmin(), oFilter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		return this.openMainListItemPopup(true);
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {loadAccountsItemAction} = this.props;

		// Open main list item popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the item or to 
		// avoid opening another dialog if item ID in the URL changes programmatically. Dialog data will change because 
		// Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openMainListItemPopup();
		}
		// If dialog is opened update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			mainListItemPopup.updateDynamics({isNew: false}).then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadAccountsItemAction, isSystemAdmin(), id)
					.then(mainListItem => {
						// If main list item is successfully loaded
						if (mainListItem) {
							return this.urlComponentGUIID;
						}
						// If main list item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close main list item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes main list item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new main list item popup should be opened.
	 */
	openMainListItemPopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(ItemPopup, {
			isNew,
			onClose: this.handleMainListItemPopupClose,
			redirectToItem: this.redirectToItem,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {
			closePopupAction, clearAccountsItemAction, clearAccountsItemRolesAction, clearAccountsItemSubAccountsAction
		} = this.props;

		// Close item popup
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();

		// Clear popup Redux data
		clearAccountsItemAction();
		clearAccountsItemRolesAction();
		clearAccountsItemSubAccountsAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleMainListItemPopupClose() {
		this.redirectToBase();
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadMainList()))
		);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Block devices for a specified account
	 * 
	 * @param {AccountsListItemDataObject} account - Account's info data.
	 * @return {Promise<void>}
	 */
	blockDevices(account) {
		const {
			openDialogAction, closeDialogAction, blockDevicesAccountsItemAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t(
					(account.blockDevices ? 'confirm_unblock_devices' : 'confirm_block_devices'), 
					undefined, 
					undefined, 
					{name: account.name}
				),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(blockDevicesAccountsItemAction, account)
						.then(response => {
							if (isSuccessful(response)) {
								if (account.blockDevices) addSuccessMessageAction(this.t('unblock_devices_success_msg'));
								else addSuccessMessageAction(this.t('block_devices_success_msg'));
								return this.reloadMainList();
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'block-devices-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 550
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {AccountsListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions">
				{
					pageConfig.enableBlockDevices === true && isSystemAdmin() ?
						<Tooltip
							tag="div"
							title={
								row.blockDevices ? 
									this.t('unblock_devices_tooltip') : 
									this.t('block_devices_tooltip')
							}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								className="action-btn no-border-radius"
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={row.blockDevices ? 'check-circle-o' : 'ban'}
								label={row.blockDevices ? this.t('unblock_devices') : this.t('block_devices')}
								onClick={() => this.blockDevices(row)}
							/>
						</Tooltip>
						: null
				}
				
				<Tooltip
					tag="div"
					title={this.t('edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>

					<div className="action-button">
						<Link to={this.getCreateRedirectTo()} className="button action solid big">
							<Label icon={icon_font_create_symbol} content={this.t('create_new')} />
						</Link>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction
		} = this.props;
		const {limitToAvailableSpace} = this.state;
		const isSysAdmin = isSystemAdmin();
		
		let filterOptions = [
			new SimpleStaticSearchOptionObject(
				'idAccount',
				this.t('idAccountField')
			),
			new SimpleStaticSearchOptionObject(
				'name',
				this.t('nameField')
			),
		];
		// Flag filters are not supported by the API at this time. Uncomment when they become supported.
		// if (isSysAdmin) {
		// 	filterOptions.push(new SimpleStaticSearchOptionObject(
		// 		'autoArchiveStudies',
		// 		this.t('autoArchiveStudiesField'),
		// 		SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
		// 		{
		// 			isClearable: true,
		// 			optionsTranslationPath: 'constants.booleans.yes_no',
		// 		},
		// 		BooleanSelectInput
		// 	));
		// 	filterOptions.push(new SimpleStaticSearchOptionObject(
		// 		'deleteRawFiles',
		// 		this.t('deleteRawFilesField'),
		// 		SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
		// 		{
		// 			isClearable: true,
		// 			optionsTranslationPath: 'constants.booleans.yes_no',
		// 		},
		// 		BooleanSelectInput
		// 	));
		// 	filterOptions.push(new SimpleStaticSearchOptionObject(
		// 		'systemAutoArchive',
		// 		this.t('systemAutoArchiveField'),
		// 		SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
		// 		{
		// 			isClearable: true,
		// 			optionsTranslationPath: 'constants.booleans.yes_no',
		// 		},
		// 		BooleanSelectInput
		// 	))
		// }
		
		let tableColumns = [
			{
				name: 'idAccount',
				sortName: 'IDAccount',
				label: this.t('idAccountField'),
				width: 1,
			},
			{
				name: 'name',
				sortName: 'name',
				label: this.t('nameField')
			},
		];
		if (isSysAdmin) {
			tableColumns.push({
				name: 'autoArchiveStudies',
				sortName: 'autoArchiveStudies',
				label: this.t('autoArchiveStudiesField'),
				dataType: DATA_TABLE_CELL_TYPE.BOOL,
				dataTypeOptions: {
					supportHtml: true,
					alignContent: 'center',
					translatePath: 'constants.booleans.icon_colored',
					trueLabel: 'True',
					falseLabel: 'False',
				},
				width: 1,
			});
			tableColumns.push({
				name: 'deleteRawFiles',
				sortName: 'deleteRawFiles',
				label: this.t('deleteRawFilesField'),
				dataType: DATA_TABLE_CELL_TYPE.BOOL,
				dataTypeOptions: {
					supportHtml: true,
					alignContent: 'center',
					translatePath: 'constants.booleans.icon_colored',
					trueLabel: 'True',
					falseLabel: 'False',
				},
				width: 1,
			});
			tableColumns.push({
				name: 'systemAutoArchive',
				sortName: 'systemAutoArchive',
				label: this.t('systemAutoArchiveField'),
				dataType: DATA_TABLE_CELL_TYPE.BOOL,
				dataTypeOptions: {
					supportHtml: true,
					alignContent: 'center',
					translatePath: 'constants.booleans.icon_colored',
					trueLabel: 'True',
					falseLabel: 'False',
				},
				width: 1,
			});
		}
		tableColumns.push({
			dataType: DATA_TABLE_CELL_TYPE.ANY,
			dataTypeOptions: {
				standardWrapper: false,
				content: this.renderActions,
			},
			stopPropagation: true,
			width: 1,
		});
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={filterOptions}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="main-page-table"
					className="standard sticky-last-column"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={tableColumns}
					onRowClick={data => this.redirectToItem(data.id)}
					data={mainList}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), 'layout-with-table', undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(AccountsPage);
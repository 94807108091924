import React from "react";
import {has, get} from "lodash";

/**
 * Get element or component type from component or instance
 * 
 * @param {any} component - Element, component or instance of element or component
 * @return {*}
 */
export const getComponentType = component => (React.isValidElement(component) ? component.type : component);

/**
 * Get the name of any element or component or and instance of element or component
 * 
 * @param {any} component
 * @return string
 */
export const getComponentName = component => {
	let result = '';
	
	try {
		// Get type from element or component instance
		const type = getComponentType(component);

		// Redux connected component
		if (has(type, 'WrappedComponent')) {
			if (get(type, 'type.name') === 'ConnectFunction') {
				result = get(type, 'WrappedComponent.prototype.constructor.name', '');
			} else {
				result = get(type, 'WrappedComponent.name', '');
			}
		}
		// HTML elements (div, span, ...)
		else if (typeof type !== 'string') {
			result = get(type, 'prototype.constructor.name', '');
		}
		// React components (both class and function)
		else {
			result = type;
		}
	} catch (e) {
		return '';
	}
	
	return result;
};
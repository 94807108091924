import {ioJsonManualAction, ioJsonSaveAction} from "Core/store/actions/io";
import {showLoading, hideLoadingFunction, hideLoading, showPageLoading} from "Core/helpers/loading";
import {getBool} from "Core/helpers/data";
import * as dataMap from "./dataMap/unblockData";
import {isSuccessful} from "Core/helpers/io";

/**
 * Fetch security question for unblocking the IP address
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Unblock IP request ID.
 * @returns {function(*): Promise<UnblockIpDataObject|undefined|null>}
 */
export const fetchUnblockIpSecurityQuestionAction = (abortCallback, id) => dispatch => {
	const loading = showPageLoading(false);
	return ioJsonManualAction(
		abortCallback,
		'authApi',
		'unblock-ip/fetch-request-by-id',
		{id},
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			hideLoading(loading);
			return (isSuccessful(responseData) ? dataMap.input(responseData.data) : null);
		})
		// Just hide the loading overlay since error will be handled on the page itself
		.catch(error => {
			hideLoading(loading);

			if (error.name === 'AbortError') return undefined;
			else return null;
		});
}

/**
 * Submits the answer for unblocking the IP address
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Unblock IP request ID.
 * @param {UnblockIpDataObject} data - Data object containing required information for answer submit.
 * @returns {function(*): Promise<boolean>}
 */
export const submitUnblockIpSecurityAnswerAction = (abortCallback, id, data) => dispatch => {
	const loading = showLoading('#user-unblock-ip-page');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'authApi',
		'unblock-ip/confirm-answer',
		{
			id,
			data: dataMap.output(data)
		},
		undefined,
		false,
		hideLoadingFunction(loading)
	)(dispatch)
		// Return success flag from API
		.then(response => getBool(response, 'success'));
}
import {find, isNumber, sortBy} from "lodash";
import {main_scroll_element, responsive_breakpoints, responsive_default_breakpoints_name} from "Config/app";
import {getNumber} from "Core/helpers/data";
import {spliceStr} from "Core/helpers/string";
import {CSS_UNITS} from "Core/const/global";

/**
 * Programmatically add JS script
 * @note If id is specified and script tag with the same id already exists it won't be added again (nothing will be 
 * changed).
 *
 * @param {string} src - Script source.
 * @param {string} [id] - Script tags' id attribute.
 * @param {boolean} [async=true]
 * @param {boolean} [defer=true]
 */
export const addJs = (src, id, async = true, defer = true) => {
	if(typeof id !== 'undefined' && id){
		if(!document.getElementById(id)){
			let ref = window.document.getElementsByTagName("script")[0];
			let script = window.document.createElement("script");
			script.id = id;
			script.src = src;
			script.async = async;
			script.defer = defer;
			ref.parentNode.insertBefore(script, ref);
		}
	} else {
		let ref = window.document.getElementsByTagName("script")[0];
		let script = window.document.createElement("script");
		script.src = src;
		script.async = async;
		script.defer = defer;
		ref.parentNode.insertBefore(script, ref);
	}
};

/**
 * Set browser favicon
 * @note If favicon DOM element (link) exists only href will be replaced, if not new icon element will be created and
 * added to the DOM.
 *
 * @param {string} href - Href (URL) of the image to use as browser favicon. If not specified, nothing will be changed.
 */
export const setFavicon = (href) => {
	if(href){
		let link = document.querySelector("#favicon") || document.createElement('link');
		link.id = 'favicon';
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = href;
		document.getElementsByTagName('head')[0].appendChild(link);
	}
};

/**
 * Set the browser favicon to the default one
 */
export const resetFavicon = () => {
	setFavicon('/favicon.ico');
};

/**
 * Get the current responsive breakpoint
 * @note Breakpoints are defined in app config.
 * @return {string}
 */
export const calculateCurrentBreakpointName = () => {
	const sortedBreakpoints = sortBy(responsive_breakpoints, ['maxWidth']);
	if (sortedBreakpoints.length) {
		// Handle first breakpoint
		if (window.innerWidth <= sortedBreakpoints[0].maxWidth) return sortedBreakpoints[0].name;
		else if (sortedBreakpoints.length > 1) {
			// Handle last breakpoint
			if (window.innerWidth > sortedBreakpoints[sortedBreakpoints.length - 1].maxWidth) {
				return responsive_default_breakpoints_name;
			}

			// Handle all other breakpoints
			else {
				for (let i = 1; i < sortedBreakpoints.length; i++) {
					if (
						window.innerWidth <= sortedBreakpoints[i].maxWidth &&
						window.innerWidth > sortedBreakpoints[i-1].maxWidth
					) {
						return sortedBreakpoints[i].name;
					}
				}
			}
		}
	}
}

/**
 * Check if a specified breakpoint name is smaller than the reference one
 * @note Breakpoints are defined in the app config under 'responsive_breakpoints' option.  
 * 
 * @param {string} check - Specified breakpoint name to check against the reference one.
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isBreakpointSmaller = (check, reference) => {
	const checkMaxWidth = getNumber(find(responsive_breakpoints, {name: check}), 'maxWidth');
	const referenceMaxWidth = getNumber(find(responsive_breakpoints, {name: reference}), 'maxWidth');
	return checkMaxWidth < referenceMaxWidth;
};

/**
 * Check if current breakpoint is smaller than the reference one
 *
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isCurrentBreakpointSmaller = reference => isBreakpointSmaller(calculateCurrentBreakpointName(), reference);

/**
 * Check if a specified breakpoint name is smaller or equal to the reference one
 * @note Breakpoints are defined in the app config under 'responsive_breakpoints' option.
 *
 * @param {string} check - Specified breakpoint name to check against the reference one.
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isBreakpointSmallerOrEqual = (check, reference) => {
	const checkMaxWidth = getNumber(find(responsive_breakpoints, {name: check}), 'maxWidth');
	const referenceMaxWidth = getNumber(find(responsive_breakpoints, {name: reference}), 'maxWidth');
	return checkMaxWidth <= referenceMaxWidth;
};

/**
 * Check if current breakpoint is smaller or equal to the reference one
 *
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isCurrentBreakpointSmallerOrEqual = reference => 
	isBreakpointSmallerOrEqual(calculateCurrentBreakpointName(), reference);

/**
 * Check if a specified breakpoint name is bigger than the reference one
 * @note Breakpoints are defined in the app config under 'responsive_breakpoints' option.
 *
 * @param {string} check - Specified breakpoint name to check against the reference one.
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isBreakpointBigger = (check, reference) => {
	const checkMaxWidth = getNumber(find(responsive_breakpoints, {name: check}), 'maxWidth');
	const referenceMaxWidth = getNumber(find(responsive_breakpoints, {name: reference}), 'maxWidth');
	return checkMaxWidth > referenceMaxWidth;
};

/**
 * Check if a specified breakpoint name is bigger or equal to the reference one
 * @note Breakpoints are defined in the app config under 'responsive_breakpoints' option.
 *
 * @param {string} check - Specified breakpoint name to check against the reference one.
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isBreakpointBiggerOrEqual = (check, reference) => {
	const checkMaxWidth = getNumber(find(responsive_breakpoints, {name: check}), 'maxWidth');
	const referenceMaxWidth = getNumber(find(responsive_breakpoints, {name: reference}), 'maxWidth');
	return checkMaxWidth >= referenceMaxWidth;
};

/**
 * Check if current breakpoint is bigger than the reference one
 *
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isCurrentBreakpointBigger = reference => isBreakpointBigger(calculateCurrentBreakpointName(), reference);

/**
 * Check if current breakpoint is bigger or equal to the reference one
 *
 * @param {string} reference - Reference breakpoint.
 * @return {boolean}
 */
export const isCurrentBreakpointBiggerOrEqual = reference => 
	isBreakpointBiggerOrEqual(calculateCurrentBreakpointName(), reference);

/**
 * Check if element has a vertical scrollbar
 *
 * @param {HTMLElement|Element} element
 * @return {boolean}
 */
export const vertScrollbarVisible = element => element.scrollHeight > element.clientHeight;

/**
 * Check if element has a horizontal scrollbar
 *
 * @param {HTMLElement|Element} element
 * @return {boolean}
 */
export const horScrollbarVisible = element => element.scrollWidth > element.clientWidth;

/**
 * Get cumulative scroll position of all parent elements
 * @description Goes through all parent elements and adds their scroll position to the result.
 * 
 * @param {HTMLElement|Element} element - Element to calculate cumulative parent scroll position for.
 * @return {{top: number, left: number}}
 */
export const getCumulativeScroll = element => {
	let result = { top: 0, left: 0 };
	if (element) {
		let parent = element.parentNode;
		while (parent) {
			result = { top: result.top + getNumber(parent.scrollTop), left: result.left + getNumber(parent.scrollLeft) };
			parent = parent.parentNode;
		}
	}
	return result;
}

/**
 * Get element's absolute position
 * 
 * @param {HTMLElement|Element} element - Element to get the absolute position for.
 * @param {HTMLElement|Element} [endParent] - Parent element where position calculation will stop.
 * @return {{top: number, left: number}}
 */
export const getElementAbsolutePos = (element, endParent) => {
	let left = 0;
	let top = 0;
	let parent = element;
	
	if (endParent) {
		while (parent !== endParent && parent && !isNaN(parent.offsetLeft) && !isNaN(parent.offsetTop)) {
			left += parent.offsetLeft;
			top += parent.offsetTop;
			parent = parent.offsetParent;
		}
	} else {
		while (parent && !isNaN(parent.offsetLeft) && !isNaN(parent.offsetTop)) {
			left += parent.offsetLeft;
			top += parent.offsetTop;
			parent = parent.offsetParent;
		}
	}
	
	return { top: top, left: left };
}

/**
 * Get viewport width
 * @description Cross-browser @media (width) value.
 *
 * @param {boolean} [includeScrollbar=false] - If true, viewport size will not include horizontal scrollbar.
 * @return {number}
 */
export const getVw = (includeScrollbar = false) => {
	if (includeScrollbar) return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	else return document.documentElement.clientWidth;
};

/**
 * Get viewport height
 * @description Cross-browser @media (height) value.
 *
 * @param {boolean} [includeScrollbar=false] - If true, viewport size will not include vertical scrollbar.
 * @return {number}
 */
export const getVh = (includeScrollbar = false) => {
	if (includeScrollbar) return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
	else return document.documentElement.clientHeight;
};

/**
 * Get viewport size (width and height)
 * @description Cross-browser @media (width) and @media (height) values
 * 
 * @param {boolean} [includeHorizontalScrollbar=false] - If true, viewport size will not include horizontal scrollbar.
 * @param {boolean} [includeVerticalScrollbar=false] - If true, viewport size will not include vertical scrollbar.
 * @return {{width: number, height: number}}
 */
export const getViewportSize = (includeHorizontalScrollbar = false, includeVerticalScrollbar = false) => ({ 
	width: getVw(includeHorizontalScrollbar), 
	height: getVh(includeVerticalScrollbar) 
});

/**
 * Check if element is in viewport
 * 
 * @param {HTMLElement|Element} element - Element to check.
 * @param {boolean} [includeScrollbar=false] - If true, viewport size will not include the scrollbars.
 * @return {boolean}
 */
export const isInViewport = (element, includeScrollbar = false) => {
	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= getVh(includeScrollbar) &&
		rect.right <= getVw(includeScrollbar)
	);
}

/**
 * Get the viewport overflow for position
 * @note If the is no overflow it will be 0.
 * 
 * @param {{top: number, right: number, bottom: number, left: number}} position - Position to check.
 * @param {boolean} [includeScrollbar=false] - If true, viewport size will not include the scrollbars.
 * @return {{top: (number|0), left: (number|0), bottom: (number|0), right: (number|0)}}
 */
export const viewportOverflow = (position, includeScrollbar = false) => {
	const viewport = getViewportSize(includeScrollbar, includeScrollbar);
	return {
		top: (position.top < 0 ? position.top : 0),
		left: (position.left < 0 ? position.left : 0),
		bottom: (position.bottom > viewport.height ? position.bottom - viewport.height : 0),
		right: (position.right > viewport.width ? position.right - viewport.width : 0)
	};
}

/**
 * Get the elements overflow outside the viewport
 * @note If the is no overflow it will be 0.
 * 
 * @param {HTMLElement|Element} element - Element to check.
 * @param {boolean} [includeScrollbar=false] - If true, viewport size will not include the scrollbars.
 * @return {{top: (number|0), left: (number|0), bottom: (number|0), right: (number|0)}}
 */
export const elementViewportOverflow = (element, includeScrollbar = false) => 
	viewportOverflow(element.getBoundingClientRect(), includeScrollbar);

/**
 * Get the overflow outside the bound rectangle for position
 * @note If the is no overflow it will be 0.
 * 
 * @param {{top: number, right: number, bottom: number, left: number}} position - Position to check.
 * @param {DOMRect} boundRect - DOM boundary rectangle (use getBoundingClientRect on any element to ge it).
 * @return {{top: (number|0), left: (number|0), bottom: (number|0), right: (number|0)}}
 */
export const boundsOverflow = (position, boundRect) => ({
	top: (position.top < boundRect.top ? boundRect.top - position.top : 0),
	left: (position.left < boundRect.left ? boundRect.left - position.left : 0),
	bottom: (position.bottom > boundRect.bottom ? position.bottom - boundRect.bottom : 0),
	right: (position.right > boundRect.right ? position.right - boundRect.right : 0)
});

/**
 * Get the elements overflow outside the bound rectangle
 * @note If the is no overflow it will be 0.
 *
 * @param {HTMLElement|Element} element - Element to check.
 * @param {DOMRect} boundRect - DOM boundary rectangle (use getBoundingClientRect on any element to ge it).
 * @param {{[top]: number, [right]: number, [bottom]: number, [left]: number} | null} offset - Element position offset.
 * @return {{top: (number|0), left: (number|0), bottom: (number|0), right: (number|0)}}
 */
export const elementBoundsOverflow = (element, boundRect, offset = null) => {
	const elemRect = element.getBoundingClientRect();
	return boundsOverflow({
		top: elemRect.top + getNumber(offset, 'top'),
		left: elemRect.left + getNumber(offset, 'left'),
		bottom: elemRect.bottom + getNumber(offset, 'bottom'),
		right: elemRect.right + getNumber(offset, 'right'),
	}, boundRect);
};

/**
 * Like 'querySelector' but it only selects a direct child of the target element
 * 
 * @param {HTMLElement|SVGElement|Element} targetElement
 * @param {string} selectors
 * @return {HTMLElement|SVGElement|Element|null}
 */
export const queryDirectChildSelector = (targetElement, selectors) => {
	const element = targetElement.querySelector(selectors);
	return (element && element.parentNode === targetElement ? element : null);
};

/**
 * Like 'querySelectorAll' but it only selects a direct children of the target element
 * instead of NodeList
 *
 * @param {HTMLElement|SVGElement|Element} targetElement
 * @param {string} selectors
 * @return {NodeList}
 */
export const queryDirectChildSelectorAll = (targetElement, selectors) => {
	// Set 'data-query-direct-child-selector-all' attribute for all direct children of the target element
	// @note This is in order to return direct child elements as a NodeList just like 'querySelectorAll'.
	targetElement.querySelectorAll(selectors).forEach(element => {
		if (element.parentNode === targetElement) element.dataset.queryDirectChildSelectorAll = '1';
	});
	
	// Get the NodeList of all direct child elements
	// @note This is done by checking the previously added 'data-query-direct-child-selector-all' attribute.
	const directChildren = targetElement.querySelectorAll("[data-query-direct-child-selector-all='1']");
	// Remove 'data-query-direct-child-selector-all' attribute from child elements.
	directChildren.forEach(directChild => { delete directChild.dataset.queryDirectChildSelectorAll; });
	
	return directChildren;
};

/**
 * Get element that will be used as the app main scrollable wrapper
 * @return {any}
 */
export const getMainScrollElement = () => {
	if (typeof main_scroll_element === 'string') return document.querySelector(main_scroll_element);
	else return main_scroll_element;
};

/**
 * Scroll to the top of the main scrollable wrapper
 * @see 'getMainScrollElement' to determine what main scrollable wrapper is.
 */
export const scrollToTop = () => {
	const mainWrapper = getMainScrollElement();
	if (mainWrapper) mainWrapper.scrollTo(0, 0);
}

/**
 * Scroll to any DOM element defined by the selector
 * 
 * @param {string} selector - CSS selector for the element.
 * @param {boolean} [onlyIfOutside=false] - If true, scroll will occur only if element is outside the current view.
 * @param {number|string} [offset=40] - Number of pixels used as a negative scroll offset relative to the element's 
 * actual position. This is used to scroll a to a few pixels above the actual element for better visibility.
 * @param {string} [scrollElementSelector=''] - Selector for the closest parent element that should be scrolled. If not 
 * specified main scroll element will be used (main scroll element is defined in app config).
 */
export const scrollToSelector = (selector, onlyIfOutside = true, offset = 40, scrollElementSelector = '') => {
	const anchorElem = document.querySelector(selector);
	
	const mainWrapper = (
		scrollElementSelector && anchorElem ? anchorElem.closest(scrollElementSelector) : getMainScrollElement()
	);
	
	if (anchorElem && mainWrapper) {
		mainWrapper.focus();
		
		const currentScroll = mainWrapper.scrollTop || mainWrapper.pageYOffset || 0;
		const scrollTop = anchorElem.offsetTop - parseFloat(offset);
		const mainWrapperHeight = mainWrapper.offsetHeight || mainWrapper.innerHeight;
		
		if (onlyIfOutside) {
			if (currentScroll >= scrollTop || (currentScroll + mainWrapperHeight < scrollTop)) {
				mainWrapper.scrollTo(0, scrollTop);
			}
		} else {
			mainWrapper.scrollTo(0, scrollTop);
		}
	}
};

/**
 * Scroll to any DOM element
 *
 * @param {Element} element - DOM element.
 * @param {boolean} [onlyIfOutside=false] - If true, scroll will occur only if element is outside the current view.
 * @param {number|string} [offset=40] - Number of pixels used as a negative scroll offset relative to the element's
 * actual position. This is used to scroll a to a few pixels above the actual element for better visibility.
 * @param {string} [scrollElementSelector=''] - Selector for the closest parent element that should be scrolled. If not
 * specified main scroll element will be used (main scroll element is defined in app config).
 */
export const scrollToElement = (element, onlyIfOutside = true, offset = 40, scrollElementSelector = '') => {
	const mainWrapper = (
		scrollElementSelector && element ? element.closest(scrollElementSelector) : getMainScrollElement()
	);

	if (element && mainWrapper) {
		mainWrapper.focus();

		const currentScroll = mainWrapper.scrollTop || mainWrapper.pageYOffset || 0;
		const scrollTop = element.offsetTop - parseFloat(offset);
		const mainWrapperHeight = mainWrapper.offsetHeight || mainWrapper.innerHeight;

		if (onlyIfOutside) {
			if (currentScroll >= scrollTop || (currentScroll + mainWrapperHeight < scrollTop)) {
				mainWrapper.scrollTo(0, scrollTop);
			}
		} else {
			mainWrapper.scrollTo(0, scrollTop);
		}
	}
}

/**
 * Focus to a DOM element defined by the selector
 * @param {string} selector - CSS selector for the element.
 */
export const focusSelector = (selector) => {
	const element = document.querySelector(selector);
	if (element) element.focus();
};

/**
 * Check if two DOM elements are siblings
 * @note Check is performed by checking if the parent node of both elements is the same.
 * 
 * @param {Element|HTMLElement|EventTarget} element1
 * @param {Element|HTMLElement|EventTarget} element2
 * @return {boolean}
 */
export const areElementsSiblings = (element1, element2) => {
	// Return false if elements don't exist
	if (!element1 || !element2) return false;
	
	// Get parent elements
	const parent1 = element1.parentElement;
	const parent2 = element2.parentElement;
	
	// If both elements don't have a parent element than both elements are root elements, so they are siblings
	if (!parent1 && !parent2) return true;

	// If only one of the parent elements is missing than elements are not siblings because one of them is a root element
	if (!parent1 || !parent2) return false;
	
	// Check if parents are the same and return true if they are
	return (parent1 === parent2);
}

/**
 * Delete selected portion of the input element value
 * @note This function will change the input element value.
 * @param {HTMLInputElement|EventTarget} inputElem
 */
export const deleteInputSelection = inputElem => {
	if (inputElem && inputElem.selectionStart !== inputElem.selectionEnd) {
		const currentValue = inputElem.value;
		inputElem.value = currentValue.slice(0, inputElem.selectionStart) + currentValue.slice(inputElem.selectionEnd);
	}
}

/**
 * Set cursor positron inside an input element
 * @note If input element has a selection, it will be cleared.
 * 
 * @param {HTMLInputElement|EventTarget} inputElem
 * @param {number} [position=0] - Cursor position (index starting from 0).
 */
export const setInputCursorPos = (inputElem, position = 0) => {
	inputElem.selectionStart = position;
	inputElem.selectionEnd = position;
}

/**
 * Insert value into an input element
 * @note Value will be inserted ate the current cursor position replacing any selected values. If input is not focused 
 * it will insert the value at the end of the current value. This function will change the input element value.
 *
 * @param {HTMLInputElement|EventTarget} inputElem
 * @param {string} value - Value to insert.
 */
export const inputInsertValue = (inputElem, value) => {
	// Get insert position
	// @note It will default to the end of the value. 
	const insertPos = inputElem.selectionStart || inputElem.value.length;
	
	// Delete selection if any	
	deleteInputSelection(inputElem);
	
	// Insert the value
	inputElem.value = spliceStr(inputElem.value, insertPos, 0, value);
	
	// Move cursor next to the inserted value
	setInputCursorPos(inputElem, insertPos + 1);
}

/**
 * Get CSS size with units based in value
 * @param {any} value - Value to get the size for.
 * @return {string} Parsed CSS size string with units, empty string.
 */
export const getCssSizeString = value => {
	// If value is a string
	if (typeof value === 'string') {
		// If value is a string ending with a CSS unit (for example: '10px' or '1rem') just return it
		if (CSS_UNITS.some(i => value.endsWith(i))) return value;
		
		// Try to parse the string as a number
		const valueNumber = parseFloat(value);
		
		// If value cannot be parsed as a number just return it since it probably an error that is outside the scope of 
		// this function
		if (isNaN(valueNumber)) return value;
		// If value can be parsed as a number treat it as a pixel value 
		else return `${valueNumber}px`;
	} 
	// If value is a number treat it as a pixel value
	else if (isNumber(value)) {
		return `${value}px`;
	}
	return '';
}

/**
 * Calculate the max. number of items pre grid row
 * @description When you have a grid with a dynamic number of items, and you want to find a good way of splitting the
 * items into multiple columns this function can help. It will calculate the number of items you can use per row (number
 * of grid columns) based on the total number of items and max. items per row.
 * @example
 * 	style={{
 * 		gridTemplateColumns: `repeat(${calculateSmartGridColumns(7, 5)}, 1fr)`
 * 	}}
 *
 * @param {number} items - Total number of items.
 * @param {number} [maxPerRow=5] - Max. number of items per row.
 * @return {number}
 */
export const calculateSmartGridColumns = (items, maxPerRow = 5) => {
	if (items <= maxPerRow) return items;
	else return Math.ceil(items / Math.ceil(items / maxPerRow));
}
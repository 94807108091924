import {cloneDeep} from "lodash";
import {getBoolean, getString, resetObjectValues} from "Core/helpers/data";
import {AccountsItemRoleDataObject} from '../dataObjects'
import {v4} from 'uuid';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AccountsItemRoleDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountsItemRoleDataObject(
		getString(v4()),
		getString(result, 'accountId'),
		getString(result, 'id'),
		getString(result, 'userAccountId'),
		getString(result, 'roleId'),
		getBoolean(result, 'forAllAccounts')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AccountsItemRoleDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		userAccountId: result.userAccountId,
		roleId: result.roleId,
		accountId: result.accountId,
		forAllAccounts: result.forAllAccounts,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
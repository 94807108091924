import styles from "./index.module.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {cloneDeep, get} from "lodash";
import auth from "../../../auth";
import * as pageConfig from "./config";
import {
	app_id,
	app_password_reset_request_page_router_path,
	auth_api,
	auth_api_endpoints, auth_broadcast_channel, auth_broadcast_message_login,
	auth_code_challenge_method
} from "Config/index";
import {getIOUrl} from "Core/io/helper";
import {encodeURLParams} from "Core/helpers/string";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import {getGlobalActions} from "Core/helpers/redux";
import {getString} from "Core/helpers/data";
import {appHasLogin, isLoginWithoutRedirect, isLoginWithRedirect} from "Core/helpers/login";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import Login from "Core/components/advanced/Login";
import AppName from "Core/components/display/AppName";
import {AsyncMountError} from "Core/errors";

/**
 * This page handles user login
 * @note It supports both direct login and redirect to authorization API by using the 'directLogin' option. 
 * Authorization API is usually used when single-sign-on (SSO) is used to authorize users.
 */
class CoreLoginPage extends PageComponent {
	/**
	 * Auth broadcast channel instance
	 * @type {BroadcastChannel}
	 */
	authBroadcastChannel = null
	
	constructor(props, options = {}) {
		super(props, {
			layout: 'auth',
			routerPath: pageConfig.routerPath,
			domPrefix: 'login-page',
			translationPath: pageConfig.translationPath,
			
			/**
			 * If true, login form will be rendered if. Otherwise, login button that redirects to the authorization API 
			 * (OAuth 2) will be rendered instead.
			 */
			directLogin: isLoginWithoutRedirect(),

			...cloneDeep(options)
		});

		this.setBrowserTitle('User login');
		
		// Login form methods
		this.login = this.login.bind(this);
		this.renderLoginForm = this.renderLoginForm.bind(this);
		
		// Login button methods
		this.redirectToAuthorizeUrl = this.redirectToAuthorizeUrl.bind(this);
		this.renderLoginButton = this.renderLoginButton.bind(this);
	}
	
	componentDidMount() {
		super.componentDidMount();
		
		// Listen to log in broadcast message and redirect to home page
		this.authBroadcastChannel = new BroadcastChannel(auth_broadcast_channel);
		this.authBroadcastChannel.onmessage = event => {
			if (getString(event, 'data') === auth_broadcast_message_login) this.redirectToHome();
		}
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();

		// Close user broadcast channel that was open when component mounted
		if (this.authBroadcastChannel) this.authBroadcastChannel.close();
	}


	// ACL methods ------------------------------------------------------------------------------------------------------
	/**
	 * Check if user is logged in by calling an auth ping API
	 * @note This is done on each individual section app page because page components (child components) are mounted
	 * before app and section components (parent components) and we need to display the loading overlay as soon as
	 * possible.
	 *
	 * @return {Promise<void>}
	 * @throws {AsyncMountError}
	 */
	checkLogin() {
		if (appHasLogin()) {
			// If there are no auth tokens present, user is not logged in
			if (!auth.hasTokens()) {
				// Do nothing and return a resolved promise
				return Promise.resolve();
			}
			
			const loading = (
				this.getOption('checkLoginLoadingFunction') ?
					this.getOption('checkLoginLoadingFunction')() :
					showPageLoading(true, true)
			);
			
			// Check the login by using the authorized ping request
			return this.executeAbortableAction(auth.authorizedPing, true, undefined, undefined, auth)
				// Redirect to home page (defined in app config) if user is already logged in
				// @note This is added in case someone opens this page directly.
				.then(() => this.redirectToHome())
				.then(() => { if (loading) hideLoading(loading); })
				.catch(error => {
					if (loading) hideLoading(loading);
					
					// If ping fails even after trying to refresh tokens, delete auth related tokens from storage
					// @note Logout was not called intentionally because we don't know which app specific tokens will be 
					// deleted on logout, so we cannot be sure that all of them should be deleted at this point. What we are 
					// sure is that auth related tokens should be deleted and that's exactly what we are doing.
					if (error.name !== 'AbortError') return auth.deleteAuthTokens();
					else throw new AsyncMountError('Login check aborted!');
				});
		} else {
			// Redirect to home page (defined in app config) if login in not enabled
			// @note This is added in case someone opens this page directly.
			this.redirectToHome();
		}
	}


	// Login form methods -----------------------------------------------------------------------------------------------
	/**
	 * Login user with credentials from login form
	 * @param {string} username - Username from login form.
	 * @param {string} password - Password from login form.
	 */
	login(username, password) {
		const {showErrorMessageAction} = this.props;

		const loading = showPageLoading();
		this.executeAbortableAction(auth.login, username, password, '')
			// Store newly created tokens
			.then(response => {
				if (response) {
					auth.storeAccessToken(get(response, 'data.access_token'));
					auth.storeRefreshToken(get(response, 'data.refresh_token'));
				}
				return response;
			})

			// Call security init API to handle all server-side generated cookies
			.then(response => (response ? this.executeAbortableAction(auth.securityInit) : response))

			// Try to get the authorization code with the newly created access token if login type uses redirects
			// @note If it is successful, user will be redirected to the app, otherwise login form will be shown.
			.then(() => {
				if (isLoginWithRedirect()) {
					return this.executeAbortableAction(auth.generateAuthorizationCode, auth.getAccessToken())
				}
				return Promise.resolve();
			})
			
			// Go to home page if login was successful and login type does not use redirects
			.then(() => {
				if (isLoginWithoutRedirect()) {
					hideLoading(loading);

					// Broadcast log in so that all other opened tabs that need to will redirect to home page
					const bChannel = new BroadcastChannel(auth_broadcast_channel);
					bChannel.postMessage(auth_broadcast_message_login);
					bChannel.close();
				}
			})

			.catch(error => {
				hideLoading(loading);
				showErrorMessageAction(error.message); 
			});
	}

	/**
	 * Render login form
	 * @return {JSX.Element|null}
	 */
	renderLoginForm() {
		return this.renderLayout((
			<Login
				styleName="card"
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}
				showAppName={true}
				appName={<AppName />}
				loginAction={this.login}
				changePasswordAction={() => this.redirectTo(app_password_reset_request_page_router_path)}
			/>
		), undefined, undefined, {
			showHeader: false,
			footerJustifyContent: 'center',
		});
	}


	// Login button methods ---------------------------------------------------------------------------------------------
	/**
	 * Redirect to authorize API
	 */
	redirectToAuthorizeUrl() {
		const codeVerifier = auth.generateCodeVerifier();
		auth.storeCodeVerifier(codeVerifier);
		const codeChallenge = auth.calculateCodeChallenge(codeVerifier);
		const state = auth.generateState();
		auth.storeState(state);

		const params = {
			...auth_api_endpoints.authorize.params,
			client_id: app_id,
			redirect_uri: auth.getRedirectUri(),
			code_challenge: codeChallenge,
			code_challenge_method: auth_code_challenge_method,
			state
		};
		
		// Get API to use
		const api = (
			getString(auth_api_endpoints.authorize, 'api') ?
				auth_api_endpoints.authorize.api :
				auth_api
		);
		
		window.location.href = `${getIOUrl(api, auth_api_endpoints.authorize.path)}?${encodeURLParams(params)}`;
	}

	/**
	 * Render login button that will redirect to the authentication page
	 * @return {JSX.Element|null}
	 */
	renderLoginButton() {
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<Button
					className={`redirect-login-btn ${styles['redirectLogin']}`}
					displayStyle={BUTTON_STYLE.ACTION}
					big={true}
					label={this.t('Login')}
					icon="unlock-alt"
					onClick={() => this.redirectToAuthorizeUrl()}
				/>
			</div>
		));
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		return (this.getOption('directLogin') ? this.renderLoginForm() : this.renderLoginButton());
	}
}

/**
 * Export component itself (not the connected HOC) so it can be easily extended
 * @type {CoreLoginPage}
 */
export const CoreLoginPageComponent = CoreLoginPage;
export default connect(null, getGlobalActions())(CoreLoginPage);
import {cloneDeep} from "lodash";
import {getString, getBool} from "Core/helpers/data";
import {UsersListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {UsersListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new UsersListItemDataObject(
		getString(result, 'id'),
		getString(result, 'userName'),
		getString(result, 'userType'),
		getString(result, 'contactData.firstName'),
		getString(result, 'contactData.lastName'),
		getString(result, 'contactData.email'),
		getBool(result, 'activated'),
		getBool(result, 'contactData.enabled'),
		getBool(result, 'contactData.acceptedTerms'),
		getBool(result, 'contactData.consentGDPR'),
		getString(result, 'contactData.defaultAccountDisplayName'),
		getBool(result, 'psg2'),
	);

	return result;
}

// @note There is no output function since list is read only.
import styles from './index.module.css'

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import Label from "Core/components/display/Label";
import PasswordReset from "Core/components/advanced/PasswordReset";
import ChangeSecurityQuestion from "./components/ChangeSecurityQuestion";
import PersonalData from "./components/PersonalData";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {isSuccessful} from "Core/helpers/io";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import ACL from "../../../../acl";

const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});
	
class ProfilePage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {}
		}, {
			domPrefix: 'profile-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
		}, 'page_title');

		// Action methods
		this.changePassword = this.changePassword.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Change account password
	 */
	changePassword(newPassword, repeatedPassword, currentPassword) {
		const {changePasswordAction, addSuccessMessageAction} = this.props;		

		return this.executeAbortableAction(changePasswordAction, currentPassword, newPassword, repeatedPassword)
			// Set 'success' flag depending on the password reset action result
			.then(response => {
				if (isSuccessful(response)){
					addSuccessMessageAction(this.t('change_password_success_msg'));	
				}				
			});
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className={`simple-page-description ${styles['description']}`}>
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<div className="card">
					<div className="card-header">
						<Label 
							icon="vcard-o"
							iconClassName="card-header-icon"
							content={this.t('change_data_section')}
						/>
					</div>
					<div className="card-content">
						<PersonalData buttonProps={{className: styles['save-button']}}  />
					</div>
				</div>
				<br />
				<div className="card">
					<div className="card-header">
						<Label
							icon="question-circle-o"
							iconClassName="card-header-icon"
							content={this.t('change_question_section')}
						/>
					</div>
					<div className="card-content">
						<ChangeSecurityQuestion buttonProps={{className: styles['save-button']}} />
					</div>
				</div>
				<br />
				<div className="card">
					<div className="card-header">
						<Label
							icon="lock"
							iconClassName="card-header-icon"
							content={this.t('change_password_section')}
						/>
					</div>
					<div className="card-content">
						<PasswordReset
							showFormTitle={false}
							showFormDesc={false}
							showEnterCurrentPassword={true}
							inputLabelPosition={FORM_FIELD_LABEL_POSITION.ALIGNED}
							passwordInstructions={this.t('password_instructions', 'App')}
							action={this.changePassword}
							buttonProps={{
								className: `${styles['submit-button']} ${styles['save-button']}`,
								disabled: ACL.isGuest(ACL),
							}}
							className={styles['reset-password']}
						/>
					</div>
				</div>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(ProfilePage);
import React from "react";
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from "../../DialogComponent";
import PropTypes from "prop-types";
import Label from "../../display/Label";
import {getBoolean} from "../../../helpers/data";

/**
 * Confirm dialog component
 */
class ConfirmDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'ConfirmDialog',
			alignContent: 'center',
			dialogType: DIALOG_TYPE.CONFIRM,
		});
	}

	/**
	 * Dialog no method
	 * @note This method should be called when dialog's no button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 * Overrider to close the dialog by default if 'onNo' event function is not specified in props.
	 */
	no() {
		const {dialogGUIID, onNo} = this.props;
		
		// Trigger component's 'onNo' event if it is defined
		if (typeof onNo === 'function') executeComponentCallback(this.props.onNo, dialogGUIID);
		// Close the dialog by default if 'onNo' event is not defined
		else this.close();
	}

	render() {
		const message = (this.props.message ? this.props.message : this.t('default_message'));
		return this.renderDialog(
			this.renderHelpTitle(''),
			<Label content={message} supportHtml={getBoolean(this.props, 'supportHtml')} />
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ConfirmDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Confirm message string
	// @note This should already be translated.
	message: PropTypes.string,
	// Set to true to support HTML in 'message' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	supportHtml: PropTypes.bool,

	// Events
	onClose: PropTypes.func,
	onYes: PropTypes.func,
	onNo: PropTypes.func,
};

export default ConfirmDialog;
import {cloneDeep} from "lodash";
import {getBool, getString} from "Core/helpers/data";
import {AccountsListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AccountsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AccountsListItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
		getString(result, 'idAccount'),
		getBool(result, 'blockDevices'),
		getBool(result, 'autoArchiveStudies'),
		getBool(result, 'deleteRawFiles'),
		getBool(result, 'systemAutoArchive'),
	);

	return result;
}

// @note There is no output function since list is read only.
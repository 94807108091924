import styles from "./index.module.css";

import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {teamviewer_mac_link, teamviewer_pc_link} from "Config/app";
import teamViewerLogo from "Images/teamviewer.png";
import Label from "Core/components/display/Label";
import PropTypes from "prop-types";

class TeamViewerSupport extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'TeamViewerSupport',
			domPrefix: 'team-viewer-support-component'
		});
	}

	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }
	
	render() {
		const {className, itemWrapperClassName, itemClassName} = this.props;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${className}`}>
				<div className={`${itemWrapperClassName} ${styles['itemWrapper']}`}>
					<a
						className={`${itemClassName} ${styles['item']}`}
						href={teamviewer_mac_link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={teamViewerLogo} alt="" />
						<div>
							<Label element="span" content={this.t('support_label')} />
							<Label
								element="span"
								elementProps={{className: styles['mobile']}}
								content={this.t('support_label_mobile')}
							/>

							<Label element="small" content={this.t('support_mac')} />
							<Label
								element="small"
								elementProps={{className: styles['mobile']}}
								content={this.t('support_mac_mobile')}
							/>
						</div>
					</a>
				</div>
				<div className={`${itemWrapperClassName} ${styles['itemWrapper']}`}>
					<a
						className={`${itemClassName} ${styles['item']}`}
						href={teamviewer_pc_link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={teamViewerLogo} alt="" />
						<div>
							<Label element="span" content={this.t('support_label')} />
							<Label
								element="span"
								elementProps={{className: styles['mobile']}}
								content={this.t('support_label_mobile')}
							/>

							<Label element="small" content={this.t('support_pc')} />
							<Label
								element="small"
								elementProps={{className: styles['mobile']}}
								content={this.t('support_pc_mobile')}
							/>
						</div>
					</a>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TeamViewerSupport.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	itemWrapperClassName: PropTypes.string,
	itemClassName: PropTypes.string,
};

/**
 * Define component default values for own props
 */
TeamViewerSupport.defaultProps = {
	id: '',
	className: '',
	itemWrapperClassName: '',
	itemClassName: 'button solid card',
};

export default TeamViewerSupport;
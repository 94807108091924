/**
 * Data object representing the activity log list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class ActivityLogListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} type - Activity log type.
	 * @param {string} message - Activity log message.
	 * @param {string} module - Activity log module.
	 * @param {string} userName - Username.
	 * @param {string} status - Activity log status.
	 * @param {Date} creationDate - Activity log creation date.
	 * @param {Date} sessionStarted - Session started date and time.
	 * @param {Date} sessionUpdated - Session updated date and time.
	 */
	constructor(id, type, message, module, userName, status, creationDate, sessionStarted, sessionUpdated) {
		this.id = id;
		this.type = type;
		this.message = message;
		this.module = module;
		this.userName = userName;
		this.status = status;
		this.creationDate = creationDate;
		this.sessionStarted = sessionStarted;
		this.sessionUpdated = sessionUpdated;
	}
}
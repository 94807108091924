// Import items
import ExportStudyMetricsItem from "./items/ExportStudyMetrics";
import ComparisonItem from "./items/Comparison";
import ToArchiveItem from "./items/ToArchive";
import ToAvailableItem from "./items/ToAvailable";

import React from "react";
import PropTypes from "prop-types";
import {getComponentName} from "Core/helpers/component";

// Define the list of all dynamic toolbar items
// @note This is the list of all imported toolbar item components in order in which they should appear in the toolbar.
const ITEMS = [
	ExportStudyMetricsItem, ComparisonItem, ToArchiveItem, ToAvailableItem,
];

function MainTableDynamicToolbar({maxSelection, studiesTableRef, reloadStudies}) {
	return ITEMS.map(Item => 
		<Item 
			key={getComponentName(Item)} 
			maxSelection={maxSelection} 
			studiesTableRef={studiesTableRef}
			reloadStudies={reloadStudies}
		/>
	); 
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainTableDynamicToolbar.propTypes = {
	// Max. number of selected studies
	maxSelection: PropTypes.number,
	// Studies table ref
	studiesTableRef: PropTypes.object,
	// Function that reloads the studies table
	// @note Calling this function is the same as clicking on the reload data button in the top right corner.
	// @return {Promise<*>}
	reloadStudies: PropTypes.func, // No arguments;
};

export default MainTableDynamicToolbar;
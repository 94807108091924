import styles from "../TextInput/index.module.css";

import React from "react";
import PropTypes from "prop-types";
import TextInput, {executeComponentCallback, TEXT_INPUT_TOOLBAR_POSITION} from "../TextInput";
import MaskInput from "react-maskinput";
// @note This is a "react-maskinput" dependency added as a fix for React 18 since the component was not updated to 
// support React 18.
import {defaults} from "input-core";
import {Tooltip} from "react-tippy";
import Icon from "../../display/Icon";
import {icon_font_error_symbol} from "Config/app";
import {getString} from "Core/helpers/data";
import Button from "../../display/Button";
import {omit} from "lodash";

/**
 * Formatted text input component
 * @description Text input component that supports formatting.
 * @note This component uses internal state to handle keypress updates re-rendering only itself until used hits the
 * 'Enter' key or textarea (inputRef) looses focus. This fixes one of the main performance issues in React forms or
 * pages with lost of input fields where each keypress rerender the whole form or page.
 */
class MaskedTextInput extends TextInput {
	constructor(props, data, options) {
		super(props, {
			...data
		}, {
			translationPath: 'MaskedTextInput',
			domPrefix: 'masked-text-input-component',
			dataPropAlias: 'value',
			enableLoadOnDataPropChange: true,

			...options
		});
	}

	
	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Trigger component's onChange event with component's main data as param
	 *
	 * @param {Event|SyntheticEvent} event - JavaScript change event.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) {
		// Insert value
		const hasInsertValue = (typeof event.target.value === 'string' && this.containsInsertValue(event.target.value));
		if (hasInsertValue) return super.update(event);
		
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();

		const delimiter = getString(this.props, 'options.delimiter');
		
		// Update preview
		return this.updatePreview().then(() => {
			// Trigger onChange event
			executeComponentCallback(this.props.onChange, getString(event, 'target.value').split(delimiter).join(''));
		});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render input DOM element
	 * @return {JSX.Element}
	 */
	renderInput() {
		const {
			className, name, placeholder, readOnly, disabled, formControlStyle, isFast, errors, autoComplete, options,
			inputToolbarButtons, showInsertValueButton, showClearButton
		} = this.props;
		const value = this.getData();
		const inputToolbarButtonsL = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.LEFT);
		const inputToolbarButtonsR = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.RIGHT);
		const hasInsertValue = (typeof value === 'string' && this.containsInsertValue(value));
		
		return (
			<div className={`text-input-wrapper ${styles['inputWrapper']}`}>
				<div className={`input-toolbar ${styles['input-toolbar']} position-left ${styles[`position-left`]}`}>
					{inputToolbarButtonsL.map(btnProps =>
						<Button
							{...omit(btnProps, ['className', 'onClick'])}
							className={
								`${getString(btnProps, 'className')}` +
								` input-toolbar-button ${styles['input-toolbar-button']}`
							}
							onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
						/>
					)}
					{!readOnly && !disabled && showInsertValueButton ? this.renderInsertButton() : null}
				</div>

				{
					hasInsertValue ?
						<input type="text"
							id={this.getDomId()}
							className={
								`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
								`${styles['input']} insert-value-input`
							}
							name={name}
							value={value}
							onInput={!readOnly && !isFast ? super.handleInputChange.bind(this) : null}
							onChange={!readOnly ? super.handleInputChange.bind(this) : null}
							onBlur={!readOnly && isFast ? super.handleInputBlur.bind(this) : null}
							onFocus={super.handleInputFocus.bind(this)}
							onKeyPress={!readOnly ? super.handleKeyPress.bind(this) : null}
							onKeyDown={!readOnly ? super.handleKeyDown.bind(this) : null}
							onKeyUp={!readOnly ? super.handleInputKeyUp.bind(this) : null}
							onPaste={!readOnly ? super.handleInputPaste.bind(this) : null}
							onClick={super.handleInputClick.bind(this)}
							disabled={disabled}
							placeholder={placeholder}
							readOnly={readOnly}
							autoComplete={autoComplete}
							ref={node => this.inputRef = node}
						/>
						:
						<MaskInput
							id={this.getDomId()}
							className={
								`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
								`${styles['input']}`
							}
							name={name}
							value={value}
							onInput={!readOnly && !isFast ? this.handleInputChange : null}
							onChange={!readOnly ? this.handleInputChange : null}
							onBlur={!readOnly && isFast ? this.update : null}
							onFocus={this.handleInputFocus}
							onKeyPress={!readOnly ? this.handleKeyPress : null}
							onKeyDown={!readOnly ? this.handleKeyDown : null}
							onKeyUp={!readOnly ? this.handleInputKeyUp : null}
							onPaste={!readOnly ? this.handleInputPaste : null}
							onClick={this.handleInputClick}
							disabled={disabled}
							placeholder={placeholder}
							readOnly={readOnly}
							autoComplete={autoComplete}
							getReference={node => this.inputRef = node}
							{...MaskedTextInput.defaultProps.options}
							{...options}
						/>
				}
				
				{
					errors.length ?
						<Tooltip
							className={styles['errorIcon']}
							tag="span"
							title={errors.join("<br />")}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
							supportHtml={true}
						>
							<Icon symbol={icon_font_error_symbol} />
						</Tooltip>
						: null
				}

				<div
					className={`input-toolbar ${styles['input-toolbar']} position-right ${styles[`position-right`]}`}
				>
					{inputToolbarButtonsR.map(btnProps =>
						<Button
							{...omit(btnProps, ['className', 'onClick'])}
							className={
								`${getString(btnProps, 'className')}` +
								` input-toolbar-button ${styles['input-toolbar-button']}`
							}
							onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
						/>
					)}
					{!readOnly && !disabled && showClearButton ? this.renderClearButton() : null}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MaskedTextInput.propTypes = {
	// This component uses masked-input component
	// @link https://xnimorz.github.io/masked-input/#section-react-maskinput
	
	options: PropTypes.shape({
		// Maks to use
		//  	0 — any number 0-9
		//    * — any symbol
		//    a — A-Z, a-z
		//    q — "q" letter, 2 — "2" letter etc.
		//    \a — "a" letter
		mask: PropTypes.string,
		// Character to cover unfilled editable parts of mask
		// @note If 'maskString' define 'maskChar' ignored.
		maskChar: PropTypes.string,
		// Defines custom mask by passing
		maskFormat: PropTypes.arrayOf(PropTypes.shape({
			str: PropTypes.string,
			regexp: PropTypes.any,
			type: PropTypes.any,
		})),
		// String to cover unfilled editable parts of mask
		// @note If 'maskString' define 'maskChar' ignored.
		maskString: PropTypes.string,
		// If you want to use custom reformat logic. It's useful for numeric inputs, decimal numbers, emails, etc. If 
		// reformat defined mask will be ignored.
		reformat: PropTypes.func,
		// You can use defaultValue to set up component as uncontrolled input
		defaultValue: PropTypes.string,
		// Show mask when input inactive
		alwaysShowMask: PropTypes.bool,
		// Show mask in input. It's possible only if mask have not cyclic.
		showMask: PropTypes.bool,
		// By default, starting hardcoded chars will be hidden. Use showStartChars prop to change the behavior
		showStartChars: PropTypes.bool,
		// Delimiter that will be removed from the result value
		delimiter: PropTypes.string,
		// Event fired when value has been changed
		onValueChange: PropTypes.func, // Arguments: value and maskedValue.
	}),
	
	onChange: PropTypes.func, // Arguments: {string} new input value
	
	...TextInput.propTypes
};

/**
 * Define component default values for own props
 */
MaskedTextInput.defaultProps = {
	options: {
		maskChar: '',
		alwaysShowMask: true,
		showMask: true,
		delimiter: defaults.delimiter,
		maskFormat: defaults.maskFormat,
	},
	
	...TextInput.defaultProps
};

export default MaskedTextInput;
export * from "../TextInput/const";
export {executeComponentCallback} from "../../DataComponent";
export {default as ColorPreview} from "../TextInput/preview/Color";
export {default as PasswordStrengthPreview} from "../TextInput/preview/PasswordStrength";
export {default as DatePreview} from "../TextInput/preview/Date";
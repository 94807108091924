export class FileInputFileDataObject {
	/**
	 * @param {string} [name=''] - File name
	 * @param {Element|JSX.Element} [elem=null] - Element to render for the file. Usually a label, link or a download 
	 * button.
	 */
	constructor(name = '', elem = null) {
		this.name = name;
		this.elem = elem;
	}
}

export class FileInputImgPreviewDataObject {
	/**
	 * @param {string} [name=''] - Image name (can be filename).
	 * @param {Element|JSX.Element} [img=null] - Image element to render as preview.
	 */
	constructor(name = '', img = null) {
		this.name = name;
		this.img = img;
	}
}
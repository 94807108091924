import {cloneDeep} from 'lodash';
import {getString, resetObjectValues} from 'Core/helpers/data';
import {PersonalDataDataObject} from '../dataObjects'

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PersonalDataDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new PersonalDataDataObject(
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'email'),
		getString(result, 'credentials')
	);
	
	return result;
}

/**
 * Get output data from input data
 * @param {Object} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	result = {
		firstName: result.firstName,
		middleName: result.middleName,
		lastName: result.lastName,
		email: result.email,
		credentials: result.credentials
	}
	
	// Set proper empty values expected by the API
	result = resetObjectValues(result)
	
	return result;
}
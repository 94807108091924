import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import DataValueValidation, {ValidationRuleObject} from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import PropTypes from "prop-types";
import {cloneDeep, omit} from "lodash";
import {icon_font_save_symbol} from "Config/app";
import {getString, isset} from "Core/helpers/data";
import * as pageConfig from "../../config";
import * as actions from './actions'
import ACL from "../../../../../../acl";
import {AsyncMountError} from "Core/errors";
import {PersonalDataDataObject} from "./dataObjects";
import auth from "../../../../../../auth";

class PersonalData extends DataComponent {
	constructor(props) {
		super(props, {
			data: new PersonalDataDataObject(),
		}, {
			translationPath: `${pageConfig.translationPath}.Components.PersonalData`,
			disableLoad: false
		});

		// Constants
		this.nameFieldsForbiddenChars = ['%', '/', '\\', ':', '?', '<', '>', '|', '#', '(', ')'];
		
		// Action methods
		this.saveData = this.saveData.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		const {fetchPersonalDataAction} = this.props;
		
		// If this method was not called by the override method from the child class
		if (!override) {
			await super.asyncComponentDidMount(true)
				.then(() => this.executeAbortableAction(fetchPersonalDataAction))
				.then(response => {
					if (!isset(response)) throw new AsyncMountError();
					else return response;
				})
				.then(responseData => this.setData(responseData));
		}
		// If this method was called by the override method from the child class
		else {
			await super.asyncComponentDidMount(override);
		}

		return Promise.resolve();
	}

	// DataValueValidation methods --------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		// Add validation rules
		dataValidation.addRule('firstName',
			'required',
			new ValidationRuleObject(
				'mustNotContainStrings',
				{values: this.nameFieldsForbiddenChars}
			)
		);
		dataValidation.addRule('middleName',
			new ValidationRuleObject(
				'mustNotContainStrings',
				{values: this.nameFieldsForbiddenChars}
			)
		);
		dataValidation.addRule('lastName',
			'required',
			new ValidationRuleObject(
				'mustNotContainStrings',
				{values: this.nameFieldsForbiddenChars}
			)
		);
		dataValidation.addRule('credentials',
			new ValidationRuleObject(
				'mustNotContainStrings',
				{values: this.nameFieldsForbiddenChars}
			)
		);
		dataValidation.addRule('email', 'required', 'email');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save personal info
	 */
	saveData() {
		const {changePersonalDataAction, addSuccessMessageAction} = this.props;
		/** @type {PersonalDataDataObject} */
		const data = this.state.data;

		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => {
				if (this.validate()) {
					return this.executeAbortableAction(changePersonalDataAction, data)
						// Set 'success' flag depending on the password reset action result
						.then(response => {
							if (isset(response)){
								addSuccessMessageAction(this.t('change_personal_data_success_msg'));
								
								// Update current user data in auth
								/** @type {CurrentUserDataObject} */
								let currentUser = cloneDeep(auth.getCurrentUser());
								currentUser.firstName = data.firstName;
								currentUser.lastName = data.lastName;
								currentUser.credentials = data.credentials;
								auth.storeCurrentUser(currentUser);
							}
						});
				} else {
					return Promise.resolve();
				}
			});
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {styleName, className, buttonProps, renderToTop} = this.props;

		return(
			<div 
				id={this.getDomId()} 
				className={`profile-personal-data password-reset-component ${styleName}-style ${className}`}
			>
				{renderToTop ? renderToTop : null}

				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="profile-personal-data-content">
					<FormWrapper>
						<FormField
							required={true}
							label={this.t('firstNameField')}
							defaultMessages={(
								!this.hasSpecificValidationError(
									'firstName',
									this.t(
										'mustNotContainStrings',
										'validation',
										undefined,
										{values: this.nameFieldsForbiddenChars.join(', ')}
									)
								) ? [this.t('nameFieldNotice')] : undefined
							)}
							errorMessages={this.getValidationErrors('firstName')}
						>
							<TextInput
								name="firstName"
								value={this.getValue('firstName')}
								onChange={this.handleInputChange}
							/>
						</FormField>
						
						<FormField
							required={false}
							label={this.t('middleNameField')}
							defaultMessages={(
								!this.hasSpecificValidationError(
									'middleName',
									this.t(
										'mustNotContainStrings',
										'validation',
										undefined,
										{values: this.nameFieldsForbiddenChars.join(', ')}
									)
								) ? [this.t('nameFieldNotice')] : undefined
							)}
							errorMessages={this.getValidationErrors('middleName')}
						>
							<TextInput
								name="middleName"
								value={this.getValue('middleName')}
								onChange={this.handleInputChange}
							/>
						</FormField>

						<FormField
							required={true}
							label={this.t('lastNameField')}
							defaultMessages={(
								!this.hasSpecificValidationError(
									'lastName',
									this.t(
										'mustNotContainStrings',
										'validation',
										undefined,
										{values: this.nameFieldsForbiddenChars.join(', ')}
									)
								) ? [this.t('nameFieldNotice')] : undefined
							)}
							errorMessages={this.getValidationErrors('lastName')}
						>
							<TextInput
								name="lastName"
								value={this.getValue('lastName')}
								onChange={this.handleInputChange}
							/>
						</FormField>

						<FormField
							required={true}
							label={this.t('emailField')}
							errorMessages={this.getValidationErrors('email')}
						>
							<TextInput
								name="email"
								value={this.getValue('email')}
								onChange={this.handleInputChange}
							/>
						</FormField>

						<FormField
							required={false}
							label={this.t('credentialsField')}
							defaultMessages={
							(
								!this.hasSpecificValidationError(
									'credentials',
									this.t(
										'mustNotContainStrings',
										'validation',
										undefined,
										{values: this.nameFieldsForbiddenChars.join(', ')}
									)
								) ?
									[this.t('nameFieldNotice'), this.t('credentialsFieldNotice')] : 
									[this.t('credentialsFieldNotice')]
							)}
							errorMessages={this.getValidationErrors('credentials')}
							showAllMessages={true}
						>
							<TextInput
								name="credentials"
								value={this.getValue('credentials')}
								onChange={this.handleInputChange}
							/>
						</FormField>
					</FormWrapper>
				</div>
				
				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="save-data-actions">
					<FormField label="">
						<Button
							className={`save-data-btn ${getString(buttonProps, 'className')}`}
							label={this.t('save_data')}
							onClick={this.saveData}
							disabled={ACL.isGuest(ACL)}
							{...PersonalData.defaultProps.buttonProps}
							{...omit(buttonProps, ['className', 'onClick'])}
						/>
					</FormField>
				</div>
			</div>
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PersonalData.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Personal data component wrapper element ID class attribute
	id: PropTypes.string,
	// Personal data component wrapper element class attribute
	className: PropTypes.string,

	// Main button component props
	buttonProps: PropTypes.object,
	// Main submit function
	action: PropTypes.func, // Arguments: username, password

	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
};

/**
 * Define component default values for own props
 */
PersonalData.defaultProps = {
	styleName: 'default',
	className: '',
	buttonProps: {
		icon: icon_font_save_symbol,
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},

	renderToTop: null,
	renderToBottom: null,
};

export default connect(null, getPageActions(actions))(PersonalData);
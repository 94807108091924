import {cloneDeep, get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'questionnaire';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@questionnaire/reset',
	SET: '@questionnaire/set_data',
	CLEAR: '@questionnaire/clear_data',
	SAVE_TAB_DATA: '@questionnaire/save_tab_data',
	CLEAR_ALL_SAVED_TAB_DATA: '@questionnaire/clear_all_saved_tab_data',
	SET_OSA_RISK_VALIDATION_FOR_TAB: '@questionnaire/set_osa_risk_validation_for_tab',
	CLEAR_ALL_OSA_RISK_VALIDATION: '@questionnaire/clear_all_osa_risk_validation',
	SET_RESULT: '@questionnaire/set_result',
	CLEAR_RESULT: '@questionnaire/clear_result',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	set: data => ({type: REDUCER_TYPES.SET, data}),
	clear: () => ({type: REDUCER_TYPES.CLEAR}),
	/**
	 * Save data for a specific popup tab
	 * @note Tab data is saved in Redux store because all tabs except for the first one are not mounted until they are
	 * opened. This means that any user changes in those tabs would be lost every time that tab is closed/unmounted.
	 *
	 * @param {string} tabId - ID of the popup tab.
	 * @param {Object} data - Popup tab data to save.
	 * @return {{data, tabId:string, type: string}}
	 */
	saveTabData: (tabId, data) => ({type: REDUCER_TYPES.SAVE_TAB_DATA, tabId, data}),
	/**
	 * Clear all saved tab data
	 *
	 * @return {{type: string}}
	 */
	clearAllSavedTabData: () => ({type: REDUCER_TYPES.CLEAR_ALL_SAVED_TAB_DATA}),
	/**
	 * Set validation result for a specific popup tab
	 * 
	 * @param {string} tabId - ID of the popup tab.
	 * @param {Object<string, string[]> | false} validationResult - Popup tab validation result.
	 * @return {{tabId: string, validationResult: Object<string, string[]>|null, type: string}}
	 */
	setOsaRiskValidationForTab: (tabId, validationResult) => ({
		type: REDUCER_TYPES.SET_OSA_RISK_VALIDATION_FOR_TAB, tabId, validationResult
	}),
	/**
	 * Clear validation result for a specific popup tab
	 *
	 * @return {{type: string}}
	 */
	clearAllOsaRiskValidation: () => ({type: REDUCER_TYPES.CLEAR_ALL_OSA_RISK_VALIDATION}),
	/**
	 * Set questionnaire result data
	 * @param {QuestionnaireResultDataObject} result - Questionnaire result data.
	 * @return {{result: QuestionnaireResultDataObject, type: string}}
	 */
	setResult: result => ({type: REDUCER_TYPES.SET_RESULT, result}),
	/**
	 * Clear questionnaire result data
	 * @return {{type: string}}
	 */
	clearResult: () => ({type: REDUCER_TYPES.CLEAR}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {QuestionnaireDataObject}
	 */
	data: null,

	/**
	 * Saved tab data
	 * @type {Object}
	 */
	savedTabData: null,

	/**
	 * Object containing OSA risk validation where keys are tab ID's and values are validation results for that tab.
	 * @type {Object<string, Object<string, string[]>>}
	 */
	osaRiskValidation: {},

	/**
	 * Questionnaire result
	 * @note Value is null to differentiate between un-submitted questionnaire and submitted one with an empty result.
	 * @type {QuestionnaireResultDataObject}
	 */
	result: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET: return {...state, data: action.data};
		case REDUCER_TYPES.CLEAR: return {...state, data: cloneDeep(initialState.data)};

		case REDUCER_TYPES.SAVE_TAB_DATA:
			return {
				...state,
				savedTabData: {
					...state.savedTabData,
					[action.tabId]: cloneDeep(action.data)
				}
			};
		case REDUCER_TYPES.CLEAR_ALL_SAVED_TAB_DATA: 
			return {...state, savedTabData: cloneDeep(initialState.savedTabData)};
		
		case REDUCER_TYPES.SET_OSA_RISK_VALIDATION_FOR_TAB: 
			return {
				...state,
				osaRiskValidation: {
					...state.osaRiskValidation,
					[action.tabId]: cloneDeep(action.validationResult)
				}
			};
		case REDUCER_TYPES.CLEAR_ALL_OSA_RISK_VALIDATION: 
			return {...state, osaRiskValidation: cloneDeep(initialState.osaRiskValidation)};
			
		case REDUCER_TYPES.SET_RESULT: return {...state, result: cloneDeep(action.result)};
		case REDUCER_TYPES.CLEAR_RESULT: return {...state, result: cloneDeep(initialState.result)};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getQuestionnaire: state => get(state, [reducerStoreKey, 'data']),
	
	getAllSavedTabData: state => get(state, [reducerStoreKey, 'savedTabData'], null),
	getSavedTabData: (state, tabId) => get(state, [reducerStoreKey, 'savedTabData', tabId], null),
	
	getOsaRiskWarningsForTab: (state, tabId) => get(state, [reducerStoreKey, 'osaRiskValidation', tabId]),
	getOsaRiskWarningVisible: state => {
		const osaRiskValidation = get(state, [reducerStoreKey, 'osaRiskValidation']);
		return (
			osaRiskValidation ?
				Object.keys(osaRiskValidation).some(tabId => {
					/** @type {Object<string, string[]>} */
					const tabValidation = get(osaRiskValidation, tabId);
					if (tabValidation) {
						return Object.keys(tabValidation).some(tabField => 
							Array.isArray(tabValidation[tabField]) && tabValidation[tabField].length > 0
						);
					} else {
						return false;
					}
				}) 
				: false
		);
	},

	getQuestionnaireResult: state => get(state, [reducerStoreKey, 'result']),
};

export default reducer;
import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {LanguageFilesItemDataObject} from "../dataObjects";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import * as languageItemDataMap from "../../languagesCodebook/dataMap/item";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {LanguageFilesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new LanguageFilesItemDataObject(
		getString(result, 'id'),
		getString(result, 'deviceType'),
		getString(result, 'languageFileVersion'),
		getDate(get(result, 'languageFileReleaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'notes'),
		languageItemDataMap.input(get(result, 'dmLanguageData')),
		getString(result, 'executableVoiceBinId'),
		get(result, 'executableVoiceBin'),
		getString(result, 'checksum')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {LanguageFilesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	result = {
		deviceType: result.deviceType,
		dmLanguageId: getString(result, 'language.id'),
		languageFileVersion: result.version,
		languageFileReleaseDate: getDateString(result.releaseDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		notes: result.notes,

		// Form data properties
		executableVoiceBin: result.executableVoiceBinFormData
	};

	// Set proper empty values expected by the API
	// @note This is commented out since requests that use FormData have backend issues with string values cleared by 
	// null values instead of empty strings.
	//result = resetObjectValues(result);

	return result;
}
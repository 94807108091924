/**
 * Abstract component using local state data, used to create other components
 * NOTE: Components created using this abstract component will have a 'data' field in the local state which will store
 * main component's data. This is a convention chosen by design to separate main component's data (data need by the
 * component to work properly) and other local state values used for GUI or other less significant or more specific
 * purposes.
 */

import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {v4} from 'uuid';
import {
	get, cloneDeep, isEqual, find, reject, unset, isString, isArray, isFunction, sortBy, orderBy, forOwn, omit, set
} from 'lodash';
import {getArray} from "Core/helpers/data";
import {startTransition} from "react";

export default class DataComponent extends BaseComponent {
	/**
	 * Component's options object
	 * @note This can contain any number of option fields used by the component. Child classes can override default
	 * options by sending 'options' argument to this constructor via 'super' function.
	 * @type {DataComponentOptions}
	 * @private
	 */
	_options;
	
	/**
	 * Component's initial state
	 * @note Initial state is created by getting custom initialState and adding "data" and "errors" fields.
	 * @type {object}
	 */
	initialState;
	
	/**
	 * Component's original data used to determine if data has changed
	 * @type {any}
	 */
	originalData;
	
	/**
	 * Queue of load method calls
	 * @description If load method is called while another load method is running, new load method call will be put in
	 * the queue and executed as soon as the previous one is finished. In other words, if queue of load method calls is
	 * not empty add all new load method calls to the queue. Queue will automatically pop and run load method calls.
	 * @type {LoadQueueItem[]}
	 */
	loadQueue = [];

	/**
	 * Class constructor
	 *
	 * @param {object} [props] - Component props.
	 * @param {object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {DataComponentOptions} [options={}] - Component options from child class that will override the default 
	 * options.
	 */
	constructor(props, initialState = {}, options = {}) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {DataComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Flag that determines if load queue will use the fast mode.
			 * WARNING: Load queue fast mode does not guarantee linear loads for non async load calls, for example if load
			 * method s called within a for loop. Async calls should work properly.
			 * @type {boolean}
			 */
			forceFastLoad: false,

			/**
			 * Flag that determines if load functionality is disabled. If true 'load' method will not load data from props
			 * into local state.
			 * @type {boolean}
			 */
			disableLoad: false,

			/**
			 * Flag that determines if data will be loaded from props to local state every time data prop changes.
			 * @note This flag will be ignored if 'disableLoad' is true.
			 * @type {boolean}
			 */
			enableLoadOnDataPropChange: false,

			/** 
			 * Main data prop alisa
			 * @note This is used by child components that need to have a different prop field for main data, like input
			 * components that use 'value' instead of 'data'.
			 * @type {string}
			 */
			dataPropAlias: '',
			/**
			 * Original data prop alisa
			 * @note This is used by child components that need to have a different prop field for original data, like 
			 * input components that use 'originalValue' instead of 'originalData'.
			 * @type {string}
			 */
			originalDataPropAlias: '',

			/**
			 * Flag that determines if whole props will be used as main data on load instead of 'data' prop or 
			 * 'dataPropAlias' options.
			 * @type {boolean}
			 */
			wholePropAsData: false,
			
			/**
			 * Flag that specifies if data change history will be enabled and used
			 * @note If change history is enabled some additional methods will be available, like undoData and redoData.
			 */
			enableDataChangeHistory: false,

			/**
			 * Flag that specifies if validation errors will be cleared when values change
			 */
			clearValidationErrorsOnChange: true,

			...cloneDeep(options)
		};
		
		super(props, _options);

		// Initialize initial state
		this.initialState = {
			/**
			 * Main component's date
			 * @note Components created using this abstract component will have a 'data' field in the local state which 
			 * will store main component's data. This is a convention chosen by design to separate main component's data
			 * (data need by the component to work properly) and other local state values used for GUI or other less 
			 * significant or more specific purposes. Also by convention, data should initially be undefined and should 
			 * be defined only after loading it.
			 * @type {undefined|any}
			 */
			data: undefined,
			
			/**
			 * Main component's data validation errors
			 * @description This is an object where keys are main component's data fields (when main component's data is an
			 * object) and values are arrays of validation errors for those fields.
			 * @type {Object<string, string[]>}
			 */
			errors: {},

			/**
			 * Component's overall error message
			 * @note This can be useful when creating form components that need to have additional error message related to
			 * the whole form besides input validation errors for each field.
			 * @type {string}
			 */
			error: '',

			...cloneDeep(initialState)
		};
		
		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Initializing local component original data used to determine if data has changed
		this.originalData = this.getDataToLoad(get(props, this.getOriginalDataPropName()));
		
		// Data change history
		this.dataChangeHistory = [];
		this.dataChangeHistoryIndex = -1;

		// Data methods
		this.getDataPropName = this.getDataPropName.bind(this);
		this.getOriginalDataPropName = this.getOriginalDataPropName.bind(this);
		this.getOriginalData = this.getOriginalData.bind(this);
		this.setOriginalData = this.setOriginalData.bind(this);
		this.getInitialData = this.getInitialData.bind(this);
		this.getDataToLoad = this.getDataToLoad.bind(this);
		this.getDataToReturn = this.getDataToReturn.bind(this);
		this.getLoadQueue = this.getLoadQueue.bind(this);
		this.getLoadQueueItem = this.getLoadQueueItem.bind(this);
		this._addToLoadQueue = this._addToLoadQueue.bind(this);
		this._removeFromLoadQueue = this._removeFromLoadQueue.bind(this);
		this._loadQueueRunNext = this._loadQueueRunNext.bind(this);
		this.defaultLoad = this.defaultLoad.bind(this);
		this.load = this.load.bind(this);
		this.reload = this.reload.bind(this);
		this.clear = this.clear.bind(this);
		this.canUndoData = this.canUndoData.bind(this);
		this.canRedoData = this.canRedoData.bind(this);
		this.undoData = this.undoData.bind(this);
		this.redoData = this.redoData.bind(this);
		this.updateDataHistory = this.updateDataHistory.bind(this);
		this.update = this.update.bind(this);
		this.getData = this.getData.bind(this);
		this.setData = this.setData.bind(this);
		this.clearData = this.clearData.bind(this);
		this.getValue = this.getValue.bind(this);
		this.setValue = this.setValue.bind(this);
		this.invertBoolValue = this.invertBoolValue.bind(this);
		this.getItem = this.getItem.bind(this);
		this.setItem = this.setItem.bind(this);
		this.addItem = this.addItem.bind(this);
		this.removeItem = this.removeItem.bind(this);
		this.getItemValue = this.getItemValue.bind(this);
		this.setItemValue = this.setItemValue.bind(this);
		this.invertItemBoolValue = this.invertItemBoolValue.bind(this);
		this.sortData = this.sortData.bind(this);
		this.hasDataChanged = this.hasDataChanged.bind(this);
		this.clearFileInputValue = this.clearFileInputValue.bind(this);

		// Validation and error methods
		this.validate = this.validate.bind(this);
		this.getValidationErrorsCount = this.getValidationErrorsCount.bind(this);
		this.hasSpecificValidationError = this.hasSpecificValidationError.bind(this);
		this.getValidationErrors = this.getValidationErrors.bind(this);
		this.setValidationErrors = this.setValidationErrors.bind(this);
		this.clearValidationErrors = this.clearValidationErrors.bind(this);
		this.setErrorMessage = this.setErrorMessage.bind(this);
		this.getErrorMessage = this.getErrorMessage.bind(this);
		this.clearErrorMessage = this.clearErrorMessage.bind(this);

		// Data change handling methods
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputChangeAndUpdate = this.handleInputChangeAndUpdate.bind(this);
		this.handleFileInputChange = this.handleFileInputChange.bind(this);
		this.handleFileInputChangeAndUpdate = this.handleFileInputChangeAndUpdate.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
		this.handleValueChangeAndUpdate = this.handleValueChangeAndUpdate.bind(this);
		this.handleInputEnterPress = this.handleInputEnterPress.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleItemChange = this.handleItemChange.bind(this);
		this.handleItemChangeAndUpdate = this.handleItemChangeAndUpdate.bind(this);
		this.handleItemRemove = this.handleItemRemove.bind(this);
		this.handleItemRemoveAndUpdate = this.handleItemRemoveAndUpdate.bind(this);
		this.handleItemInputChange = this.handleItemInputChange.bind(this);
		this.handleItemInputChangeAndUpdate = this.handleItemInputChangeAndUpdate.bind(this);
		this.handleItemValueChange = this.handleItemValueChange.bind(this);
		this.handleItemValueChangeAndUpdate = this.handleItemValueChangeAndUpdate.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();

		// If this method was not called by the override method from the child class
		if (!override) {
			const dataPropName = this.getDataPropName();
			const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
			// Load initial props data into local component's state
			return this.load(data);
		}
		
		return Promise.resolve();
	}

	/**
	 * @inheritDoc
	 * 
	 * @return {Promise<object>} Promise that is resolved with entire component's local state. This promise will
	 * always resolve (it will never fail).
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.getOption('enableLoadOnDataPropChange', false)) {
			const dataPropName = this.getDataPropName();
			const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
			const prevData = (dataPropName ? prevProps[this.getDataPropName()] : prevProps);

			// If prop data changes load it into local component's state
			// NOTE: Only crude comparison is done (no custom data class support) because load method will check that 
			// before changing local state. This simple check is done just to prevent calling load method on every prop
			// change for optimization.
			if (!isEqual(data, prevData)) return this.load(data);
		}
		return Promise.resolve(this.state);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get component's prop name containing main data
	 * @note This is used by child components that need to have a different prop field for main data, like input
	 * components that use 'value' instead of 'data'.
	 *
	 * @return {string} Component's prop name containing main data.
	 */
	getDataPropName() {
		const dataPropAlias = this.getOption('dataPropAlias');
		const wholePropAsData = this.getOption('wholePropAsData');
		return (wholePropAsData ? '' : (dataPropAlias ? dataPropAlias : 'data'));
	}

	/**
	 * Get component's prop name containing original data
	 * @note This is used by child components that need to have a different prop field for original data, like input
	 * components that use 'originalValue' instead of 'originalData'.
	 *
	 * @return {string} Component's prop name containing main data.
	 */
	getOriginalDataPropName() {
		const originalDataPropAlias = this.getOption('originalDataPropAlias');
		return (originalDataPropAlias ? originalDataPropAlias : 'originalData');
	}

	/**
	 * Get component's original data
	 * @note Original data is used to check if data has changed. This is useful for components that can save data to the
	 * DB where change indicator is needed. This method will return deep cloned result data.
	 *
	 * @return {any} Deep-cloned component's original data.
	 */
	getOriginalData() {
		return cloneDeep(this.originalData);
	}

	/**
	 * Set component's original data
	 * @note Original data is used to check if data has changed. This is useful for components that can save data to the
	 * DB where change indicator is needed. This is not an async method because changes to original data will not cause
	 * component render or state changes. This method will not mutate (it will deep-clone) the passed data.
	 *
	 * @param {any} rawOriginalData - Component's raw original data to set. Raw means that this data will be run through
	 * 'getDataToLoad' method before it is set.
	 */
	setOriginalData(rawOriginalData) {
		this.originalData = this.getDataToLoad(rawOriginalData);
	}

	/**
	 * Get initial component's state data
	 * @note This is just a helper function, and it probably should not be changed. This method will return deep cloned
	 * result data.
	 *
	 * @return {any} Deep-cloned component's initial main data.
	 */
	getInitialData() {
		return cloneDeep(this.initialState.data);
	}

	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null|undefined} Deep-cloned local component's state compatible data, null or undefined. 
	 */
	getDataToLoad(rawData) {
		return cloneDeep(rawData);
	}

	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Deep-cloned data that will be returned to the parent component.
	 */
	getDataToReturn() {
		return cloneDeep(this.state.data);
	}

	/**
	 * Get the clone of the queue of load method calls
	 *
	 * @return {LoadQueueItem[]} Clone of the queue of load method calls or an empty array if queue does not exist.
	 */
	getLoadQueue() { return (Array.isArray(this.loadQueue) ? cloneDeep(this.loadQueue) : []); }

	/**
	 * Get the clone of the load method queue item
	 *
	 * @param {string} queueItemId - Unique queue item identifier.
	 * @return {LoadQueueItem|undefined} Returns the cloned matching load method queue item.
	 */
	getLoadQueueItem(queueItemId) { return find(this.getLoadQueue(), { id: queueItemId }); }

	/**
	 * Add call to the queue of load method calls
	 *
	 * @param {any} data - Data to pass to the load method.
	 * @param {Function} [loadCallback] - Callback function that will be executed after load queue item called and done 
	 * loading.
	 * @return {string} New queue call identifier.
	 * @private
	 */
	_addToLoadQueue(data, loadCallback) {
		const id = v4();
		this.loadQueue.push({ id, data: cloneDeep(data), loadCallback });
		return id;
	}

	/**
	 * Remove a call from the queue of load method calls
	 *
	 * @param {string} queueItemId - Unique queue item identifier.
	 * @return {LoadQueueItem[]} Clone of the queue of load method calls after a call was removed.
	 * @private
	 */
	_removeFromLoadQueue(queueItemId) {
		const loadQueue = this.getLoadQueue();
		this.loadQueue = reject(loadQueue, { id: queueItemId });
		return this.loadQueue;
	}

	/**
	 * Run the next load queue item if there is one
	 * @note Queue item has its own callback function so this method does not have to return anything.
	 *
	 * @param {LoadQueueItem[]} loadQueue - Load queue.
	 * @private
	 */
	_loadQueueRunNext(loadQueue) {
		// If there are still some items in the queue run the first one
		if(loadQueue.length > 0) {
			if (this.getOption('forceFastLoad') === true) {
				this.load(null, loadQueue[0]).then();
			} else {
				setTimeout(() => this.load(null, loadQueue[0]));
			}
		}
	}

	/**
	 * Load data into local component's state and call component's update method if data is different from current data
	 * @note This method will not mutate the passed data. We recommend that you override this method in your child
	 * classes if you need to alter the loading procedure. We do not recommend overriding the 'load' method!
	 *
	 * @param {any} data - Data to load into local component's state. If data is the same as current local component's
	 * state data it will not be loaded and component's update method will not be called.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been loaded.
	 */
	defaultLoad(data) {
		// Get current and data to load
		const currentData = this.getData();
		const dataToLoad = this.getDataToLoad(data);
		
		// Load data if it is different from current data
		if (!isEqual(currentData, dataToLoad)) return this.setState({data: dataToLoad});
		// Do not load data if it is not different from current data
		return new Promise(resolve => resolve(this.state));
	}

	/**
	 * Load data into local component's state using the load method call queue
	 * @note This method will not mutate the passed data. In most cases you don't need to override this method. It is
	 * recommended that you override the 'defaultLoad' method instead.
	 *
	 * @param {any} [data] - Data to load into local component's state. If load method call queue is not empty, this data
	 * will be added to the queue. If this method was called by the queue this param will be ignored.
	 * @param {LoadQueueItem} [queueItem] - If this method was called by the load method call queue this param must
	 * contain a call item from the queue that needs to be executed.
	 * @param {boolean} [forceLoad=false] - Force load ignoring the 'disableLoad' option. Use this carefully!
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been loaded.
	 */
	load(data, queueItem, forceLoad = false) {
		// Do not load data if loading is disabled in component options
		if (this.getOption('disableLoad') === true && !forceLoad) {
			return new Promise(resolve => resolve(this.state));
		}

		// Get the queue of load method calls
		const loadQueue = this.getLoadQueue();

		// If this call was initiated by the queue or if the load method call queue is empty (there are no currently
		// running load methods)
		if (queueItem || loadQueue.length === 0) {
			// If this call was initiated by the queue
			if(queueItem) {
				return new Promise(resolve => {
					// Call the default load function
					this.defaultLoad(queueItem.data)
						.then(state => {
							// Remove the item from the load queue
							const updatedLoadQueue = this._removeFromLoadQueue(queueItem.id);

							// Execute the callback function for this queue item if it was defined
							if(typeof queueItem.loadCallback === 'function') queueItem.loadCallback(state);

							// Resolve the return promise
							resolve(state);

							// If there are still some calls in the load queue run the first one
							this._loadQueueRunNext(updatedLoadQueue);
						});
				});
			}
			// If the load method call queue is empty
			else {
				// Add this call to the queue
				const newQueueItemId = this._addToLoadQueue(data);

				return new Promise(resolve => {
					// Call the default load function
					this.defaultLoad(data)
						.then(state => {
							// Remove the item from the load queue
							const updatedLoadQueue = this._removeFromLoadQueue(newQueueItemId);

							// Resolve the return promise
							resolve(state);

							// If there are still some calls in the queue run the first one
							this._loadQueueRunNext(updatedLoadQueue);
						});
				});
			}
		}

		// Add this load method call to the load queue if the queue of load method calls is not empty (there are currently
		// running load methods)
		// @note To ensure that this load call returns a promise and that the promise resolves only after this call was
		// executed we used 'loadCallback' argument of the '_addToLoadQueue' method. This callback will be executed once
		// this call is executed by the queue, so we pass the 'resolve' function as the callback function.
		else return new Promise(resolve => this._addToLoadQueue(data, resolve));
	}

	/**
	 * Reload main component's data from props
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been loaded.
	 */
	reload() {
		const dataPropName = this.getDataPropName();
		const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
		return this.load(data);
	}

	/**
	 * Clear component's internal state and call component's update method if it is not already cleared
	 * @note This will basically reset the component to its initial state.
	 *
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been cleared.
	 */
	clear() {
		let currentState = this.clonedState();
		let initialState = cloneDeep(this.initialState);
		const currentData = this.getData();
		const initialData = this.getInitialData();
		let shouldClear = false;

		// Remove data from states because states will be compared separately
		unset(currentState, 'data');
		unset(initialState, 'data');

		// If state values (without data) are different from initial state values
		if (!isEqual(currentState, initialState)) shouldClear = true;
		// If state values (without data) are the same as initial state values and current state data is different form 
		// initial state data
		else if (!isEqual(currentData, initialData)) shouldClear = true;

		// If data is different that initial data
		if (shouldClear){
			return this.setState({data: cloneDeep(this.initialState)})
				.then(state => { this.update(); return state; });
		}
		// Do not clear state if it is already cleared
		return new Promise(resolve => resolve(currentState));
	}

	/**
	 * Check if you can undo the main data
	 * @return {boolean}
	 */
	canUndoData() {
		return (this.dataChangeHistoryIndex > -1);
	}

	/**
	 * Check if you can redo the main data
	 * @return {boolean}
	 */
	canRedoData() {
		return (this.dataChangeHistoryIndex < this.dataChangeHistory.length - 1);
	}
	
	/**
	 * Undo data change
	 * @note In order to use this 'enableDataChangeHistory' option should be true.
	 *
	 * @param {boolean} [update=true] - Flag that specifies if components 'onChange' event will be triggerred.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated. 
	 */
	undoData(update = true) {
		return new Promise(resolve => {
			if (this.canUndoData()) {
				// Move the index back
				this.dataChangeHistoryIndex = this.dataChangeHistoryIndex - 1;
				const undoData = (
					this.dataChangeHistoryIndex > -1 ?
						get(this.dataChangeHistory, `[${this.dataChangeHistoryIndex}]`)  :
						this.getOriginalData()
				);
				this.setData(undoData)
					.then(() => {
						if (update) executeComponentCallback(this.props.onChange, this.getDataToReturn());
						else return Promise.resolve();
					})
					.then(() => resolve(this.state));
			}
		});
	}

	/**
	 * Redo data change
	 * @note In order to use this 'enableDataChangeHistory' option should be true.
	 *
	 * @param {boolean} [update=true] - Flag that specifies if components 'onChange' event will be triggerred.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	redoData(update = true) {
		return new Promise(resolve => {
			// If data chang history index is not on the last change
			if (this.canRedoData()) {
				// Move then index forward
				this.dataChangeHistoryIndex = this.dataChangeHistoryIndex + 1;
				if (this.dataChangeHistoryIndex < this.dataChangeHistory.length) {
					const redoData = get(this.dataChangeHistory, `[${this.dataChangeHistoryIndex}]`);
					this.setData(redoData)
						.then(() => {
							if (update) executeComponentCallback(this.props.onChange, this.getDataToReturn());
							else return Promise.resolve();
						})
						.then(() => resolve(this.state));
				} else {
					resolve(this.state);
				}
			}
		});
	}

	/**
	 * Update main data history
	 * @note This will only work if 'enableDataChangeHistory' option is true.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	updateDataHistory() {
		return new Promise(resolve => {
			if (this.getOption('enableDataChangeHistory') === true) {
				const canUndoBefore = this.canUndoData();
				const canRedoBefore = this.canRedoData();
				this.dataChangeHistory.splice(this.dataChangeHistoryIndex + 1);
				this.dataChangeHistory.push(this.getData());
				this.dataChangeHistoryIndex = this.dataChangeHistory.length - 1;
				const canUndoAfter = this.canUndoData();
				const canRedoAfter = this.canRedoData();
				if (canUndoBefore !== canUndoAfter || canRedoBefore !== canRedoAfter) {
					this.forceUpdate(() => resolve(this.state));
				} else {
					resolve(this.state);
				}
			}
		});
	}
	
	/**
	 * Trigger component's onChange event with component's main data as param
	 * @param {Event} [event] - Optional JavaScript event that triggered the update. 
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	update(event) { 
		this.updateDataHistory().then();
		
		if (event) return executeComponentCallback(this.props.onChange, event, this.getDataToReturn());
		else return executeComponentCallback(this.props.onChange, this.getDataToReturn());
	}

	/**
	 * Get component's main data from local state
	 *
	 * @return {any} Deep-cloned component's main data from local state.
	 */
	getData() {
		return cloneDeep(this.state.data);
	}

	/**
	 * Set component's main data in local state if it is different from current component's main data
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} data - Data to set. If data is the same as current local component's state data it will not be set.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	setData(data) {
		const currentData = this.getData();
		const dataToSet = cloneDeep(data);

		// Set data if it is different from current data
		if(!isEqual(currentData, dataToSet)) return this.setState({data: dataToSet});
		// Do not set data if it is not different from current data
		return new Promise(resolve => resolve(this.state));
	}

	/**
	 * Clear local component's data if data is not already cleared
	 *
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been cleared.
	 */
	clearData() {
		const currentData = this.getData();
		const initialData = this.getInitialData();
		
		// Clear data if it is not already cleared
		if (!isEqual(currentData, initialData)) return this.setState({data: cloneDeep(initialData)});
		// Do not clear data if it is already cleared
		return new Promise(resolve => resolve(this.state));
	}

	/**
	 * Get the value of component's main data field from local state
	 * @note This method is used if component's main data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object.
	 * @param {any} [defaultValue] - Default value if filed was not found.
	 * @return {any} Deep-cloned value of the component's main data field from local state.
	 */
	getValue(path, defaultValue) {
		return get(this.getData(), path, defaultValue);
	}

	/**
	 * Set the value of component's main data field in local state if field changed
	 * @note This method is used if component's main data is an object. This method will not mutate the passed value.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object.
	 * @param {any} value - Value to assign to the data field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	setValue(path, value) {
		const currentData = this.getValue(path);
		const dataToSet = cloneDeep(value);
		
		// Set data if it is different from current data
		if (!isEqual(currentData, dataToSet)) {
			if (Array.isArray(path)) {
				return this.setState(state => {
					let updatedData = cloneDeep(state.data);
					set(updatedData, path, dataToSet);
					return {data: updatedData};
				});
			} else {
				return this.setSubState('data', {[path]: dataToSet});
			}
		}
		// Do not set data if it is not different from current data
		return new Promise(resolve => resolve(this.state));
	}

	/**
	 * Invert and set the boolean value of component's main data field in local state
	 * @note This method is used if component's main data is an object.
	 *
	 * @param {string} field - Field name inside the data object.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	invertBoolValue(field) {
		return this.setSubState('data', subState => ({[field]: !get(subState, field)}));
	}
	
	/**
	 * Get component's main data item from local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 */
	getItem(predicate) { return find(this.getData(), predicate); };

	/**
	 * Update component's main data item in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {Function|Object} item - Item to set. Similar to standard 'setState' updater param but if it is used as
	 * an array it receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and 
	 * props). If item does not exist 'arrayItem' will be null.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item has been set.
	 */
	setItem(predicate, item, sort = null) {
		return this.updateStateArrayItem('data', predicate, item).then(() => this.sortData(sort));
	};

	/**
	 * Add item to component's main data in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Function|Object} item - Item to add. Similar to standard 'setState' updater param but if it is used as an
	 * array it receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and 
	 * props) where 'arrayItem' will always be null.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item has been added.
	 */
	addItem(item, sort = null) { return this.addStateArrayItem('data', item).then(() => this.sortData(sort)); }

	/**
	 * Remove item from component's main data in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item has been
	 * removed.
	 */
	removeItem(predicate, sort = null) {
		return this.removeStateArrayItem('data', predicate).then(() => this.sortData(sort));
	}

	/**
	 * Get component's main data item field value from local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string} path - Path of the field inside component's main data item.
	 * @return {any} Component's main data item field value from local state.
	 */
	getItemValue(predicate, path) { return get(this.getItem(predicate), path); }

	/**
	 * Set component's main data item field value in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string} field - Name of the main data item filed to set.
	 * @param {any} value - Value to set.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item field has been
	 * set.
	 */
	setItemValue(predicate, field, value, sort = null) {
		return this.setStateArrayItemValue('data', predicate, field, value).then(() => this.sortData(sort));
	}

	/**
	 * Invert and set the boolean value of component's main data item field in local state
	 * @note This method is used if component's main data is an array of objects.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string} field - Field name inside the data object.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	invertItemBoolValue(predicate, field) {
		return this.updateStateArrayItem('data', predicate, item => ({[field]: !get(item, field)}));
	}

	/**
	 * Sort main data array
	 *
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will 
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort 
	 * order. Function should define a custom sort method.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item field has been
	 * set.
	 */
	sortData(sort = null) {
		if ((isString(sort) && sort) || isFunction(sort)) {
			return this.setState({data: sortBy(this.getData(), sort)});
		}
		if (isArray(sort) && sort.length === 2) {
			return this.setState({data: orderBy(this.getData(), [sort[0]], [sort[1]])});
		}
		return Promise.resolve(this.state.data);
	}
	
	/**
	 * Check if current component's main data is different than component's original data
	 *
	 * @return {boolean} True if current component's main data is different than component's original data.
	 */
	hasDataChanged() {
		const data = this.getData();
		const originalData = this.getOriginalData();
		
		return !isEqual(data, originalData);
	}

	/**
	 * Clear file input and data value represented that file input
	 * @important File input component or element related to 'fileInputRef' must have a 'name' attribute in order for 
	 * this method to work properly.
	 * @note This method supports FileInput component and simple DOM input element as ref.
	 * 
	 * @param {Object} fileInputRef - File input ref. If ref is a component with 'clear' method, that method will be 
	 * called. If not, this method will assume ref is a simple input DOM element, and it will just clear the 'value'.
	 * @return {Promise<any>} Promise that is resolved with entire component's local state after data item field has been
	 * set.
	 */
	clearFileInputValue(fileInputRef) {
		if (fileInputRef) {
			// If file input component has a 'clear' method call it (usually a 'FileInput' component)
			try { fileInputRef.clear(); }
			// If file input is not a component that has a 'clear' method assume it is a simple input DOM element
			catch (e) {
				try { fileInputRef.value = ''; } 
				catch (e1) { /* Do nothing */ }
			}
			
			// Get the name from the file input ref
			const name = fileInputRef.props.name;
			return this.setState(prevState => ({
				...prevState,
				data: {...prevState.data, [name]: null}
			}));
		}
		return Promise.resolve(this.state);
	}


	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() { return true; }

	/**
	 * Get the number validation errors
	 * @note Use this if component data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to get the errors array for. If not
	 * specified, errors for all fields will be returned.
	 * @return {number} Number of validation errors for a specified field. If 'path' is not specified, number of all
	 * errors for all fields will be returned.
	 */
	getValidationErrorsCount(path = '') {
		if (path) {
			const fieldErrors = get(this.state, ['errors', ...(Array.isArray(path) ? path : [path])]);
			return (Array.isArray(fieldErrors) && fieldErrors.length > 0 ? fieldErrors.length : 0);
		} else {
			let result = 0;
			const errors = get(this.state, 'errors');
			forOwn(errors, fieldErrors => {
				if (Array.isArray(fieldErrors)) result += fieldErrors.length;
			});
			return result;
		}
	}

	/**
	 * Check if a specific validation error exists
	 * @note Use this if component data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to check if it has the specified error. 
	 * If not specified, all field errors will be checked.
	 * @param {string} error - Validation error translation to check for. Translated error is used because it is 
	 * difficult to handle custom translations since this method can only get the list of translated errors from local 
	 * state.
	 */
	hasSpecificValidationError(path = '', error) {
		if (path) {
			return getArray(this.state, ['errors', ...(Array.isArray(path) ? path : [path])]).includes(error);
		} else {
			let result = false;
			const errors = get(this.state, 'errors');
			forOwn(errors, (field, fieldErrors) => {
				if (!result) result = getArray(fieldErrors).includes(error);
			});
			return result;
		}
	}
	
	/**
	 * Get validation errors array
	 * @note Use this if component data is an object.
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to get the errors array for. If not 
	 * specified, errors for all fields will be returned.
	 * @return {string[]|Object<string, string[]>|undefined} Validation errors array for a specified field or undefined 
	 * if there are no errors for the field. If 'path' is not specified, errors for all fields will be returned.
	 */
	getValidationErrors(path = '') {
		if (path) return get(this.state, ['errors', ...(Array.isArray(path) ? path : [path])]);
		else return get(this.state, 'errors');
	}

	/**
	 * Set validation errors array
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to ser the errors array for. If not
	 * specified, errors for all fields will be set.
	 * @param {string[]|Object} errors - Array of translated error messages for the field or an object if 'fieldName' is
	 * empty (errors for all fields will be set).
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	setValidationErrors(path = '', errors) {
		if (path) return this.setState(state => {
			let updatedErrors = cloneDeep(state.errors);
			set(updatedErrors, path, errors);
			return {errors: updatedErrors};
		});
		else return this.setSubState('errors', errors);
	}

	/**
	 * Clear validation errors for a specific component's field
	 *
	 * @param {string|string[]} path - Path of the field inside the data object to ser the errors array for. If not
	 * specified, errors for all fields will be cleared.
	 * @return {Promise<Object>}
	 */
	clearValidationErrors(path = '') {
		return new Promise(resolve => {
			startTransition(() => {
				if (path) this.setState(state => ({...state, errors: omit(state.errors, path)})).then(resolve);
				else this.setState({errors: {}}).then(resolve);
			});
		});
	}

	/**
	 * Set component's error message
	 * @description Component's error message is a single error message for the whole component. This can be useful when 
	 * creating form components that need to have additional error message related to the entire form besides input 
	 * validation errors for each field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	setErrorMessage(message) { return this.setState({error: message}); }

	/**
	 * Get component's error message
	 * @description Component's error message is a single error message for the whole component. This can be useful when
	 * creating form components that need to have additional error message related to the entire form besides input
	 * validation errors for each field.
	 * @return {string}
	 */
	getErrorMessage() { return get(this.state, 'error', ''); }

	/**
	 * Clear component's error message
	 * @description Component's error message is a single error message for the whole component. This can be useful when
	 * creating form components that need to have additional error message related to the entire form besides input
	 * validation errors for each field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	clearErrorMessage() { return this.setState({error: ''}); }


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle input component changes
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleInputChange(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();
		
		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setValue(fieldName, value)
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(fieldName) :
						Promise.resolve(state)
				));
		}
		// Update component's main data if field name is not specified
		else return this.setData(value);
	}

	/**
	 * Handle input component changes and call component's update method
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleInputChangeAndUpdate(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();
		
		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setValue(fieldName, value)
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(fieldName) :
						Promise.resolve(state)
				))
				.then(s => { this.update(event); return s; });
		}
		// Update component's main data if field name is not specified
		else return this.setData(value).then(s => { this.update(event); return s; });
	}

	/**
	 * Handle file input component changes
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @param {string} [formDataFieldName='file'] - Field name in FormData where selected file(s) will be stored.
	 * @param {Object} [additionalFormData={}] - Additional FormData values that will be added.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleFileInputChange(event, formDataFieldName = 'files', additionalFormData = {}) {
		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const files = Array.from(get(target, 'files', []));

		let formData = null;
		if (files.length > 0) {
			// Generate FormData from selected files
			formData = new FormData();
			if (target.multiple) files.forEach(file => { formData.append(`${formDataFieldName}[]`, file); });
			else {
				const file = get(files, '[0]');
				if (file) formData.append(formDataFieldName, file);
			}

			// Add additional FormData fields
			if (additionalFormData) {
				forOwn(additionalFormData, (value, field) => formData.append(field, value));
			}
		}

		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setState(prevState => ({
				...prevState,
				data: {...prevState.data, [fieldName]: formData}
			}))
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(fieldName) :
						Promise.resolve(state)
				));
		}
		// Update component's main data if field name is not specified
		else return this.setState({data: formData});
	}

	/**
	 * Handle file input component changes and call component's update method
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @param {string} [formDataFieldName='file'] - Field name in FormData where selected file(s) will be stored.
	 * @param {Object} [additionalFormData={}] - Additional FormData values that will be added.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleFileInputChangeAndUpdate(event, formDataFieldName = 'files', additionalFormData = {}) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();

		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const files = Array.from(get(target, 'files', []));

		// Generate FormData from selected files
		const formData = new FormData();
		if (target.multiple) files.forEach(file => { formData.append(`${formDataFieldName}[]`, file); });
		else {
			const file = get(files, '[0]');
			if (file) formData.append(formDataFieldName, file);
		}

		// Add additional FormData fields
		if (additionalFormData) {
			forOwn(additionalFormData, (value, field) => formData.append(field, value));
		}

		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setState(prevState => ({
				...prevState,
				data: {...prevState.data, [fieldName]: formData}
			}))
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(fieldName) :
						Promise.resolve(state)
				))
				.then(s => { this.update(event); return s; });
		}
		// Update component's main data if field name is not specified
		else return this.setState({data: formData}).then(s => { this.update(event); return s; });
	}

	/**
	 * Handle item child field changes
	 *
	 * @param {string} path - Path to the component's main data field that will be updated.
	 * @param {any} value - Value that will be used to update component's main data field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleValueChange(path, value) {
		// Update component's main data field if field path is specified
		if (path) {
			if (this.getOption('clearValidationErrorsOnChange') === true) this.clearValidationErrors(path).then();
			return this.setValue(path, value);
		}
		else return Promise.resolve(this.state);
	}

	/**
	 * Handle item child field changes and call component's update method
	 *
	 * @param {string} path - Path to the component's main data field that will be updated.
	 * @param {any} value - Value that will be used to update component's main data field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleValueChangeAndUpdate(path, value) {
		// Update component's main data field if field path is specified
		if(path) {
			return this.setValue(path, value)
				.then(state => (
					this.getOption('clearValidationErrorsOnChange') === true ?
						this.clearValidationErrors(path) :
						Promise.resolve(state)
				))
				.then(s => { this.update(); return s; });
		}
		else return Promise.resolve(this.state);
	}

	/**
	 * Handle DOM element enter key press event
	 * @note By convention 'Enter' key press on an input child component will call the component's update method. This
	 * is done to improve rendering performance of parent components using this component.
	 *
	 * @param {KeyboardEvent} event - JavaScript keyboard event (onKeyPress event is recommended).
	 */
	handleInputEnterPress(event) {
		// Call component's update method if 'Enter' key was pressed
		if(event.key === 'Enter') {
			// In fast mode
			if (this.getProp('isFast')) {
				const updateResult = this.update(event);
				if (updateResult instanceof Promise) {
					updateResult.finally(() => executeComponentCallback(this.props.onEnterKey, event));
				} else {
					executeComponentCallback(this.props.onEnterKey, event);
				}
			}
			// Not in fast mode
			else {
				executeComponentCallback(this.props.onEnterKey, event);
			}
		}
	}

	/**
	 * Handle DOM element blur event
	 * @note By convention input child component's blur event will call the component's update method. This is done to
	 * improve rendering performance of parent components using this component.
	 * @param {Event} event - JavaScript blur event.
	 */
	handleInputBlur(event) {
		// Call component's update method
		this.update(event);
	}

	/**
	 * Handle component's main data item changes
	 * @note This is used when component's main data item is represented by a custom child component that returns its own
	 * data. In other words, when data item is handled by one component.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {Function|Object} item - Item to set. Similar to standard 'setState' updater param but if it is used as
	 * an array it receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and
	 * props). If item does not exist 'arrayItem' will be null.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemChange(predicate, item, sort = null) { return this.setItem(predicate, item, sort); }

	/**
	 * Handle component's main data item changes and call component's update method
	 * @note This is used when component's main data item is represented by a custom child component that returns its own
	 * data. In other words, when data item is handled by one component.
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {Function|Object} item - Item to set. Similar to standard 'setState' updater param but if it is used as
	 * an array it receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and
	 * props). If item does not exist 'arrayItem' will be null.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemChangeAndUpdate(predicate, item, sort = null) {
		return this.setItem(predicate, item, sort).then(s => { this.update(); return s; });
	}

	/**
	 * Handle component's main data item remove
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemRemove(predicate, sort) { return this.removeItem(predicate, sort); }

	/**
	 * Handle component's main data item remove and call component's update method
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemRemoveAndUpdate(predicate, sort) {
		return this.removeItem(predicate, sort).then(s => { this.update(); return s; })
	}

	/**
	 * Handle item child input component changes
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemInputChange(predicate, event, sort = null) {
		if(predicate){
			const target = event.target;
			// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
			// main data item field.
			const fieldName = target.name;
			const value = target.type === 'checkbox' ? target.checked : target.value;

			// Update component's main data item field if field name is specified
			if(fieldName) return this.setItemValue(predicate, fieldName, value, sort);
			else return Promise.resolve(this.state);
		}
	}

	/**
	 * Handle item child input component changes and call component's update method
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated. 
	 */
	handleItemInputChangeAndUpdate(predicate, event, sort = null) {
		if(predicate){
			// Persist event in order for it to work asynchronously (in promise then for example)
			event.persist();
			
			const target = event.target;
			// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
			// main data item field.
			const fieldName = target.name;
			const value = target.type === 'checkbox' ? target.checked : target.value;

			// Update component's main data item field if field name is specified
			if(fieldName) this.setItemValue(predicate, fieldName, value, sort).then(s => {this.update(event); return s;});
			else return Promise.resolve(this.state);
		}
	}

	/**
	 * Handle item child field changes
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string} field - Name of the main data item filed to set.
	 * @param {any} value - Value to set.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemValueChange(predicate, field, value, sort = null) {
		if (predicate && field) return this.setItemValue(predicate, field, value, sort);
		else return Promise.resolve(this.state);
	}

	/**
	 * Handle item child field changes and call component's update method
	 *
	 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
	 * @param {string} field - Name of the main data item filed to set.
	 * @param {any} value - Value to set.
	 * @param {string, Array<string, 'asc'|'desc'>|function|null} [sort=null] - Sort the items after update. String will
	 * sort items by the field name in the ascending order. Array expects two items: field name to sort by and sort
	 * order. Function should define a custom sort method.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleItemValueChangeAndUpdate(predicate, field, value, sort = null) {
		if(predicate && field) {
			return this.setItemValue(predicate, field, value, sort).then(s => { this.update(); return s; });
		} else {
			return Promise.resolve(this.state);
		}
	}
}

// TYPE DEFINITIONS ****************************************************************************************************
/**
 * @typedef {Object} DataComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load 
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within 
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load' 
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to 
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a 
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need 
 * to have a different prop field for original data, like input components that use 'originalValue' instead of 
 * 'originalData'.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load 
 * instead of 'data' prop or 'dataPropAlias' options.
 * @property {boolean} [enableDataChangeHistory=false] - Flag that specifies if data change history will be enabled and 
 * used. If change history is enabled some additional methods will be available, like undoData and redoData.
 * @property {boolean} [clearValidationErrorsOnChange=true] - Flag that specifies if validation errors will be cleared 
 * when values change.
 */

/**
 * @typedef {{id: string, data: any, [loadCallback]: Function}} LoadQueueItem
 * 	* id - Unique queue item identifier.
 * 	* data - Data to pass to the load method.
 * 	* loadCallback - Callback function that will be executed after load queue item called and done loading.
 */

export {executeComponentCallback, executeComponentCallbackPromise} from "./BaseComponent";

/**
 * Define component's own props that can be passed to it by parent components
 */
DataComponent.propTypes = {
	// Component's input data
	data: PropTypes.any,
	// Component's original data used to determine if data has changed
	originalData: PropTypes.any,

	// Events
	onChange: PropTypes.func,
	onEnterKey: PropTypes.func,
};
import styles from "./index.module.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from "Core/components/DialogDataComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../../../../../config";
import {StudiesComparisonDataObject} from "../../dataObjects";
import * as actions from "../../actions";
import {getString, isset} from "Core/helpers/data";
import auth from "../../../../../../../../../../auth";
import PropTypes from "prop-types";
import {getGlobalActions} from "Core/helpers/redux";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import FileInput from "Core/components/input/FileInput";
import Label from "Core/components/display/Label";
import {isNotSuccessful, isSuccessful} from "Core/helpers/io";
import {icon_font_close_symbol, icon_font_error_symbol, icon_font_save_symbol} from "Config/app";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {isMacintosh} from "Core/helpers/system";

class StudiesComparisonDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			/** @type {StudiesComparisonDataObject|null} */
			data: new StudiesComparisonDataObject(
				props.study1Id, 
				props.study2Id,
				getString(auth.getCurrentUser(), 'firstName'),
				getString(auth.getCurrentUser(), 'lastName'),
				getString(auth.getCurrentUser(), 'credentials')
			),
			/**
			 * Flag that specifies if comparison report request was successfully submitted
			 * @note Undefined means that request was not sent yet. This flag is used to display appropriate messages 
			 * inside the dialog.
			 */
			success: undefined,
		}, {
			domPrefix: 'studies-comparison-dialog',
			translationPath: `${pageConfig.translationPath}.dialogs.StudiesComparisonDialog`,
			disableLoad: true,
		});
		
		// Render methods
		this.renderError = this.renderError.bind(this);
		this.renderSuccess = this.renderSuccess.bind(this);
	}


	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	save() {
		const {dialogGUIID, generateStudiesComparisonReportAction} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			this.executeAbortableAction(generateStudiesComparisonReportAction, data)
				.then(res => {
					if (isSuccessful(res)) {
						return this.setState({success: true});
					} else if (isNotSuccessful(res)) {
						return this.setErrorMessage(res.errorMessage)
							.then(() => this.setState({success: false}))
					} else {
						return Promise.resolve();
					}
				})
				// Trigger component's onSave event with component's return data as param
				.then(() => executeComponentCallback(this.props.onSave, data, dialogGUIID));
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 *
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation 
	 * file.
	 * @param {string|null} [saveLabel] - Label used for save button. Default value will be loaded from translation file.
	 * @param {string|null} [saveIcon] - Optional icon used for save button.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation 
	 * file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm
	 * dialogs.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderDataActionButtons({
		saveLabel = undefined, saveIcon = undefined, closeLabel = undefined, closeIcon = undefined
	} = {}, hideClose = false) {
		const {success} = this.state;

		let buttons = [];

		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		// Only add save button if 'success' state flag is not set
		// @note The flag is not set if report request was not sent yet
		if (!isset(success)) {
			buttons.push({
				style: BUTTON_STYLE.SUCCESS,
				label: (saveLabel ? saveLabel : this.t('Save', 'general')),
				icon: (isset(saveIcon) && saveIcon !== null ? saveIcon : icon_font_save_symbol),
				onClick: this.save,
			});
		}

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		return this.renderActionButtons(buttons);
	}
	
	/**
	 * Content to render if state 'success' flag is false 
	 * @return {JSX.Element}
	 */
	renderError() {
		return (
			<FormWrapper>
				<br />
				<div className={styles['error-message-title']}>
					<Label 
						icon={icon_font_error_symbol}
						content={this.t('error', 'errors.io.defaultAuthorizedApi.study/generate-comparison-report')} 
					/>
				</div>
				<div className={styles['error-message']}>
					<Label content={this.getErrorMessage()} />
				</div>
			</FormWrapper>
		);
	}

	/**
	 * Content to render if state 'success' flag is true
	 * @return {JSX.Element}
	 */
	renderSuccess() {
		return (
			<FormWrapper>
				<br />
				<div className={styles['success-message-title']}>
					<Label icon="check-circle" content={this.t('success_title')} />
				</div>
				<div className={styles['success-message']}>
					<Label content={this.t('success_desc')} />
				</div>
			</FormWrapper>
		);
	}
	
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		return this.getData() !== null;
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		/** @type {StudiesComparisonDataObject} */
		const data = this.getData();
		const {success} = this.state;
		
		return this.renderDialog(
			this.renderTitle(<Label icon="compress" content={this.t('title')} />),
			(
				success === true ? this.renderSuccess() : 
				success === false ? this.renderError() :
				<FormWrapper>
					<br />
					<FormField label={this.t('physicianSection')} labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}>
						<div className="data-table-component standard full-width">
							<table className="data-table full-width">
								<thead>
									<tr>
										<th className="column"><div className="content">{this.t('firstName')}</div></th>
										<th className="column"><div className="content">{this.t('lastName')}</div></th>
										<th className="column"><div className="content">{this.t('credentials')}</div></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td><div className="content">{data.firstName}</div></td>
										<td><div className="content">{data.lastName}</div></td>
										<td><div className="content">{data.credentials}</div></td>
									</tr>
								</tbody>
							</table>
						</div>
					</FormField>

					<FormField label={this.t('signatureField')} labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}>
						<FileInput
							acceptExtensions={['.jpg', '.png', '.bmp', '.gif']}
							convertExtensions={['.jpg', '.png', '.bmp', '.gif']}
							name="signatureFormData"
							fieldName="multipartSignatureFile"
							showClearButton={true}
							showImgPreviews={true}
							transparentPreview={true}
							onChange={this.handleFileInputChange}
						/>
					</FormField>
				</FormWrapper>
			), {
				saveLabel: this.t('submitButton'),
				saveIcon: 'check',
			}
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
StudiesComparisonDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// ID of the first study
	study1Id: PropTypes.string.isRequired,
	// ID of the second study
	study2Id: PropTypes.string.isRequired,
	
	// Events
	onClose: PropTypes.func,
	onSave: PropTypes.func,	
};

export default connect(null, getGlobalActions(actions))(StudiesComparisonDialog);
import {find, isPlainObject, get, reject, isEmpty, filter} from "lodash";
import {parse, format, isValid, isEqual, isAfter, isBefore} from "date-fns";
import Color from "color";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import reduxStore from "Core/store";
import {selectors as reduxStoreI18nSelectors} from "Core/i18n/reducer";
import {
	getAppDatePluginLocale,
	getAppLocaleDateFormat,
	getAppLocaleDatetimeFormat,
	getLocaleDateFormat
} from "Core/helpers/locale";
import {translate, translatePath} from "Core/i18n";
import zxcvbn from "zxcvbn";
import {minimal_password_strength} from "Config/app";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "Core/const/locale";
import {getDate, getTimeString, timeStrCompare} from "Core/helpers/datetime";
import {getArray, getString, isset} from "Core/helpers/data";

/**
 * Data value validation class
 * @description Class used to validate values inside data objects or arrays.
 */
class DataValueValidation {
	constructor() {
		this.rules = [];
		this.constraints = [];
		this.translations = [];

		// Validation definition methods
		this.addRule = this.addRule.bind(this);
		this.removeRule = this.removeRule.bind(this);
		this.addConstraint = this.addConstraint.bind(this);
		this.addTranslation = this.addTranslation.bind(this);
		this.removeConstraint = this.removeConstraint.bind(this);
		this.clear = this.clear.bind(this);

		// Rule methods
		this.requiredRule = this.requiredRule.bind(this);
		this.numberRule = this.numberRule.bind(this);
		this.integerRule = this.integerRule.bind(this);
		this.timestampRule = this.timestampRule.bind(this);
		this.dateRule = this.dateRule.bind(this);
		this.timeRule = this.timeRule.bind(this);
		this.datetimeRule = this.datetimeRule.bind(this);
		// TODO: this.dateIntervalRule = this.dateIntervalRule.bind(this);
		// TODO: this.timeIntervalRule = this.timeIntervalRule.bind(this);
		this.dateStringRule = this.dateStringRule.bind(this);
		this.dateTimeStringRule = this.dateTimeStringRule.bind(this);
		this.passwordRule = this.passwordRule.bind(this);
		this.colorRule = this.colorRule.bind(this);
		this.emailRule = this.emailRule.bind(this);
		this.sameAsRule = this.sameAsRule.bind(this);
		this.mustNotContainStringsRule = this.mustNotContainStringsRule.bind(this);
		this.customRule = this.customRule.bind(this);

		// Array rule methods
		this.uniqueRule = this.uniqueRule.bind(this);
		this.uniqueIgnoreEmptyRule = this.uniqueIgnoreEmptyRule.bind(this);

		// Constraint methods
		this.minConstraint = this.minConstraint.bind(this);
		this.maxConstraint = this.maxConstraint.bind(this);
		this.betweenConstraint = this.betweenConstraint.bind(this);
		
		// Validation methods
		this.run = this.run.bind(this);
		this.runOnArray = this.runOnArray.bind(this);
	}


	// Validation definition methods ------------------------------------------------------------------------------------
	/**
	 * Add validation rule to the queue
	 *
	 * @param {string} field - Name of the data value field to validate.
	 * @param {...ValidationRule|ValidationRuleObject|{name: string, [options]: {}}} rules - Array of all validation 
	 * rules data value has to pass.
	 */
	addRule(field, ...rules) {
		try {
			for(let idx in rules) {
				// Ignore duplicate rules
				if(typeof find(rules, { field, rule: rules[idx] }) === 'undefined'){
					let ruleName = rules[idx];
					let ruleOptions;

					if(isPlainObject(rules[idx]) || rules[idx] instanceof ValidationRuleObject){
						ruleName = get(rules[idx], 'name');
						ruleOptions = get(rules[idx], 'options');
					}

					if(ruleName){
						this.rules = [
							...this.rules,
							{field, rule: ruleName, options: ruleOptions}
						];
					}
				}
			}
		} catch (e) {
			// do nothing
		}
	}

	/**
	 * Remove validation rule from the queue
	 * @param {string} field - Name of the data value field to remove the rule for.
	 */
	removeRule(field) { this.rules = reject(this.rules, {field}); }

	/**
	 * Add validation constraint to the list of constraints
	 *
	 * @param {string} field - Name of the data value field to add the constraint for.
	 * @param {ValidationConstraintObject} constraints - Constraints array.
	 */
	addConstraint(field, ...constraints){
		try {
			for(let idx in constraints) {
				this.constraints = [
					...this.constraints,
					{field, ...constraints[idx]}
				];
			}
		} catch (e) {
			// do nothing
		}
	}

	/**
	 * Remove validation constraint from the list of constraints
	 * @param {string} field - Name of the data value field to remove the constraint for.
	 */
	removeConstraint(field) { this.constraints = reject(this.constraints, {field}); }

	/**
	 * Add validation error custom translation
	 * 
	 * @param {string} error - Validation error.
	 * @param {string} translation - Validation error custom translation.
	 */
	addTranslation(error, translation) {
		this.translations = {
			...this.translations,
			[error]: translation
		};
	}

	/**
	 * Clear all rules and constraints
	 */
	clear() {
		this.rules = [];
		this.constraints = [];
	}


	// Rule methods -----------------------------------------------------------------------------------------------------
	/**
	 * Validate required
	 *
	 * @param {any} value - Value to validate.
	 * @returns {{passed: boolean, error: ValidationError}}
	 */
	requiredRule(value) {
		let passed;
		
		if (isPlainObject(value)) passed = !isEmpty(value);
		else if(Array.isArray(value)) passed = (value.length > 0);
		else passed = (typeof value !== 'undefined' && value !== null && value !== '');
		
		return passed ? {passed} : {passed: false, error: 'required'};
	}

	/**
	 * Validate floats and integers supporting both . and , decimal delimiters
	 *
	 * @param {any} value - Value to validate.
	 * @returns {{passed: boolean, error: ValidationError}}
	 */
	numberRule(value) {
		const passed = (
			typeof value === 'undefined' || value === null || value === '' ||
			(
				(typeof value === 'number' && isFinite(value)) ||
				(!isNaN(parseFloat(value)) && (value.match(/^-?\d+(\.\d+)?$/) !== null) && isFinite(value))
			)
		);
		return passed ? {passed} : {passed: false, error: 'number'};
	}

	/**
	 * Validate integers
	 *
	 * @param {any} value - Value to validate.
	 * @returns {{passed: boolean, error: ValidationError}}
	 */
	integerRule(value) {
		const passed = (
			typeof value === 'undefined' || value === null || value === '' ||
			(
				(Number.isInteger(value) && isFinite(value)) ||
				(!isNaN(parseInt(value)) && (typeof value !== 'number' && value.match(/^-?\d+$/) !== null) && isFinite(value))
			)
		);
		return passed ? {passed} : {passed: false, error: 'integer'};
	}

	/**
	 * Validate timestamp
	 *
	 * @param {any} value - Value to validate.
	 * @param {string} [error='timestamp'] - Error type (this rule will be used for another date/time related rules).
	 * @returns {{passed: boolean, [error]: ValidationError}}
	 */
	timestampRule(value, error = 'timestamp') {
		const intValue = parseInt(value);

		if (typeof value === 'undefined' || value === null || value === '') return {passed: true};

		try {
			let passed = (new Date(value)).getTime() > 0;
			if(!passed && !isNaN(intValue)) passed = !isNaN((new Date(intValue)).getTime());
			return passed ? {passed} : {passed: false, error};
		} catch (e) {
			return {passed: false, error: 'timestamp'};
		}
	}

	/**
	 * Validate Date
	 *
	 * @param {any} value - Value to validate.
	 * @param {string} [error='date'] - Error type (this rule will be used for another date/time related rules).
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	dateRule(value, error = 'date') {
		if (typeof value === 'undefined' || value === null || value === '' || isValid(value)) return {passed: true};
		return {passed: false, error};
	}

	/**
	 * Validate time (based on dateRule)
	 *
	 * @param {any} value - Value to validate.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	timeRule(value) { return this.dateRule(value, 'time'); }

	/**
	 * Validate datetime (based on dateRule)
	 *
	 * @param {any} value - Value to validate.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	datetimeRule(value) { return this.dateRule(value, 'datetime'); }

	/**
	 * Validate date string
	 * 
	 * @param {any} value - Value to validate.
	 * @param {DateStringRuleOptions} [options={}] - Validation rule options.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	dateStringRule(value, options = {}) {
		if (typeof value === 'undefined' || value === null || value === '') return {passed: true};
		
		const format = get(options, 'format', getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT));
		const dateLocale = get(options, 'locale', getAppDatePluginLocale());

		if (getDate(value, format, dateLocale)) return {passed: true};
		else return {passed: false, error: 'date'};
	}

	/**
	 * Validate datetime string
	 *
	 * @param {any} value - Value to validate.
	 * @param {DateStringRuleOptions} [options={}] - Validation rule options.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	dateTimeStringRule(value, options = {}) {
		if (typeof value === 'undefined' || value === null || value === '') return {passed: true};

		const format = get(
			options, 
			'format', 
			getAppLocaleDatetimeFormat(LOCALE_DATE_FORMAT_NAME.SHORT, LOCALE_TIME_FORMAT_NAME.STANDARD)
		);
		const dateLocale = get(options, 'locale', getAppDatePluginLocale());

		if (getDate(value, format, dateLocale)) return {passed: true};
		else return {passed: false, error: 'datetime'};
	}
	
	/**
	 * Validate standard password
	 * @note Password is validated using a calculated password strength and a 'minimal_password_strength' app config 
	 * option. Password strength is calculated using Dropbox 'zxcvbn' library. 
	 *
	 * @param {any} value - Value to validate.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	passwordRule(value) {
		if (typeof value === 'undefined' || value === null || value === '') return {passed: true};

		try {
			// Get password score
			// @note From 1 to 5 where 1 is the lowest score.
			const strengthData = zxcvbn(value);
			let score = strengthData.score + 1;
			
			return (score >= minimal_password_strength ? {passed: true} : {passed: false, error: 'password'});
		} catch (e) {
			return {passed: false, error: 'password'};
		}
	}

	/**
	 * Validate color
	 * NOTE: This rule uses "color" library to try and create a color.
	 *
	 * @param {any} value - Value to validate. 
	 * @param {ColorRuleOptions} [options={}] - Validation rule options.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	colorRule(value, options = {}) {
		if(typeof value === 'undefined' || value === null || value === '') {
			return {passed: true};
		} else {
			const checkHex = get(options, 'hex', false);
			if (checkHex) {
				if(/^#([0-9A-F]{3}){1,2}$/i.test(value)) return {passed: true};
				else return {passed: false, error: 'colorHex'};
			} else {
				try { new Color(value); return {passed: true}; }
				catch (e) { return {passed: false}; }
			}
		}
	}

	/**
	 * Validate email address
	 *
	 * @param {any} value - Value to validate.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	emailRule(value) {
		if(typeof value === 'undefined' || value === null || value === '') {
			return {passed: true};
		} else {
			if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())) {
				return {passed: true};
			} else {
				return {passed: false, error: 'email'};
			}
		}
	}

	/**
	 * Validate if value is the same as the value of a specific field
	 *
	 * @param {any} value - Value to validate.
	 * @param {SameAsRuleOptions} options - Validation rule options.
	 * @param {Object} data - All data values.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	sameAsRule(value, options, data) {
		if(typeof value === 'undefined' || value === null || value === '') {
			return {passed: true};
		} else {
			const matchField = get(options, 'field');
			const matchFieldLabel = get(options, 'label');
			const matchValue = get(data, matchField);
			let caseInSensitive = get(options, 'caseInSensitive');
			if (matchField && matchFieldLabel && data.hasOwnProperty(matchField)) {
				return (
					(caseInSensitive && value.toLowerCase() === matchValue.toLowerCase()) || 
					(!caseInSensitive && value === matchValue) ? 
						{passed: true} : 
						{passed: false, error: 'sameAs', errorVariables: {field: matchFieldLabel}}
				);
			} else {
				console.error(
					'Validation error! "sameAs" validation failed because match field or label are missing.', 
					{field: matchField, label: matchFieldLabel}
				);
				return {passed: false, error: 'sameAsInvalidInput'};
			}
		}
	}

	/**
	 * Validate if string does not contain forbidden values
	 * @note This validation rule only works for string values. For any other value type will it will pass successfully.
	 *
	 * @param {any} value - Value to validate.
	 * @param {MustNotContainStringsRuleOptions} options - Validation rule options.
	 * @return {
	 * 	{errorVariables: {values: Array}, passed: boolean, error: ValidationError}|{passed: boolean}|{passed: boolean}
	 * }
	 */
	mustNotContainStringsRule(value, options) {
		if (typeof value !== 'string' || value === '') {
			return {passed: true};
		} else {
			const values = getArray(options, 'values');
			const separator = getString(options, 'separator', ', ');
			const quotes = getString(options, 'quotes');
			return (values.some(v => value.indexOf(v) > -1) ?
				{
					passed: false, 
					error: 'mustNotContainStrings', 
					errorVariables: {values: quotes + values.join(quotes + separator + quotes) + quotes}
				} 
				:
				{passed: true}
			);
		}
	}

	/**
	 * Any custom rule
	 * @note Custom rule function must return a proper validation rule response object:
	 * 	{{passed: boolean, error: ValidationError}|{passed: boolean}}
	 *
	 * @param {any} value - Value to validate.
	 * @param {CustomRuleOptions} options - Custom rule options.
	 * @param {Object} data - All data values.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	customRule(value, options, data) {
		const validateFunc = get(options, 'validate');
		return (validateFunc ? validateFunc(value, options, data) : {passed: false, error: 'custom'});
	}


	// Array rules ------------------------------------------------------------------------------------------------------
	/**
	 * Check if the field value is unique to a given array of objects containing the field
	 *
	 * @param {string} field - Field name.
	 * @param {any} value - Field value.
	 * @param {array} array - Array of objects containing a given field.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	uniqueRule(field, value, array) {
		if(filter(array, { [field]: value }).length > 1){
			return {passed: false, error: 'unique'};
		} else {
			return {passed: true};
		}
	}

	/**
	 * Check if the field value is unique to a given array of objects containing the field while ignoring empty values
	 *
	 * @param {string} field - Field name.
	 * @param {any} value - Field value.
	 * @param {array} array - Array of objects containing a given field.
	 * @return {{passed: boolean, error: ValidationError}|{passed: boolean}}
	 */
	uniqueIgnoreEmptyRule(field, value, array) {
		if(value !== '' && value !== null && typeof value !== 'undefined'){
			if(filter(array, { [field]: value }).length > 1){
				return {passed: false, error: 'unique'};
			} else {
				return {passed: true};
			}
		} else {
			return {passed: true};
		}
	}


	// Constraints ------------------------------------------------------------------------------------------------------
	/**
	 * Validate min depending on type
	 *
	 * @param {any} value - Value to check constraints for.
	 * @param {any} constraintValue - Constraint value.
	 * @param {ValidationFieldType} fieldType - Field type.
	 * @param {{
	 * 	[ignoreEmpty]: boolean, 
	 * 	[dateFormat]: string, 
	 * 	[compare]: function,
	 * 	[customErrorMessage]: string|function,
	 * } | null} [options=null] - Constraint options (depends on field type).
	 */
	minConstraint(value, constraintValue, fieldType, options = null) {
		const ignoreEmpty = get(options, 'ignoreEmpty', false);
		const dateFormat = get(options, 'dateFormat', STANDARD_DATE_TIME_FORMAT.MYSQL_DATE);

		// If constraintValue is not specified validation will pass successfully
		if (typeof constraintValue === 'undefined' || constraintValue === null || constraintValue === '') {
			return {passed: true};
		}

		// If ignore empty flag is true empty values will pass validation successfully
		if (ignoreEmpty && (typeof value === 'undefined' || value === null || value === '')) {
			return {passed: true};
		}

		try {
			const constraintIntValue = parseInt(constraintValue);
			const constraintFloatValue = parseFloat(constraintValue);
			const floatValue = parseFloat(value);

			switch (fieldType){
				case VALIDATION_FIELD_TYPE_NUMBER:
					if (value === '' || floatValue >= constraintFloatValue) {
						return {passed: true};
					} else {
						return {passed: false, error: 'minNumber', errorValue: +constraintFloatValue.toFixed(2)};
					}

				case VALIDATION_FIELD_TYPE_TEXT:
					if (value.length >= constraintIntValue) {
						return {passed: true};
					} else {
						return {passed: false, error: 'minText', errorValue: constraintIntValue};
					}

				case VALIDATION_FIELD_TYPE_DATE:
					const valueDate = (value instanceof Date ? value : parse(value, dateFormat, new Date()));
					const constraintValueDate = (
						constraintValue instanceof Date ? constraintValue : parse(constraintValue, dateFormat, new Date())
					);

					if (value === '' || constraintValue === '' || constraintValueDate === null || valueDate === null) {
						return {passed: true};
					} else if (isEqual(valueDate, constraintValueDate) || isAfter(valueDate, constraintValueDate)) {
						return {passed: true};
					} else {
						return {
							passed: false, 
							error: 'minDate', 
							errorValue: format(
								constraintValueDate,
								getLocaleDateFormat(reduxStoreI18nSelectors.getLocale(reduxStore.getState()))
							)
						};
					}

				case VALIDATION_FIELD_TYPE_TIME:
					const valueTimeString = getTimeString(value);
					const constraintTimeString = getTimeString(constraintValue);
					const compareResult = timeStrCompare(valueTimeString, constraintTimeString);
					if (compareResult !== false && compareResult > 0) return {passed: true};
					else return {passed: false, error: 'minTime', errorValue: constraintValue};

				case VALIDATION_FIELD_TYPE_CUSTOM:
					const compareFunction = get(options, 'compare');
					if (typeof compareFunction === 'function') return compareFunction(value, constraintValue, fieldType);
					return {passed: true}; 
					
				// no default
			}
		} catch(e) {
			console.log(e);
			return {passed: false, error: 'min', errorValue: ''};
		}
	}

	/**
	 * Validate max depending on type
	 *
	 * @param {any} value - Value to check constraints for.
	 * @param {any} constraintValue - Constraint value.
	 * @param {ValidationFieldType} fieldType - Field type.
	 * @param {{
	 * 	[ignoreEmpty]: boolean, 
	 * 	[dateFormat]: string, 
	 * 	[compare]: function,
	 * 	[customErrorMessage]: string|function,
	 * } | null} [options=null] - Constraint options (depends on field type).
	 */
	maxConstraint(value, constraintValue, fieldType, options = null){
		const ignoreEmpty = get(options, 'ignoreEmpty', false);
		const dateFormat = get(options, 'dateFormat', STANDARD_DATE_TIME_FORMAT.MYSQL_DATE);

		// If constraintValue is not specified validation will pass successfully
		if (typeof constraintValue === 'undefined' || constraintValue === null || constraintValue === '') {
			return {passed: true};
		}

		// If ignore empty flag is true empty values will pass validation successfully
		if (ignoreEmpty && (typeof value === 'undefined' || value === null || value === '')) {
			return {passed: true};
		}

		try {
			const constraintIntValue = parseInt(constraintValue);
			const constraintFloatValue = parseFloat(constraintValue);
			const floatValue = parseFloat(value);

			switch (fieldType){
				case VALIDATION_FIELD_TYPE_NUMBER:
					if (value === '' || floatValue <= constraintFloatValue) {
						return {passed: true};
					} else {
						return {passed: false, error: 'maxNumber', errorValue: +constraintFloatValue.toFixed(2)};
					}

				case VALIDATION_FIELD_TYPE_TEXT:
					if (value.length <= constraintIntValue) {
						return {passed: true};
					} else {
						return {passed: false, error: 'maxText', errorValue: constraintIntValue};
					}

				case VALIDATION_FIELD_TYPE_DATE:
					const valueDate = (value instanceof Date ? value : parse(value, dateFormat, new Date()));
					const constraintValueDate = (
						constraintValue instanceof Date ? constraintValue : parse(constraintValue, dateFormat, new Date())
					);

					if (value === '' || constraintValue === '' || constraintValueDate === null || valueDate === null) {
						return {passed: true};
					} else if (isEqual(valueDate, constraintValueDate) || isBefore(valueDate, constraintValueDate)) {
						return {passed: true};
					} else {
						return {
							passed: false,
							error: 'maxDate',
							errorValue: format(
								constraintValueDate,
								getLocaleDateFormat(reduxStoreI18nSelectors.getLocale(reduxStore.getState()))
							)
						};
					}

				case VALIDATION_FIELD_TYPE_TIME:
					const valueTimeString = getTimeString(value);
					const constraintTimeString = getTimeString(constraintValue);
					const compareResult = timeStrCompare(valueTimeString, constraintTimeString);
					if (compareResult === -1) return {passed: true};
					else return {passed: false, error: 'maxTime', errorValue: constraintValue};

				case VALIDATION_FIELD_TYPE_CUSTOM:
					const compareFunction = get(options, 'compare');
					if (typeof compareFunction === 'function') return compareFunction(value, constraintValue, fieldType);
					return {passed: true};
					
				// no default
			}
		} catch(e) {
			console.log(e);
			return {passed: false, error: 'max', errorValue: ''};
		}
	}

	/**
	 * Validate value that must be between min and max depending on type
	 *
	 * @param {any} value - Value to check constraints for.
	 * @param {{min: any, max: any}} constraintValue - Constraint value.
	 * @param {ValidationFieldType} fieldType - Field type.
	 * @param {{
	 * 	[ignoreEmpty]: boolean,
	 * 	[dateFormat]: string,
	 * 	[compare]: function,
	 * 	[customErrorMessage]: string|function,
	 * } | null} [options=null] - Constraint options (depends on field type).
	 */
	betweenConstraint(value, constraintValue, fieldType, options = null) {
		const dateFormat = get(options, 'dateFormat', STANDARD_DATE_TIME_FORMAT.MYSQL_DATE);
		let minConstraintForDisplay, maxConstraintForDisplay;
		let constraintError = 'between';
		const minConstraintIntValue = parseInt(constraintValue.min);
		const minConstraintFloatValue = parseFloat(constraintValue.min);
		const minConstraintValueDate = (
			constraintValue.min instanceof Date ? constraintValue.min : parse(constraintValue.min, dateFormat, new Date())
		);
		const maxConstraintIntValue = parseInt(constraintValue.max);
		const maxConstraintFloatValue = parseFloat(constraintValue.max);
		const maxConstraintValueDate = (
			constraintValue.max instanceof Date ? constraintValue.max : parse(constraintValue.max, dateFormat, new Date())
		);
		
		switch (fieldType) {
			case VALIDATION_FIELD_TYPE_NUMBER:
				minConstraintForDisplay = +minConstraintFloatValue.toFixed(2);
				maxConstraintForDisplay = +maxConstraintFloatValue.toFixed(2);
				constraintError = 'betweenNumber';
				break;

			case VALIDATION_FIELD_TYPE_TEXT:
				minConstraintForDisplay = minConstraintIntValue;
				maxConstraintForDisplay = maxConstraintIntValue;
				constraintError = 'betweenText';
				break;

			case VALIDATION_FIELD_TYPE_DATE:
				minConstraintForDisplay = format(
					minConstraintValueDate,
					getLocaleDateFormat(reduxStoreI18nSelectors.getLocale(reduxStore.getState()))
				);
				maxConstraintForDisplay = format(
					maxConstraintValueDate,
					getLocaleDateFormat(reduxStoreI18nSelectors.getLocale(reduxStore.getState()))
				);
				constraintError = 'betweenDate';
				break;

			case VALIDATION_FIELD_TYPE_TIME:
				minConstraintForDisplay = constraintValue.min;
				maxConstraintForDisplay = constraintValue.max;
				constraintError = 'betweenTime';
				break;

			case VALIDATION_FIELD_TYPE_CUSTOM:
				const compareFunction = get(options, 'compare');
				if (typeof compareFunction === 'function') return compareFunction(value, constraintValue, fieldType);
				return {passed: true};

			// no default
		}
		
		const minResult = this.minConstraint(value, get(constraintValue, 'min'), fieldType, options);
		if (minResult.passed) {
			const maxResult = this.maxConstraint(value, get(constraintValue, 'max'), fieldType, options);
			if (maxResult.passed) return {passed: true};
		}
		return {
			passed: false, error: constraintError, errorValue: {min: minConstraintForDisplay, max: maxConstraintForDisplay}
		};
	}


	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Run all validation rules
	 *
	 * @param {Object} data - All data values.
	 * @returns {Object|false}	Object with key being field name and value being an array of validation errors if any.
	 */
	run(data) {
		let errors = {};

		// If there are validation rules specified
		if (this.rules && this.rules.length > 0) {

			// Go through all validation rules
			for (let idx in this.rules) {
				// Skip non-own properties
				if (!this.rules.hasOwnProperty(idx)) continue;
				
				// Get single rule object containing field and rule properties
				const ruleObj = this.rules[idx]; // { field, rule, options }

				// Get data value for ruleObject field (value that needs to be validate)
				const valueToValidate = get(data, ruleObj.field, undefined);

				// If there is a class method specified to handle ruleObj.rule type
				if (this.hasOwnProperty(ruleObj.rule + 'Rule')) {
					// Call the appropriate rule handling method
					const ruleValidateResult = this[ruleObj.rule + 'Rule'](valueToValidate, ruleObj.options, data);
					if (ruleValidateResult.passed !== true) {
						// Check for insert values
						let isInsertValue = false;
						const insertValueCheckMethods = getArray(ruleObj, 'options.insertValueCheck');
						if (insertValueCheckMethods.length) {
							insertValueCheckMethods.some(containsInsertValue => {
								if (containsInsertValue(valueToValidate)) isInsertValue = true;
								return false;
							});
						}

						// Set errors if value is not a valid insert value
						if (!isInsertValue) {
							let ruleValidateError = translate(
								ruleValidateResult.error,
								'validation',
								get(ruleValidateResult, 'errorVariables', {})
							);

							// Override error message if custom translation is specified
							if (this.translations.hasOwnProperty(ruleValidateResult.error)) {
								ruleValidateError = this.translations[ruleValidateResult.error];
							}

							// Populate errors array
							if (!errors.hasOwnProperty(ruleObj.field)) errors[ruleObj.field] = [];
							errors[ruleObj.field].push(ruleValidateError);
						}
					}
				}
			}

		}

		// If there are validation constraints specified
		if (this.constraints && this.constraints.length > 0) {

			// Go through all validation constraints
			for (let idx in this.constraints) {
				// Skip non-own properties
				if (!this.constraints.hasOwnProperty(idx)) continue;
				
				// Get single constraints object
				/** @type {ValidationConstraintItemObject} */
				const constraintsObj = this.constraints[idx];
				const constraintOptions = get(constraintsObj, 'options');

				// Get data value for ruleObject field (value that needs to be validated)
				const valueToValidate = get(data, constraintsObj.field, undefined);

				// If there is a class method specified to handle constraintsObj.constraint
				if (this.hasOwnProperty(constraintsObj.constraint + 'Constraint')) {
					// Call the appropriate constraint handling method
					const constraintValidateResult = this[constraintsObj.constraint + 'Constraint'](
						valueToValidate, constraintsObj.value, constraintsObj.fieldType, constraintOptions
					);
					
					let errorMessage;
					if (constraintValidateResult.passed !== true) {
						const customErrorMessage = get(constraintOptions, 'customErrorMessage');
						
						// Use custom error message if it was specified in the constraint options
						if (isset(customErrorMessage) && customErrorMessage !== null) {
							// If 'customErrorMessage' is a function call it with the validation result and use the result as
							// the error message
							if (typeof customErrorMessage === 'function') {
								errorMessage = customErrorMessage(constraintValidateResult);
							}
							// Use the 'customErrorMessage' as the error message
							else {
								errorMessage = customErrorMessage;
							}
						}
						// Handel "between" constraint because it has two error values
						else if (constraintsObj.constraint === 'between') {
							errorMessage = translatePath(
								`validation.constraint.${constraintValidateResult.error}`, {
									constraintMin: constraintValidateResult.errorValue.min,
									constraintMax: constraintValidateResult.errorValue.max,
								});
						}
						// Use the regular constraint error message
						else {
							errorMessage = translatePath(
								`validation.constraint.${constraintValidateResult.error}`, {
									constraint: constraintValidateResult.errorValue
								});
						}

						// Populate errors array
						if (!errors.hasOwnProperty(constraintsObj.field)) errors[constraintsObj.field] = [];
						errors[constraintsObj.field].push(errorMessage);
					}
				}
			}

		}

		return (errors.constructor === Object && Object.keys(errors).length > 0) ? errors : false;
	}

	/**
	 * Run all validation rules on an array
	 *
	 * @param {array} data - Array of same data objects where each object should be validated.
	 * @param {string|null} [rowIdentifierField=null] - Data row field name used as unique identifier for that row 
	 * (ex: 'GUIID').
	 * @returns {Object|false} Object where keys are unique row identifiers specified by "rowIdentifierField" or row
	 * index if "rowIdentifierField" is not specified and values are objects with key being field name and value being an
	 * array of validation errors if any.
	 */
	runOnArray(data, rowIdentifierField = null) {
		let errors = {};

		// If there are validation rules specified
		if (this.rules && this.rules.length > 0) {

			// Go through all validation rules
			for (let idx in this.rules) {
				// Skip non-own properties
				if (!this.rules.hasOwnProperty(idx)) continue;
				
				// Get single rule object containing field and rule properties
				const ruleObj = this.rules[idx]; // { field, rule, options }

				data.forEach((row, rowIndex) => {
					// Get data value for ruleObject field (value that needs to be validated)
					const valueToValidate = get(row, ruleObj.field, undefined);

					// If there is a class method specified to handle ruleObj.rule type
					if (this.hasOwnProperty(ruleObj.rule + 'Rule')) {
						// Call the appropriate rule handling method
						let ruleValidateResult;

						// Handle unique rule
						if (ruleObj.rule === 'unique') {
							ruleValidateResult = this.uniqueRule(ruleObj.field, valueToValidate, data);
						}
						// Handle unique rule that will ignore empty values
						else if(ruleObj.rule === 'uniqueIgnoreEmpty') {
							ruleValidateResult = this.uniqueIgnoreEmptyRule(ruleObj.field, valueToValidate, data);
						}
						// Handle all other rules (except unique)
						else {
							ruleValidateResult = this[ruleObj.rule + 'Rule'](valueToValidate, ruleObj.options, data);
						}

						if (ruleValidateResult.passed !== true) {
							// Check for insert values
							let isInsertValue = false;
							const insertValueCheckMethods = getArray(ruleObj, 'options.insertValueCheck');
							if (insertValueCheckMethods.length) {
								insertValueCheckMethods.some(containsInsertValue => {
									if (containsInsertValue(valueToValidate)) isInsertValue = true;
									return false;
								});
							}
							
							// Set errors if value is not a valid insert value
							if (!isInsertValue) {
								let ruleValidateError = translate(
									ruleValidateResult.error,
									'validation',
									get(ruleValidateResult, 'errorVariables', {})
								);

								// Override error message if custom translation is specified
								if (this.translations.hasOwnProperty(ruleValidateResult.error)) {
									ruleValidateError = this.translations[ruleValidateResult.error];
								}

								// Get unique row identifier
								// NOTE: Can be defined by rowIdentifierField or row index if rowIdentifierField was not specified
								const identifier = (rowIdentifierField ? row[rowIdentifierField] : rowIndex);

								// Create error sections for unique row identifiers if they are missing
								if (!errors.hasOwnProperty(identifier)) errors[identifier] = {};
								// Create error field arrays if they are missing
								if (!errors[identifier].hasOwnProperty(ruleObj.field)) errors[identifier][ruleObj.field] = [];

								// Populate errors object
								errors[identifier][ruleObj.field].push(ruleValidateError);
							}
						}
					}
				});
			}

		}

		// If there are validation constraints specified
		if (this.constraints && this.constraints.length > 0) {

			// Go through all validation constraints
			for (let idx in this.constraints){ 
				// Skip non-own properties
				if (!this.constraints.hasOwnProperty(idx)) continue;
				
				// Get single constraints object
				/** @type {ValidationConstraintItemObject} */
				const constraintsObj = this.constraints[idx];
				const constraintOptions = get(constraintsObj, 'options');

				data.forEach((row, rowIndex) => {
					// Get data value for ruleObject field (value that needs to be validate)
					const valueToValidate = get(row, constraintsObj.field, undefined);

					// If there is a class method specified to handle constraintsObj.constraint
					if (this.hasOwnProperty(constraintsObj.constraint + 'Constraint')) {
						// Call the appropriate constraint handling method
						const constraintValidateResult = this[constraintsObj.constraint + 'Constraint'](
							valueToValidate, constraintsObj.value, constraintsObj.fieldType, constraintOptions
						);
						
						let errorMessage;
						if (constraintValidateResult.passed !== true) {
							const customErrorMessage = get(constraintOptions, 'customErrorMessage');

							// Use custom error message if it was specified in the constraint options
							if (isset(customErrorMessage) && customErrorMessage !== null) {
								// If 'customErrorMessage' is a function call it with the validation result and use the result as
								// the error message
								if (typeof customErrorMessage === 'function') {
									errorMessage = customErrorMessage(constraintValidateResult);
								}
								// Use the 'customErrorMessage' as the error message
								else {
									errorMessage = customErrorMessage;
								}
							}
							// Handel "between" constraint because it has two error values
							else if (constraintsObj.constraint === 'between') {
								errorMessage = translatePath(
									`validation.constraint.${constraintValidateResult.error}`, {
										constraintMin: constraintValidateResult.errorValue.min,
										constraintMax: constraintValidateResult.errorValue.max,
									});
							}
							// Use the regular constraint error message
							else {
								errorMessage = translatePath(
									`validation.constraint.${constraintValidateResult.error}`, {
										constraint: constraintValidateResult.errorValue
									});
							}

							// Get unique row identifier
							// @note Can be defined by rowIdentifierField or row index if rowIdentifierField was not specified.
							const identifier = (rowIdentifierField ? row[rowIdentifierField] : rowIndex);

							// Create error sections for unique row identifiers if they are missing
							if (!errors.hasOwnProperty(identifier)) errors[identifier] = {};
							// Create error field arrays if they are missing
							if (!errors[identifier].hasOwnProperty(constraintsObj.field)) {
								errors[identifier][constraintsObj.field] = [];
							}

							// Populate errors array
							errors[identifier][constraintsObj.field].push(errorMessage);
						}
					}
				});
			}

		}

		return (errors.constructor === Object && Object.keys(errors).length > 0) ? errors : false;
	}

}

// Data types
/**
 * @typedef {
 * 'required'|'number'|'integer'|'timestamp'|'date'|'time'|'datetime'|'password'|'color'|'email'|'sameAs'|
 * 'mustNotContainStrings'|'custom'
 * } ValidationRule
 */

/**
 * @typedef {
 * 'required'|'number'|'integer'|'timestamp'|'date'|'time'|'datetime'|'password'|'unique'|'dateInterval'|
 * 'dateIntervalSeparator'|'dateIntervalFromTo'|'timeInterval'|'timeIntervalSeparator'|'timeIntervalFromTo'|'color'|
 * 'colorHex'|'email'|'sameAs'|'sameAsInvalidInput'|'mustNotContainStrings'|'custom'
 * } ValidationError
 */

/**
 * @typedef {Object} DateStringRuleOptions
 * @property {string} [format] - Date format (date-fns) to check the date string against. If not specified, current app
 * locale date format will be used.
 * @property {any} [locale] - Locale (date-fns) to use when checking the date string. If not specified, current app 
 * locale will be used.
 */

/**
 * @typedef {Object} ColorRuleOptions
 * @property {boolean} [hex=false] - If true color will be validated as a HEX color string (ex: #FFFFFF) using a regular
 * expression.
 */

/**
 * @typedef {Object} SameAsRuleOptions
 * @property {string} field - Filed to match the value with.
 * @property {string} label - Translated label of the field to match the value with used it error messages.
 * @property {boolean} [caseInSensitive=false] - Flag that determines if comparison is case-sensitive.
 */

/**
 * @typedef {Object} MustNotContainStringsRuleOptions
 * @property {string[]} values - Values that must not be contained in the value.
 * @property {string} [separator=', '] - Join separator used for displaying the forbidden values list.
 * @property {string} [quotes] - Single forbidden value quote used for displaying the forbidden values list.
 */

/**
 * @typedef {Object} CustomRuleOptions
 * @property {Function} validate - Custom validate function that will receive 'value', 'options' and 'data' argument.
 */

// Data objects
export class ValidationRuleObject {
	/**
	 * Constructor
	 * @param {ValidationRule} name - Rule name (rule function name before 'Rule' suffix).
	 * @param {ColorRuleOptions|SameAsRuleOptions|MustNotContainStringsRuleOptions|CustomRuleOptions} [options] - Rule 
	 * options (depending on the rule).
	 */
	constructor(name, options = {}) {
		this.name = name;
		this.options = options;
	}
}

export class ValidationConstraintObject {
	/**
	 * @param {'min'|'max'|'between'} constraint - Constraint type.
	 * @param {any|{min: any, max: any}} value - Constraint value.
	 * @param {ValidationFieldType} fieldType - Field type.
	 * @param {{
	 * 	[ignoreEmpty]: boolean, 
	 * 	[dateFormat]: string, 
	 * 	[compare]: function, 
	 * 	[customErrorMessage]: string|function
	 * }} [options] - Constraint options (depends on field type).
	 */
	constructor(constraint, value, fieldType, options) {
		this.constraint = constraint;
		this.value = value;
		this.fieldType = fieldType;
		this.options = options;
	}
}

export class ValidationConstraintItemObject extends ValidationConstraintObject {
	/**
	 * @param {string} field - Data field name.
	 * @param {'min'|'max'|'between'} constraint - Constraint type.
	 * @param {any|{min: any, max: any}} value - Constraint value.
	 * @param {ValidationFieldType} fieldType - Field type.
	 * @param {{
	 * 	[ignoreEmpty]: boolean, 
	 * 	[dateFormat]: string, 
	 * 	[compare]: function, 
	 * 	[customErrorMessage]: string|function
	 * }} [options] - Constraint options (depends on field type).
	 */
	constructor(field, constraint, value, fieldType, options) {
		super(constraint, value, fieldType, options);
		this.field = field;
	}
}

/** @typedef {string} ValidationFieldType */
export const VALIDATION_FIELD_TYPE_NUMBER = 'number';
export const VALIDATION_FIELD_TYPE_TEXT = 'text';
export const VALIDATION_FIELD_TYPE_DATE = 'date';
export const VALIDATION_FIELD_TYPE_TIME = 'time';
export const VALIDATION_FIELD_TYPE_CUSTOM = 'custom';
/** @enum {ValidationFieldType} */
export const VALIDATION_FIELD_TYPE = {
	NUMBER: VALIDATION_FIELD_TYPE_NUMBER,
	TEXT: VALIDATION_FIELD_TYPE_TEXT,
	DATE: VALIDATION_FIELD_TYPE_DATE,
	TIME: VALIDATION_FIELD_TYPE_TIME,
	CUSTOM: VALIDATION_FIELD_TYPE_CUSTOM,
}
/** @type {ValidationFieldType[]} */
export const VALIDATION_FIELD_TYPES = [
	VALIDATION_FIELD_TYPE_NUMBER, 
	VALIDATION_FIELD_TYPE_TEXT, 
	VALIDATION_FIELD_TYPE_DATE, 
	VALIDATION_FIELD_TYPE_TIME,
	VALIDATION_FIELD_TYPE_CUSTOM
];

export default DataValueValidation;
import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {AccountsItemSubAccountsDataObject} from '../dataObjects'

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AccountsItemSubAccountsDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new AccountsItemSubAccountsDataObject(
		getString(result, 'id'),
		getString(result, 'idAccount'),
		getString(result, 'name')
	);
	
	return result;
}

/**
 * Get output data from input data
 * @param {AccountsItemSubAccountsDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		idAccount: result.idAccount,
		name: result.name
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}
import {cloneDeep, get} from "lodash";
import {getBoolean, getNumber, getString} from "Core/helpers/data";
import {ManageDocumentsListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ManageDocumentsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ManageDocumentsListItemDataObject(
		getString(result, 'id'),
		getString(result, 'title'),
		getBoolean(result, 'linkFlag'),
		getString(result, 'link'),
		getDate(get(result, 'releaseDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getNumber(result, 'ordinal', 1)
	);

	return result;
}

// @note There is no output function since list is read only.
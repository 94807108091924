import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getNumber, isset, trimArray} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol, icon_font_edit_symbol} from "Config/app";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {isSuccessful} from "Core/helpers/io";
import {USER_TYPES} from "./const";
import {isSystemAdmin} from "../../../../helpers/currentUser";
import {getBool} from "Core/helpers/data";
import {AsyncMountError} from "Core/errors";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getUsersList(state),
	mainListPagination: selectors[reducerStoreKey].getUsersListPagination(state),
	mainListSort: selectors[reducerStoreKey].getUsersListSort(state),
	mainListFilter: selectors[reducerStoreKey].getUsersListFilter(state),
	allowedAccounts: selectors[reducerStoreKey].getAllowedAccounts(state),
});

class UsersPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
				
				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'users-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);

		// Action methods
		this.resetTermsAndConditions = this.resetTermsAndConditions.bind(this);
		this.resetConsent = this.resetConsent.bind(this);
		this.sendActivationEmail = this.sendActivationEmail.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}
	

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 */
	loadPageData() {
		const {mainList, loadUsersListAction, loadAccountRoleMapAction} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); })
				// Load account role map into Redux store
				.then(() => this.executeAbortableActionMount(loadAccountRoleMapAction, isSystemAdmin()));
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadUsersListAction, isSystemAdmin()))
				.then(() => this.executeAbortableActionMount(loadAccountRoleMapAction, isSystemAdmin()))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadUsersListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadUsersListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadUsersListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadUsersListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadUsersListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadUsersListAction, isSystemAdmin(), oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {loadUsersListAction, mainListPagination, mainListSort} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadUsersListAction, isSystemAdmin(), oFilter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		return this.openMainListItemPopup(true);
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {loadUsersItemAction} = this.props;

		// Open main list item popup if it is not already opened
		// @note This is done to ensure that create dialog does not open another dialog after creating the item or to 
		// avoid opening another dialog if item ID in the URL changes programmatically. Dialog data will change because 
		// Redux store is used.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openMainListItemPopup();
		}
		// If dialog is opened update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			mainListItemPopup.updateDynamics({isNew: false}).then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadUsersItemAction, isSystemAdmin(), id)
					.then(mainListItem => {
						// If main list item is successfully loaded
						if (mainListItem) {
							return this.urlComponentGUIID;
						}
						// If main list item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close main list item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes main list item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new main list item popup should be opened.
	 */
	openMainListItemPopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(ItemPopup, {
			isNew,
			onClose: this.handleMainListItemPopupClose,
			sendActivationEmailItemAction: this.sendActivationEmail,
			redirectToItem: this.redirectToItem,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {closePopupAction, clearUsersItemAction} = this.props;

		// Close item popup
		closePopupAction(this.getUrlComponentGUIID());
		this.clearUrlComponentGUIID();

		// Clear popup Redux data
		clearUsersItemAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {Promise<*>}
	 */
	handleMainListItemPopupClose() {
		this.redirectToBase();
		return new Promise(resolve =>
			// Timeout is added to allow for the popup close animation to finish 
			setTimeout(() => resolve(this.reloadMainList()))
		);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset terms and conditions flag for all users
	 * @return {Promise<void>}
	 */
	resetTermsAndConditions() {
		const {
			openDialogAction, closeDialogAction, resetTermsAndConditionsAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('reset_terms_confirm'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(resetTermsAndConditionsAction)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('reset_terms_success_msg', 'ItemPopup'));
								return this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'reset-terms-and-conditions-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600
			});
			this.setOption(
				'dialogsToCloseOnUnmount', 
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	/**
	 * Reset GDPR consent flag for all users
	 * @return {Promise<void>}
	 */
	resetConsent() {
		const {
			openDialogAction, closeDialogAction, resetConsentAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('reset_consent_confirm'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(resetConsentAction)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('reset_consent_success_msg', 'ItemPopup'));
								return this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'reset-consent-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}

	/**
	 * Send activation email for a main list item
	 * @param {UsersListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	sendActivationEmail(item) {
		const {
			openDialogAction, closeDialogAction, userItemSendActivationEmailAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_send_activation_email', '', '', {name: item.userName}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(userItemSendActivationEmailAction, isSystemAdmin(), item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(
									this.tt('sending_activation_email_success_msg', 'ItemPopup')
								);
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'item-send-activation-email-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	
	
	// Search filter methods --------------------------------------------------------------------------------------------
	/**
	 * Returns the list of search filters, depending on the current users type. 
	 * @returns {SimpleStaticSearchOptionObject[]}
	 */
	getSearchFilters() {
		const {allowedAccounts} = this.props;
		
		let options = [
			new SimpleStaticSearchOptionObject(
				'userName',
				this.t('userNameField')
			),
			new SimpleStaticSearchOptionObject(
				'firstName',
				this.t('firstNameField')
			),
			new SimpleStaticSearchOptionObject(
				'lastName',
				this.t('lastNameField')
			),
			new SimpleStaticSearchOptionObject(
				'email',
				this.t('emailField')
			),
			new SimpleStaticSearchOptionObject(
				'defaultAccountId',
				this.t('defaultAccountField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
				{
					isClearable: true,
					options: allowedAccounts.map(i => ({label: `${i.name} [${i.abmId}]`, value: i.id})),
				}
			)
		];
		
		// Add userType filter if current user is System Administrator.
		if(isSystemAdmin()) {
			options.push(new SimpleStaticSearchOptionObject(
				'userType',
				this.t('userTypeField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
				{
					isClearable: true,
					options: USER_TYPES.map(o => ({label: this.t(`userTypeOptions.${o}`), value: o})),
				}
			))
		}
		
		return options;
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {UsersListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		const canSendEmail = (!getBool(row, 'activated') && getBool(row, 'enabled'));
		
		return (
			<div className="actions">
				<Tooltip
					tag="div"
					title={this.t('send_activation_email_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={canSendEmail ? BUTTON_STYLE.ACTION : BUTTON_STYLE.SUBTLE}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="envelope-o"
						onClick={() => this.sendActivationEmail(row)}
						disabled={!canSendEmail}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={this.t('edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>
					
					<div className="action-button">
						<Link to={this.getCreateRedirectTo()} className="button action solid big">
							<Label icon={icon_font_create_symbol} content={this.t('create_new')} />
						</Link>
					</div>
				</div>
			</h1>
		);
	}
	
	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={this.getSearchFilters()}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				{
					isSystemAdmin() ?
						<div className="toolbar standard for-table-with-filter">
							<Button
								icon="undo"
								label={this.t('reset_terms')}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								onClick={this.resetTermsAndConditions}
							/>
							<div className="separator" />
							<Button
								icon="undo"
								label={this.t('reset_consent')}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								onClick={this.resetConsent}
							/>
							<div className="separator" />
						</div> 
						: null
				}
				
				
				<DataTable
					id="main-page-table"
					className="standard sticky-last-column"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'activated',
							sortName: 'activated',
							label: this.t('activatedField'),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							dataTypeOptions: {
								supportHtml: true,
								alignContent: 'center',
								translatePath: 'constants.booleans.icon_colored',
								trueLabel: 'True',
								falseLabel: 'False',
							},
							width: 1,
						},
						{
							name: 'enabled',
							sortName: 'enabled',
							label: this.t('enabledField'),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							dataTypeOptions: {
								supportHtml: true,
								alignContent: 'center',
								translatePath: 'constants.booleans.icon_colored',
								trueLabel: 'True',
								falseLabel: 'False',
							},
							width: 1,
						},
						{
							name: 'acceptedTerms',
							sortName: 'acceptedTerms',
							label: this.t('acceptedTermsField'),
							tooltip: this.t('acceptedTermsFieldTooltip'),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							dataTypeOptions: {
								supportHtml: true,
								alignContent: 'center',
								translatePath: 'constants.booleans.icon_colored',
								trueLabel: 'True',
								falseLabel: 'False',
							},
							width: 1,
						},
						{
							name: 'consentGDPR',
							sortName: 'consentGDPR',
							label: this.t('consentGDPRField'),
							tooltip: this.t('consentGDPRFieldTooltip'),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							dataTypeOptions: {
								supportHtml: true,
								alignContent: 'center',
								translatePath: 'constants.booleans.icon_colored',
								trueLabel: 'True',
								falseLabel: 'False',
							},
							width: 1,
							widthLessThanLabel: true,
						},
						{
							name: 'psg2',
							label: this.t('psg2Field', `${pageConfig.translationPath}.ItemPopup.MainTab`),
							dataType: DATA_TABLE_CELL_TYPE.BOOL,
							dataTypeOptions: {
								supportHtml: true,
								alignContent: 'center',
								translatePath: 'constants.booleans.icon_colored',
								trueLabel: 'True',
								falseLabel: 'False',
							},
							width: 1,
							widthLessThanLabel: true,
						},
						{
							name: 'firstName',
							sortName: 'firstName',
							label: this.t('firstNameField')
						},
						{
							name: 'lastName',
							sortName: 'lastName',
							label: this.t('lastNameField')
						},
						{
							name: 'email',
							sortName: 'email',
							label: this.t('emailField')
						},
						{
							name: 'userName',
							sortName: 'userName',
							label: this.t('userNameField')
						},
						{
							name: 'userType',
							sortName: 'userType',
							label: this.t('userTypeField'),
							dataType: DATA_TABLE_CELL_TYPE.TEXT,
							dataTypeOptions: {
								translatePath: `${pageConfig.translationPath}.userTypeOptions`
							}
						},
						{
							name: 'defaultAccountDisplayName',
							sortName: 'defaultAccountDisplayName',
							label: this.t('defaultAccountField'),
							minWidth: 250,
						},
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								standardWrapper: false,
								content: this.renderActions,
							},
							stopPropagation: true,
							width: 1,
						}
					]}
					onRowClick={data => this.redirectToItem(data.id)}
					data={mainList}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), 'layout-with-table', undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(UsersPage);
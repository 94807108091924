import {ioJsonFetchItemAction, ioJsonSaveAction} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {actionCreators} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import * as dataMap from "./dataMap";

/**
 * Fetch a specific sleep diary by ID
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Sleep diary ID.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const fetchSleepDiaryAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'study-diary/fetch-by-id',
		id
	)(dispatch);
};

/**
 * Load a specific sleep diary into Redux store by ID
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Sleep diary ID.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const loadSleepDiaryAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#sleep-diary-popup');
	return fetchSleepDiaryAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].set(dataMap.input(responseData.data)));
			hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear sleep diary from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearSleepDiaryAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clear());
};

/**
 * Create sleep diary
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - Study ID.
 * @param {SleepDiaryDataObject} sleepDiary - Sleep diary to save.
 * @return {function(*=): Promise<SleepDiaryDataObject>} Promise that will resolve with the created sleep diary received 
 * from IO or undefined if creation failed.
 */
export const createSleepDiaryAction = (abortCallback, studyId, sleepDiary) => dispatch => {
	const loading = showLoading('#sleep-diary-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'study-diary/create',
		{
			id: studyId,
			data: {...dataMap.output(sleepDiary), id: ''}
		},
		undefined,
		false,
		hideLoadingFunction(loading)
	)(dispatch);
		// NOTE: This is commented out since the sleep diary popup will close as soon as sleep diary is created so there 
		// is no need to load data into Redux store. Please note that, for the same reason, 'requestSavedData' argument of
		// the 'ioJsonSaveAction' function is set to false.
		/*// Get mapped data from response data
		.then(responseData => isset(responseData) ? dataMap.input(responseData.data) : undefined);*/
};

/**
 * Update sleep diary
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} studyId - Study ID.
 * @param {SleepDiaryDataObject} sleepDiary - Sleep diary item to save.
 * @return {function(*=): Promise<SleepDiaryDataObject>} Promise that will resolve with the updated sleep diary received 
 * from IO or undefined if updating failed.
 */
export const updateSleepDiaryAction = (abortCallback, studyId, sleepDiary) => dispatch => {
	const loading = showLoading('#sleep-diary-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'study-diary/update-by-id',
		{
			id: studyId,
			data: dataMap.output(sleepDiary)
		},
		undefined,
		false,
		hideLoadingFunction(loading)
	)(dispatch);
		// NOTE: This is commented out since the sleep diary popup will close as soon as sleep diary is updated so there 
		// is no need to load the updated data into Redux store. Please note that, for the same reason, 'requestSavedData'
		// argument of the 'ioJsonSaveAction' function is set to false.
		/*// Get mapped data from response data
		.then(responseData => isset(responseData) ? dataMap.input(responseData.data) : undefined)
		// Set current data to Redux store so that changes will be detected once item has been updated
		.then(updatedSleepDiary => {
			if (isset(updatedSleepDiary)) dispatch(actionCreators[reducerStoreKey].set(sleepDiary));
			return updatedSleepDiary;
		})
		// Save the updated data to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updatedSleepDiary => {
			if (isset(updatedSleepDiary)) dispatch(actionCreators[reducerStoreKey].set(updatedSleepDiary));
			return updatedSleepDiary; 
		});*/
};
import {cloneDeep, get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'sleepDiary';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@sleepDiary/reset',
	SET: '@sleepDiary/set_data',
	CLEAR: '@sleepDiary/clear_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	set: data => ({type: REDUCER_TYPES.SET, data}),
	clear: () => ({type: REDUCER_TYPES.CLEAR}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {SleepDiaryDataObject}
	 */
	data: null, 
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET: return {...state, data: action.data};
		case REDUCER_TYPES.CLEAR: return {...state, data: cloneDeep(initialState.data)}

		default: return state;
	}
};

// Selectors
export const selectors = {
	getSleepDiary: state => get(state, [reducerStoreKey, 'data']),
};

export default reducer;
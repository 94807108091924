import {fileNameMap} from "./fileNameMap";
import {getString} from "Core/helpers/data";

/**
 * Get the name of the file to download for a specified study action
 * 
 * @param {StudyAction} studyAction
 * @param {string} [studyId]
 * @param {string} [defaultFileName='file']
 * @return {string}
 */
export const getFileNameForStudyAction = (studyAction, studyId, defaultFileName = 'file') => {
	return (
		!!studyAction ? 
			getString(fileNameMap, studyAction, defaultFileName).replace('##STUDY_ID##', getString(studyId)) : 
			defaultFileName
	);
}
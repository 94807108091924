/** @typedef {string} DeviceType */
export const DEVICE_TYPE_X4 = 'X4';
export const DEVICE_TYPE_X8 = 'X8';
export const DEVICE_TYPE_NIGHT_SHIFT = 'NIGHT_SHIFT';
export const DEVICE_TYPE_X8_LE = 'X8_LE';
/** @enum {DeviceType} */
export const DEVICE_TYPE = {
	X4: DEVICE_TYPE_X4,
	X8: DEVICE_TYPE_X8,
	NIGHT_SHIFT: DEVICE_TYPE_NIGHT_SHIFT,
	X8_LE: DEVICE_TYPE_X8_LE,
};
/** @type {DeviceType[]} */
export const DEVICE_TYPES = [
	DEVICE_TYPE_X4,
	DEVICE_TYPE_X8,
	DEVICE_TYPE_NIGHT_SHIFT,
	DEVICE_TYPE_X8_LE,
];


/** @typedef {string} TicketStatus */
export const TICKET_STATUS_PROCESSING = 'PROCESSING';
export const TICKET_STATUS_RESOLVED = 'RESOLVED';
/** @enum {TicketStatus} */
export const TICKET_STATUS = {
	PROCESSING: TICKET_STATUS_PROCESSING,
	RESOLVED: TICKET_STATUS_RESOLVED,
};
/** @type {TicketStatus[]} */
export const TICKET_STATUSES = [
	TICKET_STATUS_PROCESSING,
	TICKET_STATUS_RESOLVED,
];
import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {get} from "lodash";
import {getArray, getString} from "Core/helpers/data";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import AdvancedDropdown, {ADVANCED_DROPDOWN_POSITION} from "Core/components/display/AdvancedDropdown";
import Label from "Core/components/display/Label";
import DownloadButton from "Core/components/action/DownloadButton";
import {getIOUrl} from "Core/io/helper";
import {main_layout_element} from "Config/app";
import {rtrimString} from "Core/helpers/string";

class MainTableReports extends DataComponent {
	constructor(props) {
		super(props, {
			/** @type {{questionnaireReports: string[], sleepProfilerReports: string[]} | null} */
			data: null,
			/** @type {boolean} */
			dataLoading: false,
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'reports-dropdown',
			disableLoad: true,
		});

		// Data methods
		this.loadReportList = this.loadReportList.bind(this);
		this.clearAllData = this.clearAllData.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load the list of reports
	 *
	 * @return {Promise<Object>}
	 */
	loadReportList() {
		const {fetchStudiesListItemReportListAction} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');

		return this.setState({dataLoading: true})
			.then(() => this.executeAbortableAction(
				{id: 'fetch-reports',  action: fetchStudiesListItemReportListAction}, 
				study.id
			))
			.then(response => this.setData(get(response, 'data')))
			.then(() => this.setState({dataLoading: false}));
	}

	/**
	 * Clear all component's relevant internal data
	 *
	 * @return {Promise<Object>}
	 */
	clearAllData() {
		// Abort fetching reports
		this.abortAction('fetch-reports');
		
		return this.clearData()
			.then(() => this.setState({dataLoading: false}));
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {disabled} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');
		const {dataLoading} = this.state;
		const data = this.getData();
		const questionnaireReports = getArray(data, 'questionnaireReports');
		const sleepProfilerReports = getArray(data, 'sleepProfilerReports');

		return (
			<AdvancedDropdown
				className={this.getOption('domPrefix')}
				defaultPosition={ADVANCED_DROPDOWN_POSITION.LEFT}
				label={(
					<Button
						big={true}
						className="action-btn"
						displayStyle={disabled ? BUTTON_STYLE.SUBTLE : BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="page"
						iconProps={{symbolPrefix: 'icofont-'}}
					/>
				)}
				parent={main_layout_element}
				boundRect={document.querySelector(`.layout-page`)?.getBoundingClientRect()}
				loading={dataLoading}
				contentClassName={`${this.getOption('domPrefix')}-content no-padding`}
				contentSize="0.8rem"
				onOpen={this.loadReportList}
				onClosed={this.clearAllData}
				disabled={disabled}
			>
				{/* Questionnaire reports ------------------------------------------------------------------------------ */}
				{
					data !== null ?
						<div className={`${this.getOption('domPrefix')}-item text-left`} data-group-label={true}>
							<Label content={this.t('questionnaire_reports_label')} />
						</div>
						: null
				}
				{
					data !== null ?
						(
							questionnaireReports.length > 0 ?
								questionnaireReports.map(qr =>
									<DownloadButton
										id={qr}
										label={qr}
										className={`${this.getOption('domPrefix')}-item`}
										displayStyle={BUTTON_DISPLAY_TYPE.NONE}
										url={getIOUrl('defaultAuthorizedApi', 'study/read-report')}
										filename={rtrimString(getString(qr), '.enc2')}
										data={{
											id: study.id,
											file: qr,
										}}
									/>
								)
								:
								<div className={`${this.getOption('domPrefix')}-item`} data-label={true}>
									<Label content={this.t('no_reports')} />
								</div>
						)
						: null
				}


				{/* Sleep Profiler reports ------------------------------------------------------------------------------ */}
				{
					data !== null ?
						<div className={`${this.getOption('domPrefix')}-item text-left`} data-group-label={true}>
							<Label content={this.t('sleep_profiler_reports_label')} />
						</div>
						: null
				}
				{
					data !== null ?
						(
							sleepProfilerReports.length > 0 ?
								sleepProfilerReports.map(spr =>
									<DownloadButton
										id={spr}
										label={spr}
										className={`${this.getOption('domPrefix')}-item`}
										displayStyle={BUTTON_DISPLAY_TYPE.NONE}
										url={getIOUrl('defaultAuthorizedApi', 'study/read-report')}
										filename={rtrimString(getString(spr), '.enc2')}
										data={{
											id: study.id,
											file: spr,
										}}
									/>
								)
								:
								<div className={`${this.getOption('domPrefix')}-item`} data-label={true}>
									<Label content={this.t('no_reports')} />
								</div>
						)
						: null
				}
			</AdvancedDropdown>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainTableReports.propTypes = {
	study: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
};

export default connect(null, getPageActions(actions))(MainTableReports);
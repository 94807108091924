import React from "react";
import {v4} from "uuid";
import Spinner from "../components/display/Spinner";

/**
 * Overlay object
 */
export class OverlayObject {
	/**
	 * Overlay object constructor
	 * 
	 * @param {string} selector - CSS selector for target elements.
	 * @param {string|JSX.Element|Element} [content=''] - Content that will be displayed centered inside the overlay.
	 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be 
	 * defined by the skin in '/skin/css/common.css' file.
	 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
	 * '/skin/css/common.css' file.
	 * @param {string} [className=''] - Additional overlay element class name.
	 * @return {string} Overlay GUI ID. 
	 */
	constructor(selector, content = '', transparent = true, blur = false, className = '') {
		// Check for missing params
		if (typeof selector === 'undefined' || !selector) {
			throw new Error(`${this.constructor.name} error! Required 'selector' param is missing or empty.`);
		}

		this.GUIID = v4();
		this.selector = selector;
		this.content = content;
		this.transparent = transparent;
		this.blur = blur;
		this.className = className;
		
		return this.GUIID;
	}
}

/**
 * Loading object
 */
export class LoadingOverlayObject extends OverlayObject {
	/**
	 * Loading overlay object constructor
	 *
	 * @param {string} selector - CSS selector for target elements.
	 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be
	 * defined by the skin in '/skin/css/common.css' file.
	 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
	 * '/skin/css/common.css' file.
	 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
	 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
	 * @param {string} [className=''] - Additional overlay element class name.
	 * @return {string} Loading overlay GUI ID.
	 */
	constructor(selector, transparent = true, blur = false, size = '1em', weight = 2, className = '') {
		const GUIID = super(selector, '', transparent, blur, className);
		this.content = <Spinner size={size} weight={weight} />;
		return GUIID;
	}
}
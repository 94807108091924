import {
	cloneDeep,
	find,
	findIndex,
	get,
	set,
	sortBy,
	isObject,
	forEach,
	omit,
	isString,
	isNumber,
	reject,
	forOwn,
	isPlainObject
} from "lodash";
import {v4} from "uuid";

/**
 * Check if variable is not undefined
 *
 * @param {any} variable - Variable to check.
 * @return {boolean} True if variable is defined, false otherwise.
 */
export const isset = variable => typeof variable !== 'undefined';

/**
 * Check if variable is an empty array
 *
 * @param {any} array - Any variable to check.
 * @return {boolean} True if variable is an empty array, or it is not an array.
 */
export const isArrayEmpty = array => (!isset(array) || !Array.isArray(array) || array.length === 0);

/**
 * Get integer value from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {number|function} [defaultValue=0] - Value returned if 'path' could not be found, is undefined or is not a 
 * number. If function is passed, return value will be used as the default value. This is done so undefined can be used
 * as a default value.
 * @returns {number}
 */
export const getInteger = (source, path = '', defaultValue = 0) => {
	const value = (path ? get(source, path) : source);
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
	return isNaN(parseInt(value)) ? def : parseInt(value);
};

/**
 * Get float value from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {number|function} [defaultValue=0] - Value returned if 'path' could not be found, is undefined or is not a
 * number. If function is passed, return value will be used as the default value. This is done so undefined can be used
 * as a default value.
 * @returns {number}
 */
export const getFloat = (source, path = '', defaultValue = 0) => {
	const value = (path ? get(source, path) : source);
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
	return isNaN(parseFloat(value)) ? def : parseFloat(value);
};

/**
 * Get float value from source and path (similar to lodash 'get' function)
 * @note Alias of getFloat.
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {number|function} [defaultValue=0] - Value returned if 'path' could not be found, is undefined or is not a
 * number. If function is passed, return value will be used as the default value. This is done so undefined can be used
 * as a default value.
 */
export const getNumber = getFloat;

/**
 * Get boolean value from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {boolean|null|function} [defaultValue=false] - Value returned if 'path' could not be found or is undefined.
 * @returns {boolean}
 */
export const getBoolean = (source, path = '', defaultValue = false) => {
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
	const value = (path ? get(source, path, def) : source);
	return !!value;
}

/**
 * Get boolean value from source and path (similar to lodash 'get' function)
 * @note Alias for 'getBoolean' function.
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {boolean|null|function} [defaultValue=false] - Value returned if 'path' could not be found or is undefined.
 * @returns {boolean}
 */
export const getBool = getBoolean;

/**
 * Get boolean value from source and path converting tiny int into bool values (similar to lodash 'get' function)
 * @note '0' or 0 = false; '1' or 1 = true; anything else equals to false.
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {boolean|function} [defaultValue=false] - Value returned if 'path' could not be found, is undefined or is not
 * a number. If function is passed, return value will be used as the default value. This is done so undefined can be
 * used as a default value.
 * @returns {boolean}
 */
export const getBooleanFromTinyInt = (source, path = '', defaultValue = false) => {
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
	const value = (path ? get(source, path) : source);
	return (value === '1' || value === 1) ? true : (value === '0' || value === 0) ? false : def;
};

/**
 * Alias for 'getBooleanFromTinyInt' function
 * @see getBooleanFromTinyInt
 */
export const getBoolFromTinyInt = getBooleanFromTinyInt;

/**
 * Get tiny int value from source and path converting boolean into tiny int values (similar to lodash _.get function)
 * @note false = '0'; true = '1'; anything else equals to '0'. Invert param will just invert the result so that
 * false = '1' and true = '0'. Depending on 'asString', returned value will be a number or a string.
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {string|number} [defaultValue='0'] - Value returned if 'path' could not be found or is undefined.
 * @param {boolean} [invert=false] - Flag that determines if result will be inverted.
 * @param {boolean} [asString=true] - Flag that determines if result will be returned as string.
 * @return {number|string} Depending on 'asString', returned value will be a number or a string.
 */
export const getTinyIntFormBoolean = (source, path = '', defaultValue = '0', invert = false, asString = true) => {
	const defaultValueString = (isString(defaultValue) ? defaultValue : defaultValue.toString());
	const defaultValueNumber = (isNumber(defaultValue) ? defaultValue : getInteger(defaultValue));
	const value = (path ? get(source, path, (asString ? defaultValueString : defaultValueNumber)) : source);
	return (
		invert ? 
			(value === true ? (asString ? '0' : 0) : (asString ? '1' : 1)) : 
			(value === true ? (asString ? '1' : 1) : (asString ? '0' : 0))
	);
};

/**
 * Alias for 'getTinyIntFormBoolean' function
 * @see getTinyIntFormBoolean
 */
export const getTinyIntFormBool = getTinyIntFormBoolean;

/**
 * Get string value from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {string} [defaultValue=''] - Value returned if 'path' could not be found or is undefined.
 * @param {boolean} [nullAsDefault=false] - If true, null values will be treated as undefined so default value will be 
 * returned instead of an empty string.
 * @returns {string}
 */
export const getString = (source, path = '', defaultValue = '', nullAsDefault = false) => {
	const value = (path ? get(source, path, defaultValue) : source);
	return (
		value === null ? 
			(nullAsDefault ? defaultValue : '') : 
			(typeof value === 'undefined' ? defaultValue : value.toString())
	);
};

/**
 * Get a string ready for display
 * @description Default values are set in a way that allows the value to be rendered easily. This is mostly used when
 * empty values should be rendered as '—' or 'N/A'.
 * 
 * @param {any} value - Value to use.
 * @param {string} [defaultValue='—'] - Default value used if value is empty.
 * @param {boolean} [nullAsDefault=true] - If true, null values will be treated as undefined so default value will be
 * returned instead of an empty string.
 * @param {boolean} [emptyAsDefault=false] - If true, empty string values will be treated as undefined so default value 
 * will be returned instead of an empty string.
 * @return {string}
 */
export const getStringForDisplay = (value, defaultValue = '—', nullAsDefault = true, emptyAsDefault = false) =>
	emptyAsDefault ?
		getString(value, '') === '' ? defaultValue : getString(value, '', defaultValue, nullAsDefault) :
		getString(value, '', defaultValue, nullAsDefault)
;

/**
 * Get a string ready to be used as a file name
 * @note This function does not change the case of the string.
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {string} [defaultValue=''] - Value returned if 'path' could not be found or is undefined.
 * @param {boolean} [nullAsDefault=false] - If true, null values will be treated as undefined so default value will be
 * returned instead of an empty string.
 * @param {string} [replaceString='_'] - Special characters replacement string.
 * @returns {string}
 */
export const getStringForFileName = (source, path = '', defaultValue = '', nullAsDefault = false, replaceString = '_') =>
	getString(source, path, defaultValue, nullAsDefault).replace(/[^a-z0-9_-]/gi, replaceString);

/**
 * Get array from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {array} [defaultValue=[]] - Value returned if 'path' could not be found or is undefined.
 * @returns {array}
 */
export const getArray = (source, path = '', defaultValue = []) => {
	const value = (path ? get(source, path, defaultValue) : source);
	if (value instanceof NodeList) return Array.from(value);
	else return (!Array.isArray(value) || value === null ? defaultValue : value);
};

/**
 * Get object from source and path (similar to lodash 'get' function)
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {any|function} [defaultValue=false] - Value returned if 'path' could not be found, is undefined or is not
 * an object. If function is passed, return value will be used as the default value. This is done so undefined can be
 * used as a default value.
 * @param {boolean} [nullAsDefault=true] - If true, null values will be treated as undefined so default value will be
 * returned instead of an empty string.
 * @return {Object|any} Object or the default value.
 */
export const getObject = (source, path = '', defaultValue = {}, nullAsDefault = true) => {
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
	const value = (path ? get(source, path, defaultValue) : source);
	return (!isset(value) || !isPlainObject(value) || (nullAsDefault && value === null) ? def : value);
};

/**
 * Return a new trimmed array where empty strings and undefined values are removed
 * 
 * @param {array} array - Array to trim.
 * @param {''|'left'|'right'} trimStyle - Trim style. If empty or not specified result array will be trimmed from both 
 * sides. If 'left' only values from the beginning of the array will be trimmed. If 'right' only values from the end of 
 * the array will be trimmed.
 * @return {array} New trimmed array.
 */
export const trimArray = (array, trimStyle = '') => {
	// Basic input check
	if (isArrayEmpty(array)) return array;

	// Generate result by cloning the original array
	let result = cloneDeep(array);

	// If left trim should be performed
	if (!trimStyle || trimStyle === 'left') {
		// While there are empty or undefined elements at the beginning of the array
		while (result.length > 0 && (result[0] === '' || typeof result[0] === 'undefined')) {
			// Remove empty or undefined element from the beginning of the array
			result.shift();
		}
	}

	// If right trim should be performed
	if (!trimStyle || trimStyle === 'right') {
		// While there are empty or undefined elements at the end of the array
		while (result.length > 0 && (result[result.length-1] === '' || typeof result[result.length-1] === 'undefined')) {
			// Remove empty or undefined element from the end of the array
			result.pop();
		}
	}

	return result;
}

/**
 * Convert an array of key/value objects into a key/value object
 * @note This method will not mutate the original array of objects.
 *
 * @param {array} keyValueArray - Key/value array.
 * @param {string} [keyName='key'] string - Array item property name used for result object key property.
 * @param {string} [valueName='value'] - Array item property name used for result object value property.
 * @param {boolean} [removeEmptyKeys=false] - Flag that specifies if empty keys will be removed form the result.
 * @return {object|null} Object where keys and values are extracted from the array or null.
 * @example:
 * 	converts [{key: 'id', value: '1'}, {key: 'name', value: 'Test'}]
 * 	into {id: '1', name: 'Test'}
 */
export const keyValueArrayToObject = (keyValueArray, keyName = 'key', valueName = 'value', removeEmptyKeys = false) => {
	if(!keyValueArray || !Array.isArray(keyValueArray) || keyValueArray.length === 0) return null;

	let result = {};
	cloneDeep(keyValueArray).forEach(item => {
		const key = get(item, keyName);
		const value = get(item, valueName);
		if ((removeEmptyKeys && isset(key) && key !== '' && key !== null) || !removeEmptyKeys) set(result, key, value);
	});
	return result;
};

/**
 * Convert a key/value object into an array of key/value objects
 * @note This method will not mutate the original object.
 *
 * @param {object} keyValueObject - Key/value object.
 * @param {string} [keyName='key'] - Property name used in result item object.
 * @param {string} [valueName='value'] - Property value used in result item object.
 * @return {array|null} Array of key/value objects extracted from the object.
 * @example:
 * 	converts {id: '1', name: 'Test'}
 * 	into [{key: 'id', value: '1'}, {key: 'name', value: 'Test'}]
 */
export const keyValueObjectIntoArray = (keyValueObject, keyName = 'key', valueName = 'value') => {
	if(!keyValueObject || !isObject(keyValueObject)) return null;

	let result = [];
	forEach(cloneDeep(keyValueObject), (value, key) => {
		result.push({[keyName]: key, [valueName]: value});
	});
	return result;
};

/**
 * Check if all object's own properties are empty
 * @note This will check just the first level of object's own properties.
 * 
 * @param {object} object - Object to check.
 * @param {boolean} [checkNull=true] - If true null will be considered as an empty value. 
 * @param {boolean} [checkEmptyString=true] - If true empty string will be considered as an empty value.
 */
export const areAllObjectPropsEmpty = (object, checkNull = true, checkEmptyString = true) => {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			const value = object[key];
			if (typeof value !== 'undefined') {
				const isNull = (value === null);
				const isEmptyString = (value === '');

				if (checkNull && checkEmptyString) { if (!isNull && !isEmptyString) return false;}
				else if (checkNull) { if (!isNull) return false; }
				else if (checkEmptyString) { if (!isEmptyString) return false; }
				else return false;
			}
		}
	}
	return true;
};

/**
 * Returns a new object not containing empty object properties
 * @note This function does not mutate the original object.
 * 
 * @param {object} object - Source object.
 * @param {boolean} [checkNull=true] - If true null will be considered as an empty value.
 * @param {boolean} [checkEmptyString=true] - If true empty string will be considered as an empty value.
 * @return {object} New object not containing empty properties from source object.
 */
export const removeEmptyObjectProps = (object, checkNull = true, checkEmptyString = true) => {
	let result = {};

	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			const value = object[key];

			if (typeof value !== 'undefined') {
				const isNull = (value === null);
				const isEmptyString = (value === '');

				if (checkNull && checkEmptyString) { if (!isNull && !isEmptyString) set(result, key, cloneDeep(value)); }
				else if (checkNull) { if (!isNull) set(result, key, cloneDeep(value)); }
				else if (checkEmptyString) { if (!isEmptyString) set(result, key, cloneDeep(value)); }
				else set(result, key, cloneDeep(value));
			}
		}
	}

	return result;
};

/**
 * Return a new object array with each object in the array not containing specified properties
 * @note This function does not mutate the original object.
 * 
 * @param {object[]} objectArray
 * @param {string|string[]} properties - Property or an array of properties that will be omitted from result objects.
 * @return {object[]} New object array with each object in the array not containing specified properties.
 */
export const removeObjectPropsFromObjectArray = (objectArray, properties) => {
	let result = [];
	if (isArrayEmpty) cloneDeep(objectArray).forEach(object => result.push(omit(object, properties)))
	return result;
};

/**
 * Find an item in the array of items (objects) and update it
 * @note This function does not mutate the original array.
 *
 * @param {Object[]} array - Original items array.
 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
 * @param {Object} newItem - New item data.
 * @param {string} [GUISTATUS=''] - If item has a "GUISTATUS" field this is the way to set it.
 * @param {Array|function|null} [sort=null] - Sort options used by lodash 'sortBy' function to sort the result array.
 * @return {Array} Updated clone of the original array of items.
 */
export const updateArrayItem = (array, predicate, newItem, GUISTATUS = '', sort = null) => {
	let result = cloneDeep(array);

	const index = findIndex(result, predicate);
	if(index !== -1) {
		set(result, `[${index}]`, cloneDeep(newItem));
		if(GUISTATUS) set(result, `[${index}].GUISTATUS`, GUISTATUS);
	}

	// If 'sort' is specified result array will be sorted using this argument and lodash sortBy function
	if(sort) sortBy(result, sort);

	return result;
};

/**
 * Add item to an array
 * @note This function does not mutate the original array.
 *
 * @param {Object[]} array - Original items array.
 * @param {Object} item - Item object to add to the array of items.
 * @param {boolean} [addGUIID=false] - If true 'GUIID' property will be added to the item object if it does not exist or
 * is empty.
 * @param {Array|function|null} [sort=null] - Sort options used by lodash 'sortBy' function to sort the result array.
 * @return {Array} Updated clone of the original array of items with the added item.
 */
export const addArrayItem = (array, item, addGUIID = false, sort = null) => {
	let result = cloneDeep(array);

	// If 'addGUIID' flag is true and item.GUIID does not exist or is empty, add a new unique GUIID
	if(addGUIID && !get(item, 'GUIID')) set(item, 'GUIID', v4());

	// Add a new item to a result array
	result.push(item);

	// If 'sort' is specified result array will be sorted using this argument and lodash sortBy function
	if(sort) sortBy(result, sort);

	return result;
};

/**
 * Remove item from an array
 * NOTE: This function does not mutate the original array.
 *
 * @param {Object[]} array - Original items array.
 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
 * @param {Array|function|null} [sort=null] - Sort options used by lodash 'sortBy' function to sort the result array.
 * @return {Array} Updated clone of the original array without the removed item. If item does not exist clone of the
 * original array will be returned.
 */
export const removeArrayItem = (array, predicate, sort = null) => {
	let result = cloneDeep(array);

	// Remove item from result array
	result = reject(result, predicate);

	// If 'sort' is specified result array will be sorted using this argument and lodash sortBy function
	if(sort) sortBy(result, sort);

	return result;
};

/**
 * Find an item in the array of items (objects) and update one or more of its properties
 *
 * @param {Array} array - Array of items (objects).
 * @param {Object} predicate - Selector object used to find the item (for example: { id: '123' }).
 * @param {Object} propsUpdater - Object containing one or more item property updates (for example: { 
 * 	title: "The Hitchhiker's Guide to the Galaxy", author: "Douglas Adams" 
 *	}).
 * @param {string} [GUISTATUS=''] - If item has a "GUISTATUS" this is the way to set it.
 * @param {Array|function|null} [sort=null] - Sort options used by lodash 'sortBy' function to sort the result array.
 * @return {Array} Updated array of items.
 */
export const updateArrayItemProps = (array, predicate, propsUpdater, GUISTATUS = '', sort = null) => {
	let result = cloneDeep(array);

	const index = findIndex(result, predicate);
	const item = find(result, predicate);
	if(index !== -1) {
		set(result, `[${index}]`, cloneDeep({
			...item,
			...propsUpdater
		}));
		if(GUISTATUS) set(result, `[${index}].GUISTATUS`, GUISTATUS);
	}

	// If 'sort' is specified result array will be sorted using this argument and lodash sortBy function
	if(sort) sortBy(result, sort);

	return result;
};

/**
 * Reset empty object values to a specific reset value
 * @note This method will not mutate the original object.
 * 
 * @param {Object} object - Source object.
 * @param {Object} [resetValues={}] - Plain object defining reset values for individual fields.
 * @param {any} [defaultResetValue=null] - Default reset value if field does not have one defined in 'resetValues'.
 * @param {boolean} [skipMissing=false] - If true fields not defined in 'resetValues' will be skipped and 
 * 'defaultResetValue' will be ignored.
 * @return {*} Deep-cloned object with reset values.
 */
export const resetObjectValues = (object, resetValues = {}, defaultResetValue = null, skipMissing = false) => {
	let result = cloneDeep(object);
	
	forOwn(object, (value, key) => {
		// Get reset value
		let resetValue = get(resetValues, key);
		if (typeof resetValue === 'undefined' && !skipMissing) resetValue = defaultResetValue;
		
		// Reset the value if it is empty
		// @note A value is considered to be empty if it is undefined, an empty string or null.
		if (typeof resetValue !== 'undefined' && (typeof value === 'undefined' || value === '' || value === null)) {
			set(result, key, resetValue);
		} 
	});
	
	return result;
};


/**
 * Check if a target array included all elements from another array
 * @note This method does not check array item positions.
 * 
 * @param {Array} target - Target array to check if it contains the specified array.
 * @param {Array} arr - Specified array to check if all it's elements exist in the target array.
 * @return boolean
 */
export const arrayIncludes = (target, arr) => (target.length < arr.length ? false : arr.every(v => target.includes(v)));

/**
 * Check if a target array included any of the elements from another array
 * @note This method does not check array item positions.
 *
 * @param {Array} target - Target array to check if it contains any of the specified array's item.
 * @param {Array} arr - Specified array to check if any of its elements exist in the target array.
 * @return boolean
 */
export const arrayIncludesAny = (target, arr) => arr.some(v => target.includes(v));

/**
 * Get object key by value
 * @param {Object} object - Object to search.
 * @param {any} value - value to search the key by.
 * @return {string}
 */
export const getObjectKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

/**
 * Convert JSON object to FormData
 * @note This function only works with one-dimensional JSON objects with simple values and arrays. Arrays form items
 * will have '[]' suffix added. Value can be a FormData as well in which case it will be merged into the result.
 *
 * @param {Object} json - One-dimensional JSON data object.
 * @return {FormData}
 */
export const jsonToFormData = json => {
	let result = new FormData();

	if (json) {
		forOwn(json, (value, key) => {
			if (value instanceof FormData) {
				let entities = Array.from(value.entries());
				if (entities.length > 0) entities.forEach(entry => result.append(entry[0], entry[1]));
				else result.append(key, null);
			}
			else if (Array.isArray(value)) value.forEach(v => result.append(`${key}[]`, v));
			else result.append(key, value);
		});
	}

	return result;
};
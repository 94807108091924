import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as actions from "../../../../actions";
import * as pageConfig from "../../../../config";
import Label from "Core/components/display/Label";
import {getString} from "Core/helpers/data";
import {STUDY_TROUBLESHOOTING_ICON} from "../../const";
import {icon_font_symbol_class_prefix} from "Config/app";
import {closeDialog, openDialog} from "Core/helpers/dialog";
import ReProcessDialog from "./dialogs/ReProcessDialog";
import {getPageActions} from "Core/helpers/redux";
import {isSuccessful} from "Core/helpers/io";

class ReProcessAction extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.study_troubleshooting`
		});
		
		this.dialogGUIID = '';
	}

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, action, studyId, reloadMainList, studiesListItemActionReProcessStudyAction} = this.props;

		return (
			<div 
				id={this.getDomId()} 
				className={className}
				onClick={e => {
					this.dialogGUIID = openDialog('re-process-dialog', ReProcessDialog, {
						showNuseSubtracted: e.shiftKey,
						onYes: (dialogGUIID, selectedCriteria, nuseSubtracted) => {
							this.executeAbortableAction(
								studiesListItemActionReProcessStudyAction, studyId, selectedCriteria, nuseSubtracted
							)
								// Handle main table update if updated was successful
								.then(response => {
									closeDialog(dialogGUIID);
									if (isSuccessful(response)) return reloadMainList().then(() => response);
									else return Promise.resolve(response);
								});
						},
						onNo: dialogGUIID => {
							closeDialog(dialogGUIID);
						}
					}, {
						id: 're-process-dialog',
						className: 'bordered-title',
						closeOnEscape: true,
						closeOnClickOutside: false,
						hideCloseBtn: true,
						maxWidth: 600
					})
				}}
			>
				<Label
					icon={getString(STUDY_TROUBLESHOOTING_ICON, [action, 'symbol'])}
					iconSymbolPrefix={getString(
						STUDY_TROUBLESHOOTING_ICON,
						[action, 'symbolPrefix'],
						icon_font_symbol_class_prefix
					)}
					content={this.t(action)}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ReProcessAction.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Action 
	// @type {StudyAction}
	action: PropTypes.string.isRequired,
	// Study id
	studyId: PropTypes.string.isRequired,
	// Function to reload the main page list after re-processing study is done
	reloadMainList: PropTypes.func.isRequired,
};

/**
 * Define component default values for own props
 */
ReProcessAction.defaultProps = {
	id: '',
	className: '',
};

export default connect(null, getPageActions(actions))(ReProcessAction);
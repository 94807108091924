import "./index.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Icon from "Core/components/display/Icon";
import AdvancedDropdown from "Core/components/display/AdvancedDropdown";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {getArray, getBoolean, getString} from "Core/helpers/data";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import * as pageConfig from "../../config";
import {get, orderBy} from "lodash";
import {isSuccessful} from "Core/helpers/io";
import {main_layout_element, messages_default_auto_hide_after} from "Config/app";

class MainTableStatusChange extends DataComponent {
	constructor(props) {
		super(props, {
			data: [],
			dataLoading: false,
		}, {
			translationPath: pageConfig.translationPath,
			domPrefix: 'study-status-dropdown',
			disableLoad: true,
		});
		
		// Data methods
		this.loadStatusList = this.loadStatusList.bind(this);
		this.clearStatusList = this.clearStatusList.bind(this);
		
		// Action methods
		this.updateStatus = this.updateStatus.bind(this);
		this.handleStatusClick = this.handleStatusClick.bind(this);
	}
	
	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load the list of available study statuses
	 * @note Only statuses that are not disabled can be clicked.
	 * 
	 * @return {Promise<Object>}
	 */
	loadStatusList() {
		const {fetchStudiesListItemStatusListAction} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');

		return this.setState({dataLoading: true})
			.then(() => this.executeAbortableAction(
				{id: 'fetch-statuses', action: fetchStudiesListItemStatusListAction}, study.id
			))
			.then(response => this.setData(orderBy(getArray(response, 'data'), ['studyStatusData.ordinal'])))
			.then(() => this.setState({dataLoading: false}));
	}

	/**
	 * Clear the list of available study statuses
	 * 
	 * @return {Promise<Object>}
	 */
	clearStatusList() {
		// Abort fetching statuses
		this.abortAction('fetch-statuses');
		
		return this.clearData()
			.then(() => this.setState({dataLoading: false}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Update study status
	 *
	 * @param {string} studyStatusCode - Selected study status code.
	 * @return {Promise<Object>}
	 */
	updateStatus(studyStatusCode) {
		const {
			reloadMainList, updateStudiesListItemStatusAction, addSuccessMessageAction, addWarningMessageAction,
		} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');
		return this.executeAbortableAction(updateStudiesListItemStatusAction, study.id, studyStatusCode)
			// Handle messages if updated was successful
			.then(response => {
				const studyStatusCode = getString(response, 'data.studyStatusCode');
				const successSentToMails = getBoolean(response, 'data.successSentToMails');
				const sentToMails = getString(response, 'data.sentToMails')
					.split(',')
					.map(i => i.trim())
					.join(', ');
				if (isSuccessful(response)) {
					// Add success message
					addSuccessMessageAction(this.tt('update_success', 'messages.statusUpdate'));
					
					// Handle email sent messages
					if (
						get(response, 'data.successSentToMails') !== null &&
						this.hasTranslation(studyStatusCode, this.getTranslationPath('messages.statusUpdate.success'))
					) {
						// Add email sent success message
						if (successSentToMails) {
							addSuccessMessageAction(this.tt(
								studyStatusCode, 
								'messages.statusUpdate.success', 
								'', 
								{emails: sentToMails}
							), messages_default_auto_hide_after * 1.5);
						}
						// Add email sent fail message
						else {
							addWarningMessageAction(
								this.tt(
									studyStatusCode,
									'messages.statusUpdate.error',
									'',
									{emails: sentToMails}
								), messages_default_auto_hide_after * 1.5);
						}
					}
				}
				return response;
			})
			// Handle main table update if updated was successful
			.then(response => {
				if (isSuccessful(response)) return reloadMainList().then(() => response);
				else return Promise.resolve(response);
			});
	}
	
	/**
	 * Handle status dropdown item click 
	 * 
	 * @param {Object} studyStatus - Selected study status.
	 */
	handleStatusClick(studyStatus) {
		const {openDialogAction, closeDialogAction} = this.props;
		const confirmationRequired = getBoolean(studyStatus, 'confirmationRequired');
		const statusCode = getString(studyStatus, 'studyStatusData.code');
		if (confirmationRequired) {
			return new Promise(resolve => {
				const dialogGUIID = openDialogAction('study-status-change-dialog', ConfirmDialog, {
					message: (
						this.hasTranslation(
							statusCode, `${this.getOption('translationPath')}.messages.statusUpdateConfirmation`
						) ? 
							this.tt(statusCode, 'messages.statusUpdateConfirmation') :
							getString(studyStatus, 'studyStatusData.name')
					),
					supportHtml: true,
					onYes: () => { 
						this.updateStatus(statusCode)
							.then(() => closeDialogAction(dialogGUIID))
							.finally(() => resolve());
					},
					onNo: () => { closeDialogAction(dialogGUIID); resolve(); }
				}, {
					id: 'study-status-change-confirm-dialog',
					closeOnEscape: true,
					closeOnClickOutside: true,
					hideCloseBtn: true,
					maxWidth: 550
				});
			});
		} else {
			return this.updateStatus(statusCode);
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {disabled} = this.props;
		/** @type {StudiesListItemDataObject} */
		const study = this.getProp('study');
		const {dataLoading} = this.state;
		const data = getArray(this.getData())
			// Remove non-clickable options (Study Underway and Processing Error). The statuses should still be used when 
			// applicable, but they don’t need to be in the drop-down menu since they cannot be selected under any 
			// circumstance.
			.filter(i =>
				// Study underway
				getString(i, 'studyStatusData.code') !== '15' &&
				// Processing error
				getString(i, 'studyStatusData.code') !== '00'
			);
		
		return (
			<AdvancedDropdown
				className={this.getOption('domPrefix')}
				label={(
					<Button
						className={`${this.getOption('domPrefix')}-button ${disabled ? 'disabled' : ''}`}
						label={
							// Use i18n to get the label but fallback to the study status name field
							this.hasTranslation(study.studyStatusCode, 'constants.studyStatus') ?
								this.t(study.studyStatusCode, 'constants.studyStatus') :
								study.studyStatusName
						}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						displayStyle={BUTTON_STYLE.ACTION}
					>
						{
							!disabled ? 
								<Icon symbol={'chevron-down'} className={`${this.getOption('domPrefix')}-arrow`} /> 
								: null
						}
					</Button>
				)}
				parent={main_layout_element}
				boundRect={document.querySelector(`.layout-page`)?.getBoundingClientRect()}
				loading={dataLoading}
				contentClassName="no-padding"
				contentSize="0.8rem"
				contentMaxHeightProtection={false}
				onOpen={this.loadStatusList}
				onClosed={this.clearStatusList}
				disabled={disabled}
			>
				{data.map(i =>
					<div 
						key={`${study.id}_${getString(i, 'studyStatusData.id')}`}
						data-disabled={getBoolean(i, 'disabled')}
						onClick={() => this.handleStatusClick(i)}
					>
						{
							// Use i18n to get the name but fallback to the study status name field
							this.hasTranslation(getString(i, 'studyStatusData.code'), 'constants.studyStatus') ?
								this.t(getString(i, 'studyStatusData.code'), 'constants.studyStatus') :
								getString(i, 'studyStatusData.name')
						}
					</div>
				)}
			</AdvancedDropdown>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MainTableStatusChange.propTypes = {
	study: PropTypes.object.isRequired,
	reloadMainList: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default connect(null, getPageActions(actions))(MainTableStatusChange);
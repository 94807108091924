import {actionCreators} from '../reducers';
import {OverlayObject} from "../../objects";
import {get} from "lodash";

/**
 * Add overlay to the list of overlays
 * 
 * @param {OverlayObject} overlay
 * @return {function(*): string} Overlay GUI ID.
 */
export const addOverlayAction = overlay => dispatch => {
	dispatch(actionCreators.overlay.addOverlay(overlay));
	return get(overlay, 'GUIID');
};
/**
 * Add an overlay to all HTML elements found by the selector
 * 
 * @param {string} selector - CSS selector for target elements.
 * @param {string|JSX.Element|Element} [content=''] - Content display in overlay.
 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be defined
 * by the skin in '/skin/css/common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
 * '/skin/css/common.css' file.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return {function(*): string} Overlay GUI ID.
 */
export const showOverlayAction = (
	selector, content = '', transparent = true, blur = false, className = ''
) => dispatch => {
	return addOverlayAction(new OverlayObject(selector, content, transparent, blur, className))(dispatch);
};

/**
 * Remove overlay from the list of overlays
 *
 * @param {string} GUIID - Overlay GUI ID
 * @return {function(*): void}
 */
export const removeOverlayAction = GUIID => dispatch => {
	dispatch(actionCreators.overlay.removeOverlay(GUIID));
};
/**
 * Remove overlay from all HTML elements found by the selector
 * @param {string} GUIID - Overlay GUI ID.
 */
export const hideOverlayAction = GUIID => dispatch => { 
	removeOverlayAction(GUIID)(dispatch); 
}
/**
 * Data object representing the account settings list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class AccountSettingsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} abmAccountId - List item's abm account ID.
	 * @param {string} name - List item's name.
	 */
	constructor(id, abmAccountId, name) {
		this.id = id;
		this.abmAccountId = abmAccountId;
		this.name = name;
		
	}
}

/**
 * Data object representing the account settings item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class AccountSettingsItemDataObject {
	/**
	 * @param {string} id - Account settings ID.
	 * @param {string} name - Account settings name.
	 * @param {string} abmAccountId - Account's unique ID.
	 * @param {boolean} [sendEmailTechReviewRequired=false] - Flag that determines if emails should be sent to 
	 * 'emailsTechReviewRequired' addresses when study changes status to TechReviewRequired.
	 * @param {string} [emailsTechReviewRequired=''] - Physician(s) email addresses for mail to be sent when study 
	 * changes status to TechReviewRequired.
	 * @param {boolean} [sendEmailTechReviewCompleted=false] - Flag that determines if emails should be sent to
	 * 'emailsTechReviewCompleted' addresses when study changes status to TechReviewCompleted.
	 * @param {string} [emailsTechReviewCompleted=''] - Physician(s) email addresses for mail to be sent when study
	 * changes status to TechReviewCompleted.
	 * @param {boolean} [sendEmailToInterpretingPhysicians=false] - Flag if emails should be sent to 
	 * 'interpretingPhysicianEmail' addresses defined.
	 * @param {string} [interpretingPhysicianEmail=''] - Interpreting physician(s) email address.
	 * @param {boolean} [sendEmailInterpretationCompleted=false] - Flag that determines if emails should be sent to
	 * 'emailsInterpretationCompleted' addresses when study changes status to InterpretationCompleted.
	 * @param {string} [emailsInterpretationCompleted=''] - Physician(s) email addresses for mail to be sent when study
	 * changes status to InterpretationCompleted.
	 * @param {boolean} [sendEmailFinalReportAvailable=false] - Flag that determines if emails should be sent to
	 * 'emailsFinalReportAvailable' addresses when study changes status to FinalReportAvailable.
	 * @param {string} [emailsFinalReportAvailable=''] - Physician(s) email addresses for mail to be sent when study
	 * changes status to FinalReportAvailable.
	 * @param {number} [desatSetting] - Default desat setting value.
	 * @param {AccountSettingsProcessingSetting} selectedProcessingSetting - Indicates if L2/ICU/NDD control for 
	 * processing parameters.
	 * @param {boolean} selectedL2 - Account processing setting for Light N2 parameter.
	 * @param {boolean} selectedAbnormalN3 - Account processing setting for AbnormalN3 parameter.
	 * @param {boolean} selectedNRSwH - Account processing setting for NRSwH parameter.
	 * @param {boolean} [includeLetterhead=false] - Flag, if report should include report letterhead.
	 * @param {string} [letterheadPath=''] - Account's report letterhead image location path on the server.
	 * @param {string} [letterheadFileName=''] - Account's report letterhead image file name.
	 * @param {boolean} [shouldSaveLetterhead=false] - Flag that specifies if new account's report letterhead image 
	 * should be saved.
	 * @param {FormData} [letterheadFormData=null] - Account's report letterhead image form data (from file input).
	 */
	constructor(
		id, name, abmAccountId, sendEmailTechReviewRequired = false, emailsTechReviewRequired = '', 
		sendEmailTechReviewCompleted = false, emailsTechReviewCompleted = '', sendEmailToInterpretingPhysicians = false, 
		interpretingPhysicianEmail = '', sendEmailInterpretationCompleted = false, emailsInterpretationCompleted = '', 
		sendEmailFinalReportAvailable = false, emailsFinalReportAvailable, desatSetting, selectedProcessingSetting, 
		selectedL2, selectedAbnormalN3, selectedNRSwH, includeLetterhead = false, letterheadPath = '', 
		letterheadFileName = '', shouldSaveLetterhead = false, letterheadFormData = null
	) {
		this.id = id;
		this.name = name;
		this.abmAccountId = abmAccountId;

		// Email distribution tab (MainTab)
		this.sendEmailTechReviewRequired = sendEmailTechReviewRequired;
		this.emailsTechReviewRequired = emailsTechReviewRequired;
		this.sendEmailTechReviewCompleted = sendEmailTechReviewCompleted;
		this.emailsTechReviewCompleted = emailsTechReviewCompleted;
		this.sendEmailToInterpretingPhysicians = sendEmailToInterpretingPhysicians;
		this.interpretingPhysicianEmail = interpretingPhysicianEmail;
		this.sendEmailInterpretationCompleted = sendEmailInterpretationCompleted;
		this.emailsInterpretationCompleted = emailsInterpretationCompleted;
		this.sendEmailFinalReportAvailable = sendEmailFinalReportAvailable;
		this.emailsFinalReportAvailable = emailsFinalReportAvailable;

		// Desat settings tab
		this.desatSetting = desatSetting;

		// Processing settings tab
		this.selectedProcessingSetting = selectedProcessingSetting;
		this.selectedL2 = selectedL2;
		this.selectedAbnormalN3 = selectedAbnormalN3;
		this.selectedNRSwH = selectedNRSwH;
		
		// Report letterhead tab
		this.includeLetterhead = includeLetterhead;
		// File read properties
		// @desc Properties used to display current files already saved in the database.
		this.letterheadPath = letterheadPath;
		this.letterheadFileName = letterheadFileName;
		// File write properties
 		// @desc Properties used to store selected file(s) that will be saved in the database.
		this.shouldSaveLetterhead = shouldSaveLetterhead;
		this.letterheadFormData = letterheadFormData;
	}
}
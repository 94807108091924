/**
 * Abstract dialog component used to create other dialog components
 */

import React from "react";
import BaseComponent, {executeComponentCallback} from "./BaseComponent";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";
import {getBool, isset} from "../helpers/data";
import {isMacintosh} from "../helpers/system";
import Label from "./display/Label";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "./display/Button";
import {LABEL_ICON_POSITION} from "./display/Label";
import {
	icon_font_error_symbol,
	icon_font_help_symbol,
	icon_font_info_symbol,
	icon_font_success_symbol,
	icon_font_warning_symbol
} from "Config/app";

/** @typedef {string} DialogType */
export const DIALOG_TYPE_NONE = 'none';
export const DIALOG_TYPE_MESSAGE = 'message';
export const DIALOG_TYPE_STANDARD = 'standard';
export const DIALOG_TYPE_CONFIRM = 'confirm';
export const DIALOG_TYPE_DATA = 'data';
export const DIALOG_TYPE_CUSTOM = 'custom';
/** @enum {DialogType} */
export const DIALOG_TYPE = {
	NONE: DIALOG_TYPE_NONE,
	MESSAGE: DIALOG_TYPE_MESSAGE,
	STANDARD: DIALOG_TYPE_STANDARD,
	CONFIRM: DIALOG_TYPE_CONFIRM,
	DATA: DIALOG_TYPE_DATA,
	CUSTOM: DIALOG_TYPE_CUSTOM,
}
/** @type {DialogType[]} */
export const DIALOG_TYPES = [
	DIALOG_TYPE_NONE, 
	DIALOG_TYPE_MESSAGE, 
	DIALOG_TYPE_STANDARD, 
	DIALOG_TYPE_CONFIRM, 
	DIALOG_TYPE_DATA,
	DIALOG_TYPE_CUSTOM,
];

export default class DialogComponent extends BaseComponent {
	/**
	 * Class constructor
	 *
	 * @param {object} [props] - Component props.
	 * @param {DialogComponentOptions} [options={}] - Component options from child class that will override the default 
	 * options.
	 */
	constructor(props, options = {}) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {DialogComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Dialog type used to determine witch action buttons will be rendered
			 * @type {DialogType}
			 */
			dialogType: DIALOG_TYPE_NONE,

			/**
			 * CSS text-align value that will be used for dialog content
			 * @type {'left'|'right'|'center'|'justify'|'initial'|'inherit'}
			 */
			alignContent: 'initial',

			...cloneDeep(options)
		};

		super(props, _options);

		// Dialog methods
		this.close = this.close.bind(this);
		this.ok = this.ok.bind(this);
		this.yes = this.yes.bind(this);
		this.no = this.no.bind(this);

		// Render methods
		this.renderTitle = this.renderTitle.bind(this);
		this.renderCustomIconTitle = this.renderCustomIconTitle.bind(this);
		this.renderInfoTitle = this.renderInfoTitle.bind(this);
		this.renderHelpTitle = this.renderHelpTitle.bind(this);
		this.renderSuccessTitle = this.renderSuccessTitle.bind(this);
		this.renderWarningTitle = this.renderWarningTitle.bind(this);
		this.renderErrorTitle = this.renderErrorTitle.bind(this);
		this.renderActionButtons = this.renderActionButtons.bind(this);
		this.renderMessageActionButtons = this.renderMessageActionButtons.bind(this);
		this.renderStandardActionButtons = this.renderStandardActionButtons.bind(this);
		this.renderConfirmActionButtons = this.renderConfirmActionButtons.bind(this);
		this.renderDialog = this.renderDialog.bind(this);
	}

	/**
	 * Dialog close method
	 * @note This method should be called when dialog's close button is clicked. This method does not actually close the
	 * dialog. That should be done by the parent component.
	 */
	close() {
		const {dialogGUIID, dialogCloseAction} = this.props;
		dialogCloseAction(dialogGUIID);
	}

	/**
	 * Dialog OK method
	 * @note This method should be called when dialog's OK button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 */
	ok() {
		const {dialogGUIID} = this.props;

		// Trigger component's onOk event
		executeComponentCallback(this.props.onOk, dialogGUIID);
	}

	/**
	 * Dialog yes method
	 * @note This method should be called when dialog's yes button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 */
	yes() {
		const {dialogGUIID} = this.props;

		// Trigger component's onOk event
		executeComponentCallback(this.props.onYes, dialogGUIID);
	}

	/**
	 * Dialog no method
	 * @note This method should be called when dialog's no button is clicked. This method does not actually do much. It
	 * just triggers the onOk event.
	 */
	no() {
		const {dialogGUIID} = this.props;

		// Trigger component's onOk event
		executeComponentCallback(this.props.onNo, dialogGUIID);
	}

	/**
	 * Render standard dialog title
	 * @description Use this method to render a dialog title with standard CSS class and structure.
	 * 
	 * @param {any} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Standard dialog title element or null if 'label' is not specified or is empty. 
	 */
	renderTitle(label, className = 'title') {
		return (
			label ? <div className={className}>{label}</div> : null
		);
	}

	/**
	 * Render a custom dialog title with icon
	 * @note Style should be defined by the skin.
	 * 
	 * @param {string|string[]} icon - Dialog title icon symbol name or array symbol names.
	 * @param {string} [label=''] - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @param {string} [iconSymbolPrefix] - Font icon symbol prefix.
	 * @return {JSX.Element|null} Custom dialog title with icon or null.
	 */
	renderCustomIconTitle(icon, label = '', className = 'title', iconSymbolPrefix) {
		return this.renderTitle(
			<Label
				icon={icon}
				iconPosition={LABEL_ICON_POSITION.NONE}
				iconSymbolPrefix={iconSymbolPrefix}
				content={label ? <span className="title-label">{label}</span> : null}
			/>,
			`custom ${className}`
		);
	}
	
	/**
	 * Render info dialog title
	 * @note Style should be defined by the skin.
	 * 
	 * @param {string|null} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Info dialog title or null.
	 */
	renderInfoTitle(label = undefined, className = 'title info') {
		return this.renderCustomIconTitle(
			icon_font_info_symbol,
			isset(label) && label !== null ? label : this.translate('Info', 'general'),
			className
		);
	}
	
	/**
	 * Render help dialog title
	 * @note Style should be defined by the skin.
	 *
	 * @param {string|null} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Help dialog title or null.
	 */
	renderHelpTitle(label = undefined, className = 'title help') {
		return this.renderCustomIconTitle(
			icon_font_help_symbol,
			isset(label) && label !== null ? label : this.translate('Help', 'general'),
			className
		);
	}
	
	/**
	 * Render success dialog title
	 * @note Style should be defined by the skin.
	 *
	 * @param {string|null} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Success dialog title or null.
	 */
	renderSuccessTitle(label = undefined, className = 'title success') {
		return this.renderCustomIconTitle(
			icon_font_success_symbol,
			isset(label) && label !== null ? label : this.translate('Success', 'general'),
			className
		);
	}
	
	/**
	 * Render warning dialog title
	 * @note Style should be defined by the skin.
	 *
	 * @param {string|null} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Warning dialog title or null.
	 */
	renderWarningTitle(label = undefined, className = 'title warning') {
		return this.renderCustomIconTitle(
			icon_font_warning_symbol,
			isset(label) && label !== null ? label : this.translate('Warning', 'general'),
			className
		);
	}
	
	/**
	 * Render error dialog title
	 * @note Style should be defined by the skin.
	 *
	 * @param {string|null} label - Dialog title label.
	 * @param {string} [className='title'] - Use this to override the title's standard CSS class.
	 * @return {JSX.Element|null} Error dialog title or null.
	 */
	renderErrorTitle(label = undefined, className = 'title error') {
		return this.renderCustomIconTitle(
			icon_font_error_symbol,
			isset(label) && label !== null ? label : this.translate('Error', 'general'),
			className
		);
	}

	/**
	 * Render dialog action buttons
	 * @note Low-level method to render any dialog action buttons.
	 *
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @param {string} [className=''] - Buttons wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div className={`buttons ${className}`}>
					{buttons.map((button, index) =>
						<Button
							key={index}
							icon={button.icon}
							displayType={button.type ? button.type : BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={button.style ? button.style : BUTTON_STYLE.DEFAULT}
							onClick={button.onClick}
							disabled={getBool(button, 'disabled')}
						>
							{button.label ? button.label : null}
						</Button>
					)}
				</div>
				:
				null
		);
	}

	/**
	 * Render message dialog action buttons
	 * @note Message dialog has one close style button that closes the dialog on click.
	 *
	 * @param {string|null} [closeLabel] - Label used for the close button. Default value will be loaded from translation
	 * file.
	 * @param {string|null} [closeIcon] - Optional icon used for the close button.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderMessageActionButtons(closeLabel = undefined, closeIcon = undefined) {
		return this.renderActionButtons([
			{
				style: BUTTON_STYLE.DEFAULT,
				label: (isset(closeLabel) && closeLabel !== null ? closeLabel : this.t('Close', 'general')),
				icon: (closeIcon ? closeIcon : ''),
				onClick: this.close,
			}
		]);
	}

	/**
	 * Render standard dialog action buttons
	 * @note Standard dialog has OK and close/cancel style buttons. OK button will trigger onOk event and close/cancel
	 * button will trigger onClose event. Buttons will be rendered in different order base on the operating system. This
	 * is done because macOS has different order of OK and Cancel buttons that Windows.
	 *
	 * @param {string|null} [okLabel] - Label used for OK button. Default value will be loaded from translation file.
	 * @param {string|null} [okIcon] - Optional icon used for OK button.
	 * @param {string|null} [cancelLabel] - Label used for cancel button. Default value will be loaded from translation file.
	 * @param {string|null} [cancelIcon] - Optional icon used for cancel button.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderStandardActionButtons(
		okLabel = undefined, okIcon = undefined, cancelLabel = undefined, cancelIcon = undefined
	) {
		let buttons = [];

		if (isMacintosh()) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (isset(cancelLabel) && cancelLabel !== null ? cancelLabel : this.t('Cancel', 'general')),
			icon: (cancelIcon ? cancelIcon : ''),
			onClick: this.close,
		});

		buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (isset(okLabel) && okLabel !== null ? okLabel : this.t('OK', 'general')),
			icon: (okIcon ? okIcon : ''),
			onClick: this.ok,
		});

		if (!isMacintosh()) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (isset(cancelLabel) && cancelLabel !== null ? cancelLabel : this.t('Cancel', 'general')),
			icon: (cancelIcon ? cancelIcon : ''),
			onClick: this.close,
		});

		return this.renderActionButtons(buttons);
	}

	/**
	 * Render confirm dialog action buttons
	 * @note Confirm dialog has yes and no style buttons. Yes button will trigger onYes event and no button will trigger
	 * onNo event.
	 *
	 * @param {string|null} [yesLabel] - Label used for yes button. Default value will be loaded from translation file.
	 * @param {string|null} [yesIcon] - Optional icon used for yes button.
	 * @param {string|null} [noLabel] - Label used for no button. Default value will be loaded from translation file.
	 * @param {string|null} [noIcon] - Optional icon used for no button.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderConfirmActionButtons(yesLabel = undefined, yesIcon = undefined, noLabel = undefined, noIcon = undefined) {
		let buttons = [];

		buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (isset(yesLabel) && yesLabel !== null ? yesLabel : this.t('Yes', 'general')),
			icon: (yesIcon ? yesIcon : ''),
			onClick: this.yes,
		});

		buttons.push({
			style: BUTTON_STYLE.ERROR,
			label: (isset(noLabel) && noLabel !== null ? noLabel : this.t('No', 'general')),
			icon: (noIcon ? noIcon : ''),
			onClick: this.no,
		});

		return this.renderActionButtons(buttons);
	}

	/**
	 * Use this method to render dialog structure with action buttons based on dialog type
	 *
	 * @param {Element|string} [title=null] - Main dialog title to render inside the standard dialog structure. Any 
	 * render title method can be used to generate this value (@see renderTitle, renderInfoTitle, renderHelpTitle, ... 
	 * methods).
	 * @param {Element|string} [content] - Main dialog content to render inside the standard dialog structure.
	 * @param {any} [buttonOptions] - Action button options used by the appropriate render action buttons method.
	 * @return {JSX.Element} - Dialog JSX with action buttons based on dialog type.
	 */
	renderDialog(title = null, content, ...buttonOptions) {
		const dialogType = this.getOption('dialogType');
		const alignContent = this.getOption('alignContent');

		return (
			<div className={`dialog-content-component type-${dialogType}`}>
				{title ? title : null}

				<div className="content" style={{textAlign: alignContent}}>
					{content}
				</div>

				{
					dialogType === DIALOG_TYPE_MESSAGE ?
						this.renderMessageActionButtons(...buttonOptions)
					: dialogType === DIALOG_TYPE_STANDARD ?
						this.renderStandardActionButtons(...buttonOptions)
					: dialogType === DIALOG_TYPE_CONFIRM ?
						this.renderConfirmActionButtons(...buttonOptions)
					: dialogType === DIALOG_TYPE_CUSTOM ?
						this.renderActionButtons(...buttonOptions) 
					: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DialogComponent.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Events
	onClose: PropTypes.func,
	onOk: PropTypes.func,
	onYes: PropTypes.func,
	onNo: PropTypes.func
};

export {executeComponentCallback, executeComponentCallbackPromise} from "./BaseComponent";

// Type definitions
/**
 * @typedef {Object} DialogComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {DialogType} [dialogType='none'] - Dialog type used to determine witch action buttons will be rendered.
 * @property {'left'|'right'|'center'|'justify'|'initial'|'inherit'} [alignContent='initial'] - CSS text-align value 
 * that will be used for dialog content.
 */

/**
 * @typedef {object} DialogButton
 * @property {string} [type]
 * @property {string} [style]
 * @property {string} [label]
 * @property {string} [icon]
 * @property {Function} onClick
 */
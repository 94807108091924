/** @typedef {string} CurrentUserType */
export const CURRENT_USER_TYPE_SUPER_ADMIN = "SUPER_ADMIN";
export const CURRENT_USER_TYPE_OPERATOR = "OPERATOR";
/** @enum {CurrentUserType} */
export const CURRENT_USER_TYPE = {
	SUPER_ADMIN: CURRENT_USER_TYPE_SUPER_ADMIN,
	OPERATOR: CURRENT_USER_TYPE_OPERATOR
}
/** @type {CurrentUserType[]} */
export const CURRENT_USER_TYPES = [
	CURRENT_USER_TYPE_SUPER_ADMIN,
	CURRENT_USER_TYPE_OPERATOR
];
export class StudiesComparisonDataObject {
	/**
	 * 
	 * @param {string} study1Id - ID of the first study in the comparison report.
	 * @param {string} study2Id - ID of the second study in the comparison report.
	 * @param {string} firstName - First name of the ordering physician (currently logged in user).
	 * @param {string} lastName - Last name of the ordering physician (currently logged in user).
	 * @param {string} [credentials=''] - Credentials of the ordering physician (currently logged in user).
	 * @param {*} [signatureFormData=null] - Selected document file. This is only relevant if 'isLink' is false.
	 */
	constructor(study1Id, study2Id, firstName, lastName, credentials = '', signatureFormData = null) {
		this.study1Id = study1Id;
		this.study2Id = study2Id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.credentials = credentials;

		// File write properties
		// @desc Properties used to store selected file(s) that will be saved in the database.
		this.signatureFormData = signatureFormData;
	}
}
/** @type {string} */
export const SLEEP_DIARY_EMPTY_VALUE_LABEL = '--';

/** @typedef {string} SleepDiaryBoolSelectValue */
export const SLEEP_DIARY_BOOL_SELECT_VALUE_NONE = '';
export const SLEEP_DIARY_BOOL_SELECT_VALUE_TRUE = 'true';
export const SLEEP_DIARY_BOOL_SELECT_VALUE_FALSE = 'false';
/** @enum {SleepDiaryBoolSelectValue} */
export const SLEEP_DIARY_BOOL_SELECT_VALUE = {
	NONE: SLEEP_DIARY_BOOL_SELECT_VALUE_NONE,
	TRUE: SLEEP_DIARY_BOOL_SELECT_VALUE_TRUE,
	FALSE: SLEEP_DIARY_BOOL_SELECT_VALUE_FALSE,
};
/** @type {SleepDiaryBoolSelectValue[]} */
export const SLEEP_DIARY_BOOL_SELECT_VALUES = [
	SLEEP_DIARY_BOOL_SELECT_VALUE_NONE,
	SLEEP_DIARY_BOOL_SELECT_VALUE_TRUE,
	SLEEP_DIARY_BOOL_SELECT_VALUE_FALSE,
];

/** @typedef {string} SleepDiaryNumber4Value */
export const SLEEP_DIARY_NUMBER4_VALUE_NONE = '';
export const SLEEP_DIARY_NUMBER4_VALUE_ZERO = 'ZERO';
export const SLEEP_DIARY_NUMBER4_VALUE_ONE = 'ONE';
export const SLEEP_DIARY_NUMBER4_VALUE_TWO = 'TWO';
export const SLEEP_DIARY_NUMBER4_VALUE_THREE = 'THREE';
export const SLEEP_DIARY_NUMBER4_VALUE_FOUR_OR_MORE = 'FOUR_OR_MORE';
/** @enum {SleepDiaryNumber4Value} */
export const SLEEP_DIARY_NUMBER4_VALUE = {
	NONE: SLEEP_DIARY_NUMBER4_VALUE_NONE,
	ZERO: SLEEP_DIARY_NUMBER4_VALUE_ZERO,
	ONE: SLEEP_DIARY_NUMBER4_VALUE_ONE,
	TWO: SLEEP_DIARY_NUMBER4_VALUE_TWO,
	THREE: SLEEP_DIARY_NUMBER4_VALUE_THREE,
	FOUR_OR_MORE: SLEEP_DIARY_NUMBER4_VALUE_FOUR_OR_MORE,
};
/** @type {SleepDiaryNumber4Value[]} */
export const SLEEP_DIARY_NUMBER4_VALUES = [
	SLEEP_DIARY_NUMBER4_VALUE_NONE,
	SLEEP_DIARY_NUMBER4_VALUE_ZERO,
	SLEEP_DIARY_NUMBER4_VALUE_ONE,
	SLEEP_DIARY_NUMBER4_VALUE_TWO,
	SLEEP_DIARY_NUMBER4_VALUE_THREE,
	SLEEP_DIARY_NUMBER4_VALUE_FOUR_OR_MORE, 
];

/** @typedef {string} SleepDiaryNumber10Value */
export const SLEEP_DIARY_NUMBER10_VALUE_NONE = '';
export const SLEEP_DIARY_NUMBER10_VALUE_ZERO = 'ZERO';
export const SLEEP_DIARY_NUMBER10_VALUE_ONE = 'ONE';
export const SLEEP_DIARY_NUMBER10_VALUE_TWO = 'TWO';
export const SLEEP_DIARY_NUMBER10_VALUE_THREE = 'THREE';
export const SLEEP_DIARY_NUMBER10_VALUE_FOUR = 'FOUR';
export const SLEEP_DIARY_NUMBER10_VALUE_FIVE = 'FIVE';
export const SLEEP_DIARY_NUMBER10_VALUE_SIX = 'SIX';
export const SLEEP_DIARY_NUMBER10_VALUE_SEVEN = 'SEVEN';
export const SLEEP_DIARY_NUMBER10_VALUE_EIGHT = 'EIGHT';
export const SLEEP_DIARY_NUMBER10_VALUE_NINE = 'NINE';
export const SLEEP_DIARY_NUMBER10_VALUE_TEN_OR_MORE = 'TEN_OR_MORE';
/** @enum {SleepDiaryNumber10Value} */
export const SLEEP_DIARY_NUMBER10_VALUE = {
	NONE: SLEEP_DIARY_NUMBER10_VALUE_NONE,
	ZERO: SLEEP_DIARY_NUMBER10_VALUE_ZERO,
	ONE: SLEEP_DIARY_NUMBER10_VALUE_ONE,
	TWO: SLEEP_DIARY_NUMBER10_VALUE_TWO,
	THREE: SLEEP_DIARY_NUMBER10_VALUE_THREE,
	FOUR: SLEEP_DIARY_NUMBER10_VALUE_FOUR,
	FIVE: SLEEP_DIARY_NUMBER10_VALUE_FIVE,
	SIX: SLEEP_DIARY_NUMBER10_VALUE_SIX,
	SEVEN: SLEEP_DIARY_NUMBER10_VALUE_SEVEN,
	EIGHT: SLEEP_DIARY_NUMBER10_VALUE_EIGHT,
	NINE: SLEEP_DIARY_NUMBER10_VALUE_NINE,
	TEN_OR_MORE: SLEEP_DIARY_NUMBER10_VALUE_TEN_OR_MORE,
};
/** @type {SleepDiaryNumber10Value[]} */
export const SLEEP_DIARY_NUMBER10_VALUES = [
	SLEEP_DIARY_NUMBER10_VALUE_NONE,
	SLEEP_DIARY_NUMBER10_VALUE_ZERO,
	SLEEP_DIARY_NUMBER10_VALUE_ONE,
	SLEEP_DIARY_NUMBER10_VALUE_TWO,
	SLEEP_DIARY_NUMBER10_VALUE_THREE,
	SLEEP_DIARY_NUMBER10_VALUE_FOUR,
	SLEEP_DIARY_NUMBER10_VALUE_FIVE,
	SLEEP_DIARY_NUMBER10_VALUE_SIX,
	SLEEP_DIARY_NUMBER10_VALUE_SEVEN,
	SLEEP_DIARY_NUMBER10_VALUE_EIGHT,
	SLEEP_DIARY_NUMBER10_VALUE_NINE,
	SLEEP_DIARY_NUMBER10_VALUE_TEN_OR_MORE,
];

/** @typedef {string} SleepDiaryDayPeriod */
export const SLEEP_DIARY_DAY_PERIOD_NONE = 'none';
export const SLEEP_DIARY_DAY_PERIOD_AM = 'AM';
export const SLEEP_DIARY_DAY_PERIOD_PM = 'PM';
/** @enum {SleepDiaryDayPeriod} */
export const SLEEP_DIARY_DAY_PERIOD = {
	NONE: SLEEP_DIARY_DAY_PERIOD_NONE,
	AM: SLEEP_DIARY_DAY_PERIOD_AM,
	PM: SLEEP_DIARY_DAY_PERIOD_PM,
};
/** @enum {SleepDiaryDayPeriod[]} */
export const SLEEP_DIARY_DAY_PERIODS = [
	SLEEP_DIARY_DAY_PERIOD_NONE,
	SLEEP_DIARY_DAY_PERIOD_AM,
	SLEEP_DIARY_DAY_PERIOD_PM,
];

/** @typedef {string} SleepDiaryQuestionType */
export const SLEEP_DIARY_QUESTION_TYPE_BOOL = 'BOOLEAN';
export const SLEEP_DIARY_QUESTION_TYPE_NUMBER4 = 'NUMBER4';
export const SLEEP_DIARY_QUESTION_TYPE_NUMBER10 = 'NUMBER10';
export const SLEEP_DIARY_QUESTION_TYPE_TIME_OF_DAY = 'TIME_OF_DAY';
export const SLEEP_DIARY_QUESTION_TYPE_TIME = 'TIME';
/** @enum {SleepDiaryQuestionType} */
export const SLEEP_DIARY_QUESTION_TYPE = {
	BOOLEAN: SLEEP_DIARY_QUESTION_TYPE_BOOL,
	NUMBER4: SLEEP_DIARY_QUESTION_TYPE_NUMBER4,
	NUMBER10: SLEEP_DIARY_QUESTION_TYPE_NUMBER10,
	TIME_OF_DAY: SLEEP_DIARY_QUESTION_TYPE_TIME_OF_DAY,
	TIME: SLEEP_DIARY_QUESTION_TYPE_TIME,
};
/** @type {SleepDiaryQuestionType[]} */
export const SLEEP_DIARY_QUESTION_TYPES = [
	SLEEP_DIARY_QUESTION_TYPE_BOOL,
	SLEEP_DIARY_QUESTION_TYPE_NUMBER4,
	SLEEP_DIARY_QUESTION_TYPE_NUMBER10,
	SLEEP_DIARY_QUESTION_TYPE_TIME_OF_DAY,
	SLEEP_DIARY_QUESTION_TYPE_TIME,
];

/** @typedef {string} SleepDiaryQuestion */
export const SLEEP_DIARY_QUESTION_PRESCRIPTION_MEDICATION = 'prescriptionMedication';
export const SLEEP_DIARY_QUESTION_ALCOHOL_BEFORE_BEDTIME = 'alcoholBeforeBedtime';
export const SLEEP_DIARY_QUESTION_CAFFEINE_BEFORE_BEDTIME = 'caffeineBeforeBedtime';
export const SLEEP_DIARY_QUESTION_TIME_TURNED_LIGHTS_OUT = 'timeTurnedLightsOut';
export const SLEEP_DIARY_QUESTION_TIME_NEEDED_TO_FALL_ASLEEP = 'timeNeededToFallAsleep';
export const SLEEP_DIARY_QUESTION_WOKEN_UP_NUMBER = 'wokenUpNumber';
export const SLEEP_DIARY_QUESTION_TIME_TOTAL_AWAKE = 'timeTotalAwake';
export const SLEEP_DIARY_QUESTION_TIME_FINAL_AWAKENING = 'timeFinalAwakening';
export const SLEEP_DIARY_QUESTION_TIME_GET_OUT_OF_BED = 'timeGetOutOfBed';
export const SLEEP_DIARY_QUESTION_TIME_ASLEEP_APPROXIMATELY = 'timeAsleepApproximately';
export const SLEEP_DIARY_QUESTION_WEAR_SLEEP_PROFILER = 'wearSleepProfiler';
/* @enum {SleepDiaryQuestion} */
export const SLEEP_DIARY_QUESTION = {
	PRESCRIPTION_MEDICATION: SLEEP_DIARY_QUESTION_PRESCRIPTION_MEDICATION,
	ALCOHOL_BEFORE_BEDTIME: SLEEP_DIARY_QUESTION_ALCOHOL_BEFORE_BEDTIME,
	CAFFEINE_BEFORE_BEDTIME: SLEEP_DIARY_QUESTION_CAFFEINE_BEFORE_BEDTIME,
	TIME_TURNED_LIGHTS_OUT: SLEEP_DIARY_QUESTION_TIME_TURNED_LIGHTS_OUT,
	TIME_NEEDED_TO_FALL_ASLEEP: SLEEP_DIARY_QUESTION_TIME_NEEDED_TO_FALL_ASLEEP,
	WOKEN_UP_NUMBER: SLEEP_DIARY_QUESTION_WOKEN_UP_NUMBER,
	TIME_TOTAL_AWAKE: SLEEP_DIARY_QUESTION_TIME_TOTAL_AWAKE,
	TIME_FINAL_AWAKENING: SLEEP_DIARY_QUESTION_TIME_FINAL_AWAKENING,
	TIME_GET_OUT_OF_BED: SLEEP_DIARY_QUESTION_TIME_GET_OUT_OF_BED,
	TIME_ASLEEP_APPROXIMATELY: SLEEP_DIARY_QUESTION_TIME_ASLEEP_APPROXIMATELY,
	WEAR_SLEEP_PROFILER: SLEEP_DIARY_QUESTION_WEAR_SLEEP_PROFILER,
}
/* @type {SleepDiaryQuestion[]} */
export const SLEEP_DIARY_QUESTIONS = [
	SLEEP_DIARY_QUESTION_PRESCRIPTION_MEDICATION,
	SLEEP_DIARY_QUESTION_ALCOHOL_BEFORE_BEDTIME,
	SLEEP_DIARY_QUESTION_CAFFEINE_BEFORE_BEDTIME,
	SLEEP_DIARY_QUESTION_TIME_TURNED_LIGHTS_OUT,
	SLEEP_DIARY_QUESTION_TIME_NEEDED_TO_FALL_ASLEEP,
	SLEEP_DIARY_QUESTION_WOKEN_UP_NUMBER,
	SLEEP_DIARY_QUESTION_TIME_TOTAL_AWAKE,
	SLEEP_DIARY_QUESTION_TIME_FINAL_AWAKENING,
	SLEEP_DIARY_QUESTION_TIME_GET_OUT_OF_BED,
	SLEEP_DIARY_QUESTION_TIME_ASLEEP_APPROXIMATELY,
	SLEEP_DIARY_QUESTION_WEAR_SLEEP_PROFILER,
];

/** @type {Object<SleepDiaryQuestion, SleepDiaryQuestionType>} */
export const SLEEP_DIARY_QUESTION_TYPE_FOR_QUESTION = {
	[SLEEP_DIARY_QUESTION_PRESCRIPTION_MEDICATION]: SLEEP_DIARY_QUESTION_TYPE.BOOLEAN,
	[SLEEP_DIARY_QUESTION_ALCOHOL_BEFORE_BEDTIME]: SLEEP_DIARY_QUESTION_TYPE.NUMBER4,
	[SLEEP_DIARY_QUESTION_CAFFEINE_BEFORE_BEDTIME]: SLEEP_DIARY_QUESTION_TYPE.NUMBER4,
	[SLEEP_DIARY_QUESTION_TIME_TURNED_LIGHTS_OUT]: SLEEP_DIARY_QUESTION_TYPE.TIME_OF_DAY,
	[SLEEP_DIARY_QUESTION_TIME_NEEDED_TO_FALL_ASLEEP]: SLEEP_DIARY_QUESTION_TYPE.TIME,
	[SLEEP_DIARY_QUESTION_WOKEN_UP_NUMBER]: SLEEP_DIARY_QUESTION_TYPE.NUMBER10,
	[SLEEP_DIARY_QUESTION_TIME_TOTAL_AWAKE]: SLEEP_DIARY_QUESTION_TYPE.TIME,
	[SLEEP_DIARY_QUESTION_TIME_FINAL_AWAKENING]: SLEEP_DIARY_QUESTION_TYPE.TIME_OF_DAY,
	[SLEEP_DIARY_QUESTION_TIME_GET_OUT_OF_BED]: SLEEP_DIARY_QUESTION_TYPE.TIME_OF_DAY,
	[SLEEP_DIARY_QUESTION_TIME_ASLEEP_APPROXIMATELY]: SLEEP_DIARY_QUESTION_TYPE.TIME,
	[SLEEP_DIARY_QUESTION_WEAR_SLEEP_PROFILER]: SLEEP_DIARY_QUESTION_TYPE.BOOLEAN,
};
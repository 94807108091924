import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";

// @note There is no input function since comparison data is only submitted and not read.

/**
 * Get output data from input data
 * @param {StudiesComparisonDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		study1Id: getString(result, 'study1Id'),
		study2Id: getString(result, 'study2Id'),
		interpretingPhysicianName: getString(result, 'firstName'),
		interpretingPhysicianSurname: getString(result, 'lastName'),
		interpretingPhysicianCredentials: getString(result, 'credentials'),

		// Form data properties
		multipartSignatureFile: result.multipartSignatureFile ? result.multipartSignatureFile : '',
	};

	// Set proper empty values expected by the API
	// @note This is commented out since requests that use FormData have backend issues with string values cleared by 
	// null values instead of empty strings.
	//result = resetObjectValues(result);

	return result;
}

import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {UnblockIpDataObject} from "../dataObject"

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @returns {UnblockIpDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new UnblockIpDataObject(
		getString(result, 'id'),
		getString(result, 'securityQuestion')
	);
	return result;
}

/**
 * Get output data from input data
 * @param {UnblockIpDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	
	result = {
		id: result.id,
		securityQuestion: result.securityQuestion,
		securityAnswer: result.securityAnswer
	};
	
	return result;
}
import styles from "../TextInput/index.module.css";
import componentStyles from "./index.module.css";

import React from "react";
import TextInput, {TEXT_INPUT_LABEL_POSITION, executeComponentCallback} from "../TextInput";
import PropTypes from "prop-types";
import {Tooltip} from "react-tippy";
import Icon from "../../display/Icon";
import {icon_font_error_symbol} from "../../../../config";
import {COMMAND_CODES} from "../../../const/global";
import {getTimeString, timeStrCompare} from "../../../helpers/datetime";

/**
 * Time input component
 * @description Text input component that does not update parent component's state on each key press ('fast' component).
 * @note This component uses internal state to handle keypress updates re-rendering only itself until used hits the
 * 'Enter' key or textarea (inputRef) looses focus. This fixes one of the main performance issues in React forms or
 * pages with lost of input fields where each keypress rerender the whole form or page.
 *
 * This component is based on 'TextInput' component.
 */
class TimeInput extends TextInput {
	/**
	 * Calendar wrapper element ref
	 * @type {HTMLElement|null}
	 */
	inputRef = null;
	
	constructor(props) {
		super(props, {
			data: '',
		}, {
			domPrefix: 'time-input-component',
		});

		// Data methods
		this.isPropValueInvalid = this.isPropValueInvalid.bind(this);
		this.isValueInvalid = this.isValueInvalid.bind(this);
		this.getInternalValue = this.getInternalValue.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);
		this.getMinValue = this.getMinValue.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Check if value send through the props is invalid
	 * @return {boolean}
	 */
	isPropValueInvalid() {
		const {value, size, hoursFirst, dayLimit} = this.props;
		const timeStringData = getTimeString(value, size, hoursFirst, dayLimit);
		return (value && !timeStringData);
	}

	/**
	 * Check if value send through the props is invalid
	 * @return {boolean}
	 */
	isValueInvalid() {
		const {size, hoursFirst, dayLimit} = this.props;
		const timeStringData = getTimeString(this.getData(), size, hoursFirst, dayLimit);
		return (this.getData() && !timeStringData);
	}

	/**
	 * Get value that can be used internally by this component
	 *
	 * @param {any} value - Any value.
	 * @return {string|undefined}
	 */
	getInternalValue(value) {
		const {size, hoursFirst, dayLimit} = this.props;
		return getTimeString(value, size, hoursFirst, dayLimit);
	}

	/**
	 * Get max value
	 * @return {string|undefined}
	 */
	getMaxValue() { return this.getInternalValue(this.getProp('maxValue')); }

	/**
	 * Get min value
	 * @return {string|undefined}
	 */
	getMinValue() { return this.getInternalValue(this.getProp('minValue')); }
	
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {string|undefined} Local component's state compatible data or undefined if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		const {size, hoursFirst, dayLimit} = this.props;
		
		// Handle all empty values as undefined
		if (typeof rawData === 'undefined' || rawData === '' || rawData === null) return '';

		// Raw data cannot be parsed as a time string
		if (this.isPropValueInvalid()) return '';
		
		// Raw data is valid
		return getTimeString(rawData, size, hoursFirst, dayLimit);
	}
	
	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {any} Data that will be returned to the parent component.
	 */
	getDataToReturn() { return this.getInternalValue(this.state.data); }
	
	/**
	 * Trigger component's onChange event with component's main data as param
	 *
	 * @param {Event|SyntheticEvent} event - JavaScript change event.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	async update(event) {
		const {fallbackToMinMax} = this.props;
		const value = this.getDataToReturn();
		const maxValue = this.getMaxValue();
		const minValue = this.getMinValue();
		
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();
		
		// Handle max and min values
		if (value && maxValue && timeStrCompare(value, maxValue) === 1) {
			await this.setData((fallbackToMinMax ? maxValue : ''));
		} else if (value && minValue && timeStrCompare(value, minValue) === -1) {
			await this.setData((fallbackToMinMax ? minValue : ''));
		}

		// Properly format internal data before triggering 'onChange' event.
		else await this.setData(value);

		// Update preview
		return this.updatePreview().then(() => {
			// Trigger onChange event
			executeComponentCallback(this.props.onChange, event);
		});
	}
	
	/**
	 * Input element key down handler
	 * @param {KeyboardEvent|SyntheticEvent} event - JavaScript key down event.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleKeyDown(event) {
		const {value} = this.props;
		const stateValue = this.state.value;

		// Prevent input of non digit characters except for ':'
		if (COMMAND_CODES.indexOf(event.nativeEvent.code) === -1 && /[^\d:]/.test(event.key)) event.preventDefault();

		if(event.key === 'Escape') {
			// Stop propagation of the escape key press
			// @description This is done to prevent parent elements from catching the escape key press while this input 
			// element is focused because we explicitly want escape key to be handled by it. For example, this is necessary
			// to prevent closing a dialog, that closes on escape key press, when escape key is pressed on a focused input.
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();

			// If current value is changes but component didn't update (user typed something but didn't press enter or 
			// moved to another element)
			if (stateValue !== value) {
				// Reset value to the original one (props value)
				return this.setData(value);
			}
			// If input have not changed 
			else {
				// Blur the input element
				// @note This will in turn re-enable propagation so that dialogs can close next time escape key is pressed.
				this.inputRef.blur();
				return Promise.resolve({...this.state});
			}
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render input DOM element
	 * @return {JSX.Element}
	 */
	renderInput() {
		const {
			className, name, readOnly, disabled, formControlStyle, isFast, invalidValueAsPlaceholder, showIcon, size
		} = this.props;
		const value = this.getData();
		const propValue = this.getProp('value');
		const maxValue = this.getMaxValue();
		const minValue = this.getMinValue();
		
		// Show error if input value is invalid, too big or too small
		let errors = this.getProp('errors');
		const isPropValueInvalid = this.isPropValueInvalid();
		const isValueInvalid = this.isValueInvalid()
		// Value is invalid
		if (isPropValueInvalid || isValueInvalid) {
			errors = errors.concat([`${this.t('Invalid value')}${isPropValueInvalid ? `: ${propValue}` : ''}`]);
		}
		// Value is too large (uses 'maxValue' prop)
		else if (value && maxValue && timeStrCompare(value, maxValue) === 1) {
			errors = errors.concat([`${this.t('Value too large', 'validation')}`]);
		}
		// Value is too small (uses 'minValue' prop)
		else if (value && minValue && timeStrCompare(value, minValue) === -1) {
			errors = errors.concat([`${this.t('Value too small', 'validation')}`]);
		}
		
		// Get input placeholder
		let placeholder = this.getProp('placeholder');
		if (invalidValueAsPlaceholder && isPropValueInvalid) placeholder = propValue;
		else if (!this.getProp('placeholder')) placeholder = [...Array(size).keys()].map(() => '00').join(':');
		
		return (
			<div className={`input-wrapper time-input-wrapper ${styles['inputWrapper']}`}>
				<input type="text"
					id={this.getDomId()}
					className={
						`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
						`${styles['input']} ${showIcon ? componentStyles['withIcon'] : ''}`
					}
					name={name}
					value={value}
					onInput={!readOnly && !isFast ? this.handleInputChange : null}
					onChange={!readOnly ? this.handleInputChange : null}
					onBlur={!readOnly && isFast ? this.update : null}
					onKeyPress={!readOnly ? this.handleInputEnterPress : null}
					onKeyDown={!readOnly ? this.handleKeyDown : null}
					disabled={disabled}
					placeholder={placeholder}
					readOnly={readOnly}
					ref={node => this.inputRef = node}
				/>
				
				{showIcon ? <Icon symbol="clock-o" className={`input-icon ${componentStyles['clockIcon']}`} /> : null}
				
				{
					errors.length ?
						<Tooltip
							className={styles['errorIcon']}
							tag="span"
							title={errors.join("<br />")}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
							supportHtml={true}
						>
							<Icon symbol={icon_font_error_symbol} />
						</Tooltip>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TimeInput.propTypes = {
	// Input value
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Min. allowed value that can be selected
	minValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Max. allowed value that can be selected
	maxValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Flag that determines if values outside min and max range will automatically fallback to min or max, otherwise 
	// input will be cleared
	fallbackToMinMax: PropTypes.bool,
	// Number of time sections to allow
	// @note Max. is 3 which will display 00:00:00
	size: PropTypes.number,
	// Flag that determines if first part of the time string is in hours
	// @note If 'size' prop is 3 this prop will be irrelevant (hour will be the first part).
	hoursFirst: PropTypes.bool,
	// Flag that determines if hours should be limited to one day (max. 23)
	// @note If 'hoursFirst' prop is false this prop will be ignored.
	dayLimit: PropTypes.bool,
	// Flag that specifies if invalid value will be rendered inside input placeholder
	invalidValueAsPlaceholder: PropTypes.bool,
	// Flag that specifies if clock icon will be shown inside the time input
	// @description This icon does not perform any additional purpose, it cannot be clicked or do any other action. It 
	// will be rendered on the right side of the input.
	showIcon: PropTypes.bool,
	
	...TextInput.propTypes
};

/**
 * Define component default values for own props
 */
TimeInput.defaultProps = {
	id: '',
	className: '',
	name: '',
	value: '',
	minValue: '',
	maxValue: '',
	fallbackToMinMax: true,
	size: 3,
	hoursFirst: true,
	dayLimit: false,
	readOnly: false,
	disabled: false,
	formControlStyle: true,
	isFast: true,
	invalidValueAsPlaceholder: true,
	showIcon: true,

	showLabel: false,
	labelPosition: TEXT_INPUT_LABEL_POSITION.LEFT,
	labelWidth: 30,
	labelClass: '',
	label: '',
	previewAsLabel: false,
	labelDialogOptions: {},
	type: 'text', // @note This is ignored because TimeInput will always use type 'text'.

	errors: [],
};

export * from "../TextInput/const";
export default TimeInput;
export class QuestionnairePatientDataObject {
	/**
	 * @param {string} [patientId=''] - Patient's ID.
	 * @param {string} [patientRefId=''] - Patient's user-friendly reference ID.
	 * @param {string} [firstName=''] - Patient's first name.
	 * @param {string} [middleName=''] - Patient's middle name.
	 * @param {string} [lastName=''] - Patient's last name.
	 * @param {string} [idNumber=''] - Patient's optional ID.
	 * @param {Date} [dateOfBirth=null] - Patient's date of birth (must be older than 18).
	 * @param {Gender} [gender] - Patient's gender.
	 * @param {boolean} [changePatient=false] - Flag that specifies if patient data should be updated.
	 */
	constructor(
		patientId = '', patientRefId = '', firstName = '', middleName = '', lastName = '', idNumber = '', 
		dateOfBirth = null, gender, changePatient = false,
	) {
		this.patientId = patientId;
		this.patientRefId = patientRefId;
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.idNumber = idNumber;
		this.dateOfBirth = dateOfBirth;
		this.gender = gender;
		this.changePatient = changePatient;
	}
}

export class QuestionnaireDataObject extends QuestionnairePatientDataObject {
	/**
	 * @param {string|null} [id=null] - Questionnaire ID. Null is for a new questionnaire.
	 * 
	 * @param {string} [patientId=''] - Patient's ID.
	 * @param {string} [patientRefId=''] - Patient's user-friendly reference ID.
	 * @param {string} [firstName=''] - Patient's first name.
	 * @param {string} [middleName=''] - Patient's middle name.
	 * @param {string} [lastName=''] - Patient's last name.
	 * @param {string} [idNumber=''] - Patient's optional ID.
	 * @param {Date} [dateOfBirth=null] - Patient's date of birth (must be older than 18).
	 * @param {Date} [dateCompleted=null] - Date when the questionnaire was completed.
	 * @param {Gender} [gender] - Patient's gender.
	 * @param {number} [heightFoot] - Patient's height in feet.
	 * @param {number} [heightInch] - Patient's height in inches.
	 * @param {number} [heightCentimeter] - Patient's height in centimeters.
	 * @param {number} [weightPound] - Patient's weight in pounds.
	 * @param {number} [weightKilogram] - Patient's weight in kilograms (required for OSA risk assessment).
	 * @param {number} [neckCircumferenceInch] - Patient's neck circumference in inch (required for OSA risk assessment).
	 * @param {number} [neckCircumferenceCentimeter] - Patient's neck circumference in centimeters (required for OSA risk
	 * assessment).
	 * @param {boolean} [changePatient=false] - Flag that specifies if patient data should be updated.
	 * 
	 * @param {boolean} [highBloodPressure]
	 * @param {boolean} [restlessLeg]
	 * @param {boolean} [narcolepsy]
	 * @param {boolean} [headTrauma]
	 * @param {boolean} [painfulCondition]
	 * @param {boolean} [heartDisease]
	 * @param {boolean} [sleepApnea]
	 * @param {boolean} [depression]
	 * @param {boolean} [stroke]
	 * @param {boolean} [morningHeadaches]
	 * @param {boolean} [diabetes]
	 * @param {boolean} [insomnia]
	 * @param {boolean} [ptsd]
	 * @param {boolean} [neurologicalDisorder]
	 * @param {boolean} [nightSweats]
	 * 
	 * @param {EpworthRadioValue|''} [dozeChanceSittingReading='']
	 * @param {EpworthRadioValue|''} [dozeChanceWatchingTV='']
	 * @param {EpworthRadioValue|''} [dozeChanceSittingPublicPlace='']
	 * @param {EpworthRadioValue|''} [dozeChanceCarPassenger='']
	 * @param {EpworthRadioValue|''} [dozeChanceAfternoonRest='']
	 * @param {EpworthRadioValue|''} [dozeChanceSittingTalking='']
	 * @param {EpworthRadioValue|''} [dozeChanceSittingAfterLunch='']
	 * @param {EpworthRadioValue|''} [dozeChanceCarStopped='']
	 *
	 * @param {InsomniaProblemRadiaValue|''}[difficultyFallingAsleep]
	 * @param {InsomniaProblemRadiaValue|''}[difficultyStayingAsleep]
	 * @param {InsomniaProblemRadiaValue|''}[wakingUpEarly]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaSatisfied]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaNoticable]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaWorried]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaInterfere]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaAlcohol]
	 * @param {InsomniaProblemRadiaValue|''}[insomniaCaffeine]
	 * 
	 * @param {HabitsRadioValue|''} [snoring]
	 * @param {HabitsRadioValue|''} [chokingGasping]
	 * @param {HabitsRadioValue|''} [stopBreathing]
	 * @param {HabitsRadioValue|''} [legsStill]
	 * @param {HabitsRadioValue|''} [vividNightmares]
	 * @param {HabitsRadioValue|''} [prescriptionFallAsleep]
	 * @param {HabitsRadioValue|''} [overCounterFallAsleep]
	 * 
	 * @param {PatientHealthRadioValue} [phqLittleInterestPleasure]
	 * @param {PatientHealthRadioValue} [phqFeelingDepressed]
	 * @param {PatientHealthRadioValue} [phqTroubleFallingToSleep]
	 * @param {PatientHealthRadioValue} [phqTiredLittleEnergy]
	 * @param {PatientHealthRadioValue} [phqPoorAppetiteOvereating]
	 * @param {PatientHealthRadioValue} [phqFeelingBadAboutYourself]
	 * @param {PatientHealthRadioValue} [phqTroubleConcentrating]
	 * @param {PatientHealthRadioValue} [phqMovingSpeakingSlowly]
	 * @param {PatientHealthRadioValue} [phqSuicidalThoughts]
	 * 
	 * @param {GadRadioValue|''} [gadNervousAnxious]
	 * @param {GadRadioValue|''} [gadNonStopWorry]
	 * @param {GadRadioValue|''} [gadToMuchWorryDifferentThings]
	 * @param {GadRadioValue|''} [gadTroubleRelaxing]
	 * @param {GadRadioValue|''} [gadRestlessHardToSitStill]
	 * @param {GadRadioValue|''} [gadEasilyAnnoyedIrritable]
	 * @param {GadRadioValue|''} [gadAfraidSomethingAwful]
	 * 
	 * @param {boolean} [medicationPain]
	 * @param {boolean} [medicationHeartDisease]
	 * @param {boolean} [medicationAntihistamine]
	 * @param {boolean} [medicationHighBloodPressure]
	 * @param {boolean} [medicationAntidepressant]
	 * @param {boolean} [medicationAntianxiety]
	 * @param {boolean} [medicationSteroid]
	 * @param {boolean} [medicationParkinson]
	 * @param {boolean} [medicationStimulant]
	 * @param {boolean} [medicationAmbien]
	 * @param {boolean} [medicationRozerem]
	 * @param {boolean} [medicationIntermezzo]
	 * @param {boolean} [medicationLunesta]
	 * @param {boolean} [medicationSonata]
	 * @param {boolean} [medicationSilenor]
	 * @param {boolean} [medicationHalcion]
	 * @param {boolean} [medicationRestorill]
	 * @param {boolean} [medicationXanax]
	 * 
	 * @param {string} [deviceTypeLabel]
	 */
	constructor(
		id = null, patientId = '', patientRefId = '', firstName = '', middleName = '', 
		lastName = '', idNumber = '', dateOfBirth = null, dateCompleted = new Date(), gender, 
		heightFoot, heightInch, heightCentimeter, weightPound, weightKilogram, 
		neckCircumferenceInch, neckCircumferenceCentimeter, changePatient = false,
		
		highBloodPressure, restlessLeg, narcolepsy, headTrauma, painfulCondition, heartDisease, sleepApnea,	depression, 
		stroke, morningHeadaches, diabetes, insomnia, ptsd, neurologicalDisorder, nightSweats,

		dozeChanceSittingReading = '', dozeChanceWatchingTV = '', dozeChanceSittingPublicPlace = '', 
		dozeChanceCarPassenger = '', dozeChanceAfternoonRest = '',	dozeChanceSittingTalking = '', 
		dozeChanceSittingAfterLunch = '', dozeChanceCarStopped = '', 
		
		difficultyFallingAsleep = '', difficultyStayingAsleep = '', wakingUpEarly = '',
		insomniaSatisfied = '',	insomniaNoticable = '', insomniaWorried = '', 
		insomniaInterfere = '', insomniaAlcohol = '', insomniaCaffeine = '',

		snoring, chokingGasping, stopBreathing, legsStill, 
		vividNightmares, prescriptionFallAsleep, overCounterFallAsleep,

		phqLittleInterestPleasure = '', phqFeelingDepressed = '', phqTroubleFallingToSleep = '',
		phqTiredLittleEnergy = '', phqPoorAppetiteOvereating = '', phqFeelingBadAboutYourself = '',
		phqTroubleConcentrating = '', phqMovingSpeakingSlowly = '', phqSuicidalThoughts = '',

		gadNervousAnxious = '', gadNonStopWorry = '', gadToMuchWorryDifferentThings = '',
		gadTroubleRelaxing = '', gadRestlessHardToSitStill = '', gadEasilyAnnoyedIrritable = '',
		gadAfraidSomethingAwful = '',

		medicationPain, medicationHeartDisease, medicationAntihistamine, medicationHighBloodPressure, 
		medicationAntidepressant, medicationAntianxiety, medicationSteroid, medicationParkinson, medicationStimulant, 
		medicationAmbien, medicationRozerem, medicationIntermezzo, medicationLunesta, medicationSonata, medicationSilenor, 
		medicationHalcion, medicationRestorill, medicationXanax, 
		
		deviceTypeLabel,
	) {
		// Demographic info
		super(patientId, patientRefId, firstName, middleName, lastName, idNumber, dateOfBirth, gender, changePatient);
		this.dateCompleted = dateCompleted;
		this.heightFoot = heightFoot;
		this.heightInch = heightInch;
		this.heightCentimeter = heightCentimeter;
		this.weightPound = weightPound;
		this.weightKilogram = weightKilogram;
		this.neckCircumferenceInch = neckCircumferenceInch;
		this.neckCircumferenceCentimeter = neckCircumferenceCentimeter;
		
		// Diseases and conditions
		this.highBloodPressure = highBloodPressure;
		this.restlessLeg = restlessLeg;
		this.narcolepsy = narcolepsy;
		this.headTrauma = headTrauma;
		this.painfulCondition = painfulCondition;
		this.heartDisease = heartDisease;
		this.sleepApnea = sleepApnea;
		this.depression = depression;
		this.stroke = stroke;
		this.morningHeadaches = morningHeadaches;
		this.diabetes = diabetes;
		this.insomnia = insomnia;
		this.ptsd = ptsd;
		this.neurologicalDisorder = neurologicalDisorder;
		this.nightSweats = nightSweats;
		
		// Epworth
		this.dozeChanceSittingReading = dozeChanceSittingReading;
		this.dozeChanceWatchingTV = dozeChanceWatchingTV; 
		this.dozeChanceSittingPublicPlace = dozeChanceSittingPublicPlace;
		this.dozeChanceCarPassenger = dozeChanceCarPassenger;
		this.dozeChanceAfternoonRest = dozeChanceAfternoonRest;
		this.dozeChanceSittingTalking = dozeChanceSittingTalking; 
		this.dozeChanceSittingAfterLunch = dozeChanceSittingAfterLunch;
		this.dozeChanceCarStopped = dozeChanceCarStopped;
		
		// Sleep problems
		this.difficultyFallingAsleep = difficultyFallingAsleep;
		this.difficultyStayingAsleep = difficultyStayingAsleep;
		this.wakingUpEarly = wakingUpEarly;
		this.insomniaSatisfied = insomniaSatisfied;
		this.insomniaNoticable = insomniaNoticable;
		this.insomniaWorried = insomniaWorried;
		this.insomniaInterfere = insomniaInterfere;
		this.insomniaAlcohol = insomniaAlcohol;
		this.insomniaCaffeine = insomniaCaffeine;
		
		// Habits
		this.snoring = snoring;
		this.chokingGasping = chokingGasping;
		this.stopBreathing = stopBreathing;
		this.legsStill = legsStill;
		this.vividNightmares = vividNightmares;
		this.prescriptionFallAsleep = prescriptionFallAsleep;
		this.overCounterFallAsleep = overCounterFallAsleep;
		
		//Patient Health
		this.phqLittleInterestPleasure = phqLittleInterestPleasure;
		this.phqFeelingDepressed = phqFeelingDepressed;
		this.phqTroubleFallingToSleep = phqTroubleFallingToSleep;
		this.phqTiredLittleEnergy = phqTiredLittleEnergy;
		this.phqPoorAppetiteOvereating = phqPoorAppetiteOvereating;
		this.phqFeelingBadAboutYourself = phqFeelingBadAboutYourself;
		this.phqTroubleConcentrating = phqTroubleConcentrating;
		this.phqMovingSpeakingSlowly = phqMovingSpeakingSlowly;
		this.phqSuicidalThoughts = phqSuicidalThoughts;
		
		// Generalized Anxiety Disorders
		this.gadNervousAnxious = gadNervousAnxious;
		this.gadNonStopWorry = gadNonStopWorry;
		this.gadToMuchWorryDifferentThings = gadToMuchWorryDifferentThings;
		this.gadTroubleRelaxing = gadTroubleRelaxing;
		this.gadRestlessHardToSitStill = gadRestlessHardToSitStill;
		this.gadEasilyAnnoyedIrritable = gadEasilyAnnoyedIrritable;
		this.gadAfraidSomethingAwful = gadAfraidSomethingAwful;

		// Medications
		this.medicationPain = medicationPain;
		this.medicationHeartDisease = medicationHeartDisease;
		this.medicationAntihistamine = medicationAntihistamine;
		this.medicationHighBloodPressure = medicationHighBloodPressure;
		this.medicationAntidepressant = medicationAntidepressant;
		this.medicationAntianxiety = medicationAntianxiety;
		this.medicationSteroid = medicationSteroid;
		this.medicationParkinson = medicationParkinson;
		this.medicationStimulant = medicationStimulant;
		this.medicationAmbien = medicationAmbien;
		this.medicationRozerem = medicationRozerem;
		this.medicationIntermezzo = medicationIntermezzo;
		this.medicationLunesta = medicationLunesta;
		this.medicationSonata = medicationSonata;
		this.medicationSilenor = medicationSilenor;
		this.medicationHalcion = medicationHalcion;
		this.medicationRestorill = medicationRestorill;
		this.medicationXanax = medicationXanax;
		
		this.deviceTypeLabel = deviceTypeLabel;
		this.id = id;
	}
}

export class QuestionnaireResultDataObject {
	/**
	 * @param {string} id - Questionnaire ID.
	 * @param {string} osaRiskMessage - OSA risk info message.
	 * @param {string} reportPath - Path to the generated report
	 */
	constructor(id, osaRiskMessage, reportPath) {
		this.id = id;
		this.osaRiskMessage = osaRiskMessage;
		this.reportPath = reportPath;
	}
}
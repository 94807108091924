/**
 * Data object representing users personal data
 */
export class PersonalDataDataObject {
	/**
	 * 
	 * @param {string} [firstName=''] - Users first name.
	 * @param {string} [middleName=''] - Users middle name.
	 * @param {string} [lastName=''] - Users last name.
	 * @param {string} [email=''] - Users email address.
	 * @param {string} [credentials=''] - Users credentials.
	 */
	constructor(firstName = '', middleName = '', lastName = '', email = '', credentials = '') {
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.email = email;
		this.credentials = credentials;
	}
}
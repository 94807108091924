import React, {useState, useEffect} from "react";
import {i18nEffect, translate, getTranslation} from "Core/i18n";
import {app_id, locale_cookie_group, sidebar_menu_shrank_cookie_group} from "Config/app";

/**
 * Cookie policy for en-US locale
 * @note This will automatically be used in CookiePolicy component (index.js) if en-US is the current locale.
 * There is no need to use this component directly.
 *
 * @param {any} props - Any prop that will be added to the DIV element.
 * @return {JSX.Element}
 * @constructor
 */
function CookiePolicyEnUs(props) {
	const i18nEffectHook = i18nEffect.bind(null, useState(getTranslation())[1]);
	useEffect(() => { i18nEffectHook(); }, [i18nEffectHook]);
	
	return (
		<div {...props}>
			<h2>About cookies</h2>
			<p>When you visit the Sleep Profiler portal, we collect information necessary to ensure it functions properly. These technologies, which include cookies, pixels, web beacons, and gifs, are collectively referred to as “cookies” in this Cookie Management Policy.</p>
			<p>Information on the protection of personal data processed by Sleep Profiler can be found in our <a href={translate('privacy_policy_link', 'App.info')} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and if you have any questions regarding the processing of personal data, please send an inquiry to the e-mail address <a href="mailto:sleepprofiler@advanced-sleep.com">SleepProfiler@advanced-sleep.com</a>.</p>
			<p>By using cookies on our portal, we may collect information about your computer, which may include IP address, browser type, domain name, and access time. The cookies are enabled on the Sleep Profiler site are required for its functional operation.</p>
			
			<h2>What are cookies?</h2>
			<p>Cookies are small text files that are usually downloaded to your computer, tablet or mobile device (hereinafter: “device”) from the websites you visit. Cookies are used to ensure the proper functioning of the website, to provide a better user experience, and also to enable the gathering of data about the site visits and performance and for advanced online advertising.</p>
			
			<h2>Types of cookies</h2>
			<p>The Sleep Profiler portal only uses necessary cookies.</p>
			<p>The types of necessary cookies we employ include:</p>
			<ul>
				<li>
					<strong>Persistent cookies</strong><br />
					Persistent cookies remain stored on your device even after you close your internet browser. With the help of these cookies, the websites store data that facilitate their use. For example, websites that require you to enter a username and password will remember your entry that will show up when you visit next time.
				</li>
				<li>
					<strong>Session cookies</strong><br />
					Session cookies store temporary data that serve to ensure proper functionality of the Sleep Profiler portal.Session cookies are temporary and removed from your device when you close the browser after visiting the Sleep Profiler portal.
				</li>
			</ul>
			<p>We DO NOT employ third party cookies that could be used for analytics or marketing purposes.</p>
			
			<h2>Which cookies does the Sleep Profiler portal use?</h2>
			<p>Cookies used on the Sleep Profiler portal are limited to those Necessary for performance of the site.</p>
			<ul>
				<li>Cookies used to make the site work and cannot be turned off. You can set up your internet browser to notify you of the usage.</li>
				<li>Cookies are used to adjust the user interface when you set the language, font size, or specific version of the site (e.g. high-contrast mode). Once set up, you don’t need to specify your preferences the next time you visit this site.</li>
				<li>Cookies that allow you to go and return to parts of the site without re-authentication.</li>
			</ul>

			<h2>Cookie specification</h2>
			<div className="data-table-component standard">
				<table>
					<thead>
						<tr>
							<th className="column"><div className="content">COOKIE CATEGORY</div></th>
							<th className="column" width="1"><div className="content">COOKIE NAME</div></th>
							<th className="column"><div className="content">COOKIE DESCRIPTION</div></th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_pauseRequests</div></td>
							<td><div className="content">A cookie used to pause system IO requests</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_authTokenData</div></td>
							<td><div className="content">A cookie containing the system data of the logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_refreshingToken</div></td>
							<td><div className="content">A cookie used to authorize a logged in user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_acl</div></td>
							<td><div className="content">A cookie containing current users access control list (ACL)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_currentUser</div></td>
							<td><div className="content">A cookie containing current users system data (username, user type, etc.)</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_userActivity</div></td>
							<td><div className="content">A cookie containing current users system activity data.</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">{app_id}_cookieConsentSettings</div></td>
							<td><div className="content">A cookie that stores information about which cookies are accepted by the user</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${locale_cookie_group}`)}</div></td>
							<td><div className="content">{app_id}_locale</div></td>
							<td><div className="content">A cookie containing the system locale code used to determine the language that will be used</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', `CookieSettings.tab.@cookieGroup:${sidebar_menu_shrank_cookie_group}`)}</div></td>
							<td><div className="content">sidebar_menu_shrank</div></td>
							<td><div className="content">A cookie used to determines the size of the main menu sidebar</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">studies_list_advanced_search_visible</div></td>
							<td><div className="content">A cookie used to determine if advanced study search sidebar will be displayed</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">application_parameters</div></td>
							<td><div className="content">A cookie containing content that will be shown throughout the Sleep Profiler Portal like software version, copyright label, etc.</div></td>
						</tr>
						<tr>
							<td><div className="content">{translate('name', 'CookieSettings.tab.@cookieGroup:necessary')}</div></td>
							<td><div className="content">do_not_show_password_expiration</div></td>
							<td><div className="content">A cookie used to determine if password expiration notice will be shown.</div></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CookiePolicyEnUs;